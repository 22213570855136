import React, { Component } from 'react';
import { connect } from 'react-redux';
// import DashboardView from './dashboardView';
import Header from '../../components/commons/header/headerComponent'

class DashboardComponent extends Component {
  

  render() {

    return (
        <React.Fragment>
            <Header />
            {/* <DashboardView
                handleLogin={this.onHandleLogin}
                success={this.state.isSuccess}
                message={this.state.message} /> */}
        </React.Fragment>
    );
  }
}

const mapStateToProps = (response) => ({response});

export default connect(mapStateToProps)(DashboardComponent);