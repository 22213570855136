import React from 'react';
import ReactToExcel from "react-html-table-to-excel";
import InputField from '../../components/inputfield'
import FieldHeader from '../../components/fieldheader'
import CurrencyFormat from 'react-currency-format'


const CargaPagosOxxoViewFiles = (props) => {
  if (props.list === undefined) {
    return null;
  }
  
  return (
    <div className='pl-3 pt-3'>

    <div className='row'>
        <InputField 
            className='col-12' 
            type='file'
            field='archivopagooxxo'
            accept='*.csv'
            path = 'cargaarchivopagosoxxo'
            value={props.params.filename}
            onChange={props.handleChange}
            onFileUploaded={props.onFileUploadClick}
            />
      </div>
       
      <div>
        <div className='row py-3'>
            <div className=" col-md-2">
              <ReactToExcel 
                table="tabla-certificados" 
                filename="certificados"
                sheet='Certificados'
                buttonText="export"
                />
            </div>
        </div>
      </div>
      <div className='row'>

      <InputField className='col-md-6' type='date' field='fechainicio' value={props.params.fechainicio} onChange={props.handleChange} />
      <InputField className='col-md-6' type='date' field='fechatermino' value={props.params.fechatermino} onChange={props.handleChange} />
        
          
      </div>
      <div className='table-responsive'>
        <table id="tabla-certificados" className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>


              <th><FieldHeader field={'pagosoxxo.fecha'} /></th>
              <th><FieldHeader field={'pagosoxxo.importe'} /></th>
              <th><FieldHeader field={'.accion'} /></th>

            </tr>
          </thead>
          <tbody>
            {props.list
              .map(li => (  
              <tr key={li.id_pagooxxo}>
                <td className='uppercase'>
                    <label >{li.fecha.substring(0,10)}</label>
                </td>
                <td>
                    <label ><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.importe}/></label>
              {(li.registrosfalla!==0)?<p className='alert alert-danger'>Error: ({li.registrosfalla}) - <CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.importefallas}/></p>:''}
                </td>
                <td >
                  <button className="btn btn-outline-dark align-bottom mt-4" 
                        onClick={(e) => props.onPagoSelectClick(e, li.id_pagooxxo)}>
                    <i className="fa fa-arrow-right"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default CargaPagosOxxoViewFiles;