import * as types from '../../actions/prueba/types';


const estadoinicial = []

export default function(state = estadoinicial, action) {
    const response = action.response;
    //console.log('REDUCER...', action, state);
  

  switch(action.type) {
    case types.GET_LISTA_SUCCESS:
      return { ...state, response };
    case types.GET_LISTA_ERROR:
    return { ...state, response };

    default:
      return state;
  }
};

