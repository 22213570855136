import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import FieldHeaderText from '../fieldheadertext'
import FA_Botons from './fa-boton'


export default function Contenedor(props) {
  const { className, to, onClick,  disabled, warning, small, id, mostrar, form} = props;
  
  var classNameBtn = 'btn' 
  if (warning) classNameBtn = classNameBtn + ' btn-warning'; else classNameBtn = classNameBtn + ' btn-primary';
  if (small) classNameBtn = classNameBtn + ' btn-sm'; 
  //if (fa) classFA = 'fa ' + fa + ' p-1'
  if (mostrar===undefined || mostrar === true )
    return (
      <div className={className} >
            {(to)
            ?<Link  className={classNameBtn} disabled={disabled} to={to}>
              <div className='d-flex align-items-center '>
                {(FA_Botons[id])?<li className={FA_Botons[id]}/>:''}
                <FieldHeaderText id={id} />
              </div>
            </Link>
            :<button className={classNameBtn} disabled={disabled} onClick={onClick} form={form}>
              <div className='d-flex align-items-center '>
                {(FA_Botons[id])?<li className={FA_Botons[id]}/>:''}
                <FieldHeaderText id={id} />
              </div>
            </button>}
        
      </div>
    );
  else
    return (
      <div className={className}/>
    );
}




Contenedor.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  small: PropTypes.bool,
  mostrar: PropTypes.bool,
};