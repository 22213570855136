import React from 'react';
import ReactToExcel from "react-html-table-to-excel";
import { Link } from 'react-router-dom';
import InputField from '../../components/inputfield'
import FieldHeader from '../../components/fieldheader'

const ConciliaPagosViewPendiente = (props) => {
  if (props.list === undefined) {
    return null;
  }
  
  return (
    <div className='pl-3 pt-3'>
       
      <div>
        <div className='row py-3'>
            <div className=" col-md-2">
              <ReactToExcel 
                table="tabla-certificados" 
                filename="certificados"
                sheet='Certificados'
                buttonText="export"
                />
            </div>
        </div>
      </div>
      <div className='row'>

        <InputField className='col-md-3' type='date' field='dpscertificadopoliza.fecha' value={props.params.fecha} onChange={props.handleChange} />
        <InputField className='col-md-3' type='text' field='polizas.poliza' value={props.params.poliza} onChange={props.handleChange} />
        <InputField className='col-md-3' type='text' field='certificadospoliza.certificado' value={props.params.certificado} onChange={props.handleChange} />
        <InputField className='col-md-3' type='text' field='certificadospoliza.contratante' value={props.params.contratante} onChange={props.handleChange} />
          
          
      </div>
      <div className='table-responsive'>
        <table id="tabla-certificados" className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>


              <th><FieldHeader field={'certificadospoliza.id_poliza'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.fecha'} /></th>
              <th><FieldHeader field={'certificadospoliza.certificado'} /></th>
              <th><FieldHeader field={'certificadospoliza.contratante'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_producto'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_formapago'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.total'} /></th>
              <th><FieldHeader field={'.accion'} /></th>

            </tr>
          </thead>
          <tbody>
            {props.list
              .sort((a, b) => a.certificado -  b.certificado)
              .sort((a, b) => a.id_poliza  -  b.id_poliza )
              .filter( elem => { return elem.poliza.includes(props.params.poliza) })
              .filter( elem => { return elem.contratante.toUpperCase().includes(props.params.contratante.toUpperCase()) })
              .filter( elem => { return props.params.certificado === '' || elem.certificado === parseInt(props.params.certificado) })
              .map(li => (  
              <tr key={li.id_dpcertificadopoliza}>
              <td>
                    <label >{li.poliza}</label>
              </td>
                <td className='uppercase'>
                    <label >{li.fecha.substring(0,10)}</label>
                </td>
                <td>
                    <label >{li.certificado}</label>
                </td>
                <td>
                  <Link className='name' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.contratante}</Link>
                </td>
                <td className='uppercase'>
                    <label >{li.producto}</label>
                </td>
                <td className='uppercase'>
                    <label >{li.formapago}</label>
                </td>
                <td className='uppercase'>
                    <label >{li.total}</label>
                </td>
                <td >
                  <button className="btn btn-outline-dark align-bottom mt-4" 
                        onClick={(e) => props.onConciliarClick(e, li.id_dpcertificadopoliza)}>
                    <i className="fa fa-arrow-right"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default ConciliaPagosViewPendiente;