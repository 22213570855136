import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    ruta: {
 id_ruta : 0, ruta : '', metodo : '',
      },

      lista_rutarol_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_RUTA:
      item = state.ruta;
      action.fn(item);
      return { ...state,  ruta: item };


    case types.GET_RUTA_SUCCESS:
      respuesta= action.response;
      console.log('GET_RUTA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', ruta: respuesta };

    case types.GET_RUTA_ERROR:
      respuesta= action.response;
      console.log('GET_RUTA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_RUTA_SUCCESS:
      respuesta= action.response;
      console.log('POST_RUTA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', ruta: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_RUTA_ERROR:
      respuesta= action.response;
      console.log('POST_RUTA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_RUTA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_RUTA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', ruta: respuesta };

    case types.PUT_RUTA_ERROR:
      respuesta= action.response;
      console.log('PUT_RUTA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_RUTA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_RUTA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_RUTA_ERROR:
      respuesta= action.response;
      console.log('DELETE_RUTA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_RUTAROL_EN_RUTA:
      item = state.ruta;
      item.rutasrol= [...item.rutasrol, action.payload]
      return { ...state, rutarol:item};


    case types.BORRA_RUTAROL_EN_RUTA:
      item = state.ruta;
      let lista_rutarol_borrados= state.lista_rutarol_borrados
      if (item.rutasrol[action.payload].id_rutarol > 0){
            const elementorutarolborrar = { id_rutarol : item.rutasrol[action.payload].id_rutarol }
            lista_rutarol_borrados= [...lista_rutarol_borrados,  elementorutarolborrar]
      }
      item.rutasrol.splice(action.payload,1)
      return { ...state, rutarol:item, lista_rutarol_borrados : lista_rutarol_borrados};


    default:
      return state;
  }
};

