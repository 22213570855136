import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderComponent from '../../components/commons/header/headerComponent';
//import LoadingComponent from '../commons/loading/loadingComponent'

import ComisionesConciliaView from './comisionesConciliaView';


import { getComsionesAgentesAction, getComisionesAgenteDPsPendientesAction, getComisionesAgenteFacturasAction } from '../../store/actions/comisiones/actions';


class ConciliapagosComponent extends Component {
    state={}
    handleChangeAgentes(event) {
        console.log('handleChangeAgentes antes', this.state);
        let name = event.target.name;
        let params = this.state.paramsagentes;
        params[name]= event.target.value;
        this.setState({
            paramsagentes: params
        });
      }

  onAgenteClick = (event, id) =>{
    event.preventDefault();
    this.props.dispatch(getComisionesAgenteDPsPendientesAction(
        {id_agente:id
        }));
    this.props.dispatch(getComisionesAgenteFacturasAction(
        {id_agente:id
        }));
  }


  componentDidMount() {
    console.log('componentDidMount');
    if (!this.state.cargado){
      this.props.dispatch(getComsionesAgentesAction({  }));
    }
    
  }



  static getDerivedStateFromProps(nextProps, prevState) {
  return nextProps;
}


    render() {
        console.log('Estatdo',this.state);
        return (
            <div>
              <HeaderComponent />
              <div className='row'>
                  <div className='col-12'>
                      <ComisionesConciliaView 
                        params={this.state.paramsagentes}
                        list={this.state.listaagentes} 
                        onAgenteClick={this.onAgenteClick}
                        handleChange={this.handleChangeAgentes.bind(this)}/>
                  </div>
              </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    console.log('COMISIONES',state.comisionesReducer);
    return state.comisionesReducer;
} 
  

export default connect(mapStateToProps)(ConciliapagosComponent);