import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderComponent from '../../components/commons/header/headerComponent';
import LoadingComponent from '../../components/commons/loading/loadingwithheaderComponent'

import DashboardVentasView from './dashboardVentasView';


import { getDashboardVentasAction } from '../../store/actions/dashboards/actions';


class DashboardVentasComponent extends Component {

    state = {
      }


  componentDidMount() {
    console.log('componentDidMount', this.state);
      let anio = parseInt(this.props.match.params.anio,10);
      let mes = parseInt(this.props.match.params.mes,10);
      if (isNaN(anio)) {
         mes = new Date().getMonth()+1;
         anio = new Date().getFullYear();
        }
      if (!this.state.cargado || this.state.anio !== anio || this.state.mes !== mes){
        this.props.dispatch(getDashboardVentasAction({ anio: anio, mes:mes }));
    }
  }



  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerived', nextProps);
    return nextProps.response;
}


    render() {
        console.log('ESTADO...', this.state);
        if (this.state.cargado === false) {
          return (
            <LoadingComponent />
            )
        }

        var lista = this.state.ventas;
        var obj2 = this.state.ventas;
        var order = 'asc';
        var mapped = [];
        mapped = lista.map(function(el, i) {
          return { index: i, value: el.agente.toLowerCase() };
          })
        mapped.sort(function(a, b) {
          if ((a.value < b.value && order === 'desc') || (a.value > b.value && order === 'asc')) { return 1; }
          if ((a.value > b.value && order === 'desc') || (a.value < b.value && order === 'asc')) { return -1; }
          return 0;
          });
        obj2 = mapped.map(function(el){
          return lista[el.index];
          });


        console.log('Estatdo',this.state);
        return (
            <div>
              <HeaderComponent />
              <div className='row'>
                  <div className='col-12'>
                      <DashboardVentasView 
                        params={this.state.paramspendientes}
                        cargado = {this.state.cargado}
                        meses= {this.state.meses}
                        anio = {this.state.anio}
                        mes = {this.state.mes}
                        periodo = {this.state.periodo}
                        list={obj2} />
                  </div>
              </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => {

    console.log('mapStateToProps call',state);
    return (
    {
    response: state.dashboardsReducer.dashventas
  });
  
} 
  

export default connect(mapStateToProps)(DashboardVentasComponent);