import React from 'react';
import { Link } from 'react-router-dom';
//import ReactToExcel from "react-html-table-to-excel";
import CurrencyFormat from 'react-currency-format'
import Mailto from 'react-protected-mailto'

const ActualizaPolizaViewListaCertificados = (props) => {
  if (props.list === undefined) {
    return null;
  }
  console.log('Propiedades', props);
  return (
    <div className='pl-3 pt-3'>
       
       {props.list.map((cert) => (  
            <div key={cert.certificados[0].id_referencia} >
                <div className='row pt-2  bg-primary text-white'>
                    <div className='col-3 h2'>
                        {cert.referencia } ( {cert.cantidad} )
                    </div>
                    <div className='col-3 h2'>
                        {(cert.certificados[0].id_referencia===1)?<div className='col-3 h2'>
                        $<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={cert.importe}/>
                        </div>:''}
                    </div>
                    
                </div>

                <div className='table-responsive'>
                    <table id="tabla-certificados" className='table table-bordered'>
                    <thead className='bg-secondary'>
                        <tr>
                        <th>Poliza</th>
                        <th>Certificado</th>
                        <th>Contratante</th>
                        <th>Producto</th>
                        <th>Agente</th>
                        <th>Último pago</th>
                        <th>Siguiente pago</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cert.certificados.map(li => (  
                        <tr key={li.id_certificadopoliza}>
                            <td className='uppercase'>
                                <label >{li.poliza}</label><br/>
                                <label >{li.fechainicio.substring(0,10)}</label>
                            </td>
                            <td>
                            <Link className='name uppercase' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.certificado}</Link>
                            </td>
                            <td>
                            <Link className='name' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.contratante}</Link>
                            
                            {(li.contratante!==li.aseguradotitular)?<div><label >{li.aseguradotitular}</label></div>:''}
                            <div><Mailto email={li.c_correoelectronico1}
                                                    headers={
                                                        {
                                                            subject:'Recordatorio de pago',
                                                            cc:li.c_correoelectronico2,
                                                            
                                                        }   
                                                    } /></div>
                            </td>
                            <td className='uppercase'>
                                <label >{li.producto}</label><br/>
                                <label >{li.formapago}</label>
                            </td>
                            <td className='uppercase'>
                                <label >{li.agente}</label><br/>
                                <label >{li.correoagente}</label>
                            </td>
                            <td className='uppercase'>
                                {(li.totalultimopago > 0)?<div>
                                    <div className='text-right'>
                                        <CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.totalultimopago}/>
                                    </div>
                                    <div className='text-right'>
                                        {li.fechaultimopago.substring(0,10)}
                                    </div>
                                    
                                    </div>:''}
                                    
                                
                            </td>
                            <td className='uppercase'>
                                {(li.id_estatuspago === 1)?<div>{li.totalsiguientepago}<br/>{li.fechasiguientepago.substring(0,10)}</div>:''}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
        ))}                 
    </div>
  );
};

export default ActualizaPolizaViewListaCertificados;