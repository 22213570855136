import * as types from './types';


export const getConciliaPagosPagadoAction = (data) => {
    return {
      type: types.GET_CONCILIAPAGOS_PAGADO,
      data
    }
  };



  export const getConciliaPagosPendienteAction = (data) => {
    return {
      type: types.GET_CONCILIAPAGOS_PENDIENTE,
      data
    }
  };

  export const postConciliaPagoAction = (data) => {
    return {
      type: types.POST_CONCILIAPAGO,
      data
    }
  };


  export const deleteConciliaPagoAction = (data) => {
    return {
      type: types.DELETE_CONCILIAPAGO,
      data
    }
  };

