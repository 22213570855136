import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    producto: {
 id_producto : 0, producto : '', id_aseguradora : 0, id_tipoproducto : 0, primastope : 0,
      },

      lista_productopoliza_borrados : [],
      lista_detallelistadeprecio_borrados : [],
      lista_certificadopoliza_borrados : [],
      lista_aseguradoproducto_borrados : [],
      lista_dpcertificadopoliza_borrados : [],
      lista_dpproductopoliza_borrados : [],
      lista_primaproductopoliza_borrados : [],
      lista_coberturaproducto_borrados : [],
      lista_productoclavecomision_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_PRODUCTO:
      item = state.producto;
      action.fn(item);
      return { ...state,  producto: item };


    case types.GET_PRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('GET_PRODUCTO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', producto: respuesta };

    case types.GET_PRODUCTO_ERROR:
      respuesta= action.response;
      console.log('GET_PRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_PRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('POST_PRODUCTO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', producto: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_PRODUCTO_ERROR:
      respuesta= action.response;
      console.log('POST_PRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_PRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_PRODUCTO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', producto: respuesta };

    case types.PUT_PRODUCTO_ERROR:
      respuesta= action.response;
      console.log('PUT_PRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_PRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_PRODUCTO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_PRODUCTO_ERROR:
      respuesta= action.response;
      console.log('DELETE_PRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_PRODUCTOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      item.productospoliza= [...item.productospoliza, action.payload]
      return { ...state, productopoliza:item};


    case types.BORRA_PRODUCTOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      let lista_productopoliza_borrados= state.lista_productopoliza_borrados
      if (item.productospoliza[action.payload].id_productopoliza > 0){
            const elementoproductopolizaborrar = { id_productopoliza : item.productospoliza[action.payload].id_productopoliza }
            lista_productopoliza_borrados= [...lista_productopoliza_borrados,  elementoproductopolizaborrar]
      }
      item.productospoliza.splice(action.payload,1)
      return { ...state, productopoliza:item, lista_productopoliza_borrados : lista_productopoliza_borrados};

 
    case types.INSERTA_DETALLELISTADEPRECIO_EN_PRODUCTO:
      item = state.producto;
      item.detalleslistadeprecio= [...item.detalleslistadeprecio, action.payload]
      return { ...state, detallelistadeprecio:item};


    case types.BORRA_DETALLELISTADEPRECIO_EN_PRODUCTO:
      item = state.producto;
      let lista_detallelistadeprecio_borrados= state.lista_detallelistadeprecio_borrados
      if (item.detalleslistadeprecio[action.payload].id_detallelistadeprecio > 0){
            const elementodetallelistadeprecioborrar = { id_detallelistadeprecio : item.detalleslistadeprecio[action.payload].id_detallelistadeprecio }
            lista_detallelistadeprecio_borrados= [...lista_detallelistadeprecio_borrados,  elementodetallelistadeprecioborrar]
      }
      item.detalleslistadeprecio.splice(action.payload,1)
      return { ...state, detallelistadeprecio:item, lista_detallelistadeprecio_borrados : lista_detallelistadeprecio_borrados};

 
    case types.INSERTA_CERTIFICADOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      item.certificadospoliza= [...item.certificadospoliza, action.payload]
      return { ...state, certificadopoliza:item};


    case types.BORRA_CERTIFICADOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      let lista_certificadopoliza_borrados= state.lista_certificadopoliza_borrados
      if (item.certificadospoliza[action.payload].id_certificadopoliza > 0){
            const elementocertificadopolizaborrar = { id_certificadopoliza : item.certificadospoliza[action.payload].id_certificadopoliza }
            lista_certificadopoliza_borrados= [...lista_certificadopoliza_borrados,  elementocertificadopolizaborrar]
      }
      item.certificadospoliza.splice(action.payload,1)
      return { ...state, certificadopoliza:item, lista_certificadopoliza_borrados : lista_certificadopoliza_borrados};

 
    case types.INSERTA_ASEGURADOPRODUCTO_EN_PRODUCTO:
      item = state.producto;
      item.aseguradosproducto= [...item.aseguradosproducto, action.payload]
      return { ...state, aseguradoproducto:item};


    case types.BORRA_ASEGURADOPRODUCTO_EN_PRODUCTO:
      item = state.producto;
      let lista_aseguradoproducto_borrados= state.lista_aseguradoproducto_borrados
      if (item.aseguradosproducto[action.payload].id_aseguradoproducto > 0){
            const elementoaseguradoproductoborrar = { id_aseguradoproducto : item.aseguradosproducto[action.payload].id_aseguradoproducto }
            lista_aseguradoproducto_borrados= [...lista_aseguradoproducto_borrados,  elementoaseguradoproductoborrar]
      }
      item.aseguradosproducto.splice(action.payload,1)
      return { ...state, aseguradoproducto:item, lista_aseguradoproducto_borrados : lista_aseguradoproducto_borrados};

 
    case types.INSERTA_DPCERTIFICADOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      item.dpscertificadopoliza= [...item.dpscertificadopoliza, action.payload]
      return { ...state, dpcertificadopoliza:item};


    case types.BORRA_DPCERTIFICADOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      let lista_dpcertificadopoliza_borrados= state.lista_dpcertificadopoliza_borrados
      if (item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza > 0){
            const elementodpcertificadopolizaborrar = { id_dpcertificadopoliza : item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza }
            lista_dpcertificadopoliza_borrados= [...lista_dpcertificadopoliza_borrados,  elementodpcertificadopolizaborrar]
      }
      item.dpscertificadopoliza.splice(action.payload,1)
      return { ...state, dpcertificadopoliza:item, lista_dpcertificadopoliza_borrados : lista_dpcertificadopoliza_borrados};

 
    case types.INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      item.dpsproductopoliza= [...item.dpsproductopoliza, action.payload]
      return { ...state, dpproductopoliza:item};


    case types.BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      let lista_dpproductopoliza_borrados= state.lista_dpproductopoliza_borrados
      if (item.dpsproductopoliza[action.payload].id_dpproductopoliza > 0){
            const elementodpproductopolizaborrar = { id_dpproductopoliza : item.dpsproductopoliza[action.payload].id_dpproductopoliza }
            lista_dpproductopoliza_borrados= [...lista_dpproductopoliza_borrados,  elementodpproductopolizaborrar]
      }
      item.dpsproductopoliza.splice(action.payload,1)
      return { ...state, dpproductopoliza:item, lista_dpproductopoliza_borrados : lista_dpproductopoliza_borrados};

 
    case types.INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      item.primasproductopoliza= [...item.primasproductopoliza, action.payload]
      return { ...state, primaproductopoliza:item};


    case types.BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO:
      item = state.producto;
      let lista_primaproductopoliza_borrados= state.lista_primaproductopoliza_borrados
      if (item.primasproductopoliza[action.payload].id_primaproductopoliza > 0){
            const elementoprimaproductopolizaborrar = { id_primaproductopoliza : item.primasproductopoliza[action.payload].id_primaproductopoliza }
            lista_primaproductopoliza_borrados= [...lista_primaproductopoliza_borrados,  elementoprimaproductopolizaborrar]
      }
      item.primasproductopoliza.splice(action.payload,1)
      return { ...state, primaproductopoliza:item, lista_primaproductopoliza_borrados : lista_primaproductopoliza_borrados};

 
    case types.INSERTA_COBERTURAPRODUCTO_EN_PRODUCTO:
      item = state.producto;
      item.coberturasproducto= [...item.coberturasproducto, action.payload]
      return { ...state, coberturaproducto:item};


    case types.BORRA_COBERTURAPRODUCTO_EN_PRODUCTO:
      item = state.producto;
      let lista_coberturaproducto_borrados= state.lista_coberturaproducto_borrados
      if (item.coberturasproducto[action.payload].id_coberturaproducto > 0){
            const elementocoberturaproductoborrar = { id_coberturaproducto : item.coberturasproducto[action.payload].id_coberturaproducto }
            lista_coberturaproducto_borrados= [...lista_coberturaproducto_borrados,  elementocoberturaproductoborrar]
      }
      item.coberturasproducto.splice(action.payload,1)
      return { ...state, coberturaproducto:item, lista_coberturaproducto_borrados : lista_coberturaproducto_borrados};

 
    case types.INSERTA_PRODUCTOCLAVECOMISION_EN_PRODUCTO:
      item = state.producto;
      item.productosclavecomision= [...item.productosclavecomision, action.payload]
      return { ...state, productoclavecomision:item};


    case types.BORRA_PRODUCTOCLAVECOMISION_EN_PRODUCTO:
      item = state.producto;
      let lista_productoclavecomision_borrados= state.lista_productoclavecomision_borrados
      if (item.productosclavecomision[action.payload].id_productoclavecomision > 0){
            const elementoproductoclavecomisionborrar = { id_productoclavecomision : item.productosclavecomision[action.payload].id_productoclavecomision }
            lista_productoclavecomision_borrados= [...lista_productoclavecomision_borrados,  elementoproductoclavecomisionborrar]
      }
      item.productosclavecomision.splice(action.payload,1)
      return { ...state, productoclavecomision:item, lista_productoclavecomision_borrados : lista_productoclavecomision_borrados};


    default:
      return state;
  }
};

