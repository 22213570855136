import { put, call } from 'redux-saga/effects';
import {
    postAbrirCertificadoPolizaService,
    postCerrarCertificadoPolizaService,
    postCancelarCertificadoPolizaService,
    postActivarCertificadoPolizaService,
    postBorrarCertificadoPolizaService,
} from '../../services/procesos/certificadospolizaServices';

import * as types from '../../actions/db/types'
import * as procesostypes from '../../actions/procesos/types'



  export function* postAbrirCertificadoPolizaSaga(payload) {
    try {
  
      let response = yield call(postAbrirCertificadoPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const certificadopoliza = {id : payload.id } 
      const accion = { type: types.GET_CERTIFICADOPOLIZA, id:certificadopoliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postCerrarCertificadoPolizaSaga(payload) {
    try {
  
      let response = yield call(postCerrarCertificadoPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const certificadopoliza = {id : payload.id } 
      const accion = { type: types.GET_CERTIFICADOPOLIZA, id:certificadopoliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }


  export function* postCancelarCertificadoPolizaSaga(payload) {
    try {
  
      let response = yield call(postCancelarCertificadoPolizaService, payload.id, payload.id_motivocancelacion);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const certificadopoliza = {id : payload.id } 
      const accion = { type: types.GET_CERTIFICADOPOLIZA, id:certificadopoliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postActivarCertificadoPolizaSaga(payload) {
    try {
  
      let response = yield call(postActivarCertificadoPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const certificadopoliza = {id : payload.id } 
      const accion = { type: types.GET_CERTIFICADOPOLIZA, id:certificadopoliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postBorrarCertificadoPolizaSaga(payload) {
    try {
      let accion = {}
      let response = yield call(postBorrarCertificadoPolizaService, payload.id);
      if (response.error !== undefined){
        response = {...response, success:false, message:response.error}
        accion = { type: procesostypes.BORRAR_CERTIFICADOPOLIZA_ERROR, response };
      }
      else{
        response = {...response, success:true, message:''}
        accion = { type: procesostypes.BORRAR_CERTIFICADOPOLIZA_SUCCESS, response };
      }
      //const poliza = { id: payload.id } 
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }