import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderComponent from '../../components/commons/header/headerComponent';
import LoadingComponent from '../../components/commons/loading/loadingComponent'

//import { getFormasPagoAction, getEstatusPolizasAction } from '../../store/actions/db/actions';
import {  getPolizasAction } from '../../store/actions/db/actions';
import { getCookie } from '../../utils/cookies';
import ListaPolizasView from './listaPolizasView';

class ListaPolizasComponent extends Component {
  state={}

  componentDidMount() {
    //this.props.dispatch(getEstatusPolizasAction());
    //this.props.dispatch(getFormasPagoAction());
    console.log('Lista',this.state);
    if (this.state.cargado===false) this.props.dispatch(getPolizasAction());
  }


  onSortHandle = (event) => {
    event.preventDefault();
    
    const sortBy = event.target.innerHTML.toLowerCase();

    if (this.state.data.order === 'asc') {
      this.setState({
        data: {
          id: getCookie('id'),
          access: getCookie('role'),
          order: 'desc',
          sortBy: sortBy
        }
      });
    } else {
      this.setState({
        data: {
          id: getCookie('id'),
          access: getCookie('role'),
          order: 'asc',
          sortBy: sortBy
        }
      });
    }

    //this.props.dispatch(getPolizasAction(this.state.data));
  }

  static getDerivedStateFromProps(nextProps, prevState) {

      return nextProps;
  }

  render() {
      console.log('Listado de polizas', this.state);
      if (this.state.cargado === false) {
        return (
          <LoadingComponent />
          )
      }
      var lista = this.state.polizas.lista;
      var obj2 = this.state.polizas.lista;
      var order = this.state.data.order;
      var mapped = [];
      console.log(this.state.data.sortBy);
      if (this.state.data.sortBy === 'poliza') {
        mapped = lista.map(function(el, i) {
          return { index: i, value: el.poliza.toLowerCase() };
          })
      }
      if (this.state.data.sortBy === 'descripcion') {
          mapped = lista.map(function(el, i) {
            return { index: i, value: el.descripcion.toLowerCase() };
            })
      }

      if (this.state.data.sortBy === 'fecha' ) {
        mapped = lista.map(function(el, i) {
          return { index: i, value: el.fechainicio.substring(0,10) };
          })
    }
      
      mapped.sort(function(a, b) {
        if ((a.value < b.value && order === 'desc') || (a.value > b.value && order === 'asc')) { return 1; }
        if ((a.value > b.value && order === 'desc') || (a.value < b.value && order === 'asc')) { return -1; }
        return 0;
        });
      obj2 = mapped.map(function(el){
        return lista[el.index];
       });

      console.log(obj2);
    return (
      <div>
        <HeaderComponent />
        <ListaPolizasView
          list={obj2}
          onSortHandle = {this.onSortHandle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.polizasReducer
};

export default connect(mapStateToProps)(ListaPolizasComponent
);