import React from 'react';

import './polizas.css';
//import ProdutosComboBox from '../../components/comboboxes/productos'
import InputField from '../../components/inputfield'
import Lookup from '../../components/lookups'

const ActualizaPolizaViewListaProductos = (props) => {
  if (props.list === undefined) {
    return null;
  }

  return (
    <div className='pt-3'>

      <div className='row'>
        <InputField type='combobox' className='col-4' 
                    field='productospoliza.id_producto' 
                    value={props.id_producto}
                    onChange={props.handleChange}/>
                    
        <div className=" col-1">
          <button className="btn btn-outline-dark align-bottom mt-4 " onClick={props.onAgregaProducto}>
            <i className="fa fa-plus"></i></button>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
      <div className='table-responsive'>
        <table className='table table-bordered'>
          <thead className='bg-secondary'>
          </thead>
          <tbody>
            {props.list.map((li,index) => (  
              <tr key={index}>
                <td >
                <Lookup field='productospoliza.id_producto' value = {li.id_producto} />
                </td>
                <td >
                  <button className="btn btn-outline-dark align-bottom mt-4" 
                        onClick={(e) => props.onBorraProducto(e, index)}>
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      </div>

    </div>
  );
};

export default ActualizaPolizaViewListaProductos;