export const GET_CONCILIAPAGOS_PENDIENTE = 'GET_CONCILIAPAGOS_PENDIENTE';
export const GET_CONCILIAPAGOS_PENDIENTE_SUCCESS = 'GET_CONCILIAPAGOS_PENDIENTE_SUCCESS';
export const GET_CONCILIAPAGOS_PENDIENTE_ERROR = 'GET_CONCILIAPAGOS_PENDIENTE_ERROR';


export const GET_CONCILIAPAGOS_PAGADO = 'GET_CONCILIAPAGOS_PAGADO';
export const GET_CONCILIAPAGOS_PAGADO_SUCCESS = 'GET_CONCILIAPAGOS_PAGADO_SUCCESS';
export const GET_CONCILIAPAGOS_PAGADO_ERROR = 'GET_CONCILIAPAGOS_PAGADO_ERROR';


export const POST_CONCILIAPAGO = 'POST_CONCILIAPAGO';
export const POST_CONCILIAPAGO_SUCCESS = 'POST_CONCILIAPAGO_SUCCESS';
export const POST_CONCILIAPAGO_ERROR = 'POST_CONCILIAPAGO_ERROR';

export const DELETE_CONCILIAPAGO = 'DELETE_CONCILIAPAGO';
export const DELETE_CONCILIAPAGO_SUCCESS = 'DELETE_CONCILIAPAGO_SUCCESS';
export const DELETE_CONCILIAPAGO_ERROR = 'DELETE_CONCILIAPAGO_ERROR';
