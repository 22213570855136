import React from 'react';
import Modal from 'react-modal'
import { connect } from 'react-redux';
import {getBuscarContratanteAction} from '../../store/actions/buscar/actions'
const ActualizaCertificadoView = (props) => {
    Modal.setAppElement('#root')

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        maxHeight             : '50%'
      }
    };
    
    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {

        let concepto = props.value;
        const data = {concepto}
        props.getBuscarContratanteAction(data);
        setIsOpen(true);

    }

    function selecciona(li) {
        props.handleSelect(li);
        setIsOpen(false);
    }
  
    function afterOpenModal() {
        
    }
  
    function closeModal(){
      setIsOpen(false);
    }
    return (
        <div>
        <button className='btn btn-primary' onClick={openModal}>Buscar</button>
        <Modal
          isOpen={modalIsOpen && props.listacontratantes.length > 0}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>Contratantes</h2>
          <div className='container overflow-auto '>
            {props.listacontratantes.map((li,index)=>{
                return (<div key={index} className='row'>
                <button type='link' className='btn btn-sm w-100 text-left btn-outline-primary mb-1' onClick={() => selecciona(li)} >{li.contratante} ({li.c_domicilio})</button>
                </div>)
             })  }

          </div>

        </Modal>
      </div>
    )

}


const mapDispatchToProps = {
          getBuscarContratanteAction,
} 
const mapStateToProps = (state) => {
    console.log('state', state);
    return {
      listacontratantes:state.buscar.listacontratantes
     };
   };
   

export default connect(mapStateToProps,mapDispatchToProps)(ActualizaCertificadoView);