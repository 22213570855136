import React from 'react';
import LoadingComponent from '../../components/commons/loading/loadingComponent'
import CurrencyFormat from 'react-currency-format'
import Mailto from 'react-protected-mailto'
import Boton from '../../components/buttons/boton'
import SiguienteAnterior from '../../components/commons/siguienteanterior/siguienteanteriorComponent'
//import './seguros.css';

const DashboardPagosView = (props) => {
  console.log(DashboardPagosView,props);
  if (props.list === undefined) {
    return <LoadingComponent />;
  }

  if (props.list.length === 0) {
    return <div className='p-4' >
        <SiguienteAnterior anio={props.anio} mes={props.mes} liga='/dashboard/pagos/' />
        <div className='seguros-none'>No se encontraron pagos.</div>
    </div>
  }
  return (
    <div className='p-4' >
        
        <SiguienteAnterior anio={props.anio} mes={props.mes} liga='/dashboard/pagos/' />
              <button className="btn btn-primary mr-1" onClick={props.onPorPolizaClick}>Por póliza</button>
              <button className="btn btn-primary mr-1" onClick={props.onPorAgenteClick}>Por agente</button>
              <Boton className="btn mr-1" id='correoagentes' onClick={props.onCorreoAgentesClick} />
            <p>
              <button className="btn btn-secondary mr-1" type="button" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Mostrar detalle</button>
              <button className="btn btn-primary ml-5 mr-1" onClick={props.onPagadoClick}>Pagado</button>
              <button className="btn btn-primary mr-1" onClick={props.onCanceladoClick}>Cancelado</button>
              <button className="btn btn-primary mr-1" onClick={props.onCXCClick}>Pendiente</button>
              <button className="btn btn-primary mr-1" onClick={props.onTodoClick}>Todo</button>
            </p>
          {props.list.filter(li=>{
                        return (
                            (props.filtro === '') ||
                            (props.filtro === 'Pagado' && li.pagado >0) ||
                            (props.filtro === 'CXC' && li.cxc > 0) ||
                            (props.filtro === 'Cancelado' && li.cancelado > 0) 
                        )
                    })
            .map((pol) => (  
            <div key={(props.vista===''||props.vista==='PorPoliza')?pol.pagos[0].id_poliza:pol.pagos[0].id_agente} >
                  <div className='row pt-2  bg-light border border-primary'>

                  {(props.vista===''||props.vista==='PorPoliza')
                      ?<div className='col-6'>
                            <div className='row '>
                              <div className='col-12 h2'>{pol.grupo }</div>
                              <div className='col-12 h5'>{pol.pagos[0].descripcionpoliza}</div>
                            </div>
                        </div>
                      :<div className='col-6'>
                        <div className='row '>
                          <div className='col-12 h2'>{pol.pagos[0].agente }</div>
                          <div className='col-12 h5'>
                            <Mailto email={pol.pagos[0].correoagente}
                                headers={
                                    {
                                        subject:'Taffeit, estatus de pagos.',
                                        cc:'',
                                        body:'Estimad@ agente, \r\nPor medio del presente le informamos el estatus del pago de pólizas. \r\n \r\nEstamos a sus órdenes para cualquier duda al respecto.\r\n \r\nAtte: Taffeit Agentes de Seguros'
                                    }   
                                } />
                          </div>
                          <div className='col-12 h5'>{pol.pagos[0].telefonoagente}</div>
                          <div className='col-12 h5'>
                            <Boton className="col-12 "  id='correoagente' onClick={(e) => props.onCorreoAgenteClick(e, pol.pagos[0].id_agente)}/>
                          </div>
                        </div>
                      </div>}

                  
                    <div className='col-6'>
                      <div className='row '>
                          <div className='col-12'>
                              <div className='row  text-white'>
                                <div className='col-4 text-center bg-primary'>Pendiente</div>
                                <div className='col-4 text-center bg-primary'>Cancelado</div>
                                <div className='col-4 text-center bg-primary'>Pagado</div>
                              </div>
                              <div className='row '>
                                <div className='col-4 border border-primary text-center '><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={pol.cxc}/></div>
                                <div className='col-4 border border-primary text-center '><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={pol.cancelado}/></div>
                                <div className='col-4 border border-primary text-center '><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={pol.pagado}/></div>
                              </div>
                              <div className='row '>
                                <div className='col-4 border border-primary text-center '><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={pol.c_cxc}/></div>
                                <div className='col-4 border border-primary text-center '><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={pol.c_cancelado}/></div>
                                <div className='col-4 border border-primary text-center '><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={pol.c_pagado}/></div>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>
                  <div className='row pb-5 bg-light '>
                    <div className='col-12 collapse multi-collapse'>
                      <div className='row bg-primary'>
                          <div className='col-1 text-white'>
                              Cert
                          </div>
                          <div className='col-11'>
                              <div className='row border-bottom border-primary text-white'>
                                <div className='col-4'>Contratante</div>
                                <div className='col-3'>Correo</div>
                                <div className='col-1 text-right'>Pendiente</div>
                                <div className='col-1 text-right'>Cancelado</div>
                                <div className='col-1 text-right'>Pagado</div>
                                <div className='col-2'>Fecha</div>

                                <div className='col-4'></div>
                                <div className='col-3'></div>
                                <div className='col-1 text-right'></div>
                                <div className='col-1 text-right'></div>
                                <div className='col-1 text-right'></div>
                                <div className='col-2'>Medio</div>
                                  
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className='col-12 collapse multi-collapse'>
                    {pol.pagos.filter(li=>{
                        return (
                            (props.filtro === '') ||
                            (props.filtro === 'Pagado' && li.id_estatuspago === 2) ||
                            (props.filtro === 'CXC' && li.id_estatuspago === 1) ||
                            (props.filtro === 'Cancelado' && li.id_estatuspago === 3) 
                        )
                    })
                    .map(li => (  
                      <div key={li.id_dpcertificadopoliza} >
                          <div className='row'>
                            <div className='col-1 bg-primary text-white'>
                                {(props.vista===''||props.vista==='PorPoliza')?li.certificado:<div className='small'>{li.poliza} ({li.certificado})</div>}
                            </div>
                            <div className='col-11'>
                                <div className='row border border-primary'>
                                    <div className='col-4'>{li.contratante}</div><div className='col-3'>{(li.c_correoelectronico1 !== '')?(
                                        <Mailto email={li.c_correoelectronico1}
                                        headers={
                                            {
                                                subject:'Recordatorio de pago',
                                                cc:li.c_correoelectronico2,
                                                body:'Hola, \r\nPor medio del presente le recordamos que tiene un pago pendiente de su seguro de Taffeit, el cuál, deberá ser liquidado antes de su fecha de vencimiento. \r\n \r\n Estamos a sus órdenes para cualquier duda al respecto.\r\n \r\n Atte: Taffeit Agentes de Seguros'
                                            }   
                                        } />) :''} </div>
                                    <div className='col-1 text-right'>{(li.id_estatuspago===1)?(<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.total}/>):''}</div>
                                    <div className='col-1 text-right'>{(li.id_estatuspago===3)?(<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.total}/>):''}</div>
                                    <div className='col-1 text-right border-left border-primary'>{(li.id_estatuspago===2)?(<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.total}/>):''}</div>
                                    <div className='col-2 border-right border-primary'>{(li.id_estatuspago===2)?li.fechapago.substr(0,10):''}</div>
                                    
                                    <div className='col-4'><br/></div>
                                    <div className='col-3'>{li.c_telefono}</div>
                                    <div className='col-1 text-right'></div>
                                    <div className='col-1 text-right'></div>
                                    <div className='col-1 border-left border-primary'></div>
                                    <div className='col-2 border-right border-primary'>{(li.id_estatuspago===2)?li.mediopago:''}</div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                      </div>
                      ))}
                      


                    </div>
                  </div>
              </div>
          ))}
    </div>
  );
};

export default DashboardPagosView;