import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';


export const getCargaPagosOxxoService = (request) => {
  const apiURL = baseurl('cargapagosoxxopagos');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.payload)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};

export const getCargaPagosOxxoDetalleService = (request) => {
  const apiURL = baseurl('cargapagosoxxodetalle');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.payload)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};


