import React, { Component } from 'react';
import Modal from 'react-modal'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
//import { createBrowserHistory } from "history";


import './App.css'

import DashboardComponent from 'views/dashboard/dashboardComponent'
import DashboardAgenteComponent from 'views/dashboard/dashboardAgenteComponent'
import DashboardVentasComponent from 'views/dashboard/dashboardVentasComponent'
import DashboardPagosComponent from 'views/dashboard/dashboardPagosComponent'

//import PolizasComponent from '../../views/Seguros/polizasComponent'
import NotFoundComponent from '../components/notFoundComponent'

import LoginComponent from 'views/authentications/login/loginComponent';
import RegisterComponent from 'views/authentications/register/registerComponent';
import ForgotComponent from 'views/authentications/forgot/forgotComponent';
import LogoutComponent from 'views/authentications/logoutComponent';

import PrivateRoute from './privateRoute';
import BuscarComponent from '../components/commons/buscar/buscarComponent'
import CrearPolizaComponent from 'views/polizas/creaPolizaComponent'
import ActualizaPolizaComponent from 'views/polizas/actualizaPolizaComponent'
import ArchivoPolizaComponent from 'views/polizas/archivoPolizaComponent'
import ListaPolizasComponent from 'views/polizas/listaPolizasComponent'
 
import CrearCertificadoComponent from 'views/certificados/crearCertificadoComponent'
import ActualizaCertificadoComponent from 'views/certificados/actualizarCertificadoComponent'
//import AgregarAseguradoComponent from '../components/asegurados/agregarAseguradoComponent'
//import ActualizarAseguradoComponent from '../components/asegurados/actualizarAseguradoComponent'

import ConciliaPagosComponent from '../views/conciliapagos/conciliapagosComponent';
import CargaPagosOxxoView from '../views/cargapagosoxxo/cargapagosoxxoView'
import ComisionesComponent from '../views/comisiones/comisionesAgentesComponent'
import ComisionesAgenteComponent from '../views/comisiones/comisionesConciliaComponent'
import ComisionesFacturasAgenteComponent from 'views/comisiones/comisionesFacturasAgenteComponent';
import ComisionesConciliaComponent from 'views/comisiones/comisionesConciliaComponent'

Modal.setAppElement('#root')

class App extends Component {
  render() {
    //const hist = createBrowserHistory();
    return (
      <BrowserRouter>
        <div>
          <Switch>
            
          <Route path='/' exact={true} component={DashboardAgenteComponent} />
            <Route path='/login' component={LoginComponent} />
            <Route path='/register' component={RegisterComponent} />
            <Route path='/forgot' component={ForgotComponent} />
            <PrivateRoute path='/logout' component={LogoutComponent} />
            
            
            <PrivateRoute path='/dashboard/ventas/:anio/:mes' component={DashboardVentasComponent} /> 
            <PrivateRoute path='/dashboard/ventas' component={DashboardVentasComponent} /> 
            <PrivateRoute path='/dashboard/pagos/:anio/:mes' component={DashboardPagosComponent} /> 
            <PrivateRoute path='/dashboard/pagos' component={DashboardPagosComponent} /> 
            <PrivateRoute path='/dashboard' component={DashboardComponent} /> 
            <PrivateRoute path='/buscar' component={BuscarComponent} /> 

            <PrivateRoute path='/polizas' component={ListaPolizasComponent} />
            <PrivateRoute path='/poliza/crear' component={CrearPolizaComponent} />
            <PrivateRoute path='/poliza/archivo/:id' component={ArchivoPolizaComponent} />
            <PrivateRoute path='/poliza/actualizar/:id' component={ActualizaPolizaComponent} />
            <PrivateRoute path='/certificado/crear/:id' component={CrearCertificadoComponent} />
            <PrivateRoute path='/certificado/actualizar/:id' component={ActualizaCertificadoComponent} />

            <PrivateRoute path='/conciliapagos' component={ConciliaPagosComponent} />
            <PrivateRoute path='/cargapagosoxxo' component={CargaPagosOxxoView} />
            <PrivateRoute path='/comisiones/agente/:id' component={ComisionesAgenteComponent} />
            <PrivateRoute path='/comisiones/pendientes' component={ComisionesComponent} />
            <PrivateRoute path='/comisiones/facturas/:id' component={ComisionesFacturasAgenteComponent} />
            <PrivateRoute path='/comisiones/conciliar/:id' component={ComisionesConciliaComponent} />

            <Route component={NotFoundComponent} />
          </Switch>
        </div>
      </BrowserRouter>
    );
    
  }
}

export default App;