import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    dpcertificadopoliza: {
 id_dpcertificadopoliza : 0, id_certificadopoliza : 0, id_producto : 0, fecha : '', secuencia : 0, descripcion : '', primaneta : 0, cargopagofraccionado : 0, derechodepoliza : 0, subtotal : 0, iva : 0, total : 0, cargoalagentepornopago : 0, comisionagente : 0, totalcomision : 0, id_estatuspago : 0, id_mediopago : 0, referencia : '', fechapago : '', esasignadoporproducto : false, fechalimitedepago : '', pagosoxxo : 0, descpagooxxo1 : '', descpagooxxo2 : '', descpagooxxo3 : '', descpagooxxo4 : '', id_endosocertificadopoliza : 0, id_facturaagente : 0, descpagooxxo5 : '', porcpagofraccionado : 0, porccomision : 0,
      },

      lista_actualizacion_borrados : [],
      lista_registropagooxxo_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_DPCERTIFICADOPOLIZA:
      item = state.dpcertificadopoliza;
      action.fn(item);
      return { ...state,  dpcertificadopoliza: item };


    case types.GET_DPCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_DPCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', dpcertificadopoliza: respuesta };

    case types.GET_DPCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_DPCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_DPCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_DPCERTIFICADOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', dpcertificadopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_DPCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_DPCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_DPCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_DPCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', dpcertificadopoliza: respuesta };

    case types.PUT_DPCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_DPCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_DPCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_DPCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_DPCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_DPCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA:
      item = state.dpcertificadopoliza;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA:
      item = state.dpcertificadopoliza;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};

 
    case types.INSERTA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA:
      item = state.dpcertificadopoliza;
      item.registrospagooxxo= [...item.registrospagooxxo, action.payload]
      return { ...state, registropagooxxo:item};


    case types.BORRA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA:
      item = state.dpcertificadopoliza;
      let lista_registropagooxxo_borrados= state.lista_registropagooxxo_borrados
      if (item.registrospagooxxo[action.payload].id_registropagooxxo > 0){
            const elementoregistropagooxxoborrar = { id_registropagooxxo : item.registrospagooxxo[action.payload].id_registropagooxxo }
            lista_registropagooxxo_borrados= [...lista_registropagooxxo_borrados,  elementoregistropagooxxoborrar]
      }
      item.registrospagooxxo.splice(action.payload,1)
      return { ...state, registropagooxxo:item, lista_registropagooxxo_borrados : lista_registropagooxxo_borrados};


    default:
      return state;
  }
};

