import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { loginUserAction } from '../../../store/actions/auth/actions';
import LoginView from './loginView';

class LoginComponent extends Component {
state = {}
  componentDidMount(){
    document.title = 'Taffeit';
  }

  onHandleLogin = (event) => {
    event.preventDefault();

    let username = event.target.username.value;
    let password = event.target.password.value;

    const data = {
      username, password
    };

    this.props.dispatch(loginUserAction(data));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {...nextProps.login}
  }

  render() {
    console.log('Render Login isSuccess', this.state.isSuccess);
    if (this.state.isSuccess) {
      console.log('Entro a redirect', this.state.isSuccess);
      return <Redirect to='/' />;
    }

    return (
      <LoginView
        handleLogin={this.onHandleLogin}
        success={this.state.isSuccess}
        message={this.state.message} />
    );
  }
}

const mapStateToProps = (estado) => ({login:estado.login.response});

export default connect(mapStateToProps)(LoginComponent);