import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    genero: {
 id_genero : 0, genero : '', clave : '',
      },

      lista_aseguradocertificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_GENERO:
      item = state.genero;
      action.fn(item);
      return { ...state,  genero: item };


    case types.GET_GENERO_SUCCESS:
      respuesta= action.response;
      console.log('GET_GENERO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', genero: respuesta };

    case types.GET_GENERO_ERROR:
      respuesta= action.response;
      console.log('GET_GENERO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_GENERO_SUCCESS:
      respuesta= action.response;
      console.log('POST_GENERO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', genero: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_GENERO_ERROR:
      respuesta= action.response;
      console.log('POST_GENERO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_GENERO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_GENERO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', genero: respuesta };

    case types.PUT_GENERO_ERROR:
      respuesta= action.response;
      console.log('PUT_GENERO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_GENERO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_GENERO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_GENERO_ERROR:
      respuesta= action.response;
      console.log('DELETE_GENERO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO:
      item = state.genero;
      item.aseguradoscertificadopoliza= [...item.aseguradoscertificadopoliza, action.payload]
      return { ...state, aseguradocertificadopoliza:item};


    case types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO:
      item = state.genero;
      let lista_aseguradocertificadopoliza_borrados= state.lista_aseguradocertificadopoliza_borrados
      if (item.aseguradoscertificadopoliza[action.payload].id_aseguradocertificadopoliza > 0){
            const elementoaseguradocertificadopolizaborrar = { id_aseguradocertificadopoliza : item.aseguradoscertificadopoliza[action.payload].id_aseguradocertificadopoliza }
            lista_aseguradocertificadopoliza_borrados= [...lista_aseguradocertificadopoliza_borrados,  elementoaseguradocertificadopolizaborrar]
      }
      item.aseguradoscertificadopoliza.splice(action.payload,1)
      return { ...state, aseguradocertificadopoliza:item, lista_aseguradocertificadopoliza_borrados : lista_aseguradocertificadopoliza_borrados};


    default:
      return state;
  }
};

