import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    dpproductopoliza: {
 id_dpproductopoliza : 0, id_productopoliza : 0, id_poliza : 0, id_producto : 0, fecha : '', fechalimitedepago : '', secuencia : 0, descripcion : '', primaneta : 0, cargopagofraccionado : 0, derechodepoliza : 0, subtotal : 0, iva : 0, total : 0, cargoalagentepornopago : 0, pagosoxxo : 0, descpagooxxo1 : '', descpagooxxo2 : '', descpagooxxo3 : '', descpagooxxo4 : '', descpagooxxo5 : '', porcpagofraccionado : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_DPPRODUCTOPOLIZA:
      item = state.dpproductopoliza;
      action.fn(item);
      return { ...state,  dpproductopoliza: item };


    case types.GET_DPPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_DPPRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', dpproductopoliza: respuesta };

    case types.GET_DPPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_DPPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_DPPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_DPPRODUCTOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', dpproductopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_DPPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_DPPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_DPPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_DPPRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', dpproductopoliza: respuesta };

    case types.PUT_DPPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_DPPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_DPPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_DPPRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_DPPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_DPPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

