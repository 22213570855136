import React from 'react';
import { Link } from 'react-router-dom';
import Lookup from '../../components/lookups'
import './polizas.css';

import FieldHeader from '../../components/fieldheader'
import Boton from '../../components/buttons/boton'

const ActualizaPolizaViewListaCertificados = (props) => {
  if (props.list === undefined) {
    return null;
  }

  return (
    <div className='pl-3 pt-3'>
      {
         (props.poliza.id_estatuspoliza===1) ? (
              <div>
                <div className='row py-3'>
                    <div className=" col-md-2">
                      <a className="btn btn-sm btn-primary" href={`/certificado/crear/${props.id_poliza}`} role="button">Crea certificado</a>
                    </div>
                    <div className=" col-md-6 ">
                      <button className="btn btn-sm btn-warning  " onClick={props.onAsignaCertificadosClick}>
                        Asigna Certificados</button> 

                      {/* <ReactToExcel 
                          table="tabla-certificados2" 
                          filename="certificados"
                          sheet='Certificados'
                          buttonText="Exportar"
                          /> */}
                      <button className="btn btn-sm btn-warning ml-2" onClick={props.onAgregarRenovacionesClick}>Agrega Renovaciones</button>
                    </div>

                </div>
              </div>

        ):(
          <div>
          </div>
        )
      }
      <div className='table-responsive'>
        <table id="tabla-certificados"  className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>
              <th><FieldHeader field={'certificadospoliza.certificado'} /></th>
              <th><FieldHeader field={'certificadospoliza.aseguradotitular'} /></th>
              <th><FieldHeader field={'certificadospoliza.antiguedad'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_producto'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_agente'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_origen'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_estatuscertificado'} /></th>
              <th><FieldHeader field={'certificadospoliza.validacion'} /></th>
              <th>PDF</th>
              <th>PDF</th>
            </tr>
          </thead>
          <tbody>
            {props.list.sort((a, b) => a.certificado - b.certificado).map(li => (  
              <tr key={li.id_certificadopoliza}>
                <td>
                  <Link className='name uppercase' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.certificado}</Link>
                </td>
                <td>
                  <Link className='name' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.aseguradotitular}</Link>
                </td>
                <td>
                  {li.antiguedad.substring(0,10)}
                </td>
                <td className='uppercase'>
                  <Lookup field='certificadospoliza.id_producto' value = {li.id_producto} />
                </td>
                <td className='uppercase'>
                  <Lookup field='certificadospoliza.id_agente' value = {li.id_agente} />
                </td>
                <td className='uppercase'>
                  <Lookup field='certificadospoliza.id_origen' value = {li.id_origen} />
                </td>
                <td className='uppercase'>
                  <Lookup className={(li.id_estatuscertificado===1)?"alert alert-primary":""} field='certificadospoliza.id_estatuscertificado' value = {li.id_estatuscertificado} />
                </td>
                <td>
                  <div className={(li.validacion==='OK')?"":"alert alert-danger"}>{li.validacion}</div>
                </td>
                <td>
                  
                  {(props.poliza.id_estatuspoliza===2) ? (
                      <Boton className="col-1 "  id='pdfcert' onClick={(e) => props.Handler_PDF_Cert_Certificado(e, li.id_certificadopoliza)}/>
                  ):''}
                </td>
                <td>
                  
                  {(props.poliza.id_estatuspoliza===2) ? (
                      <Boton className="col-1 "  id='pdfrecibo' onClick={(e) => props.Handler_PDF_Recibo_Certificado(e, li.id_certificadopoliza)}/>
                  ):''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>




    </div>
  );
};

export default ActualizaPolizaViewListaCertificados;