import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderComponent from '../../components/commons/header/headerComponent';
//import LoadingComponent from '../commons/loading/loadingComponent'

import ComisionesFacturasAgenteView from './comisionesFacturasAgenteView';
import EditarFactura from './comisionesFacturaAgenteEditarView'
import * as Handlers from './handlers'


import {  getComisionesAgenteAction, 
          getComisionesAgenteFacturasAction, 
          getComisionesFacturaDPsAction,
          getComisionesAgenteDPsPendientesAction,
          postComisionesAsignacionAction,
          deleteComisionesAsignacionAction,
          postComisionesFacturaAgenteAction,
          ComisionesFacuraCrear,
          ComisionesFacuraEditar,
          ComisionesFacuraClose,
          DeleteComisionesFacuraAgenteAction,
          putComisionesFacturaAgenteAction,
         } from '../../store/actions/comisiones/actions';


class ConciliapagosComponent extends Component {
    state={}
    
    onEditarFacturaClick= (event,id) => {
      event.preventDefault();
      if (id>0)  this.props.dispatch(ComisionesFacuraEditar())
      else this.props.dispatch(ComisionesFacuraCrear());
    }

    onBorrarFacturaClick= (event,id) => {
      event.preventDefault();
      this.props.dispatch(DeleteComisionesFacuraAgenteAction(this.props.facturaseleccionada))
    }

    onGrabarFacturaClick= (event) => {
      event.preventDefault();
      let newstate = this.props.item;
      newstate.fechapago = newstate.fechapago.substr(0,10);

      if (this.props.item.id_facturaagente===0) this.props.dispatch(postComisionesFacturaAgenteAction(newstate))
      else this.props.dispatch(putComisionesFacturaAgenteAction(newstate));
    }

    onCerrarFacturaClick= (event) => {
      event.preventDefault();
      this.props.dispatch(ComisionesFacuraClose());
    }



    onDesAsignarClick= (event,id) => {
      event.preventDefault();
      this.props.dispatch(deleteComisionesAsignacionAction({id_dpcertificadopoliza:id}));
    }

    onAsignarClick= (event,id) => {
      event.preventDefault();
      this.props.dispatch(postComisionesAsignacionAction({id_dpcertificadopoliza:id, id_facturaagente: this.state.facturaseleccionada.id_facturaagente}));
    }

    onSeleccionarFacturaClick = (event,id) => {
      event.preventDefault();
      this.props.dispatch(getComisionesFacturaDPsAction({id_facturaagente:id}));
    }

  componentDidMount() {
    console.log('componentDidMount');
    const id = parseInt(this.props.match.params.id,10);
    if (!this.state.cargado){
      this.props.dispatch(getComisionesAgenteAction({ id_agente:id }));
      this.props.dispatch(getComisionesAgenteFacturasAction({ id_agente:id }));
      this.props.dispatch(getComisionesAgenteDPsPendientesAction({ id_agente:id }));
    }
    
  }




  static getDerivedStateFromProps(nextProps, prevState) {
  return nextProps;
}


    render() {
        console.log('Estado',this.state);
        return (
            <div>
              <HeaderComponent />
              <div className='row'>
                  <div className='col-12'>
                      <ComisionesFacturasAgenteView
                        agente={this.state.agente}
                        facturaseleccionada={this.state.facturaseleccionada}
                        listagentefacturas={this.state.listagentefacturas} 
                        listafacturadps={this.state.listafacturadps}
                        listagentedpspendientes={this.state.listagentedpspendientes}
                        facturamodal={this.state.facturamodal}
                        comisionespendientes={this.state.comisionespendientes}
                        onSeleccionarFacturaClick={this.onSeleccionarFacturaClick}
                        onAsignarClick={this.onAsignarClick}
                        onDesAsignarClick={this.onDesAsignarClick}  
                          onEditarFacturaClick={this.onEditarFacturaClick}
                          onBorrarFacturaClick={this.onBorrarFacturaClick}
                        />
                        <EditarFactura
                          facturamodal={this.state.facturamodal}
                          facturaagente={this.state.facturamodal.facturaagente}
                          HandleChange={Handlers.HandleChange.bind(this)} 
                          onCerrarFacturaClick={this.onCerrarFacturaClick}
                          onGrabarFacturaClick={this.onGrabarFacturaClick}
                        />
                  </div>
              </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    console.log('FACTURAS AGENTE',state.comisionesReducer);
    return {
        ...state.comisionesReducer,
        ...state.comisionesReducer.facturamodal,
        item : state.comisionesReducer.facturamodal.facturaagente
      };
} 
  

export default connect(mapStateToProps)(ConciliapagosComponent);