import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    mediopago: {
 id_mediopago : 0, mediopago : '',
      },

      lista_dpcertificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_MEDIOPAGO:
      item = state.mediopago;
      action.fn(item);
      return { ...state,  mediopago: item };


    case types.GET_MEDIOPAGO_SUCCESS:
      respuesta= action.response;
      console.log('GET_MEDIOPAGO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', mediopago: respuesta };

    case types.GET_MEDIOPAGO_ERROR:
      respuesta= action.response;
      console.log('GET_MEDIOPAGO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_MEDIOPAGO_SUCCESS:
      respuesta= action.response;
      console.log('POST_MEDIOPAGO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', mediopago: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_MEDIOPAGO_ERROR:
      respuesta= action.response;
      console.log('POST_MEDIOPAGO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_MEDIOPAGO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_MEDIOPAGO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', mediopago: respuesta };

    case types.PUT_MEDIOPAGO_ERROR:
      respuesta= action.response;
      console.log('PUT_MEDIOPAGO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_MEDIOPAGO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_MEDIOPAGO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_MEDIOPAGO_ERROR:
      respuesta= action.response;
      console.log('DELETE_MEDIOPAGO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO:
      item = state.mediopago;
      item.dpscertificadopoliza= [...item.dpscertificadopoliza, action.payload]
      return { ...state, dpcertificadopoliza:item};


    case types.BORRA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO:
      item = state.mediopago;
      let lista_dpcertificadopoliza_borrados= state.lista_dpcertificadopoliza_borrados
      if (item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza > 0){
            const elementodpcertificadopolizaborrar = { id_dpcertificadopoliza : item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza }
            lista_dpcertificadopoliza_borrados= [...lista_dpcertificadopoliza_borrados,  elementodpcertificadopolizaborrar]
      }
      item.dpscertificadopoliza.splice(action.payload,1)
      return { ...state, dpcertificadopoliza:item, lista_dpcertificadopoliza_borrados : lista_dpcertificadopoliza_borrados};


    default:
      return state;
  }
};

