import * as types from '../../actions/buscar/types';

const initialState = {
  listacertificados: [],
  listacontratantes: []
};

export default function(state = initialState, action) {
  //console.log('buscarReducer', action);
  switch(action.type) {
    case types.GET_BUSCAR:
      return {...state, listacertificados: []}
    case types.GET_BUSCAR_SUCCESS:
      const response = action.response;
      if (response.listacertificados === undefined) {
        return {...state, listacertificados: []}
      }
      return { listacertificados: response.listacertificados };
    case types.GET_BUSCAR_ERROR:
      return { listacertificados: [],
        listacontratantes: [] };




    case types.GET_BUSCARCONTRATANTE:
          return {...state, listacontratantes: []}

    case types.GET_BUSCARCONTRATANTE_SUCCESS:
      const response2 = action.response;
      if (response2.listacontratantes === undefined) {
        return {...state, listacontratantes: []}
      }
      return { listacontratantes: response2.listacontratantes };

    case types.GET_BUSCARCONTRATANTE_ERROR:
      return {...state, listacontratantes: []}


    default:
      return state;
  }
};

