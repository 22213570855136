import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Handlers from './handlers'
import HeaderComponent from '../../components/commons/header/headerComponent';

import { postCertificadoPolizaAction,
        ejecutaEnCertificadoPolizaAction } from '../../store/actions/db/actions';


import CertificadoView from './actualizarCertificadoView'

class CreaPolizaComponent extends Component {
  state = {}

  componentDidMount() {
    if (this.props.item.id_estatuscertificado === 0){
      this.props.ejecutaEnCertificadoPolizaAction(this.inicializa);
    }
  }

 inicializa = (item) =>{
  const dia = new Date().getDate();
  const mes = new Date().getMonth()+1;
  const anio = new Date().getFullYear();
  var messtr = ''
  var diastr = ''
  if (mes < 10) messtr = '0' + mes; else messtr = mes ;
  if (dia < 10) diastr = '0' + dia; else diastr = dia ;
  item.id_poliza = parseInt(this.props.match.params.id,10);
  item.antiguedad = anio + '-' + messtr + '-' + diastr;
  item.c_fnac = anio + '-' + messtr + '-' + diastr;
  item.t_fnac = anio + '-' + messtr + '-' + diastr;
  item.id_origen =2;
  item.id_estatuscertificado =1;
  item.contratante ='.';
  item.aseguradotitular ='.';
 
}



  render() {
    console.log('RENDER crea certificado', this.props);
    if (this.props.state.grabado){
      const _url = '/certificado/actualizar/' + this.props.item.id_certificadopoliza
      return <Redirect to={_url} />;
    }
    const mensaje = this.props.state.message

    return (
      <div>
        <HeaderComponent />
        
      <div className='container element'>
          <CertificadoView
            message={mensaje}
            success={this.props.grabado}
            certificadopoliza={this.props.item}
            onGrabarClick={Handlers.OnHandleGrabaCertificado.bind(this)}
            onCopiarContatanteClick={Handlers.onCopiarContatanteClick.bind(this)} 
            handleChange={Handlers.HandleCertificadoChange.bind(this)} 
            handleSelect={Handlers.HandleSelect.bind(this)}/>
            </div>
      </div>
    );
  }
}




const mapDispatchToProps = {

  postCertificadoPolizaAction,
  ejecutaEnCertificadoPolizaAction,


}

const mapStateToProps = (state) => {
  const x = state.polizaReducer;
  console.log('mapStateToProps',x.poliza.id_estatuspoliza);
 return {state:state.certificadopolizaReducer, item: state.certificadopolizaReducer.certificadopoliza};
};

export default connect(mapStateToProps,mapDispatchToProps)(CreaPolizaComponent);