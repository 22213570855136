import React from 'react';
import { Link } from 'react-router-dom';

import Lookup from '../../components/lookups'

import './polizas.css';

const ListaPolizasView = (props) => {
  if (props.list === undefined) {
    return null;
  }

  if (!props.list.success && props.list.hasOwnProperty('success')) {
    return <div>{props.list.message}</div>
  }

  if (props.list.length === 0) {
    return (
      <div>
        <a className="btn btn-primary btn-sm" href={`/poliza/crear`} role="button">Crea póliza</a>
        <div className='polizas-none'>No polizas Found.</div>
      </div>
    )
  }

  return (
    <div className='container'>
    <div className='pt-3'>
        <a className="btn btn-primary btn-sm" href={`/poliza/crear`} role="button">Crea póliza</a>
    </div>
    <div className='pt-3' >
    
      <table className="table table-bordered table-hover">
        <thead className='bg-primary'>
          <tr>
            <th><button onClick={props.onSortHandle} className='btn btn-link text-light'>Poliza</button></th>
            <th><button onClick={props.onSortHandle} className='btn btn-link text-light'>Descripcion</button></th>
            <th><button onClick={props.onSortHandle} className='btn btn-link text-light'>Fecha</button></th>
            <th><button  className='btn btn-link text-light'>Forma de Pago</button></th>
            <th><button  className='btn btn-link text-light'>Estatus</button></th>
          </tr>
        </thead>
        <tbody className='tbody-dark'>
          {props.list.map(li => (  
            <tr key={li.id_poliza}>
              <td>
                <Link className='name' to={`/poliza/actualizar/${li.id_poliza}`}>{li.poliza}</Link>
              </td>
              <td className='uppercase'>
                {li.descripcion}
              </td>
              <td className='uppercase'>
                {li.fechainicio.substring(0,10)}
              </td>
              <td className='uppercase'>
                <Lookup field='polizas.id_formapago' value = {li.id_formapago} />
              </td>
              <td className='uppercase'>
                <Lookup field='polizas.id_estatuspoliza' value = {li.id_estatuspoliza} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    </div>
  );
};

export default ListaPolizasView;