import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    aseguradocertificadopoliza: {
 id_aseguradocertificadopoliza : 0, id_certificadopoliza : 0, nombre : '', appaterno : '', apmaterno : '', fechanacimiento : '', id_parentezco : 0, estatura : 0, peso : 0, indice : 0, id_origen : 0, edad : 0, antiguedad : '', id_genero : 0, primaporedad : 0, factorprimaporedad : 0,
      },

      lista_actualizacion_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ASEGURADOCERTIFICADOPOLIZA:
      item = state.aseguradocertificadopoliza;
      action.fn(item);
      return { ...state,  aseguradocertificadopoliza: item };


    case types.GET_ASEGURADOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_ASEGURADOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', aseguradocertificadopoliza: respuesta };

    case types.GET_ASEGURADOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_ASEGURADOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ASEGURADOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_ASEGURADOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', aseguradocertificadopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ASEGURADOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_ASEGURADOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ASEGURADOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ASEGURADOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', aseguradocertificadopoliza: respuesta };

    case types.PUT_ASEGURADOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_ASEGURADOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ASEGURADOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ASEGURADOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ASEGURADOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_ASEGURADOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA:
      item = state.aseguradocertificadopoliza;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA:
      item = state.aseguradocertificadopoliza;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};


    default:
      return state;
  }
};

