import { takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/procesos/types';

import {
    postAsignaDPsPolizaSaga,
    postAsignaCertificadosPolizaSaga,
    postAgregarRenovacionesPolizaSaga,
    postAbrirPolizaSaga,
    postCerrarPolizaSaga,
    postBorrarPolizaSaga,
   } from './polizasSaga';

import {
    postAbrirCertificadoPolizaSaga,
    postCerrarCertificadoPolizaSaga,
    postCancelarCertificadoPolizaSaga,
    postActivarCertificadoPolizaSaga,
    postBorrarCertificadoPolizaSaga,

   } from './certificadospolizaSaga';

import {
    postSepararAseguradoCertificadoPolizaSaga,
    postEnviarAseguradoCertificadoPolizaSaga,
   } from './aseguradocertificadopolizaSaga';
   
export default function* watchUserAuthentication() {

  yield takeLeading (types.ASIGNA_DPS_POLIZA, postAsignaDPsPolizaSaga);
  yield takeLeading (types.ASIGNA_CERTIFICADOS_POLIZA, postAsignaCertificadosPolizaSaga);
  yield takeLeading (types.AGREGAR_RENOVACIONES_POLIZA, postAgregarRenovacionesPolizaSaga);
  yield takeLeading (types.ABRIR_POLIZA, postAbrirPolizaSaga);
  yield takeLeading (types.CERRAR_POLIZA, postCerrarPolizaSaga);
  yield takeLeading (types.BORRAR_POLIZA, postBorrarPolizaSaga);

  yield takeLeading (types.ABRIR_CERTIFICADOPOLIZA, postAbrirCertificadoPolizaSaga);
  yield takeLeading (types.CERRAR_CERTIFICADOPOLIZA, postCerrarCertificadoPolizaSaga);
  yield takeLeading (types.CANCELAR_CERTIFICADOPOLIZA, postCancelarCertificadoPolizaSaga);
  yield takeLeading (types.ACTIVAR_CERTIFICADOPOLIZA, postActivarCertificadoPolizaSaga);
  yield takeLeading (types.BORRAR_CERTIFICADOPOLIZA, postBorrarCertificadoPolizaSaga);
  yield takeLeading (types.SEPARAR_ASEGURADOCERTIFICADOPOLIZA, postSepararAseguradoCertificadoPolizaSaga);
  yield takeLeading (types.ENVIAR_ASEGURADOCERTIFICADOPOLIZA, postEnviarAseguradoCertificadoPolizaSaga);

}

