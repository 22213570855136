import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    detallelistadeprecio: {
 id_detallelistadeprecio : 0, id_listadeprecio : 0, id_producto : 0, secuencia : 0, descripcion : '', primaneta : 0, cargopagofraccionado : 0, derechodepoliza : 0, subtotal : 0, iva : 0, total : 0, cargoalagentepornopago : 0, mesessiguientepago : 0, limitedepagomeses : 0, limitedepagodias : 0, pagosoxxo : 0, descpagooxxo1 : '', descpagooxxo2 : '', descpagooxxo3 : '', descpagooxxo4 : '', id_formapago : 0, descpagooxxo5 : '', porcpagofraccionado : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_DETALLELISTADEPRECIO:
      item = state.detallelistadeprecio;
      action.fn(item);
      return { ...state,  detallelistadeprecio: item };


    case types.GET_DETALLELISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('GET_DETALLELISTADEPRECIO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', detallelistadeprecio: respuesta };

    case types.GET_DETALLELISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('GET_DETALLELISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_DETALLELISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('POST_DETALLELISTADEPRECIO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', detallelistadeprecio: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_DETALLELISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('POST_DETALLELISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_DETALLELISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_DETALLELISTADEPRECIO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', detallelistadeprecio: respuesta };

    case types.PUT_DETALLELISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('PUT_DETALLELISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_DETALLELISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_DETALLELISTADEPRECIO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_DETALLELISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('DELETE_DETALLELISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

