import React from 'react';
import InputField from '../../components/inputfield'

//import { link } from 'fs';

const ArchivoPolizaView = (props) => (
  <div className='container'>
     <form >
      <div className={(props.success) ? 'message success' : 'message error'}>{props.message}</div>
            <div className='row'>
              <InputField className='col-md-3' type='text' disabled field='polizas.poliza' value={props.poliza.poliza} onChange={props.handleChange} />
              <InputField className='col-md-6' type='text' disabled field='polizas.descripcion' value={props.poliza.descripcion} onChange={props.handleChange} />
              <InputField className='col-md-3' type='date' disabled field='polizas.fechainicio' value={props.poliza.fechainicio} onChange={props.handleChange} />
                  

              <InputField className='col-md-3' type='lookup' field='polizas.id_formapago' value={props.poliza.id_formapago} />
              <InputField className='col-md-3' type='lookup' field='polizas.id_aseguradora' value={props.poliza.id_aseguradora} />
              <div className='col col-md-3'></div>
              <InputField className='col-md-3' type='lookup' field='polizas.id_estatuspoliza' value={props.poliza.id_estatuspoliza} />
              
              
            </div>
            
      <div className='row'></div>

          
    </form>
  </div>
);

export default ArchivoPolizaView;
