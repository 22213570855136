


export function  HandleChange(event) {
    console.log('event', event.target);
    let name = event.target.name;
    let item = this.props.item;
    
    item[name]= eventvalue(event);
    console.log('item', item);

    if(name==='subtotal'){
      item['iva'] =  parseFloat(Number.parseFloat(item['subtotal'] * item['porciva'] / 100).toFixed(2));
      item['retiva'] =  parseFloat(Number.parseFloat(item['subtotal'] * item['porcretiva'] / 100).toFixed(2));
      item['retisr'] =  parseFloat(Number.parseFloat(item['subtotal'] * item['porcretisr'] / 100).toFixed(2));


      item['total'] = parseFloat(Number.parseFloat(item['subtotal'] +  item['iva'] - item['retiva'] - item['retisr'] ).toFixed(2));

    }

    this.setState({
      item: item
    });
  }
  
  
  export function eventvalue(event) {
      let name = event.target.name;
  
      if (event.target.type==='number'){
        if (event.target.step){
          return  parseFloat(parseFloat(event.target.value).toFixed(2));
        }else{
          return parseInt(event.target.value,10);
        }
      }
      else if (event.target.type==='date'){
        return event.target.value.substr(0,10);
      }
      else if (name.startsWith('id_')) {
        return parseInt(event.target.value,10)
      } else {
        return event.target.value;
      };
  
    }
  