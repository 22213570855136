import { takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/dashboards/types';

import {
    getDashboardVentasSaga } 
    from './ventassaga';

    import {
        getDashboardPagosSaga } 
        from './pagossaga';
    import {
        getDashboardCertificadosSaga } 
        from './certificdossaga';

export default function* watchConciliaPagos() {

    yield takeLeading (types.GET_DASHBOARD_VENTAS, getDashboardVentasSaga);
    yield takeLeading (types.GET_DASHBOARD_PAGOS, getDashboardPagosSaga);
    yield takeLeading (types.GET_DASHBOARD_CERTIFICADOS, getDashboardCertificadosSaga);

}
