import React, { Component } from 'react';
import axios from 'axios'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCookie } from '../../utils/cookies';
import HeaderComponent from '../../components/commons/header/headerComponent';
import * as Handlers from './handlers'

import { getCertificadoPolizaAction, 
         putCertificadoPolizaAction,
        deleteEveryAseguradoCertificadoPolizaAction,
        deleteEveryBenefCertificadoPolizaAction,
        deleteEveryEndosoCertificadoPolizaAction,
        borraAseguradoCertificadoPolizaEnCertificadoPolizaAction,
        borraBenefCertificadoPolizaEnCertificadoPolizaAction,
        insertaAseguradoCertificadoPolizaEnCertificadoPolizaAction,
        insertaBenefCertificadoPolizaEnCertificadoPolizaAction,
        borraEndosoCertificadoPolizaEnCertificadoPolizaAction,
        insertaEndosoCertificadoPolizaEnCertificadoPolizaAction,
        postEveryAseguradoCertificadoPolizaAction,
        postEveryBenefCertificadoPolizaAction,
        putEveryAseguradoCertificadoPolizaAction,
        putEveryBenefCertificadoPolizaAction,
        postEveryEndosoCertificadoPolizaAction,
        putEveryEndosoCertificadoPolizaAction,
        ejecutaEnCertificadoPolizaAction,
        getPolizaAction,
        putEveryDPCertificadoPolizaAction,
         } from '../../store/actions/db/actions';

import {  abrirCertificadoPolizaAction, 
          cerrarCertificadoPolizaAction, 
          seprarAseguradoCertificadoPolizaAction,
          enviarAseguradoCertificadoPolizaAction,
          cancelarCertificadoPolizaAction,
          activarCertificadoPolizaAction,
          borrarCertificadoPolizaAction,
          borrarCertificadoPolizaActionRESET } from '../../store/actions/procesos/actions'

import LoadingComponent from '../../components/commons/loading/loadingwithheaderComponent';
import ActualizarCertificadoView from './actualizarCertificadoView';

class ActualizarCertificadoComponent extends Component {
  
  state = {}


  componentDidMount() {
    const id = parseInt(this.props.match.params.id,10);
    if (!this.props.state.cargado){
      this.props.getCertificadoPolizaAction({ id: id });
    }else{
      if (this.props.item.id_certificadopoliza !== id){
        this.props.getCertificadoPolizaAction({ id: id });
      }else{
        this.props.getPolizaAction({id:this.props.item.id_poliza});
      }
    }
    
    console.log('salio componentDidMount');
  }

  componentWillUnmount(){
    this.props.borrarCertificadoPolizaActionRESET();
    console.log('getpoliza',this.props.item.id_poliza);
      this.props.getPolizaAction({id:this.props.item.id_poliza});
  }



  onSepararAseguradoClick = (event, _id_certificadopoliza, _id_aseguradocertificadopoliza, index) =>{
    event.preventDefault();
    const asegurado = this.props.asegurados[index];
    console.log('Separar', asegurado.clave);
    if (asegurado.clave === 'separar')
      this.props.seprarAseguradoCertificadoPolizaAction( _id_certificadopoliza ,_id_aseguradocertificadopoliza);
  }
  
  onEnviarACertificadoClick = (event, _id_certificadopoliza, _id_aseguradocertificadopoliza, index) =>{
    event.preventDefault();
    const asegurado = this.props.asegurados[index];
    const clave = parseInt( asegurado.clave,10);
    console.log('Hola');
    if (clave > 0)
      this.props.enviarAseguradoCertificadoPolizaAction( _id_certificadopoliza ,_id_aseguradocertificadopoliza, clave);
  }
  


  HandlerPDF_Recibo = async (event) => {
    event.preventDefault();
    

    const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'

    //axios(`https://biggenzi01.appspot.com/pdfrecibo/certificadopoliza/`+this.props.item.id_certificadopoliza, {
    //axios(`http://localhost:8080/pdfrecibo/certificadopoliza/`+this.props.item.id_certificadopoliza, {
      axios(`${srv}/pdfrecibo/certificadopoliza/`+this.props.item.id_certificadopoliza, {
      method: "GET",
      responseType: "blob",
      headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}

      
      //Force to receive data in a Blob Format
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf"
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);

        


      })
      .catch(error => {
        console.log(error);
      });
  };
  

  HandlerPDF_Certificado = async (event) => {
    event.preventDefault();
    

    const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'

      axios(`${srv}/pdfcertificado/certificadopoliza/`+this.props.item.id_certificadopoliza, {
      method: "GET",
      responseType: "blob",
      headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}

      
      //Force to receive data in a Blob Format
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf"
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);

        


      })
      .catch(error => {
        console.log(error);
      });
  };

  onAbrirCertificadoPolizaClick = (event) =>{
    event.preventDefault();
    const id=this.props.item.id_certificadopoliza;
    this.props.abrirCertificadoPolizaAction(id);
  }

  
  


  onCerrarCertificadoPolizaClick = (event) =>{
    event.preventDefault();
    console.log('Cerrando....');
    const id=this.props.item.id_certificadopoliza;
    this.props.cerrarCertificadoPolizaAction(id);
  }

  onCancelarCertificadoPolizaClick = (event) =>{
    event.preventDefault();
    const motivo=this.props.item.id_motivocancelacion;
    if (motivo===0)  alert('Debe indicar el Motivo de la cancelación...');
    console.log('Cancelando....');
    const id=this.props.item.id_certificadopoliza;
    this.props.cancelarCertificadoPolizaAction(id,motivo);
  }

  onActivarCertificadoPolizaClick = (event) =>{
    event.preventDefault();
    console.log('Activando....');
    const id=this.props.item.id_certificadopoliza;
    this.props.activarCertificadoPolizaAction(id);
  }

  onBorrarCertificadoPolizaClick = (event) =>{
    event.preventDefault();
    const id=this.props.item.id_certificadopoliza;
    this.props.borrarCertificadoPolizaAction(id);
  }



  onBorrarBenefHandler = (event,index) =>{
    event.preventDefault();
    const benef = this.props.benefs[index];
    if (benef.id_benefcertificadopoliza===0 || benef.clave === benef.nombre)
    this.props.borraBenefCertificadoPolizaEnCertificadoPolizaAction(index);
    
  }
  


  onBorrarAseguradoHandler = (event,index) =>{
    event.preventDefault();
    const asegurado = this.props.asegurados[index];
    if (asegurado.id_aseguradocertificadopoliza===0 || asegurado.clave === asegurado.nombre)
    this.props.borraAseguradoCertificadoPolizaEnCertificadoPolizaAction(index);
    
  }
  
  onBorrarEndosoHandler = (event,index) =>{
    event.preventDefault();
    const endoso = this.props.endosos[index];
    if (endoso.id_endosocertificadopoliza===0 || endoso.clave === endoso.endoso)
    this.props.borraEndosoCertificadoPolizaEnCertificadoPolizaAction(index);
    
  }



  onAgregaAseguradoHandler = (event) =>{
    event.preventDefault();
    const asegurado =  {
      id_aseguradocertificadopoliza:0, 
      id_certificadopoliza : this.props.item.id_certificadopoliza,
      antiguedad: this.props.item.antiguedad,
      fechanacimiento: '',
      id_origen:2,
    }
        
    this.props.insertaAseguradoCertificadoPolizaEnCertificadoPolizaAction(asegurado);
  }


  onAgregaBenefHandler = (event) =>{
    event.preventDefault();
    const benef =  {
      id_benefcertificadopoliza:0, 
      id_certificadopoliza : this.props.item.id_certificadopoliza,
      fechanacimiento: '',
    }
        
    this.props.insertaBenefCertificadoPolizaEnCertificadoPolizaAction(benef);
  }

    

      onAgregaEndosoHandler = (event) =>{
        event.preventDefault();
        const endoso = {
          id_endosocertificadopoliza:0, 
          id_certificadopoliza : this.props.item.id_certificadopoliza,
          fechainicio: this.props.item.antiguedad,
          endoso: '',
        }
            
        this.props.insertaEndosoCertificadoPolizaEnCertificadoPolizaAction(endoso);
      
      }
    
    
  
  
      


    
  
  
      
  


  render() {
    // if (this.props.success){
    //   const _url = '/poliza/actualizar/' + this.props.item.id_poliza
    //   return <Redirect to={_url} />;
    // }

    if (this.props.proceso.borrado){
      const _url = '/poliza/actualizar/' + this.props.item.id_poliza
      return <Redirect to={_url} />;
    }

    if (this.props.state.cargado === false) {
      return (
        <LoadingComponent />
        )
    }



      console.log('RENDER ------- >', this.props);
      return (
        <div>
          <HeaderComponent />
          <div className='container element'>
          <ActualizarCertificadoView
            message={this.props.state.message}
            success={this.props.state.grabado}
            certificadopoliza={this.props.item}
            onGrabarClick={Handlers.OnHandleGrabaCertificado.bind(this)}
            HandlerImprimirCredencial={this.HandlerImprimirCredencial}
            HandlerPDF_Certificado={this.HandlerPDF_Certificado}
            HandlerPDF_Recibo={this.HandlerPDF_Recibo}
            handleChange={Handlers.HandleCertificadoChange.bind(this)} 
            onAbrirCertificadoPolizaClick={this.onAbrirCertificadoPolizaClick.bind(this)} 
            onCopiarContatanteClick={Handlers.onCopiarContatanteClick.bind(this)} 
            onCerrarCertificadoPolizaClick={this.onCerrarCertificadoPolizaClick.bind(this)}
            onCancelarCertificadoPolizaClick={this.onCancelarCertificadoPolizaClick.bind(this)}
            onActivarCertificadoPolizaClick={this.onActivarCertificadoPolizaClick.bind(this)}
            onBorrarCertificadoPolizaClick={this.onBorrarCertificadoPolizaClick.bind(this)}
            
            handleAseguradoChange={Handlers.HandleAseguradoChange.bind(this)} 
            onSepararAseguradoClick ={this.onSepararAseguradoClick.bind(this)} 
            onEnviarACertificadoClick ={this.onEnviarACertificadoClick.bind(this)} 
            onAgregaAseguradoHandler = {this.onAgregaAseguradoHandler.bind(this)}
            onBorrarAseguradoHandler={this.onBorrarAseguradoHandler.bind(this)}

            handleBenefChange={Handlers.HandleBenefChange.bind(this)} 
            onAgregaBenefHandler = {this.onAgregaBenefHandler.bind(this)}
            onBorrarBenefHandler={this.onBorrarBenefHandler.bind(this)}

            handleEndosoChange={Handlers.HandleEndosoChange.bind(this)} 
            onAgregaEndosoHandler = {this.onAgregaEndosoHandler.bind(this)}
            onBorrarEndosoHandler={this.onBorrarEndosoHandler.bind(this)}
            
            handlePagosChange={Handlers.HandlePagosChange.bind(this)}
            handleSelect={Handlers.HandleSelect.bind(this)}
            />   

          </div>

        </div>
      );
  }
}



const mapDispatchToProps = {
          getPolizaAction,
          getCertificadoPolizaAction, 
          putCertificadoPolizaAction,
          deleteEveryAseguradoCertificadoPolizaAction,
          deleteEveryEndosoCertificadoPolizaAction,
          borraAseguradoCertificadoPolizaEnCertificadoPolizaAction,
          insertaAseguradoCertificadoPolizaEnCertificadoPolizaAction,
          borraEndosoCertificadoPolizaEnCertificadoPolizaAction,
          insertaEndosoCertificadoPolizaEnCertificadoPolizaAction,
          postEveryAseguradoCertificadoPolizaAction,
          putEveryAseguradoCertificadoPolizaAction,
          postEveryEndosoCertificadoPolizaAction,
          putEveryEndosoCertificadoPolizaAction,
          ejecutaEnCertificadoPolizaAction,
          abrirCertificadoPolizaAction, 
          cerrarCertificadoPolizaAction, 
          seprarAseguradoCertificadoPolizaAction,
          enviarAseguradoCertificadoPolizaAction,

          cancelarCertificadoPolizaAction,
          activarCertificadoPolizaAction,
          borrarCertificadoPolizaAction,
          borrarCertificadoPolizaActionRESET,
          putEveryDPCertificadoPolizaAction,


          postEveryBenefCertificadoPolizaAction,
          putEveryBenefCertificadoPolizaAction,
          deleteEveryBenefCertificadoPolizaAction,
          borraBenefCertificadoPolizaEnCertificadoPolizaAction,
          insertaBenefCertificadoPolizaEnCertificadoPolizaAction,
}






const mapStateToProps = (state) => {
  console.log('mapStatToPropsCertificadoComponent',state.certificadopolizaReducer);
 return {
   state:state.certificadopolizaReducer, 
   item: state.certificadopolizaReducer.certificadopoliza,
   asegurados: state.certificadopolizaReducer.certificadopoliza.aseguradoscertificadopoliza,
   benefs: state.certificadopolizaReducer.certificadopoliza.benefscertificadopoliza,
   endosos: state.certificadopolizaReducer.certificadopoliza.endososcertificadopoliza,
   pagos: state.certificadopolizaReducer.certificadopoliza.dpscertificadopoliza,
   proceso: state.procsCertificadoPolizaReducer,
   poliza:state.polizaReducer,

  };
};


export default connect(mapStateToProps,mapDispatchToProps)(ActualizarCertificadoComponent);