import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    archivocertificadopoliza: {
 id_archivocertificadopoliza : 0, id_certificadopoliza : 0, archivo : '',
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ARCHIVOCERTIFICADOPOLIZA:
      item = state.archivocertificadopoliza;
      action.fn(item);
      return { ...state,  archivocertificadopoliza: item };


    case types.GET_ARCHIVOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_ARCHIVOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', archivocertificadopoliza: respuesta };

    case types.GET_ARCHIVOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_ARCHIVOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ARCHIVOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_ARCHIVOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', archivocertificadopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ARCHIVOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_ARCHIVOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ARCHIVOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ARCHIVOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', archivocertificadopoliza: respuesta };

    case types.PUT_ARCHIVOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_ARCHIVOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ARCHIVOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ARCHIVOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ARCHIVOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_ARCHIVOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

