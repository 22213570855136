import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';


export const getDashboardVentasService = (anio, mes) => {
    const apiURL= baseurl(`dashboard/ventas/${anio}/${mes}`);
  
    const parameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getCookie('timeoff-token')
      }
    };
  
    return fetch(apiURL, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        return error;
      });
  };



export const getDashboardPagosService = (anio, mes) => {
  const apiURL= baseurl(`dashboard/pagos/${anio}/${mes}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};



export const getDashboardCertificadosService = () => {
  const apiURL= baseurl(`dashboard/certificados/`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};