import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderComponent from '../../components/commons/header/headerComponent';

import {  postPolizaAction, ejecutaEnPolizaAction } from '../../store/actions/db/actions';
import ActualizaPolizaView from './actualizaPolizaView';

class CreaPolizaComponent extends Component {
  state={}
  
  componentDidMount() {
    console.log('componentDidMount', this.props.item.id_estatuspoliza);
    if (this.props.item.id_estatuspoliza === 0){
      this.props.ejecutaEnPolizaAction(this.inicializa);
    }
  }

 inicializa = (pol) =>{
  pol.id_estatuspoliza = 1;
 }

  onHanldeGrabar = (event) => {
    event.preventDefault();
    console.log('Grabando.......', this.state);
    let newstate = this.state;
    newstate.grabado = false;
    newstate.poliza.fechainicio = event.target.fechainicio.value;
    console.log('Grabando.......', newstate.poliza);

    this.setState({...newstate
    }, () => {
      const data = { ...newstate.poliza
        };
      
      this.props.postPolizaAction(data);
    });
  }

  handleChange(event) {
    let name = event.target.name;
    let poliza = this.props.item;
    if (name.startsWith('id_')) {poliza[name]= parseInt(event.target.value,10)
    } else {
      poliza[name]= event.target.value;
    };
    this.setState({
      poliza: poliza
    });
  }



  render() {
    console.log('Render', this.props.item);
    if (this.props.state.grabado===true){
      const _url = '/poliza/actualizar/' + this.props.item.id_poliza
      return <Redirect to={_url} />;
    }
    let mensaje = ''
    if (this.props.state.grabado) {mensaje = 'Grabado'}
    return (
      <div >
        <HeaderComponent />
        
      <div className='container element'>
        <ActualizaPolizaView
                onHanldeGrabar={this.onHanldeGrabar}
                message={mensaje}
                success={this.props.state.grabado}
                poliza={this.props.item}
                handleChange={this.handleChange.bind(this)} 
                 />
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = {
  ejecutaEnPolizaAction,
  postPolizaAction
}

const mapStateToProps = (state) => {
  const x = state.polizaReducer;
  console.log('mapStateToProps',x.poliza.id_estatuspoliza);
 return {state:state.polizaReducer, item: state.polizaReducer.poliza};
};

export default connect(mapStateToProps,mapDispatchToProps)(CreaPolizaComponent);
 