import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    clavecomision: {
 id_clavecomision : 0, clavecomision : '',
      },

      lista_productoclavecomision_borrados : [],
      lista_usuario_borrados : [],
      lista_agente_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_CLAVECOMISION:
      item = state.clavecomision;
      action.fn(item);
      return { ...state,  clavecomision: item };


    case types.GET_CLAVECOMISION_SUCCESS:
      respuesta= action.response;
      console.log('GET_CLAVECOMISION_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', clavecomision: respuesta };

    case types.GET_CLAVECOMISION_ERROR:
      respuesta= action.response;
      console.log('GET_CLAVECOMISION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_CLAVECOMISION_SUCCESS:
      respuesta= action.response;
      console.log('POST_CLAVECOMISION_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', clavecomision: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_CLAVECOMISION_ERROR:
      respuesta= action.response;
      console.log('POST_CLAVECOMISION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_CLAVECOMISION_SUCCESS:
      respuesta= action.response;
      console.log('PUT_CLAVECOMISION_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', clavecomision: respuesta };

    case types.PUT_CLAVECOMISION_ERROR:
      respuesta= action.response;
      console.log('PUT_CLAVECOMISION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_CLAVECOMISION_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_CLAVECOMISION_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_CLAVECOMISION_ERROR:
      respuesta= action.response;
      console.log('DELETE_CLAVECOMISION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION:
      item = state.clavecomision;
      item.productosclavecomision= [...item.productosclavecomision, action.payload]
      return { ...state, productoclavecomision:item};


    case types.BORRA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION:
      item = state.clavecomision;
      let lista_productoclavecomision_borrados= state.lista_productoclavecomision_borrados
      if (item.productosclavecomision[action.payload].id_productoclavecomision > 0){
            const elementoproductoclavecomisionborrar = { id_productoclavecomision : item.productosclavecomision[action.payload].id_productoclavecomision }
            lista_productoclavecomision_borrados= [...lista_productoclavecomision_borrados,  elementoproductoclavecomisionborrar]
      }
      item.productosclavecomision.splice(action.payload,1)
      return { ...state, productoclavecomision:item, lista_productoclavecomision_borrados : lista_productoclavecomision_borrados};

 
    case types.INSERTA_USUARIO_EN_CLAVECOMISION:
      item = state.clavecomision;
      item.usuarios= [...item.usuarios, action.payload]
      return { ...state, usuario:item};


    case types.BORRA_USUARIO_EN_CLAVECOMISION:
      item = state.clavecomision;
      let lista_usuario_borrados= state.lista_usuario_borrados
      if (item.usuarios[action.payload].id_usuario > 0){
            const elementousuarioborrar = { id_usuario : item.usuarios[action.payload].id_usuario }
            lista_usuario_borrados= [...lista_usuario_borrados,  elementousuarioborrar]
      }
      item.usuarios.splice(action.payload,1)
      return { ...state, usuario:item, lista_usuario_borrados : lista_usuario_borrados};

 
    case types.INSERTA_AGENTE_EN_CLAVECOMISION:
      item = state.clavecomision;
      item.agentes= [...item.agentes, action.payload]
      return { ...state, agente:item};


    case types.BORRA_AGENTE_EN_CLAVECOMISION:
      item = state.clavecomision;
      let lista_agente_borrados= state.lista_agente_borrados
      if (item.agentes[action.payload].id_agente > 0){
            const elementoagenteborrar = { id_agente : item.agentes[action.payload].id_agente }
            lista_agente_borrados= [...lista_agente_borrados,  elementoagenteborrar]
      }
      item.agentes.splice(action.payload,1)
      return { ...state, agente:item, lista_agente_borrados : lista_agente_borrados};


    default:
      return state;
  }
};

