import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


import HeaderComponent from '../../components/commons/header/headerComponent';
import LoadingComponent from '../../components/commons/loading/loadingwithheaderComponent'

import { getArchivoPolizaAction, resetArchivoPolizaAction } from '../../store/actions/archivopoliza/actions';


import ArchivoPolizaView from './archivoPolizaView';
import ArchivoPolizaViewDetalle from './archivoPolizaViewDetalle'

class ArchivoPolizaComponent extends Component {
  
  state = {
  }

  componentDidMount() {
    console.log('componentDidMount',this.props.match.params.id);
    console.log('componentDidMount',this.state);
    const id1 = parseInt(this.props.match.params.id);
    const id2 = this.state.id_poliza;

    if (!this.state.cargado || id2!==id1) {
      console.log('CARGANDOSE NUEVAMENTE 1');
      this.props.dispatch(resetArchivoPolizaAction());
      this.props.dispatch(getArchivoPolizaAction({ id: id1 }));
    } 
    
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivered...nextProps...', nextProps);
    //console.log('getDerivered...prevState...', prevState);
    return nextProps.archivopoliza;
  }

  render() {
    console.log('RENDER ------- >', this.state);
    // if (this.state.success){
    //   const _url = '/poliza/actualizar/' + this.state.id_poliza
    //   return <Redirect to={_url} />;
    // }
    if (this.state.cargado === false) {
      return (
        <LoadingComponent />
        )
    }

    

      return (
        <div className='pb-5'>
          <HeaderComponent />
          <ArchivoPolizaView
                poliza={this.state} />

          <ArchivoPolizaViewDetalle
            poliza={this.state}
            list={this.state.certificadospoliza}  />
        </div>
      );
  }
}




const mapStateToProps = (state) => {

  console.log('mapStateToProps call');
  return (
  {
  archivopoliza: state.archivopolizaReducer.get
});

} 

export default connect(mapStateToProps)(ArchivoPolizaComponent);
