import React from 'react';
import InputField from '../../components/inputfield'
import Boton from '../../components/buttons/boton'


const ActualizarCertificadoViewAsegurados = (props) => {
  if (props.list === undefined) {
    return null;
  }

  return (
  
    <div className='p-3' >

    <Boton className='col-md-3' id='agregarasegurado' onClick={props.onAgregaAseguradoHandler} mostrar={props.certificadopoliza.id_estatuscertificado===1}/>
    
    

    {props.list
        .map((li,index) => (  
          <div key={index} className='container border border-primary p-4' >
        
      

        <div className='row '>
              <InputField type='combobox' className='col-md-2' 
                  field='aseguradoscertificadopoliza.id_parentezco' 
                  value={li.id_parentezco}
                  onChange={(e) => props.handleChange(e,index)}/>
              <div className='col-md-4'>
              </div>
              <InputField type='lookup' className='col-md-3' field="aseguradoscertificadopoliza.id_origen" value={li.id_origen}   />
                
              <InputField className='col-md-3' field="aseguradoscertificadopoliza.antiguedad" type="date" disabled={li.id_origen===1} value={li.antiguedad} onChange={(e) => props.handleChange(e,index)}  />
              
                
            </div>
      
            <div className='row'>
              <InputField className='col-md-4' field="aseguradoscertificadopoliza.nombre" value={li.nombre} onChange={(e) => props.handleChange(e,index)}  />
              <InputField className='col-md-4' field="aseguradoscertificadopoliza.appaterno" value={li.appaterno} onChange={(e) => props.handleChange(e,index)}  />
              <InputField className='col-md-4' field="aseguradoscertificadopoliza.apmaterno" value={li.apmaterno} onChange={(e) => props.handleChange(e,index)}  />
              
            </div>
                
            
            <div className='row'>
              <div className='col-md-10'>
                <div className='row'>
                      <InputField className='col-md-3' field="aseguradoscertificadopoliza.fechanacimiento" type="date" value={li.fechanacimiento} onChange={(e) => props.handleChange(e,index)}  />
                    
                    
                    <InputField type='combobox' className='col-md-3' 
                        field='aseguradoscertificadopoliza.id_genero' 
                        value={li.id_genero}
                        onChange={(e) => props.handleChange(e,index)}/>

                    <InputField className='col-md-2' type='number' field="aseguradoscertificadopoliza.edad" disabled value={li.edad} onChange={(e) => props.handleChange(e,index)}  />
                    <InputField className='col-md-2' type='number' step='0.01' field="aseguradoscertificadopoliza.estatura" value={li.estatura} onChange={(e) => props.handleChange(e,index)}  />
                    <InputField className='col-md-2' type='number' step='1' field="aseguradoscertificadopoliza.peso" value={li.peso} onChange={(e) => props.handleChange(e,index)}  />
                    
                </div>
                
              </div>
              
              <InputField className='col-md-2' field="aseguradoscertificadopoliza.indice" disabled value={li.indice} onChange={(e) => props.handleChange(e,index)}  />
                    
            </div>
      
            {(props.certificadopoliza.id_estatuscertificado===1) 
              ?<div className='d-flex flex-md-row flex-sm-column  align-items-end justify-content-end'>
                <Boton className='pt-4 pr-1' warning id='borrar' onClick={(e) => props.onBorrarAseguradoHandler(e, index)} />
                <Boton className='pt-4 pr-1' warning id='separarasegurado' onClick={(e) => props.onSepararAseguradoClick(e, props.id_certificadopoliza, li.id_aseguradocertificadopoliza, index)}  />
                <Boton className='pt-4 pr-1' warning id='enviaracertificado' onClick={(e) => props.onEnviarACertificadoClick(e, props.id_certificadopoliza, li.id_aseguradocertificadopoliza, index)}  />
                
                <InputField className='' field="clave.clave" value={li.clave} onChange={(e) => props.handleChange(e,index)}  />
              </div>
              :''
              }

      
            </div>

      ))
    }
    </div>
)
  }
export default ActualizarCertificadoViewAsegurados;