import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    benefcertificadopoliza: {
 id_benefcertificadopoliza : 0, id_certificadopoliza : 0, nombre : '', appaterno : '', apmaterno : '', fechanacimiento : '', parentezco : '', porcentaje : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_BENEFCERTIFICADOPOLIZA:
      item = state.benefcertificadopoliza;
      action.fn(item);
      return { ...state,  benefcertificadopoliza: item };


    case types.GET_BENEFCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_BENEFCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', benefcertificadopoliza: respuesta };

    case types.GET_BENEFCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_BENEFCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_BENEFCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_BENEFCERTIFICADOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', benefcertificadopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_BENEFCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_BENEFCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_BENEFCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_BENEFCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', benefcertificadopoliza: respuesta };

    case types.PUT_BENEFCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_BENEFCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_BENEFCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_BENEFCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_BENEFCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_BENEFCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

