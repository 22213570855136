import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    rutarol: {
 id_rutarol : 0, id_rol : 0, id_ruta : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_RUTAROL:
      item = state.rutarol;
      action.fn(item);
      return { ...state,  rutarol: item };


    case types.GET_RUTAROL_SUCCESS:
      respuesta= action.response;
      console.log('GET_RUTAROL_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', rutarol: respuesta };

    case types.GET_RUTAROL_ERROR:
      respuesta= action.response;
      console.log('GET_RUTAROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_RUTAROL_SUCCESS:
      respuesta= action.response;
      console.log('POST_RUTAROL_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', rutarol: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_RUTAROL_ERROR:
      respuesta= action.response;
      console.log('POST_RUTAROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_RUTAROL_SUCCESS:
      respuesta= action.response;
      console.log('PUT_RUTAROL_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', rutarol: respuesta };

    case types.PUT_RUTAROL_ERROR:
      respuesta= action.response;
      console.log('PUT_RUTAROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_RUTAROL_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_RUTAROL_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_RUTAROL_ERROR:
      respuesta= action.response;
      console.log('DELETE_RUTAROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

