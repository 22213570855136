import { takeEvery, takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/comisiones/types';

import {
    getComisionesAgentesSaga, putComisionesFacturaAgenteSaga, deleteComisionesFacturaAgenteSaga, postComisionesFacturaAgenteSaga,getComisionesAgenteSaga, getComisionesAgenteFacturasSaga, getComisionesAgenteDPsPendientesSaga, getComisionesFacturaDPsSaga, postComisionesAsingacionSaga, deleteComisionesAsingacionSaga } 
    from './comisionessaga';

export default function* watchConciliaPagos() {

    yield takeLeading (types.GET_COMISIONES_AGENTES, getComisionesAgentesSaga);
    yield takeLeading (types.GET_COMISIONES_AGENTE, getComisionesAgenteSaga);
    yield takeLeading (types.PUT_COMISIONES_FACTURAAGENTE, putComisionesFacturaAgenteSaga);
    yield takeLeading (types.DELETE_COMISIONES_FACTURAAGENTE, deleteComisionesFacturaAgenteSaga);
    yield takeLeading (types.POST_COMISIONES_FACTURAAGENTE, postComisionesFacturaAgenteSaga);
    yield takeLeading (types.GET_COMISIONES_AGENTE_FACTURAS, getComisionesAgenteFacturasSaga);
    yield takeLeading (types.GET_COMISIONES_AGENTE_DPSPENDIENTES, getComisionesAgenteDPsPendientesSaga);
    yield takeLeading (types.GET_COMISIONES_FACTURA_DPS, getComisionesFacturaDPsSaga);
    yield takeEvery (types.POST_COMISIONES_ASIGNACION, postComisionesAsingacionSaga);
    yield takeEvery (types.DELETE_COMISIONES_ASIGNACION, deleteComisionesAsingacionSaga);

}
