import { put, call } from 'redux-saga/effects';
import {
    getConciliaPagosPagadoService,
    getConciliaPagosPendientesService,
    postConciliaPagoService,
    deleteConciliaPagoService,
} from '../../services/conciliapagos/conciliapagosServices';

import * as types from '../../actions/conciliapagos/types'

export function* postConciliaPagoSaga(payload) {
    try {
      const response = yield call(postConciliaPagoService, payload);
      const accion = { type: types.POST_CONCILIAPAGO_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.POST_CONCILIAPAGO_ERROR, error };
      yield put(accion);
    }
  }

  export function* deleteConciliaPagoSaga(payload) {
    try {
      const response = yield call(deleteConciliaPagoService, payload);
      const accion = { type: types.DELETE_CONCILIAPAGO_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.DELETE_CONCILIAPAGO_ERROR, error };
      yield put(accion);
    }
  }


export function* getConciliaPagosPagadoSaga(payload) {
    try {
      const response = yield call(getConciliaPagosPagadoService, payload);
      const accion = { type: types.GET_CONCILIAPAGOS_PAGADO_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_CONCILIAPAGOS_PAGADO_ERROR, error };
      yield put(accion);
    }
  }

  
export function* getConciliaPagosPendienteSaga(payload) {
    try {
      const response = yield call(getConciliaPagosPendientesService, payload);
      const accion = { type: types.GET_CONCILIAPAGOS_PENDIENTE_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_CONCILIAPAGOS_PENDIENTE_ERROR, error };
      yield put(accion);
    }
  }
  