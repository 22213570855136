
export const GET_DASHBOARD_VENTAS = 'GET_DASHBOARD_VENTAS';
export const GET_DASHBOARD_VENTAS_SUCCESS = 'GET_DASHBOARD_VENTAS_SUCCESS';
export const GET_DASHBOARD_VENTAS_ERROR = 'GET_DASHBOARD_VENTAS_ERROR';

export const GET_DASHBOARD_PAGOS = 'GET_DASHBOARD_PAGOS';
export const GET_DASHBOARD_PAGOS_SUCCESS = 'GET_DASHBOARD_PAGOS_SUCCESS';
export const GET_DASHBOARD_PAGOS_ERROR = 'GET_DASHBOARD_PAGOS_ERROR';

export const GET_DASHBOARD_CERTIFICADOS = 'GET_DASHBOARD_CERTIFICADOS';
export const GET_DASHBOARD_CERTIFICADOS_SUCCESS = 'GET_DASHBOARD_CERTIFICADOS_SUCCESS';
export const GET_DASHBOARD_CERTIFICADOS_ERROR = 'GET_DASHBOARD_CERTIFICADOS_ERROR';


export const SET_DASHBOARD_PAGOS_VISTA = 'SET_DASHBOARD_PAGOS_VISTA';

export const SET_DASHBOARD_PAGOS_FILTRO = 'SET_DASHBOARD_PAGOS_FILTRO';
export const SET_DASHBOARD_CERTIFICADOS_FILTRO = 'SET_DASHBOARD_CERTIFICADOS_FILTRO';
export const SET_DASHBOARD_VENTAS_FILTRO = 'SET_DASHBOARD_VENTAS_FILTRO';