import { put, call } from 'redux-saga/effects';
import {
    getListaService
} from '../../services/prueba/services';

import * as types from '../../actions/prueba/types'
//import {getSegurosSuccessAction} from '../../actions/db/actions'
export function* getListaSaga(payload) {
  try {
    let response = [
        {
            lista:1,
            nombre:"Ricardo"
         },
         {
             lista:2,
             nombre:"Raquel"
          },
          {
              lista:3,
              nombre:"Diego"
           }
    ]
    
    if (1==0) {
        const response2 = yield call(getListaService, payload);
        response = response2
    }
    //const x = getSegurosSuccessAction(response);
    //console.log('Saga...', x);
    const accion = { type: types.GET_LISTA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    yield put({ type: types.GET_LISTA_ERROR, error });
  }
}