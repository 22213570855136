import React from 'react';
import InputField from '../../components/inputfield'
import Boton from '../../components/buttons/boton'


const ActualizarCertificadoViewEndosos = (props) => {
  if (props.list === undefined) {
    return null;
  }

  return (
  
    <div className='p-3' >

    <Boton className='col-md-3' id='agregarendoso' onClick={props.onAgregaEndosoHandler} mostrar={props.certificadopoliza.id_estatuscertificado===1}/>
    
    

    {props.list
        .map((li,index) => (  
          <div key={index} className='container border border-primary p-4' >
        
      

        <div className='row '>
              <InputField className='col-md-4' field="endososcertificadopoliza.endoso" disabled={props.certificadopoliza.id_estatuscertificado!==1 || li.id_endosocertificadopoliza !== 0}  value={li.endoso} onChange={(e) => props.handleChange(e,index)}  />
              
                
              <InputField className='col-md-3' field="endososcertificadopoliza.fechainicio" disabled={props.certificadopoliza.id_estatuscertificado!==1 || li.id_endosocertificadopoliza !== 0} type="date" value={li.fechainicio} onChange={(e) => props.handleChange(e,index)}  />
              
                
            </div>
      
            {(li.id_endosocertificadopoliza===0 ||
              (props.certificadopoliza.id_estatuscertificado===1 && 
                props.certificadopoliza.dpscertificadopoliza.filter(item=>(item.id_estatuspago===2 && item.id_endosocertificadopoliza===li.id_endosocertificadopoliza)).length === 0) )
              ?<div className='d-flex flex-md-row flex-sm-column  align-items-end justify-content-end'>
                <Boton className='pt-4 pr-1' warning id='borrar' onClick={(e) => props.onBorrarEndosoHandler(e, index)} />
                
                <InputField className='' field="clave.clave" value={li.clave} onChange={(e) => props.handleChange(e,index)}  />
              </div>
              :''
              }

      
            </div>

      ))
    }
    </div>
)
  }
export default ActualizarCertificadoViewEndosos;