import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    actualizacion: {
 id_actualizacion : 0, id_usuario : 0, fecha : '', id_operacion : 0, id_poliza : 0, id_certificadopoliza : 0, id_dpcertificadopoliza : 0, id_listadeprecio : 0, id_aseguradocertificadopoliza : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ACTUALIZACION:
      item = state.actualizacion;
      action.fn(item);
      return { ...state,  actualizacion: item };


    case types.GET_ACTUALIZACION_SUCCESS:
      respuesta= action.response;
      console.log('GET_ACTUALIZACION_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', actualizacion: respuesta };

    case types.GET_ACTUALIZACION_ERROR:
      respuesta= action.response;
      console.log('GET_ACTUALIZACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ACTUALIZACION_SUCCESS:
      respuesta= action.response;
      console.log('POST_ACTUALIZACION_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', actualizacion: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ACTUALIZACION_ERROR:
      respuesta= action.response;
      console.log('POST_ACTUALIZACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ACTUALIZACION_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ACTUALIZACION_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', actualizacion: respuesta };

    case types.PUT_ACTUALIZACION_ERROR:
      respuesta= action.response;
      console.log('PUT_ACTUALIZACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ACTUALIZACION_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ACTUALIZACION_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ACTUALIZACION_ERROR:
      respuesta= action.response;
      console.log('DELETE_ACTUALIZACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

