import * as types from '../../actions/conciliapagos/types';

const initialState = {
  cargado:false,
  paramspendientes: {
    fecha: '',
    poliza:'',
    certificado:'',
    contratante:'',
  },
  paramspagados: {
    fecha: '',
    id_mediopago:0,
  },
  listapendientes:[],
  listapagados:[],
      
};



export default function(state = initialState, action) {
  if (state.paramspendientes.fecha==='') {
    const dia = new Date().getDate();
    const mes = new Date().getMonth()+1;
    const anio = new Date().getFullYear();
    var messtr = ''
    var diastr = ''
    if (mes < 10) messtr = '0' + mes; else messtr = mes ;
    if (dia < 10) diastr = '0' + dia; else diastr = dia ;
    state.paramspendientes.fecha = anio + '-' + messtr + '-' + diastr;
    state.paramspagados.fecha = anio + '-' + messtr + '-' + diastr;
  }


  //console.log('buscarReducer', action);
  switch(action.type) {
    case types.GET_CONCILIAPAGOS_PENDIENTE_SUCCESS:
      const response1 = action.response;
      if (response1.listapendientes === undefined) {
        return {...state}
      }
      return { ...state, listapendientes: response1.listapendientes };
    case types.GET_CONCILIAPAGOS_PENDIENTE_ERROR:
      return { ...state };

    case types.GET_CONCILIAPAGOS_PAGADO_SUCCESS:
      const response2 = action.response;
      if (response2.listapagados === undefined) {
        return {...state}
      }
      return { ...state, listapagados: response2.listapagados };
    case types.GET_CONCILIAPAGOS_PAGADO_ERROR:
      return { ...state };

    case types.POST_CONCILIAPAGO_SUCCESS:
      const response3 = action.response;
      console.log('******************', response3);
      if (response3.id_dpcertificadopoliza > 0 ) {
          const estado = state;
          let lpend = estado.listapendientes;
          let lpag = estado.listapagados;
          let item = lpend.filter(elem=> elem.id_dpcertificadopoliza === response3.id_dpcertificadopoliza)
          let items = lpend.filter(elem=> elem.id_dpcertificadopoliza !== response3.id_dpcertificadopoliza)
          let l2 = [...lpag, ...item]
          console.log('object', l2);
      return { ...state, listapagados: l2, listapendientes: items };

      }
      return { ...state };

    case types.DELETE_CONCILIAPAGO_SUCCESS:
      const response4 = action.response;
      console.log('******************', response4);
      if (response4.id_dpcertificadopoliza > 0 ) {
          const estado = state;
          let lpend = estado.listapendientes;
          let lpag = estado.listapagados;
          let item = lpag.filter(elem=> elem.id_dpcertificadopoliza === response4.id_dpcertificadopoliza)
          let items = lpag.filter(elem=> elem.id_dpcertificadopoliza !== response4.id_dpcertificadopoliza)
          let l2 = [...lpend, ...item]
          console.log('object', l2);
      return { ...state, listapagados: items, listapendientes: l2 };

      }

      return { ...state };
    default:
      return state;
  }
};

