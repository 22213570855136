import React, {Component} from 'react'
import HeaderComponent from '../header/headerComponent'



class LoadingComponent extends Component{


render() {
    return (
        <div>
          <HeaderComponent />
          <div>Loading...</div>
        </div>
        )
}

}

export default LoadingComponent