import * as types from '../../actions/auth/types';
import { setCookie } from '../../../utils/cookies';

var initialstate = {
  isSuccess: false,
  message: ''
};

var responseLogout = {
    "token":"",
    "success":false,
    "message":"",
  }

export default function(state = initialstate, action) {
  const response = action.response;


  switch(action.type) {
    case types.LOGIN_USER_SUCCESS:
      console.log('LOGIN_USER_SUCCESS',response)
      setCookie('timeoff-token', response.token, 12);
      setCookie('role', response.role, 12);
      setCookie('id', response.id, 12);
      setCookie('name', response.name, 12);
      setCookie('username', response.name, 12);
      return { ...state, response };
    case types.LOGIN_USER_ERROR:
      return { ...state, response };
    case types.LOGOUT_USER_SUCCESS:
        return { ...state, response: {...responseLogout} };
    default:
      return state;
  }
};