import React, { Component } from 'react'


class anteriorsiguienteComponent extends Component {

    state = {
        liga:'',
        anio: 0 ,
        mes:0,
        periodo:'',
        periodoanterior:'',
        periodosiguiente:'',
      }

  constructor(props) {
    super(props);
    console.log('Parametros', props);
    //const dia = new Date().getDate();
    let anio = parseInt(this.props.anio,10);
    let mes = parseInt(this.props.mes,10);
    let liga = this.props.liga;

    const periodo = this.getMes(mes) + '-' + anio
    const periodoanterior = this.getPeriodoAnterior(anio, mes)
    const periodosiguiente= this.getPeriodoSiguiente(anio,mes)

    this.state = {
      anio: anio ,
      mes:mes,
      liga:liga,
      periodo:periodo,
      periodoanterior:periodoanterior,
      periodosiguiente:periodosiguiente,
    }

  }

getPeriodoAnterior(anio, mes){
    if (mes === 1) { 
      mes = 12;
      anio = anio - 1
    }
    else {
      mes--;
    }
    return anio.toString() + '/' + mes
  }
  
  
  getPeriodoSiguiente(anio, mes){
    if (mes === 12) { 
      mes = 1;
      anio = anio + 1
    }
    else {
      mes++
    }
    return anio.toString() + '/' + mes
  }
  
getMes(mes){
  switch (mes) {
    case 1: return 'Ene'; 
    case 2: return 'Feb'; 
    case 3: return 'Mar'; 
    case 4: return 'Abr'; 
    case 5: return 'May'; 
    case 6: return 'Jun'; 
    case 7: return 'Jul'; 
    case 8: return 'Ago'; 
    case 9: return 'Sep'; 
    case 10: return 'Oct'; 
    case 11: return 'Nov'; 
    case 12: return 'Dic'; 
    default: return ''; 
  }
}

  render() {
    return (
        <div className='row mb-3 p-1 border-bottom border-top border-primary'>
            <div className='col-12 text-right'>
                <a className="btn btn-primary mr-3 "  href={this.state.liga + this.state.periodoanterior}><i className="fas fa-angle-double-left"></i></a>
                <label className='h4 '>{this.state.periodo}</label>
                <a className="btn btn-primary ml-3 "  href={this.state.liga + this.state.periodosiguiente}><i className="fas fa-angle-double-right"></i></a>
            </div>
        </div>
    )
  }
}



export default anteriorsiguienteComponent;