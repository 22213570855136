import { put, call } from 'redux-saga/effects';
import {
    postSepararAseguradoCertificadoPolizaService,
    postEnviarAseguradoCertificadoPolizaService,
} from '../../services/procesos/aseguradoscertificadopolizaServices';

import * as types from '../../actions/db/types'



  export function* postSepararAseguradoCertificadoPolizaSaga(payload) {
    try {
  
      let response = yield call(postSepararAseguradoCertificadoPolizaService, payload.id_aseguradocertificadopoliza);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const certificadopoliza = {id : payload.id_certificadopoliza } 
      const accion = { type: types.GET_CERTIFICADOPOLIZA, id:certificadopoliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }



  export function* postEnviarAseguradoCertificadoPolizaSaga({payload}) {
    try {
  console.log('object', payload);
      let response = yield call(postEnviarAseguradoCertificadoPolizaService, payload);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const certificadopoliza = {id : payload.id_certificadopoliza } 
      const accion = { type: types.GET_CERTIFICADOPOLIZA, id:certificadopoliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }