export const ASIGNA_DPS_POLIZA = 'ASIGNA_DPS_POLIZA';
export const ASIGNA_DPS_POLIZA_SUCCESS = 'ASIGNA_DPS_POLIZA_SUCCESS';
export const ASIGNA_DPS_POLIZA_ERROR = 'ASIGNA_DPS_POLIZA_ERROR';

export const ASIGNA_CERTIFICADOS_POLIZA = 'ASIGNA_CERTIFICADOS_POLIZA';
export const ASIGNA_CERTIFICADOS_POLIZA_SUCCESS = 'ASIGNA_CERTIFICADOS_POLIZA_SUCCESS';
export const ASIGNA_CERTIFICADOS_POLIZA_ERROR = 'ASIGNA_CERTIFICADOS_POLIZA_ERROR';

export const AGREGAR_RENOVACIONES_POLIZA = 'AGREGAR_RENOVACIONES_POLIZA';
export const AGREGAR_RENOVACIONES_POLIZA_SUCCESS = 'AGREGAR_RENOVACIONES_POLIZA_SUCCESS';
export const AGREGAR_RENOVACIONES_POLIZA_ERROR = 'AGREGAR_RENOVACIONES_POLIZA_ERROR';

export const ABRIR_POLIZA = 'ABRIR_POLIZA';
export const ABRIR_POLIZA_SUCCESS = 'ABRIR_POLIZA_SUCCESS';
export const ABRIR_POLIZA_ERROR = 'ABRIR_POLIZA_ERROR';

export const CERRAR_POLIZA = 'CERRAR_POLIZA';
export const CERRAR_POLIZA_SUCCESS = 'CERRAR_POLIZA_SUCCESS';
export const CERRAR_POLIZA_ERROR = 'CERRAR_POLIZA_ERROR';

export const BORRAR_POLIZA = 'BORRAR_POLIZA';
export const BORRAR_POLIZA_SUCCESS = 'BORRAR_POLIZA_SUCCESS';
export const BORRAR_POLIZA_ERROR = 'BORRAR_POLIZA_ERROR';

export const ABRIR_CERTIFICADOPOLIZA = 'ABRIR_CERTIFICADOPOLIZA';
export const ABRIR_CERTIFICADOPOLIZA_SUCCESS = 'ABRIR_CERTIFICADOPOLIZA_SUCCESS';
export const ABRIR_CERTIFICADOPOLIZA_ERROR = 'ABRIR_CERTIFICADOPOLIZA_ERROR';

export const CERRAR_CERTIFICADOPOLIZA = 'CERRAR_CERTIFICADOPOLIZA';
export const CERRAR_CERTIFICADOPOLIZA_SUCCESS = 'CERRAR_CERTIFICADOPOLIZA_SUCCESS';
export const CERRAR_CERTIFICADOPOLIZA_ERROR = 'CERRAR_CERTIFICADOPOLIZA_ERROR';

export const CANCELAR_CERTIFICADOPOLIZA = 'CANCELAR_CERTIFICADOPOLIZA';
export const CANCELAR_CERTIFICADOPOLIZA_SUCCESS = 'CANCELAR_CERTIFICADOPOLIZA_SUCCESS';
export const CANCELAR_CERTIFICADOPOLIZA_ERROR = 'CANCELAR_CERTIFICADOPOLIZA_ERROR';

export const ACTIVAR_CERTIFICADOPOLIZA = 'ACTIVAR_CERTIFICADOPOLIZA';
export const ACTIVAR_CERTIFICADOPOLIZA_SUCCESS = 'ACTIVAR_CERTIFICADOPOLIZA_SUCCESS';
export const ACTIVAR_CERTIFICADOPOLIZA_ERROR = 'ACTIVAR_CERTIFICADOPOLIZA_ERROR';

export const BORRAR_CERTIFICADOPOLIZA = 'BORRAR_CERTIFICADOPOLIZA';
export const BORRAR_CERTIFICADOPOLIZA_SUCCESS = 'BORRAR_CERTIFICADOPOLIZA_SUCCESS';
export const BORRAR_CERTIFICADOPOLIZA_ERROR = 'BORRAR_CERTIFICADOPOLIZA_ERROR';
export const BORRAR_CERTIFICADOPOLIZA_RESET = 'BORRAR_CERTIFICADOPOLIZA_RESET';

export const SEPARAR_ASEGURADOCERTIFICADOPOLIZA = 'SEPARAR_ASEGURADOCERTIFICADOPOLIZA';
export const SEPARAR_ASEGURADOCERTIFICADOPOLIZA_SUCCESS = 'SEPARAR_ASEGURADOCERTIFICADOPOLIZA_SUCCESS';
export const SEPARAR_ASEGURADOCERTIFICADOPOLIZA_ERROR = 'SEPARAR_ASEGURADOCERTIFICADOPOLIZA_ERROR';

export const ENVIAR_ASEGURADOCERTIFICADOPOLIZA = 'ENVIAR_ASEGURADOCERTIFICADOPOLIZA';
export const ENVIAR_ASEGURADOCERTIFICADOPOLIZA_SUCCESS = 'ENVIAR_ASEGURADOCERTIFICADOPOLIZA_SUCCESS';
export const ENVIAR_ASEGURADOCERTIFICADOPOLIZA_ERROR = 'ENVIAR_ASEGURADOCERTIFICADOPOLIZA_ERROR';
