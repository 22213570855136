
import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



export const postAsignaDPsPolizaService = (id) => {
    const apiURL= baseurl(`proceso/asignadpspoliza/${id}`);
  
    const parameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getCookie('timeoff-token')
      }
    };
  
    return fetch(apiURL, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        return error;
      });
  };


export const postAsignaCertificadosPolizaService = (id) => {
  const apiURL= baseurl(`proceso/asignacertificadospoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};



export const postAgregarRenovacionesPolizaService = (id) => {
  const apiURL= baseurl(`proceso/agregarrenovacionespoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};


export const postAbrirPolizaService = (id) => {
  const apiURL= baseurl(`proceso/abrirpoliza/${id}`);
  console.log('abriend¡o poliza ****************');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};


export const postCerrarPolizaService = (id) => {
  const apiURL= baseurl(`proceso/cerrarpoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const postBorrarPolizaService = (id) => {
  const apiURL= baseurl(`proceso/borrarpoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};