import React from 'react';
import ReactToExcel from "react-html-table-to-excel";
import InputField from '../../components/inputfield'
import FieldHeader from '../../components/fieldheader'
import {Link} from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'

const ConciliaPagosViewPendiente = (props) => {
  if (props.list === undefined) {
    return null;
  }
  
  return (
    <div className='pl-3 pt-3'>
       
      <div>
        <div className='row py-3'>
            <div className=" col-md-2">
              <ReactToExcel 
                table="tabla-comisiones" 
                filename="comisionespendientes"
                sheet='ComisionesPendientes'
                buttonText="export"
                />
            </div>
        </div>
      </div>
      <div className='row'>

        <InputField className='col-md-12 pb-2' type='text' field='agentes.agente' value={props.params.agente} onChange={props.handleChange} />
        
          
      </div>
      <div className='table-responsive'>
        <table id="tabla-comisiones" className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>


            <th><FieldHeader field={'agentes.agente'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.comisionagente'} /></th>
              <th>IVA</th>
              <th>Ret IVA</th>
              <th>Ret ISR</th>
              <th><FieldHeader field={'dpscertificadopoliza.totalcomision'} /></th>

            </tr>
          </thead>
          <tbody>
            {props.list
              .sort((a, b) => a.agente -  b.agente)
              .filter( elem => { 
                 return elem.agente.toUpperCase().includes(props.params.agente.toUpperCase()) })
              .map(li => (  
              <tr key={li.id_agente}>
              <td>
                    
                  <Link className='name' to={`/comisiones/agente/${li.id_agente}`}>{li.agente}</Link>
                
                  </td>
                <td ><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                        value={li.comisionpendiente}/>
                </td>
                <td >
                  <CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                        value={li.iva}/>
                </td>
                <td ><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                      value={li.retiva}/>
                </td>
                <td ><CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                      value={li.retisr}/>
                </td>
                <td align='right' >
                <CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.total}/>
               
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default ConciliaPagosViewPendiente;