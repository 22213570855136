import * as types from './types';


export const getCargaPagosOxxoAction = (fechainicio, fechatermino) => {
    return {
      type: types.GET_CARGAPAGOSOXXO,
      payload: {fechainicio, fechatermino}
    }
  };


  export const getCargaPagosOxxoDetalleAction = (id_pagooxxo) => {
    return {
      type: types.GET_CARGAPAGOSOXXODETALLE,
      payload: {id_pagooxxo}
    }
  };
