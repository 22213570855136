import forgot from './forgotReducer';
import login from './loginReducer';
import register from './registerReducer';




export default {
    forgot, login, register  
}

