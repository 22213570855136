import { call, put, take } from 'redux-saga/effects';
import { uploadProgress, uploadSuccess, uploadFailure } from '../../actions/uploadfile/actions';
import { createUploadFileChannel2, createUploadFileChannel } from '../../services/uploadfile/uploadfile';

// Upload the specified file
export function* uploadFileSagaChannel({meta}) {
    console.log('Saga', meta);
    const file = meta.file;
    const path = meta.path;
    const channel = yield call(createUploadFileChannel, path, file);
    while (true) {
        const { progress = 0, err, success } = yield take(channel);
        if (err) {
            yield put(uploadFailure(file, path, err));
            return;
        }
        if (success) {
            yield put(uploadSuccess(file, path));
            return;
        }
        yield put(uploadProgress(file, path, progress));
    }
}


export function* uploadFileSaga(action) {
    console.log('Saga', action.meta);
    try {
        const file = action.meta.file;
        const path = action.meta.path;

      let response = yield call(createUploadFileChannel2, path, file);
      console.log('Saga Response', response);
      if (response.err){
        yield put(uploadFailure(file, path, response.err));
      }
      else{
        yield put(uploadSuccess(file, path));
        yield put(uploadSuccess(file, path));
      }
    } catch(error) {
    }
  }