import {  takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/archivopoliza/types';

import {
    getArchivoPolizaSaga
 } 
    from './archivopolizasaga';

export default function* watchConciliaPagos() {

    yield takeLeading (types.GET_ARCHIVPOLIZA, getArchivoPolizaSaga);

}
