import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    certificadopoliza: {
 id_certificadopoliza : 0, id_poliza : 0, certificado : 0, antiguedad : '', contratante : '', aseguradotitular : '', id_agente : 0, id_origen : 0, observaciones : '', id_producto : 0, id_formapago : 0, id_estatuscertificado : 0, codigo : '', codigoorigen : '', id_certificadopolizaorigen : 0, search : '', validacion : '', c_nombre : '', c_appaterno : '', c_apmaterno : '', c_fnac : '', c_ocupacion : '', c_estadocivil : '', c_domicilio : '', c_domicilionumero : '', c_cp : '', c_colonia : '', c_ciudad : '', c_estado : '', c_correoelectronico1 : '', c_telefono : '', c_movil : '', c_giro : '', c_rfc : '', c_curp : '', t_nombre : '', t_appaterno : '', t_apmaterno : '', t_fnac : '', t_ocupacion : '', t_estadocivil : '', t_domicilio : '', t_domicilionumero : '', t_cp : '', t_colonia : '', t_ciudad : '', t_estado : '', t_correoelectronico1 : '', t_telefono : '', t_movil : '', t_giro : '', t_rfc : '', t_curp : '', t_correoelectronico2 : '', c_correoelectronico2 : '', primas : 0, primaneta : 0, cantdps : 0, id_motivocancelacion : 0,
      },

      lista_aseguradocertificadopoliza_borrados : [],
      lista_telcertificadopoliza_borrados : [],
      lista_archivocertificadopoliza_borrados : [],
      lista_dpcertificadopoliza_borrados : [],
      lista_actualizacion_borrados : [],
      lista_registropagooxxo_borrados : [],
      lista_endosocertificadopoliza_borrados : [],
      lista_benefcertificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      action.fn(item);
      return { ...state,  certificadopoliza: item };


    case types.GET_CERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_CERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', certificadopoliza: respuesta };

    case types.GET_CERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_CERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_CERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_CERTIFICADOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', certificadopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_CERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_CERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_CERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_CERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', certificadopoliza: respuesta };

    case types.PUT_CERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_CERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_CERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_CERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_CERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_CERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.aseguradoscertificadopoliza= [...item.aseguradoscertificadopoliza, action.payload]
      return { ...state, aseguradocertificadopoliza:item};


    case types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_aseguradocertificadopoliza_borrados= state.lista_aseguradocertificadopoliza_borrados
      if (item.aseguradoscertificadopoliza[action.payload].id_aseguradocertificadopoliza > 0){
            const elementoaseguradocertificadopolizaborrar = { id_aseguradocertificadopoliza : item.aseguradoscertificadopoliza[action.payload].id_aseguradocertificadopoliza }
            lista_aseguradocertificadopoliza_borrados= [...lista_aseguradocertificadopoliza_borrados,  elementoaseguradocertificadopolizaborrar]
      }
      item.aseguradoscertificadopoliza.splice(action.payload,1)
      return { ...state, aseguradocertificadopoliza:item, lista_aseguradocertificadopoliza_borrados : lista_aseguradocertificadopoliza_borrados};

 
    case types.INSERTA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.telscertificadopoliza= [...item.telscertificadopoliza, action.payload]
      return { ...state, telcertificadopoliza:item};


    case types.BORRA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_telcertificadopoliza_borrados= state.lista_telcertificadopoliza_borrados
      if (item.telscertificadopoliza[action.payload].id_telcertificadopoliza > 0){
            const elementotelcertificadopolizaborrar = { id_telcertificadopoliza : item.telscertificadopoliza[action.payload].id_telcertificadopoliza }
            lista_telcertificadopoliza_borrados= [...lista_telcertificadopoliza_borrados,  elementotelcertificadopolizaborrar]
      }
      item.telscertificadopoliza.splice(action.payload,1)
      return { ...state, telcertificadopoliza:item, lista_telcertificadopoliza_borrados : lista_telcertificadopoliza_borrados};

 
    case types.INSERTA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.archivoscertificadopoliza= [...item.archivoscertificadopoliza, action.payload]
      return { ...state, archivocertificadopoliza:item};


    case types.BORRA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_archivocertificadopoliza_borrados= state.lista_archivocertificadopoliza_borrados
      if (item.archivoscertificadopoliza[action.payload].id_archivocertificadopoliza > 0){
            const elementoarchivocertificadopolizaborrar = { id_archivocertificadopoliza : item.archivoscertificadopoliza[action.payload].id_archivocertificadopoliza }
            lista_archivocertificadopoliza_borrados= [...lista_archivocertificadopoliza_borrados,  elementoarchivocertificadopolizaborrar]
      }
      item.archivoscertificadopoliza.splice(action.payload,1)
      return { ...state, archivocertificadopoliza:item, lista_archivocertificadopoliza_borrados : lista_archivocertificadopoliza_borrados};

 
    case types.INSERTA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.dpscertificadopoliza= [...item.dpscertificadopoliza, action.payload]
      return { ...state, dpcertificadopoliza:item};


    case types.BORRA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_dpcertificadopoliza_borrados= state.lista_dpcertificadopoliza_borrados
      if (item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza > 0){
            const elementodpcertificadopolizaborrar = { id_dpcertificadopoliza : item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza }
            lista_dpcertificadopoliza_borrados= [...lista_dpcertificadopoliza_borrados,  elementodpcertificadopolizaborrar]
      }
      item.dpscertificadopoliza.splice(action.payload,1)
      return { ...state, dpcertificadopoliza:item, lista_dpcertificadopoliza_borrados : lista_dpcertificadopoliza_borrados};

 
    case types.INSERTA_ACTUALIZACION_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};

 
    case types.INSERTA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.registrospagooxxo= [...item.registrospagooxxo, action.payload]
      return { ...state, registropagooxxo:item};


    case types.BORRA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_registropagooxxo_borrados= state.lista_registropagooxxo_borrados
      if (item.registrospagooxxo[action.payload].id_registropagooxxo > 0){
            const elementoregistropagooxxoborrar = { id_registropagooxxo : item.registrospagooxxo[action.payload].id_registropagooxxo }
            lista_registropagooxxo_borrados= [...lista_registropagooxxo_borrados,  elementoregistropagooxxoborrar]
      }
      item.registrospagooxxo.splice(action.payload,1)
      return { ...state, registropagooxxo:item, lista_registropagooxxo_borrados : lista_registropagooxxo_borrados};

 
    case types.INSERTA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.endososcertificadopoliza= [...item.endososcertificadopoliza, action.payload]
      return { ...state, endosocertificadopoliza:item};


    case types.BORRA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_endosocertificadopoliza_borrados= state.lista_endosocertificadopoliza_borrados
      if (item.endososcertificadopoliza[action.payload].id_endosocertificadopoliza > 0){
            const elementoendosocertificadopolizaborrar = { id_endosocertificadopoliza : item.endososcertificadopoliza[action.payload].id_endosocertificadopoliza }
            lista_endosocertificadopoliza_borrados= [...lista_endosocertificadopoliza_borrados,  elementoendosocertificadopolizaborrar]
      }
      item.endososcertificadopoliza.splice(action.payload,1)
      return { ...state, endosocertificadopoliza:item, lista_endosocertificadopoliza_borrados : lista_endosocertificadopoliza_borrados};

 
    case types.INSERTA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      item.benefscertificadopoliza= [...item.benefscertificadopoliza, action.payload]
      return { ...state, benefcertificadopoliza:item};


    case types.BORRA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA:
      item = state.certificadopoliza;
      let lista_benefcertificadopoliza_borrados= state.lista_benefcertificadopoliza_borrados
      if (item.benefscertificadopoliza[action.payload].id_benefcertificadopoliza > 0){
            const elementobenefcertificadopolizaborrar = { id_benefcertificadopoliza : item.benefscertificadopoliza[action.payload].id_benefcertificadopoliza }
            lista_benefcertificadopoliza_borrados= [...lista_benefcertificadopoliza_borrados,  elementobenefcertificadopolizaborrar]
      }
      item.benefscertificadopoliza.splice(action.payload,1)
      return { ...state, benefcertificadopoliza:item, lista_benefcertificadopoliza_borrados : lista_benefcertificadopoliza_borrados};


    default:
      return state;
  }
};

