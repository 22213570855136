import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    rol: {
 id_rol : 0, rol : '',
      },

      lista_rutarol_borrados : [],
      lista_rolperfil_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ROL:
      item = state.rol;
      action.fn(item);
      return { ...state,  rol: item };


    case types.GET_ROL_SUCCESS:
      respuesta= action.response;
      console.log('GET_ROL_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', rol: respuesta };

    case types.GET_ROL_ERROR:
      respuesta= action.response;
      console.log('GET_ROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ROL_SUCCESS:
      respuesta= action.response;
      console.log('POST_ROL_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', rol: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ROL_ERROR:
      respuesta= action.response;
      console.log('POST_ROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ROL_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ROL_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', rol: respuesta };

    case types.PUT_ROL_ERROR:
      respuesta= action.response;
      console.log('PUT_ROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ROL_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ROL_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ROL_ERROR:
      respuesta= action.response;
      console.log('DELETE_ROL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_RUTAROL_EN_ROL:
      item = state.rol;
      item.rutasrol= [...item.rutasrol, action.payload]
      return { ...state, rutarol:item};


    case types.BORRA_RUTAROL_EN_ROL:
      item = state.rol;
      let lista_rutarol_borrados= state.lista_rutarol_borrados
      if (item.rutasrol[action.payload].id_rutarol > 0){
            const elementorutarolborrar = { id_rutarol : item.rutasrol[action.payload].id_rutarol }
            lista_rutarol_borrados= [...lista_rutarol_borrados,  elementorutarolborrar]
      }
      item.rutasrol.splice(action.payload,1)
      return { ...state, rutarol:item, lista_rutarol_borrados : lista_rutarol_borrados};

 
    case types.INSERTA_ROLPERFIL_EN_ROL:
      item = state.rol;
      item.rolesperfil= [...item.rolesperfil, action.payload]
      return { ...state, rolperfil:item};


    case types.BORRA_ROLPERFIL_EN_ROL:
      item = state.rol;
      let lista_rolperfil_borrados= state.lista_rolperfil_borrados
      if (item.rolesperfil[action.payload].id_rolperfil > 0){
            const elementorolperfilborrar = { id_rolperfil : item.rolesperfil[action.payload].id_rolperfil }
            lista_rolperfil_borrados= [...lista_rolperfil_borrados,  elementorolperfilborrar]
      }
      item.rolesperfil.splice(action.payload,1)
      return { ...state, rolperfil:item, lista_rolperfil_borrados : lista_rolperfil_borrados};


    default:
      return state;
  }
};

