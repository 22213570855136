import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    origen: {
 id_origen : 0, origen : '',
      },

      lista_certificadopoliza_borrados : [],
      lista_aseguradocertificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ORIGEN:
      item = state.origen;
      action.fn(item);
      return { ...state,  origen: item };


    case types.GET_ORIGEN_SUCCESS:
      respuesta= action.response;
      console.log('GET_ORIGEN_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', origen: respuesta };

    case types.GET_ORIGEN_ERROR:
      respuesta= action.response;
      console.log('GET_ORIGEN_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ORIGEN_SUCCESS:
      respuesta= action.response;
      console.log('POST_ORIGEN_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', origen: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ORIGEN_ERROR:
      respuesta= action.response;
      console.log('POST_ORIGEN_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ORIGEN_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ORIGEN_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', origen: respuesta };

    case types.PUT_ORIGEN_ERROR:
      respuesta= action.response;
      console.log('PUT_ORIGEN_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ORIGEN_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ORIGEN_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ORIGEN_ERROR:
      respuesta= action.response;
      console.log('DELETE_ORIGEN_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_CERTIFICADOPOLIZA_EN_ORIGEN:
      item = state.origen;
      item.certificadospoliza= [...item.certificadospoliza, action.payload]
      return { ...state, certificadopoliza:item};


    case types.BORRA_CERTIFICADOPOLIZA_EN_ORIGEN:
      item = state.origen;
      let lista_certificadopoliza_borrados= state.lista_certificadopoliza_borrados
      if (item.certificadospoliza[action.payload].id_certificadopoliza > 0){
            const elementocertificadopolizaborrar = { id_certificadopoliza : item.certificadospoliza[action.payload].id_certificadopoliza }
            lista_certificadopoliza_borrados= [...lista_certificadopoliza_borrados,  elementocertificadopolizaborrar]
      }
      item.certificadospoliza.splice(action.payload,1)
      return { ...state, certificadopoliza:item, lista_certificadopoliza_borrados : lista_certificadopoliza_borrados};

 
    case types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN:
      item = state.origen;
      item.aseguradoscertificadopoliza= [...item.aseguradoscertificadopoliza, action.payload]
      return { ...state, aseguradocertificadopoliza:item};


    case types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN:
      item = state.origen;
      let lista_aseguradocertificadopoliza_borrados= state.lista_aseguradocertificadopoliza_borrados
      if (item.aseguradoscertificadopoliza[action.payload].id_aseguradocertificadopoliza > 0){
            const elementoaseguradocertificadopolizaborrar = { id_aseguradocertificadopoliza : item.aseguradoscertificadopoliza[action.payload].id_aseguradocertificadopoliza }
            lista_aseguradocertificadopoliza_borrados= [...lista_aseguradocertificadopoliza_borrados,  elementoaseguradocertificadopolizaborrar]
      }
      item.aseguradoscertificadopoliza.splice(action.payload,1)
      return { ...state, aseguradocertificadopoliza:item, lista_aseguradocertificadopoliza_borrados : lista_aseguradocertificadopoliza_borrados};


    default:
      return state;
  }
};

