import React, {Component} from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Llaves from '../../assets/db/llaves.json'





class Contenedor extends Component{
    state={
    }

  componentDidMount() {
        if (!this.props.state.cargado) {

            const get = 'GET_' + (this.props.table.toUpperCase())
            const action = {type: get} 
//            console.log('DidMount', action);
              this.props.dispatch(action );
         }
  }

    render() {


        const { className, name, value, onChange, required } = this.props;

        if (!this.props.state.cargado) {
            return (
                <div>Cargando...</div>
            )
        }

        const descripcion = this.props.descripcion
//        console.log('RENDER',descripcion);

        return (
            <div className={className} >

                <select className="form-control  " name={name} value={value} onChange={onChange} required={required} >
                     
                    <option key='0' value=''>(Seleccionar)</option>
                    {this.props.lista
                        .sort(function(a, b) {
                            if (a[descripcion]> b[descripcion]) { return 1; }
                            if (a[descripcion]< b[descripcion]) { return -1; }
                            return 0;
                            })
                        .map(li => ( 
                        <option key={`${li[this.props.llave]}`} value={`${li[this.props.llave]}`}>{`${li[this.props.descripcion]}`}</option>
                        ))}
                </select>
            </div>
        )
    }

}




const mapStateToProps = (state, ownProps) => {
    const tablacampo = ownProps.field.split('.')
    const  name = tablacampo[1]

    const table = Llaves[name].table
    const llave = Llaves[name].campollave
    const descripcion = Llaves[name].campodescripcion 
    const _reducer = table + 'Reducer'

    //console.log('mapStateToProps ', state[_reducer]);

    return {name: name, table: table, llave:llave, descripcion:descripcion, lista:state[_reducer][table].lista,  state: state[_reducer]}
}


Contenedor.propTypes = {
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
  };


export default connect(mapStateToProps)(Contenedor);






