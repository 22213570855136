import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



export const getConciliaPagosPendientesService = (request) => {
  const apiURL = baseurl('pagospendientes');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};




export const getConciliaPagosPagadoService = (request) => {
  const apiURL = baseurl('pagospagados');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};


export const postConciliaPagoService = (request) => {
  const apiURL = baseurl('conciliapago');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};




export const deleteConciliaPagoService = (request) => {
  const apiURL = baseurl('conciliapago');
  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};