import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderComponent from '../../components/commons/header/headerComponent';
//import LoadingComponent from '../commons/loading/loadingComponent'

import ConciliaPagosViewPendiente from './conciliapagosViewPendiente';
import ConciliaPagosViewPagado from './conciliapagosViewPagado';


import { getConciliaPagosPagadoAction, 
        getConciliaPagosPendienteAction, 
        postConciliaPagoAction, 
        deleteConciliaPagoAction } from '../../store/actions/conciliapagos/actions';


class ConciliapagosComponent extends Component {
 


  onConciliarClick = (event, id) =>{
    event.preventDefault();
    this.props.dispatch(postConciliaPagoAction(
        {id_dpcertificadopoliza:id,
          fechapago: this.state.paramspagados.fecha,
          id_mediopago: parseInt(this.state.paramspagados.id_mediopago),
        }));
  }

  onCancelarClick = (event, id) =>{
    event.preventDefault();
    this.props.dispatch(deleteConciliaPagoAction({id_dpcertificadopoliza:id}));
  }

  handleChangePendientes(event) {
    console.log('handleChangePendientes antes', this.state);
    let name = event.target.name;
    let params = this.state.paramspendientes;
    params[name]= event.target.value;
    this.setState({
      paramspendientes: params
    });
    this.props.dispatch(getConciliaPagosPendienteAction({ fecha: this.state.paramspendientes.fecha }));
  }

  handleChangePagados(event) {
    console.log('handleChangePendientes antes', this.state);
    let name = event.target.name;
    let params = this.state.paramspagados;
    params[name]= event.target.value;
    this.setState({
      paramspagados: params
    });
    this.props.dispatch(getConciliaPagosPagadoAction(
        { fechapago: this.state.paramspagados.fecha,
          id_mediopago: parseInt(this.state.paramspagados.id_mediopago),
          }));
  }


  componentDidMount() {
    console.log('componentDidMount');
    if (!this.state.cargado){
      this.props.dispatch(getConciliaPagosPendienteAction({ fecha: this.state.paramspendientes.fecha }));
    }
    
  }



  static getDerivedStateFromProps(nextProps, prevState) {
  return nextProps;
}


    render() {
        console.log('Estatdo',this.state);
        return (
            <div>
              <HeaderComponent />
              <div className='row'>
                  <div className='col-6'>
                      <ConciliaPagosViewPendiente 
                        params={this.state.paramspendientes}
                        list={this.state.listapendientes} 
                        onConciliarClick={this.onConciliarClick}
                        handleChange={this.handleChangePendientes.bind(this)}/>
                  </div>
                  <div className='col-6'>
                      <ConciliaPagosViewPagado 
                        params={this.state.paramspagados}
                        list={this.state.listapagados} 
                        onCancelarClick={this.onCancelarClick}
                        handleChange={this.handleChangePagados.bind(this)}/>
                  </div>
              </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return state.conciliaReducer;
} 
  

export default connect(mapStateToProps)(ConciliapagosComponent);