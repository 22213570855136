import React from 'react';
import Boton from '../../components/buttons/boton'
//import FormaPagoCombobox from '../../components/comboboxes/formaspago'
//import AseguradoraComobobox from '../../components/comboboxes/aseguradoras'
import InputField from '../../components/inputfield'

//import { link } from 'fs';

const ActualizaPolizaView = (props) => (
  <React.Fragment>
     <form onSubmit={props.onHanldeGrabar}>
      <div className='container menu'>
       {
         (props.poliza.id_estatuspoliza===1) ? (
          <div className='row '>
            <Boton className='col-md-1' id='grabar'/>
            <div className='col-md-8'>
            </div>
            <Boton className=" col-md-2 " id='archivoexcel'  to={`/poliza/archivo/${props.poliza.id_poliza}`}/>
            <Boton className="col-md-1" id='cerrar' onClick={props.onCerrarPolizaClick}/>
          </div>
        ):(
          <div className='row'>
            <div className='col-md-6'>
                
            </div>
            <Boton className="col-1 "  id='pdfcerts' onClick={props.Handler_PDF_Certs} />
            <Boton className="col-2 "  id='pdfrecibos' onClick={props.Handler_PDF_Recibo} />
            <Boton className=" col-md-2 " id='archivoexcel'  to={`/poliza/archivo/${props.poliza.id_poliza}`}/>
            <div className=" col-md-1 ">
              <button className="btn btn-sm btn-warning  " onClick={props.onAbrirPolizaClick}>
                Abrir</button>
            </div>
          </div>
        )
      }

      </div>

      <div className='container '>
      <div className={(props.success) ? 'message success' : 'message error'}>{props.message}</div>
            <div className='row'>
              <InputField className='col-md-3' type='text' field='polizas.poliza' value={props.poliza.poliza} onChange={props.handleChange} />
              <InputField className='col-md-6' type='text' field='polizas.descripcion' value={props.poliza.descripcion} onChange={props.handleChange} />
              <InputField className='col-md-3' type='date' field='polizas.fechainicio' value={props.poliza.fechainicio} onChange={props.handleChange} />
              

            </div>

            <div className='row'>

              <InputField type='combobox' className='col-md-3' field='polizas.id_formapago' value={props.poliza.id_formapago} onChange={props.handleChange}/>

              <InputField type='combobox' className='col-md-3' field='polizas.id_aseguradora' value={props.poliza.id_aseguradora} onChange={props.handleChange}/>

              <div className='col col-md-3'>
              </div>
              
              <InputField className="col col-md-3" type='lookup' field='polizas.id_estatuspoliza' value={props.poliza.id_estatuspoliza} />
              
            </div>
            
      <div className='row'></div>

          
      <div>
        <span className='required'>All fields are required.</span>
      </div>

{/* 20200502.0108 */}

      </div>
      
    </form>
  </React.Fragment>
);

export default ActualizaPolizaView;
