import React from 'react';
const formatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2
})

const FacturaAgenteView = (props) => {

  // if (props.listagentefacturas.length === 0) {
  //   return <div className='p-4' >
  //       <div className='seguros-none'>No se encontraron facturas.</div>
  //   </div>
  // }
  const fact = props.facturaagente;
  return (
    
                      <div className='row pt-2 '>

                        <div className='col-12'>
                            <div className='row m-0 p-0 '>
                                  <div className='col-4 h6 m-0 p-0 '>
                                      <div className='row '>
                                        <div className='col-12 h6'>{fact.fechapago.substr(0,10)}</div>
                                        <div className='col-12 h6'>{fact.facturaagente}</div>
                                        <div className='col-6 h6'></div>

                                        {/* <button className="col-6 btn btn-secondary" 
                                            onClick={(e) => props.onEditarFacturaClick(e, pol.id_facturaagente)}>
                                           Editar
                                      </button> */}
                                      </div>
                                  </div>
                                  
                                  <div className='col-8 h6'>

                                      <div className='row m-0 p-0 ' >
                                          <div className='col-6 text-right'><p className='m-0 p-0 align-right'>SubTotal:</p></div>
                                          <div className='col-6 text-right'>{formatter.format(fact.subtotal)}
                                          </div>
                                      </div>

                                      <div className='row m-0 p-0 ' >
                                          <div className='col-6 text-right'><p className='m-0 p-0 align-right'>IVA:</p></div>
                                          <div className='col-6 text-right'>{formatter.format(fact.iva)}
                                          </div>
                                      </div> 

                                      <div className='row m-0 p-0 ' >
                                          <div className='col-6 text-right'><p className='m-0 p-0 align-right'>Ret IVA:</p></div>
                                          <div className='col-6 text-right'>{formatter.format(fact.retiva)}
                                          </div>
                                      </div>

                                        <div className='row m-0 p-0 ' >
                                            <div className='col-6 text-right'><p className='m-0 p-0 align-right'>Ret ISR:</p></div>
                                            <div className='col-6 text-right'>{formatter.format(fact.retisr)}
                                            </div>
                                        </div>

                                      <div className='row m-0 p-0 ' >
                                          <div className='col-6 text-right'><p className='m-0 p-0 text-right'>Total:</p></div>
                                          <div className='col-6 text-right'>{formatter.format(fact.total)}
                                          </div>
                                      </div>
                                      {(fact.ajuste!==0)
                                        ?<div className='row m-0 p-0 bg-info' >
                                            <div className='col-6 text-right text-light'><p className='m-0 p-0 text-right'>Ajuste:</p></div>
                                            <div className='col-6 text-right text-light'>{formatter.format(fact.ajuste)}
                                            </div>
                                        </div>
                                        :''
                                        }
                                        {(fact.diferencia!==0)
                                          ?<div className='row m-0 p-0 bg-danger' >
                                              <div className='col-6 text-right text-light'><p className='m-0 p-0 text-right'>Diferencia:</p></div>
                                              <div className='col-6 text-right text-light'>{formatter.format(fact.diferencia)}
                                              </div>
                                          </div>
                                          :''
                                          }
                                    
                                  </div>

                            </div>
                        </div>

                      
                        </div>
                      
  );
};

export default FacturaAgenteView;