import * as types from '../../actions/archivopoliza/types';

let poliza={} ;

let initialstate ={
  get: {
    cargado: false,
    poliza: {
      poliza:'',
      id_poliza:0,
      certificadospoliza:[]
    },

}
}

export default function(state = initialstate, action) {
    //console.log('REDUCER...', action, state);
  

  switch(action.type) {


    case types.GET_ARCHIVPOLIZA_RESET:
      return initialstate;

    case types.GET_ARCHIVPOLIZA_SUCCESS:
      poliza= action.response;
      poliza.cargado = true
      poliza.certificadospoliza = agregasubgrupo(poliza.certificadospoliza)
      poliza.certificadospoliza = calculaprimatotal(poliza.certificadospoliza)
      console.log('GET_ARCHIVPOLIZA_SUCCESS', poliza);
      const { post, put, ...estado } = state;
      return { ...estado , get : poliza };

    case types.GET_ARCHIVPOLIZA_ERROR:
      poliza= action.response;
      console.log('GET_ARCHIVPOLIZA_ERROR', poliza);
      return { ...state, get : poliza};


    default:
      return state;
  }
};

function agregasubgrupo(certs){

  var i = 0
  var j = 0
  for (i = 0; i < certs.length; i++) {

    for (j = 0; j < certs[i].aseguradoscertificadopoliza.length; j++) {
      if (certs[i].aseguradoscertificadopoliza[j].factorprimaporedad === 1 ) {
        certs[i].aseguradoscertificadopoliza[j].subgrupo = 3
        if (certs[i].aseguradoscertificadopoliza[j].edad <= 19 ) {
          certs[i].aseguradoscertificadopoliza[j].subgrupo = 1
        }
        else{
          if (certs[i].aseguradoscertificadopoliza[j].edad <= 54) {
            certs[i].aseguradoscertificadopoliza[j].subgrupo = 2
          }
        }
      }
      else{
        certs[i].aseguradoscertificadopoliza[j].subgrupo = 4
      }
    }    

    }

    


  return certs;
}


// function agregasubgrupo(certs){

//   var i = 0
//   var j = 0
//   for (i = 0; i < certs.length; i++) {

//     for (j = 0; j < certs[i].aseguradoscertificadopoliza.length; j++) {
//       if (certs[i].aseguradoscertificadopoliza[j].factorprimaporedad === 1 ) {
//         certs[i].aseguradoscertificadopoliza[j].subgrupo = 1
//       }
//       else{
//         certs[i].aseguradoscertificadopoliza[j].subgrupo = 2
//       }
//     }    

//     }

    


//   return certs;
// }


// function agregasubgrupo_ant(certs){

//   var i = 0
//   var j = 0
//   var subgrupo = 0
//   for (i = 0; i < certs.length; i++) {

//     subgrupo = 0
//     for (j = 0; j < certs[i].aseguradoscertificadopoliza.length; j++) {
//       if (certs[i].aseguradoscertificadopoliza[j].id_parentezco === 3 ) {
//           ++subgrupo
//       }
//     }

//     for (j = 0; j < certs[i].aseguradoscertificadopoliza.length; j++) {
//       if (certs[i].aseguradoscertificadopoliza[j].id_parentezco === 3 ) {
//         certs[i].aseguradoscertificadopoliza[j].subgrupo =subgrupo
//       } else {
//         certs[i].aseguradoscertificadopoliza[j].subgrupo =1
//       }
//     }
    

//     }

    


//   return certs;
// }

function calculaprimatotal(certs){

  var i = 0
  var j = 0
  var prima = 0
  var primaneta = 0
  var cargo = 0
  var derpol = 0
  var iva = 0
  for (i = 0; i < certs.length; i++) {

    prima = 0
    primaneta = 0
    cargo = 0
    derpol = 0
    iva = 0
    for (j = 0; j < certs[i].dpscertificadopoliza.length; j++) {
      primaneta += certs[i].dpscertificadopoliza[j].primaneta
      derpol += certs[i].dpscertificadopoliza[j].derechodepoliza
      cargo += certs[i].dpscertificadopoliza[j].cargopagofraccionado
      iva += certs[i].dpscertificadopoliza[j].iva
      prima += certs[i].dpscertificadopoliza[j].total
    }

    certs[i].primanetatotalanual = primaneta
    certs[i].derpoltotalanual = derpol
    certs[i].cargototalanual = cargo
    certs[i].ivatotalanual = iva
    certs[i].primatotalanual = prima
    

    }

    


  return certs;
}