import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from 'react-intl'

export default function Contenedor(props) {
  const { id } = props;
  
  return (
    <FormattedMessage id={id}/>
  );
}

Contenedor.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};