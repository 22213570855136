import * as types from './types';



  export const getComsionesAgentesAction = (data) => {
    return {
      type: types.GET_COMISIONES_AGENTES,
      data
    }
  };

  export const getComisionesAgenteDPsPendientesAction = (data) => {
    return {
      type: types.GET_COMISIONES_AGENTE_DPSPENDIENTES,
      data
    }
  };

  export const getComisionesAgenteAction = (data) => {
    return {
      type: types.GET_COMISIONES_AGENTE,
      data
    }
  };

  export const getComisionesAgenteFacturasAction = (data) => {
    return {
      type: types.GET_COMISIONES_AGENTE_FACTURAS,
      data
    }
  };

  export const getComisionesFacturaDPsAction = (data) => {
    return {
      type: types.GET_COMISIONES_FACTURA_DPS,
      data
    }
  };


  export const postComisionesAsignacionAction = (data) => {
    return {
      type: types.POST_COMISIONES_ASIGNACION,
      data
    }
  };

  export const postComisionesFacturaAgenteAction = (data) => {
    return {
      type: types.POST_COMISIONES_FACTURAAGENTE,
      data
    }
  };

  export const putComisionesFacturaAgenteAction = (data) => {
    return {
      type: types.PUT_COMISIONES_FACTURAAGENTE,
      data
    }
  };


  export const deleteComisionesAsignacionAction= (data) => {
    return {
      type: types.DELETE_COMISIONES_ASIGNACION,
      data
    }
  };


  export const ComisionesFacuraCrear=() =>{
    return {
      type: types.COMISIONES_FACTURA_CREAR
    }
  }

  export const ComisionesFacuraClose=() =>{
    return {
      type: types.COMISIONES_FACTURA_CLOSE
    }
  }


  export const ComisionesFacuraEditar= (data) => {
    return {
      type: types.COMISIONES_FACTURA_EDITAR,
      data
    }
  };



  export const DeleteComisionesFacuraAgenteAction= (data) => {
    return {
      type: types.DELETE_COMISIONES_FACTURAAGENTE,
      data
    }
  };