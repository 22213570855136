import certificadospolizaReducer from './certificadospolizaReducer';
import certificadopolizaReducer from './certificadopolizaReducer';


 import polizasReducer from './polizasReducer';
import polizaReducer from './polizaReducer';


 import productospolizaReducer from './productospolizaReducer';
import productopolizaReducer from './productopolizaReducer';


 import dpsproductopolizaReducer from './dpsproductopolizaReducer';
import dpproductopolizaReducer from './dpproductopolizaReducer';


 import aseguradoscertificadopolizaReducer from './aseguradoscertificadopolizaReducer';
import aseguradocertificadopolizaReducer from './aseguradocertificadopolizaReducer';


 import telscertificadopolizaReducer from './telscertificadopolizaReducer';
import telcertificadopolizaReducer from './telcertificadopolizaReducer';


 import archivoscertificadopolizaReducer from './archivoscertificadopolizaReducer';
import archivocertificadopolizaReducer from './archivocertificadopolizaReducer';


 import dpscertificadopolizaReducer from './dpscertificadopolizaReducer';
import dpcertificadopolizaReducer from './dpcertificadopolizaReducer';


 import pagosoxxoReducer from './pagosoxxoReducer';
import pagooxxoReducer from './pagooxxoReducer';


 import registrospagooxxoReducer from './registrospagooxxoReducer';
import registropagooxxoReducer from './registropagooxxoReducer';


 import endososcertificadopolizaReducer from './endososcertificadopolizaReducer';
import endosocertificadopolizaReducer from './endosocertificadopolizaReducer';


 import benefscertificadopolizaReducer from './benefscertificadopolizaReducer';
import benefcertificadopolizaReducer from './benefcertificadopolizaReducer';


 import facturasagenteReducer from './facturasagenteReducer';
import facturaagenteReducer from './facturaagenteReducer';


 import primasproductopolizaReducer from './primasproductopolizaReducer';
import primaproductopolizaReducer from './primaproductopolizaReducer';


 import aseguradorasReducer from './aseguradorasReducer';
import aseguradoraReducer from './aseguradoraReducer';


 import productosReducer from './productosReducer';
import productoReducer from './productoReducer';


 import agentesReducer from './agentesReducer';
import agenteReducer from './agenteReducer';


 import detalleslistadeprecioReducer from './detalleslistadeprecioReducer';
import detallelistadeprecioReducer from './detallelistadeprecioReducer';


 import aseguradosproductoReducer from './aseguradosproductoReducer';
import aseguradoproductoReducer from './aseguradoproductoReducer';


 import listasdeprecioReducer from './listasdeprecioReducer';
import listadeprecioReducer from './listadeprecioReducer';


 import primaslistadeprecioReducer from './primaslistadeprecioReducer';
import primalistadeprecioReducer from './primalistadeprecioReducer';


 import coberturasproductoReducer from './coberturasproductoReducer';
import coberturaproductoReducer from './coberturaproductoReducer';


 import clavescomisionReducer from './clavescomisionReducer';
import clavecomisionReducer from './clavecomisionReducer';


 import productosclavecomisionReducer from './productosclavecomisionReducer';
import productoclavecomisionReducer from './productoclavecomisionReducer';


 import formaspagoReducer from './formaspagoReducer';
import formapagoReducer from './formapagoReducer';


 import parentezcosReducer from './parentezcosReducer';
import parentezcoReducer from './parentezcoReducer';


 import estatuspagosReducer from './estatuspagosReducer';
import estatuspagoReducer from './estatuspagoReducer';


 import tiposproductoReducer from './tiposproductoReducer';
import tipoproductoReducer from './tipoproductoReducer';


 import origenesReducer from './origenesReducer';
import origenReducer from './origenReducer';


 import generosReducer from './generosReducer';
import generoReducer from './generoReducer';


 import estatuscertificadosReducer from './estatuscertificadosReducer';
import estatuscertificadoReducer from './estatuscertificadoReducer';


 import estatuspolizasReducer from './estatuspolizasReducer';
import estatuspolizaReducer from './estatuspolizaReducer';


 import mediospagoReducer from './mediospagoReducer';
import mediopagoReducer from './mediopagoReducer';


 import estatusregistrospagooxxoReducer from './estatusregistrospagooxxoReducer';
import estatusregistropagooxxoReducer from './estatusregistropagooxxoReducer';


 import motivoscancelacionReducer from './motivoscancelacionReducer';
import motivocancelacionReducer from './motivocancelacionReducer';


 import regimenesfiscalesReducer from './regimenesfiscalesReducer';
import regimenfiscalReducer from './regimenfiscalReducer';


 import usuariosReducer from './usuariosReducer';
import usuarioReducer from './usuarioReducer';


 import actualizacionesReducer from './actualizacionesReducer';
import actualizacionReducer from './actualizacionReducer';


 import operacionesReducer from './operacionesReducer';
import operacionReducer from './operacionReducer';


 import rutasReducer from './rutasReducer';
import rutaReducer from './rutaReducer';


 import perfilesReducer from './perfilesReducer';
import perfilReducer from './perfilReducer';


 import rutasrolReducer from './rutasrolReducer';
import rutarolReducer from './rutarolReducer';


 import rolesReducer from './rolesReducer';
import rolReducer from './rolReducer';


 import rolesperfilReducer from './rolesperfilReducer';
import rolperfilReducer from './rolperfilReducer';


export default {
certificadospolizaReducer, 
certificadopolizaReducer 
,polizasReducer, 
polizaReducer 
,productospolizaReducer, 
productopolizaReducer 
,dpsproductopolizaReducer, 
dpproductopolizaReducer 
,aseguradoscertificadopolizaReducer, 
aseguradocertificadopolizaReducer 
,telscertificadopolizaReducer, 
telcertificadopolizaReducer 
,archivoscertificadopolizaReducer, 
archivocertificadopolizaReducer 
,dpscertificadopolizaReducer, 
dpcertificadopolizaReducer 
,pagosoxxoReducer, 
pagooxxoReducer 
,registrospagooxxoReducer, 
registropagooxxoReducer 
,endososcertificadopolizaReducer, 
endosocertificadopolizaReducer 
,benefscertificadopolizaReducer, 
benefcertificadopolizaReducer 
,facturasagenteReducer, 
facturaagenteReducer 
,primasproductopolizaReducer, 
primaproductopolizaReducer 
,aseguradorasReducer, 
aseguradoraReducer 
,productosReducer, 
productoReducer 
,agentesReducer, 
agenteReducer 
,detalleslistadeprecioReducer, 
detallelistadeprecioReducer 
,aseguradosproductoReducer, 
aseguradoproductoReducer 
,listasdeprecioReducer, 
listadeprecioReducer 
,primaslistadeprecioReducer, 
primalistadeprecioReducer 
,coberturasproductoReducer, 
coberturaproductoReducer 
,clavescomisionReducer, 
clavecomisionReducer 
,productosclavecomisionReducer, 
productoclavecomisionReducer 
,formaspagoReducer, 
formapagoReducer 
,parentezcosReducer, 
parentezcoReducer 
,estatuspagosReducer, 
estatuspagoReducer 
,tiposproductoReducer, 
tipoproductoReducer 
,origenesReducer, 
origenReducer 
,generosReducer, 
generoReducer 
,estatuscertificadosReducer, 
estatuscertificadoReducer 
,estatuspolizasReducer, 
estatuspolizaReducer 
,mediospagoReducer, 
mediopagoReducer 
,estatusregistrospagooxxoReducer, 
estatusregistropagooxxoReducer 
,motivoscancelacionReducer, 
motivocancelacionReducer 
,regimenesfiscalesReducer, 
regimenfiscalReducer 
,usuariosReducer, 
usuarioReducer 
,actualizacionesReducer, 
actualizacionReducer 
,operacionesReducer, 
operacionReducer 
,rutasReducer, 
rutaReducer 
,perfilesReducer, 
perfilReducer 
,rutasrolReducer, 
rutarolReducer 
,rolesReducer, 
rolReducer 
,rolesperfilReducer, 
rolperfilReducer 

}

