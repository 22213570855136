import { takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/prueba/types';

import {
    getListaSaga } from './sagas';

export default function* watchPrueba() {

  yield takeLeading (types.GET_LISTA_ERROR, getListaSaga);

}

