import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    poliza: {
 id_poliza : 0, poliza : '', descripcion : '', fechainicio : '', search : '', id_formapago : 0, id_aseguradora : 0, id_estatuspoliza : 0,
      },

      lista_certificadopoliza_borrados : [],
      lista_productopoliza_borrados : [],
      lista_dpproductopoliza_borrados : [],
      lista_actualizacion_borrados : [],
      lista_primaproductopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_POLIZA:
      item = state.poliza;
      action.fn(item);
      return { ...state,  poliza: item };


    case types.GET_POLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_POLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', poliza: respuesta };

    case types.GET_POLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_POLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_POLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_POLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', poliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_POLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_POLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_POLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_POLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', poliza: respuesta };

    case types.PUT_POLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_POLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_POLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_POLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_POLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_POLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_CERTIFICADOPOLIZA_EN_POLIZA:
      item = state.poliza;
      item.certificadospoliza= [...item.certificadospoliza, action.payload]
      return { ...state, certificadopoliza:item};


    case types.BORRA_CERTIFICADOPOLIZA_EN_POLIZA:
      item = state.poliza;
      let lista_certificadopoliza_borrados= state.lista_certificadopoliza_borrados
      if (item.certificadospoliza[action.payload].id_certificadopoliza > 0){
            const elementocertificadopolizaborrar = { id_certificadopoliza : item.certificadospoliza[action.payload].id_certificadopoliza }
            lista_certificadopoliza_borrados= [...lista_certificadopoliza_borrados,  elementocertificadopolizaborrar]
      }
      item.certificadospoliza.splice(action.payload,1)
      return { ...state, certificadopoliza:item, lista_certificadopoliza_borrados : lista_certificadopoliza_borrados};

 
    case types.INSERTA_PRODUCTOPOLIZA_EN_POLIZA:
      item = state.poliza;
      item.productospoliza= [...item.productospoliza, action.payload]
      return { ...state, productopoliza:item};


    case types.BORRA_PRODUCTOPOLIZA_EN_POLIZA:
      item = state.poliza;
      let lista_productopoliza_borrados= state.lista_productopoliza_borrados
      if (item.productospoliza[action.payload].id_productopoliza > 0){
            const elementoproductopolizaborrar = { id_productopoliza : item.productospoliza[action.payload].id_productopoliza }
            lista_productopoliza_borrados= [...lista_productopoliza_borrados,  elementoproductopolizaborrar]
      }
      item.productospoliza.splice(action.payload,1)
      return { ...state, productopoliza:item, lista_productopoliza_borrados : lista_productopoliza_borrados};

 
    case types.INSERTA_DPPRODUCTOPOLIZA_EN_POLIZA:
      item = state.poliza;
      item.dpsproductopoliza= [...item.dpsproductopoliza, action.payload]
      return { ...state, dpproductopoliza:item};


    case types.BORRA_DPPRODUCTOPOLIZA_EN_POLIZA:
      item = state.poliza;
      let lista_dpproductopoliza_borrados= state.lista_dpproductopoliza_borrados
      if (item.dpsproductopoliza[action.payload].id_dpproductopoliza > 0){
            const elementodpproductopolizaborrar = { id_dpproductopoliza : item.dpsproductopoliza[action.payload].id_dpproductopoliza }
            lista_dpproductopoliza_borrados= [...lista_dpproductopoliza_borrados,  elementodpproductopolizaborrar]
      }
      item.dpsproductopoliza.splice(action.payload,1)
      return { ...state, dpproductopoliza:item, lista_dpproductopoliza_borrados : lista_dpproductopoliza_borrados};

 
    case types.INSERTA_ACTUALIZACION_EN_POLIZA:
      item = state.poliza;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_POLIZA:
      item = state.poliza;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};

 
    case types.INSERTA_PRIMAPRODUCTOPOLIZA_EN_POLIZA:
      item = state.poliza;
      item.primasproductopoliza= [...item.primasproductopoliza, action.payload]
      return { ...state, primaproductopoliza:item};


    case types.BORRA_PRIMAPRODUCTOPOLIZA_EN_POLIZA:
      item = state.poliza;
      let lista_primaproductopoliza_borrados= state.lista_primaproductopoliza_borrados
      if (item.primasproductopoliza[action.payload].id_primaproductopoliza > 0){
            const elementoprimaproductopolizaborrar = { id_primaproductopoliza : item.primasproductopoliza[action.payload].id_primaproductopoliza }
            lista_primaproductopoliza_borrados= [...lista_primaproductopoliza_borrados,  elementoprimaproductopolizaborrar]
      }
      item.primasproductopoliza.splice(action.payload,1)
      return { ...state, primaproductopoliza:item, lista_primaproductopoliza_borrados : lista_primaproductopoliza_borrados};


    default:
      return state;
  }
};

