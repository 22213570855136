import esMX  from './es-MX.json'
import esMXCustom from './es-MX-Customs.json'
import enUS from './en-US.json'
import enUSCustom from './en-US-Customs.json'
const allMessages = { 
    'es-MX' : {...esMX, ...esMXCustom},
    'en-US' : {...enUS, ...enUSCustom},
}


export default allMessages

