import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    primaproductopoliza: {
 id_primaproductopoliza : 0, id_poliza : 0, id_productopoliza : 0, id_producto : 0, edadmin : 0, edadmax : 0, prima : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_PRIMAPRODUCTOPOLIZA:
      item = state.primaproductopoliza;
      action.fn(item);
      return { ...state,  primaproductopoliza: item };


    case types.GET_PRIMAPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_PRIMAPRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', primaproductopoliza: respuesta };

    case types.GET_PRIMAPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_PRIMAPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_PRIMAPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_PRIMAPRODUCTOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', primaproductopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_PRIMAPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_PRIMAPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_PRIMAPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_PRIMAPRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', primaproductopoliza: respuesta };

    case types.PUT_PRIMAPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_PRIMAPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_PRIMAPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_PRIMAPRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_PRIMAPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_PRIMAPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

