import * as types from '../../actions/uploadfile/types';

const initialState = []

var resto;
var miestado;
var estado;

export default function(state = initialState, action) {

  //console.log('buscarReducer', action);

  switch(action.type) {


    case types.UPLOAD_RESET:
      resto = state.filter(e => { return e.path !== action.meta.path});
      miestado = {
        file:action.meta.file,
        path:action.meta.path,
        progress: 0,
        error: undefined,
        cargando: false,
        success: false,
      }
      estado =[...resto, miestado]
      return estado;

    case types.UPLOAD_REQUEST:
        console.log('UPLOAD_REQUEST', action);
        resto = state.filter(e => { return e.path !== action.meta.path});
        miestado = {
          file:action.meta.file,
          path:action.meta.path,
          progress: 0,
          error: undefined,
          cargando: true,
          success: false,
        }
        estado =[...resto, miestado]
        return estado;

    case types.UPLOAD_SUCCESS:
      console.log('UPLOAD_SUCCESS', action);
      resto = state.filter(e => { return e.path !== action.meta.path});
      miestado = {
        file:action.meta.file,
        path:action.meta.path,
        progress: 0,
        error: undefined,
        cargando: false,
        success: true,
      }
      estado =[...resto, miestado]
      return estado;



      case types.UPLOAD_FAILURE:
        resto = state.filter(e => { return e.path !== action.meta.path});
        miestado = {
          file:action.meta.file,
          path:action.meta.path,
          progress: 0,
          error: action.payload.err,
          cargando: false,
          success: false,
        }
        console.log('UPLOAD_FAILURE',miestado);
        estado =[...resto, miestado]
        return estado;
    
      case types.UPLOAD_PROGRESS:
        return state;

      default:
        return state;
  }
};

