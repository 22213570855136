import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



export const getbaseURLService = (url,request) => {
  const apiURL = baseurl(url);
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};



export const postbaseURLService = (url,request) => {
  const apiURL = baseurl(url);
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('ERROR: ', apiURL, error);
      return error;
    });
};





export const putbaseURLService = (url,request) => {
  const apiURL = baseurl(url);
  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('ERROR: ', apiURL, error);
      return error;
    });
};


export const deletebaseURLService = (url,request) => {
    const apiURL = baseurl(url);
    const parameters = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getCookie('timeoff-token')
      },
      body: JSON.stringify(request.data)
    };
    console.log('parameters.data', parameters);
    
    return fetch(apiURL, parameters)
      .then(response => {
        console.log('respondio', response);
        return response.json();
      })
      .then(json => {
        console.log('json', json);
        return json;
      })
      .catch(error => {
        console.log('error ricardo', error);
        return error;
      });
  };

  