import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    productopoliza: {
 id_productopoliza : 0, id_poliza : 0, id_producto : 0,
      },

      lista_dpproductopoliza_borrados : [],
      lista_primaproductopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_PRODUCTOPOLIZA:
      item = state.productopoliza;
      action.fn(item);
      return { ...state,  productopoliza: item };


    case types.GET_PRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_PRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', productopoliza: respuesta };

    case types.GET_PRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_PRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_PRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_PRODUCTOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', productopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_PRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_PRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_PRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_PRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', productopoliza: respuesta };

    case types.PUT_PRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_PRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_PRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_PRODUCTOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_PRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_PRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA:
      item = state.productopoliza;
      item.dpsproductopoliza= [...item.dpsproductopoliza, action.payload]
      return { ...state, dpproductopoliza:item};


    case types.BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA:
      item = state.productopoliza;
      let lista_dpproductopoliza_borrados= state.lista_dpproductopoliza_borrados
      if (item.dpsproductopoliza[action.payload].id_dpproductopoliza > 0){
            const elementodpproductopolizaborrar = { id_dpproductopoliza : item.dpsproductopoliza[action.payload].id_dpproductopoliza }
            lista_dpproductopoliza_borrados= [...lista_dpproductopoliza_borrados,  elementodpproductopolizaborrar]
      }
      item.dpsproductopoliza.splice(action.payload,1)
      return { ...state, dpproductopoliza:item, lista_dpproductopoliza_borrados : lista_dpproductopoliza_borrados};

 
    case types.INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA:
      item = state.productopoliza;
      item.primasproductopoliza= [...item.primasproductopoliza, action.payload]
      return { ...state, primaproductopoliza:item};


    case types.BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA:
      item = state.productopoliza;
      let lista_primaproductopoliza_borrados= state.lista_primaproductopoliza_borrados
      if (item.primasproductopoliza[action.payload].id_primaproductopoliza > 0){
            const elementoprimaproductopolizaborrar = { id_primaproductopoliza : item.primasproductopoliza[action.payload].id_primaproductopoliza }
            lista_primaproductopoliza_borrados= [...lista_primaproductopoliza_borrados,  elementoprimaproductopolizaborrar]
      }
      item.primasproductopoliza.splice(action.payload,1)
      return { ...state, primaproductopoliza:item, lista_primaproductopoliza_borrados : lista_primaproductopoliza_borrados};


    default:
      return state;
  }
};

