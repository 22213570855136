import React, {Component} from 'react'



class LoadingComponent extends Component{


render() {
    return (
        <div>
          <div>Loading...</div>
        </div>
        )
}

}

export default LoadingComponent