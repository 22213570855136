import React, { Component } from 'react';
import axios from 'axios'
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCookie } from '../../utils/cookies';
//import './polizas.css'

import HeaderComponent from '../../components/commons/header/headerComponent';
import LoadingComponent from '../../components/commons/loading/loadingwithheaderComponent'

import {  
          getPolizaAction, 
          putPolizaAction, 
          postEveryProductoPolizaAction, 
          deleteEveryProductoPolizaAction,
          insertaProductoPolizaEnPolizaAction,
          borraProductoPolizaEnPolizaAction,
         } from '../../store/actions/db/actions';

import {  asignaDPsPolizaAction, 
          asignaCertificadosPolizaAction, 
          agregarRenovacionesPolizaAction,
          abrirPolizaAction, 
          cerrarPolizaAction } from '../../store/actions/procesos/actions'

import ActualizaPolizaView from './actualizaPolizaView';
import ActualizaPolizaViewCertificados from './actualizaPolizaViewCertificados'
import ActualizaPolizaViewProductos from './actualizaPolizaViewProductos'
import ActualizaPolizaViewDPs from './actualizaPolizaViewDPs';
//import { getCookie } from '../../utils/cookies';
//import { classPrivateProperty } from '@babel/types';

class ActualizaPolizaComponent extends Component {
   
  state={}

  componentDidMount() {
    //console.log('componentDidMount');
    const id = parseInt(this.props.match.params.id,10);
    if (!this.props.state.cargado){
      this.props.getPolizaAction({ id: id });
    } else {
      if (this.props.item.id_poliza !== id) {
        this.props.getPolizaAction({ id: id });
      }
    }
  }

  Handler_PDF_Recibo = async (event) => {
    event.preventDefault();
    const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'

      axios(`${srv}/pdfrecibo/poliza/`+this.props.item.id_poliza, {
      method: "GET",
      responseType: "blob",
      headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}

      
      //Force to receive data in a Blob Format
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf"
        });
        //Build a URL from the file
        //const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        //window.open(fileURL);
        

        var filename = 'Recibos-' + this.props.item.poliza

        var dllink = document.createElement('a');
        dllink.href = URL.createObjectURL(file);
        dllink.setAttribute("download",filename); // Added Line 
        dllink.click();


      })
      .catch(error => {
        console.log(error);
      });
  };
  
  Handler_PDF_Certs = async (event) => {
    event.preventDefault();
    const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'

      axios(`${srv}/pdfcertificado/poliza/`+this.props.item.id_poliza, {
      method: "GET",
      responseType: "blob",
      headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}

      
      //Force to receive data in a Blob Format
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf"
        });
        //Build a URL from the file
        //const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        //window.open(fileURL);
        

        var filename = 'Certs-' + this.props.item.poliza

        var dllink = document.createElement('a');
        dllink.href = URL.createObjectURL(file);
        dllink.setAttribute("download",filename); // Added Line 
        dllink.click();


      })
      .catch(error => {
        console.log(error);
      });
  };
  
  onHanldeGrabar = (event) => {
    event.preventDefault();

    let newstate = this.props.state;
    newstate.grabado = false;
    newstate.poliza.fechainicio = event.target.fechainicio.value;

    const data = { ...newstate.poliza
      };
    
    this.props.item.productospoliza.map( item =>{
      if (item.id_productopoliza === 0){
        this.props.postEveryProductoPolizaAction(item);
      }
      return null
    })

    this.props.state.lista_productopoliza_borrados.map( item =>{
      if (item.id_productopoliza > 0){
      this.props.deleteEveryProductoPolizaAction(item.id_productopoliza);
      }
      return null
    })

    this.props.putPolizaAction(data);
  }

  onAsignaClick = (event) =>{
    const id=this.props.item.id_poliza;
    this.props.asignaDPsPolizaAction(id);

  }

  onAsignaCertificadosClick = (event) =>{
    const id=this.props.item.id_poliza;
    this.props.asignaCertificadosPolizaAction(id);

  }

  onAgregarRenovacionesClick = (event) =>{
    const id=this.props.item.id_poliza;
    this.props.agregarRenovacionesPolizaAction(id);
  }

  onAbrirPolizaClick = (event) =>{
    event.preventDefault();
    const id=this.props.item.id_poliza;
    this.props.abrirPolizaAction(id);
  }

  onCerrarPolizaClick = (event) =>{
    event.preventDefault();
    console.log('Cerrando....');
    const id=this.props.item.id_poliza;
    this.props.cerrarPolizaAction(id);
  }

  onAgregaProductoHandler = (event) =>{
    event.preventDefault();

    let id_producto = this.state.id_producto;
    let poliza = this.props.item;
    let encontrado = false

    if (id_producto > 0) {
      const pps = poliza.productospoliza
  
      pps
      .filter( productopoliza => productopoliza.id_producto === id_producto)
      .forEach( productopoliza=>{
        encontrado = true
      })
      console.log('Encontrado',encontrado);
      if (!encontrado){
  
        console.log('Entro no Encontrado',encontrado);
        const nuevoproducto = {id_productopoliza:0, id_poliza : poliza.id_poliza, id_producto : id_producto, producto : this.props.producto}
        
        this.props.insertaProductoPolizaEnPolizaAction(nuevoproducto);
  
      }

    }
  }


    onBorraProductoHandler = (event,index) =>{
      event.preventDefault();
      console.log('Borrar',index);
      this.props.borraProductoPolizaEnPolizaAction(index);
    }


    handleproductoagregarChange(event) {
      console.log('Cambiando Antes', this.state);
      const id_producto = parseInt(event.target.value,10);
      this.setState({
        id_producto: id_producto
      });
      console.log('Cambiando Despues', event.target);
    }
  


    handleChange(event) {
      let name = event.target.name;
      let poliza = this.props.item;
      console.log(name, typeof(poliza[name]));
      if (name.startsWith('id_')) {poliza[name]= parseInt(event.target.value,10)
      } else {
        poliza[name]= event.target.value;
      };
      this.setState({
        poliza: poliza
      });
    }
  


    Handler_PDF_Recibo_Certificado = async (event,id_certificadopoliza) => {
      console.log('HandlerVerPagoCertificado', id_certificadopoliza);
      console.log('HandlerVerPagoCertificado', this.props);
      event.preventDefault();
      const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'
  
        axios(`${srv}/pdfrecibo/certificadopoliza/`+id_certificadopoliza, {
        method: "GET",
        responseType: "blob",
        headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}
  
        
        //Force to receive data in a Blob Format
      })
        .then(response => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/pdf"
          });
          //Build a URL from the file
          //const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          //window.open(fileURL);

          var filename = this.props.item.poliza

          if (this.props.item.id_aseguradora===2){
            const cp = this.props.item.certificadospoliza.filter((cp)=>(cp.id_certificadopoliza === id_certificadopoliza))
            if(cp[0]){
              filename = filename+ "-" + cp[0].c_nombre + "-" + cp[0].c_appaterno + "-" + cp[0].c_apmaterno + "-" + cp[0].certificado  + "-Recibo.pdf"
            }
          }else{
            const cp = this.props.item.certificadospoliza.filter((cp)=>(cp.id_certificadopoliza === id_certificadopoliza))
            if(cp[0]){
              filename = filename + "-" + cp[0].certificado + "-" + cp[0].c_nombre + "-" + cp[0].c_appaterno + "-" + cp[0].c_apmaterno + "-Recibo.pdf"
            }

          }

          var dllink = document.createElement('a');
          dllink.href = URL.createObjectURL(file);
          dllink.setAttribute("download",filename); // Added Line 
          dllink.click();
          
        })
        .catch(error => {
          console.log(error);
        });
    };

    Handler_PDF_Cert_Certificado = async (event,id_certificadopoliza) => {
      console.log('Handler_PDF_Cert_Certificado', id_certificadopoliza);
      console.log('Handler_PDF_Cert_Certificado', this.props);
      event.preventDefault();
      const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'
  
        axios(`${srv}/pdfcertificado/certificadopoliza/`+id_certificadopoliza, {
        method: "GET",
        responseType: "blob",
        headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}
  
        
        //Force to receive data in a Blob Format
      })
        .then(response => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/pdf"
          });
          //Build a URL from the file
          //const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          //window.open(fileURL);
          var filename = this.props.item.poliza
          if (this.props.item.id_aseguradora===2){
            const cp = this.props.item.certificadospoliza.filter((cp)=>(cp.id_certificadopoliza === id_certificadopoliza))
            if(cp[0]){
              filename = filename + "-" + cp[0].c_nombre + "-" + cp[0].c_appaterno + "-" + cp[0].c_apmaterno + "-" + cp[0].certificado + "-Cert.pdf"
            }
          } else{
            const cp = this.props.item.certificadospoliza.filter((cp)=>(cp.id_certificadopoliza === id_certificadopoliza))
            if(cp[0]){
              filename = filename + "-" + cp[0].certificado + "-" + cp[0].c_nombre + "-" + cp[0].c_appaterno + "-" + cp[0].c_apmaterno + "-Cert.pdf"
            }

          }


          var dllink = document.createElement('a');
          dllink.href = URL.createObjectURL(file);
          dllink.setAttribute("download",filename); // Added Line 
          dllink.click();
          
        })
        .catch(error => {
          console.log(error);
        });
    };
  render() {
    console.log('RENDER 1------- >', this.props);
    // if (this.props.success){
    //   const _url = '/poliza/actualizar/' + this.props.id_poliza
    //   return <Redirect to={_url} />;
    // }
    if (this.props.state.cargado === false) {
      return (
        <LoadingComponent />
        )
    }
    //console.log('RENDER 2------- >', this.props);
      let mensaje = ''
      if (this.props.state.grabado) {mensaje = 'Grabado'}
      else {mensaje = this.props.state.message}
      return (
        <div className='pb-5 '>
          <HeaderComponent />
          <div className='container element'>
              <ActualizaPolizaView
                onHanldeGrabar={this.onHanldeGrabar}
                message={mensaje}
                success={this.props.state.grabado}
                poliza={this.props.item}
                Handler_PDF_Recibo={this.Handler_PDF_Recibo}
                Handler_PDF_Certs={this.Handler_PDF_Certs}
                handleChange={this.handleChange.bind(this)} 
                onAbrirPolizaClick={this.onAbrirPolizaClick.bind(this)} 
                onCerrarPolizaClick={this.onCerrarPolizaClick.bind(this)} />

                <ActualizaPolizaViewCertificados
                  poliza={this.props.item}
                  id_poliza={this.props.item.id_poliza}
                  list={this.props.item.certificadospoliza}
                  Handler_PDF_Cert_Certificado={this.Handler_PDF_Cert_Certificado.bind(this)}
                  Handler_PDF_Recibo_Certificado={this.Handler_PDF_Recibo_Certificado.bind(this)}
                  onAsignaCertificadosClick={this.onAsignaCertificadosClick.bind(this)}
                  onAgregarRenovacionesClick={this.onAgregarRenovacionesClick.bind(this)}  />
                  
                <ActualizaPolizaViewProductos
                  id_poliza={this.props.item.id_poliza}
                  id_producto={this.props.id_producto}
                  list={this.props.item.productospoliza}
                  handleChange={this.handleproductoagregarChange.bind(this)} 
                  onAgregaProducto={this.onAgregaProductoHandler.bind(this)} 
                  onBorraProducto={this.onBorraProductoHandler.bind(this)}/>

                <ActualizaPolizaViewDPs
                  id_poliza={this.props.item.id_poliza}
                  id_producto={this.props.id_producto}
                  list={this.props.item.dpsproductopoliza}
                  onAsignaClick={this.onAsignaClick.bind(this)}/>
          </div>
        </div>
      );
  }
}



const mapDispatchToProps = {
  getPolizaAction,

  postEveryProductoPolizaAction,
  deleteEveryProductoPolizaAction,

  putPolizaAction,
  asignaDPsPolizaAction,
  asignaCertificadosPolizaAction,
  agregarRenovacionesPolizaAction,
  abrirPolizaAction,
  cerrarPolizaAction,

  insertaProductoPolizaEnPolizaAction,
  borraProductoPolizaEnPolizaAction,

}

const mapStateToProps = (state) => {
 return {
  state:state.polizaReducer, 
  item: state.polizaReducer.poliza,
  productospoliza: state.polizaReducer.poliza.productospoliza,
};
};

export default connect(mapStateToProps,mapDispatchToProps)(ActualizaPolizaComponent);
