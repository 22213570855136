

export const GET_CERTIFICADOSPOLIZA = 'GET_CERTIFICADOSPOLIZA';
export const GET_CERTIFICADOSPOLIZA_SUCCESS = 'GET_CERTIFICADOSPOLIZA_SUCCESS';
export const GET_CERTIFICADOSPOLIZA_ERROR = 'GET_CERTIFICADOSPOLIZA_ERROR';

export const EJECUTA_EN_CERTIFICADOPOLIZA = 'EJECUTA_EN_CERTIFICADOPOLIZA';
export const GET_CERTIFICADOPOLIZA = 'GET_CERTIFICADOPOLIZA';
export const GET_CERTIFICADOPOLIZA_SUCCESS = 'GET_CERTIFICADOPOLIZA_SUCCESS';
export const GET_CERTIFICADOPOLIZA_ERROR = 'GET_CERTIFICADOPOLIZA_ERROR';

export const POST_CERTIFICADOPOLIZA = 'POST_CERTIFICADOPOLIZA';
export const POST_CERTIFICADOPOLIZA_SUCCESS = 'POST_CERTIFICADOPOLIZA_SUCCESS';
export const POST_CERTIFICADOPOLIZA_ERROR = 'POST_CERTIFICADOPOLIZA_ERROR';

export const PUT_CERTIFICADOPOLIZA = 'PUT_CERTIFICADOPOLIZA';
export const PUT_CERTIFICADOPOLIZA_SUCCESS = 'PUT_CERTIFICADOPOLIZA_SUCCESS';
export const PUT_CERTIFICADOPOLIZA_ERROR = 'PUT_CERTIFICADOPOLIZA_ERROR';

export const DELETE_CERTIFICADOPOLIZA = 'DELETE_CERTIFICADOPOLIZA';
export const DELETE_CERTIFICADOPOLIZA_SUCCESS = 'DELETE_CERTIFICADOPOLIZA_SUCCESS';
export const DELETE_CERTIFICADOPOLIZA_ERROR = 'DELETE_CERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_CERTIFICADOSPOLIZA = 'GET_EVERY_CERTIFICADOSPOLIZA';
export const GET_EVERY_CERTIFICADOPOLIZA = 'GET_EVERY_CERTIFICADOPOLIZA';
export const POST_EVERY_CERTIFICADOPOLIZA = 'POST_EVERY_CERTIFICADOPOLIZA';
export const PUT_EVERY_CERTIFICADOPOLIZA = 'PUT_EVERY_CERTIFICADOPOLIZA';
export const DELETE_EVERY_CERTIFICADOPOLIZA = 'DELETE_EVERY_CERTIFICADOPOLIZA';


export const INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
export const BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'INSERTA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
export const BORRA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'BORRA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'INSERTA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
export const BORRA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'BORRA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'INSERTA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
export const BORRA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'BORRA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_ACTUALIZACION_EN_CERTIFICADOPOLIZA = 'INSERTA_ACTUALIZACION_EN_CERTIFICADOPOLIZA';
export const BORRA_ACTUALIZACION_EN_CERTIFICADOPOLIZA = 'BORRA_ACTUALIZACION_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA = 'INSERTA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA';
export const BORRA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA = 'BORRA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'INSERTA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
export const BORRA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'BORRA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
 
export const INSERTA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'INSERTA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
export const BORRA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA = 'BORRA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA';
 

export const GET_POLIZAS = 'GET_POLIZAS';
export const GET_POLIZAS_SUCCESS = 'GET_POLIZAS_SUCCESS';
export const GET_POLIZAS_ERROR = 'GET_POLIZAS_ERROR';

export const EJECUTA_EN_POLIZA = 'EJECUTA_EN_POLIZA';
export const GET_POLIZA = 'GET_POLIZA';
export const GET_POLIZA_SUCCESS = 'GET_POLIZA_SUCCESS';
export const GET_POLIZA_ERROR = 'GET_POLIZA_ERROR';

export const POST_POLIZA = 'POST_POLIZA';
export const POST_POLIZA_SUCCESS = 'POST_POLIZA_SUCCESS';
export const POST_POLIZA_ERROR = 'POST_POLIZA_ERROR';

export const PUT_POLIZA = 'PUT_POLIZA';
export const PUT_POLIZA_SUCCESS = 'PUT_POLIZA_SUCCESS';
export const PUT_POLIZA_ERROR = 'PUT_POLIZA_ERROR';

export const DELETE_POLIZA = 'DELETE_POLIZA';
export const DELETE_POLIZA_SUCCESS = 'DELETE_POLIZA_SUCCESS';
export const DELETE_POLIZA_ERROR = 'DELETE_POLIZA_ERROR';


export const GET_EVERY_POLIZAS = 'GET_EVERY_POLIZAS';
export const GET_EVERY_POLIZA = 'GET_EVERY_POLIZA';
export const POST_EVERY_POLIZA = 'POST_EVERY_POLIZA';
export const PUT_EVERY_POLIZA = 'PUT_EVERY_POLIZA';
export const DELETE_EVERY_POLIZA = 'DELETE_EVERY_POLIZA';


export const INSERTA_CERTIFICADOPOLIZA_EN_POLIZA = 'INSERTA_CERTIFICADOPOLIZA_EN_POLIZA';
export const BORRA_CERTIFICADOPOLIZA_EN_POLIZA = 'BORRA_CERTIFICADOPOLIZA_EN_POLIZA';
 
export const INSERTA_PRODUCTOPOLIZA_EN_POLIZA = 'INSERTA_PRODUCTOPOLIZA_EN_POLIZA';
export const BORRA_PRODUCTOPOLIZA_EN_POLIZA = 'BORRA_PRODUCTOPOLIZA_EN_POLIZA';
 
export const INSERTA_DPPRODUCTOPOLIZA_EN_POLIZA = 'INSERTA_DPPRODUCTOPOLIZA_EN_POLIZA';
export const BORRA_DPPRODUCTOPOLIZA_EN_POLIZA = 'BORRA_DPPRODUCTOPOLIZA_EN_POLIZA';
 
export const INSERTA_ACTUALIZACION_EN_POLIZA = 'INSERTA_ACTUALIZACION_EN_POLIZA';
export const BORRA_ACTUALIZACION_EN_POLIZA = 'BORRA_ACTUALIZACION_EN_POLIZA';
 
export const INSERTA_PRIMAPRODUCTOPOLIZA_EN_POLIZA = 'INSERTA_PRIMAPRODUCTOPOLIZA_EN_POLIZA';
export const BORRA_PRIMAPRODUCTOPOLIZA_EN_POLIZA = 'BORRA_PRIMAPRODUCTOPOLIZA_EN_POLIZA';
 

export const GET_PRODUCTOSPOLIZA = 'GET_PRODUCTOSPOLIZA';
export const GET_PRODUCTOSPOLIZA_SUCCESS = 'GET_PRODUCTOSPOLIZA_SUCCESS';
export const GET_PRODUCTOSPOLIZA_ERROR = 'GET_PRODUCTOSPOLIZA_ERROR';

export const EJECUTA_EN_PRODUCTOPOLIZA = 'EJECUTA_EN_PRODUCTOPOLIZA';
export const GET_PRODUCTOPOLIZA = 'GET_PRODUCTOPOLIZA';
export const GET_PRODUCTOPOLIZA_SUCCESS = 'GET_PRODUCTOPOLIZA_SUCCESS';
export const GET_PRODUCTOPOLIZA_ERROR = 'GET_PRODUCTOPOLIZA_ERROR';

export const POST_PRODUCTOPOLIZA = 'POST_PRODUCTOPOLIZA';
export const POST_PRODUCTOPOLIZA_SUCCESS = 'POST_PRODUCTOPOLIZA_SUCCESS';
export const POST_PRODUCTOPOLIZA_ERROR = 'POST_PRODUCTOPOLIZA_ERROR';

export const PUT_PRODUCTOPOLIZA = 'PUT_PRODUCTOPOLIZA';
export const PUT_PRODUCTOPOLIZA_SUCCESS = 'PUT_PRODUCTOPOLIZA_SUCCESS';
export const PUT_PRODUCTOPOLIZA_ERROR = 'PUT_PRODUCTOPOLIZA_ERROR';

export const DELETE_PRODUCTOPOLIZA = 'DELETE_PRODUCTOPOLIZA';
export const DELETE_PRODUCTOPOLIZA_SUCCESS = 'DELETE_PRODUCTOPOLIZA_SUCCESS';
export const DELETE_PRODUCTOPOLIZA_ERROR = 'DELETE_PRODUCTOPOLIZA_ERROR';


export const GET_EVERY_PRODUCTOSPOLIZA = 'GET_EVERY_PRODUCTOSPOLIZA';
export const GET_EVERY_PRODUCTOPOLIZA = 'GET_EVERY_PRODUCTOPOLIZA';
export const POST_EVERY_PRODUCTOPOLIZA = 'POST_EVERY_PRODUCTOPOLIZA';
export const PUT_EVERY_PRODUCTOPOLIZA = 'PUT_EVERY_PRODUCTOPOLIZA';
export const DELETE_EVERY_PRODUCTOPOLIZA = 'DELETE_EVERY_PRODUCTOPOLIZA';


export const INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA = 'INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA';
export const BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA = 'BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA';
 
export const INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA = 'INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA';
export const BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA = 'BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA';
 

export const GET_DPSPRODUCTOPOLIZA = 'GET_DPSPRODUCTOPOLIZA';
export const GET_DPSPRODUCTOPOLIZA_SUCCESS = 'GET_DPSPRODUCTOPOLIZA_SUCCESS';
export const GET_DPSPRODUCTOPOLIZA_ERROR = 'GET_DPSPRODUCTOPOLIZA_ERROR';

export const EJECUTA_EN_DPPRODUCTOPOLIZA = 'EJECUTA_EN_DPPRODUCTOPOLIZA';
export const GET_DPPRODUCTOPOLIZA = 'GET_DPPRODUCTOPOLIZA';
export const GET_DPPRODUCTOPOLIZA_SUCCESS = 'GET_DPPRODUCTOPOLIZA_SUCCESS';
export const GET_DPPRODUCTOPOLIZA_ERROR = 'GET_DPPRODUCTOPOLIZA_ERROR';

export const POST_DPPRODUCTOPOLIZA = 'POST_DPPRODUCTOPOLIZA';
export const POST_DPPRODUCTOPOLIZA_SUCCESS = 'POST_DPPRODUCTOPOLIZA_SUCCESS';
export const POST_DPPRODUCTOPOLIZA_ERROR = 'POST_DPPRODUCTOPOLIZA_ERROR';

export const PUT_DPPRODUCTOPOLIZA = 'PUT_DPPRODUCTOPOLIZA';
export const PUT_DPPRODUCTOPOLIZA_SUCCESS = 'PUT_DPPRODUCTOPOLIZA_SUCCESS';
export const PUT_DPPRODUCTOPOLIZA_ERROR = 'PUT_DPPRODUCTOPOLIZA_ERROR';

export const DELETE_DPPRODUCTOPOLIZA = 'DELETE_DPPRODUCTOPOLIZA';
export const DELETE_DPPRODUCTOPOLIZA_SUCCESS = 'DELETE_DPPRODUCTOPOLIZA_SUCCESS';
export const DELETE_DPPRODUCTOPOLIZA_ERROR = 'DELETE_DPPRODUCTOPOLIZA_ERROR';


export const GET_EVERY_DPSPRODUCTOPOLIZA = 'GET_EVERY_DPSPRODUCTOPOLIZA';
export const GET_EVERY_DPPRODUCTOPOLIZA = 'GET_EVERY_DPPRODUCTOPOLIZA';
export const POST_EVERY_DPPRODUCTOPOLIZA = 'POST_EVERY_DPPRODUCTOPOLIZA';
export const PUT_EVERY_DPPRODUCTOPOLIZA = 'PUT_EVERY_DPPRODUCTOPOLIZA';
export const DELETE_EVERY_DPPRODUCTOPOLIZA = 'DELETE_EVERY_DPPRODUCTOPOLIZA';

 

export const GET_ASEGURADOSCERTIFICADOPOLIZA = 'GET_ASEGURADOSCERTIFICADOPOLIZA';
export const GET_ASEGURADOSCERTIFICADOPOLIZA_SUCCESS = 'GET_ASEGURADOSCERTIFICADOPOLIZA_SUCCESS';
export const GET_ASEGURADOSCERTIFICADOPOLIZA_ERROR = 'GET_ASEGURADOSCERTIFICADOPOLIZA_ERROR';

export const EJECUTA_EN_ASEGURADOCERTIFICADOPOLIZA = 'EJECUTA_EN_ASEGURADOCERTIFICADOPOLIZA';
export const GET_ASEGURADOCERTIFICADOPOLIZA = 'GET_ASEGURADOCERTIFICADOPOLIZA';
export const GET_ASEGURADOCERTIFICADOPOLIZA_SUCCESS = 'GET_ASEGURADOCERTIFICADOPOLIZA_SUCCESS';
export const GET_ASEGURADOCERTIFICADOPOLIZA_ERROR = 'GET_ASEGURADOCERTIFICADOPOLIZA_ERROR';

export const POST_ASEGURADOCERTIFICADOPOLIZA = 'POST_ASEGURADOCERTIFICADOPOLIZA';
export const POST_ASEGURADOCERTIFICADOPOLIZA_SUCCESS = 'POST_ASEGURADOCERTIFICADOPOLIZA_SUCCESS';
export const POST_ASEGURADOCERTIFICADOPOLIZA_ERROR = 'POST_ASEGURADOCERTIFICADOPOLIZA_ERROR';

export const PUT_ASEGURADOCERTIFICADOPOLIZA = 'PUT_ASEGURADOCERTIFICADOPOLIZA';
export const PUT_ASEGURADOCERTIFICADOPOLIZA_SUCCESS = 'PUT_ASEGURADOCERTIFICADOPOLIZA_SUCCESS';
export const PUT_ASEGURADOCERTIFICADOPOLIZA_ERROR = 'PUT_ASEGURADOCERTIFICADOPOLIZA_ERROR';

export const DELETE_ASEGURADOCERTIFICADOPOLIZA = 'DELETE_ASEGURADOCERTIFICADOPOLIZA';
export const DELETE_ASEGURADOCERTIFICADOPOLIZA_SUCCESS = 'DELETE_ASEGURADOCERTIFICADOPOLIZA_SUCCESS';
export const DELETE_ASEGURADOCERTIFICADOPOLIZA_ERROR = 'DELETE_ASEGURADOCERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_ASEGURADOSCERTIFICADOPOLIZA = 'GET_EVERY_ASEGURADOSCERTIFICADOPOLIZA';
export const GET_EVERY_ASEGURADOCERTIFICADOPOLIZA = 'GET_EVERY_ASEGURADOCERTIFICADOPOLIZA';
export const POST_EVERY_ASEGURADOCERTIFICADOPOLIZA = 'POST_EVERY_ASEGURADOCERTIFICADOPOLIZA';
export const PUT_EVERY_ASEGURADOCERTIFICADOPOLIZA = 'PUT_EVERY_ASEGURADOCERTIFICADOPOLIZA';
export const DELETE_EVERY_ASEGURADOCERTIFICADOPOLIZA = 'DELETE_EVERY_ASEGURADOCERTIFICADOPOLIZA';


export const INSERTA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA = 'INSERTA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA';
export const BORRA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA = 'BORRA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA';
 

export const GET_TELSCERTIFICADOPOLIZA = 'GET_TELSCERTIFICADOPOLIZA';
export const GET_TELSCERTIFICADOPOLIZA_SUCCESS = 'GET_TELSCERTIFICADOPOLIZA_SUCCESS';
export const GET_TELSCERTIFICADOPOLIZA_ERROR = 'GET_TELSCERTIFICADOPOLIZA_ERROR';

export const EJECUTA_EN_TELCERTIFICADOPOLIZA = 'EJECUTA_EN_TELCERTIFICADOPOLIZA';
export const GET_TELCERTIFICADOPOLIZA = 'GET_TELCERTIFICADOPOLIZA';
export const GET_TELCERTIFICADOPOLIZA_SUCCESS = 'GET_TELCERTIFICADOPOLIZA_SUCCESS';
export const GET_TELCERTIFICADOPOLIZA_ERROR = 'GET_TELCERTIFICADOPOLIZA_ERROR';

export const POST_TELCERTIFICADOPOLIZA = 'POST_TELCERTIFICADOPOLIZA';
export const POST_TELCERTIFICADOPOLIZA_SUCCESS = 'POST_TELCERTIFICADOPOLIZA_SUCCESS';
export const POST_TELCERTIFICADOPOLIZA_ERROR = 'POST_TELCERTIFICADOPOLIZA_ERROR';

export const PUT_TELCERTIFICADOPOLIZA = 'PUT_TELCERTIFICADOPOLIZA';
export const PUT_TELCERTIFICADOPOLIZA_SUCCESS = 'PUT_TELCERTIFICADOPOLIZA_SUCCESS';
export const PUT_TELCERTIFICADOPOLIZA_ERROR = 'PUT_TELCERTIFICADOPOLIZA_ERROR';

export const DELETE_TELCERTIFICADOPOLIZA = 'DELETE_TELCERTIFICADOPOLIZA';
export const DELETE_TELCERTIFICADOPOLIZA_SUCCESS = 'DELETE_TELCERTIFICADOPOLIZA_SUCCESS';
export const DELETE_TELCERTIFICADOPOLIZA_ERROR = 'DELETE_TELCERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_TELSCERTIFICADOPOLIZA = 'GET_EVERY_TELSCERTIFICADOPOLIZA';
export const GET_EVERY_TELCERTIFICADOPOLIZA = 'GET_EVERY_TELCERTIFICADOPOLIZA';
export const POST_EVERY_TELCERTIFICADOPOLIZA = 'POST_EVERY_TELCERTIFICADOPOLIZA';
export const PUT_EVERY_TELCERTIFICADOPOLIZA = 'PUT_EVERY_TELCERTIFICADOPOLIZA';
export const DELETE_EVERY_TELCERTIFICADOPOLIZA = 'DELETE_EVERY_TELCERTIFICADOPOLIZA';

 

export const GET_ARCHIVOSCERTIFICADOPOLIZA = 'GET_ARCHIVOSCERTIFICADOPOLIZA';
export const GET_ARCHIVOSCERTIFICADOPOLIZA_SUCCESS = 'GET_ARCHIVOSCERTIFICADOPOLIZA_SUCCESS';
export const GET_ARCHIVOSCERTIFICADOPOLIZA_ERROR = 'GET_ARCHIVOSCERTIFICADOPOLIZA_ERROR';

export const EJECUTA_EN_ARCHIVOCERTIFICADOPOLIZA = 'EJECUTA_EN_ARCHIVOCERTIFICADOPOLIZA';
export const GET_ARCHIVOCERTIFICADOPOLIZA = 'GET_ARCHIVOCERTIFICADOPOLIZA';
export const GET_ARCHIVOCERTIFICADOPOLIZA_SUCCESS = 'GET_ARCHIVOCERTIFICADOPOLIZA_SUCCESS';
export const GET_ARCHIVOCERTIFICADOPOLIZA_ERROR = 'GET_ARCHIVOCERTIFICADOPOLIZA_ERROR';

export const POST_ARCHIVOCERTIFICADOPOLIZA = 'POST_ARCHIVOCERTIFICADOPOLIZA';
export const POST_ARCHIVOCERTIFICADOPOLIZA_SUCCESS = 'POST_ARCHIVOCERTIFICADOPOLIZA_SUCCESS';
export const POST_ARCHIVOCERTIFICADOPOLIZA_ERROR = 'POST_ARCHIVOCERTIFICADOPOLIZA_ERROR';

export const PUT_ARCHIVOCERTIFICADOPOLIZA = 'PUT_ARCHIVOCERTIFICADOPOLIZA';
export const PUT_ARCHIVOCERTIFICADOPOLIZA_SUCCESS = 'PUT_ARCHIVOCERTIFICADOPOLIZA_SUCCESS';
export const PUT_ARCHIVOCERTIFICADOPOLIZA_ERROR = 'PUT_ARCHIVOCERTIFICADOPOLIZA_ERROR';

export const DELETE_ARCHIVOCERTIFICADOPOLIZA = 'DELETE_ARCHIVOCERTIFICADOPOLIZA';
export const DELETE_ARCHIVOCERTIFICADOPOLIZA_SUCCESS = 'DELETE_ARCHIVOCERTIFICADOPOLIZA_SUCCESS';
export const DELETE_ARCHIVOCERTIFICADOPOLIZA_ERROR = 'DELETE_ARCHIVOCERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_ARCHIVOSCERTIFICADOPOLIZA = 'GET_EVERY_ARCHIVOSCERTIFICADOPOLIZA';
export const GET_EVERY_ARCHIVOCERTIFICADOPOLIZA = 'GET_EVERY_ARCHIVOCERTIFICADOPOLIZA';
export const POST_EVERY_ARCHIVOCERTIFICADOPOLIZA = 'POST_EVERY_ARCHIVOCERTIFICADOPOLIZA';
export const PUT_EVERY_ARCHIVOCERTIFICADOPOLIZA = 'PUT_EVERY_ARCHIVOCERTIFICADOPOLIZA';
export const DELETE_EVERY_ARCHIVOCERTIFICADOPOLIZA = 'DELETE_EVERY_ARCHIVOCERTIFICADOPOLIZA';

 

export const GET_DPSCERTIFICADOPOLIZA = 'GET_DPSCERTIFICADOPOLIZA';
export const GET_DPSCERTIFICADOPOLIZA_SUCCESS = 'GET_DPSCERTIFICADOPOLIZA_SUCCESS';
export const GET_DPSCERTIFICADOPOLIZA_ERROR = 'GET_DPSCERTIFICADOPOLIZA_ERROR';

export const EJECUTA_EN_DPCERTIFICADOPOLIZA = 'EJECUTA_EN_DPCERTIFICADOPOLIZA';
export const GET_DPCERTIFICADOPOLIZA = 'GET_DPCERTIFICADOPOLIZA';
export const GET_DPCERTIFICADOPOLIZA_SUCCESS = 'GET_DPCERTIFICADOPOLIZA_SUCCESS';
export const GET_DPCERTIFICADOPOLIZA_ERROR = 'GET_DPCERTIFICADOPOLIZA_ERROR';

export const POST_DPCERTIFICADOPOLIZA = 'POST_DPCERTIFICADOPOLIZA';
export const POST_DPCERTIFICADOPOLIZA_SUCCESS = 'POST_DPCERTIFICADOPOLIZA_SUCCESS';
export const POST_DPCERTIFICADOPOLIZA_ERROR = 'POST_DPCERTIFICADOPOLIZA_ERROR';

export const PUT_DPCERTIFICADOPOLIZA = 'PUT_DPCERTIFICADOPOLIZA';
export const PUT_DPCERTIFICADOPOLIZA_SUCCESS = 'PUT_DPCERTIFICADOPOLIZA_SUCCESS';
export const PUT_DPCERTIFICADOPOLIZA_ERROR = 'PUT_DPCERTIFICADOPOLIZA_ERROR';

export const DELETE_DPCERTIFICADOPOLIZA = 'DELETE_DPCERTIFICADOPOLIZA';
export const DELETE_DPCERTIFICADOPOLIZA_SUCCESS = 'DELETE_DPCERTIFICADOPOLIZA_SUCCESS';
export const DELETE_DPCERTIFICADOPOLIZA_ERROR = 'DELETE_DPCERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_DPSCERTIFICADOPOLIZA = 'GET_EVERY_DPSCERTIFICADOPOLIZA';
export const GET_EVERY_DPCERTIFICADOPOLIZA = 'GET_EVERY_DPCERTIFICADOPOLIZA';
export const POST_EVERY_DPCERTIFICADOPOLIZA = 'POST_EVERY_DPCERTIFICADOPOLIZA';
export const PUT_EVERY_DPCERTIFICADOPOLIZA = 'PUT_EVERY_DPCERTIFICADOPOLIZA';
export const DELETE_EVERY_DPCERTIFICADOPOLIZA = 'DELETE_EVERY_DPCERTIFICADOPOLIZA';


export const INSERTA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA = 'INSERTA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA';
export const BORRA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA = 'BORRA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA';
 
export const INSERTA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA = 'INSERTA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA';
export const BORRA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA = 'BORRA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA';
 

export const GET_PAGOSOXXO = 'GET_PAGOSOXXO';
export const GET_PAGOSOXXO_SUCCESS = 'GET_PAGOSOXXO_SUCCESS';
export const GET_PAGOSOXXO_ERROR = 'GET_PAGOSOXXO_ERROR';

export const EJECUTA_EN_PAGOOXXO = 'EJECUTA_EN_PAGOOXXO';
export const GET_PAGOOXXO = 'GET_PAGOOXXO';
export const GET_PAGOOXXO_SUCCESS = 'GET_PAGOOXXO_SUCCESS';
export const GET_PAGOOXXO_ERROR = 'GET_PAGOOXXO_ERROR';

export const POST_PAGOOXXO = 'POST_PAGOOXXO';
export const POST_PAGOOXXO_SUCCESS = 'POST_PAGOOXXO_SUCCESS';
export const POST_PAGOOXXO_ERROR = 'POST_PAGOOXXO_ERROR';

export const PUT_PAGOOXXO = 'PUT_PAGOOXXO';
export const PUT_PAGOOXXO_SUCCESS = 'PUT_PAGOOXXO_SUCCESS';
export const PUT_PAGOOXXO_ERROR = 'PUT_PAGOOXXO_ERROR';

export const DELETE_PAGOOXXO = 'DELETE_PAGOOXXO';
export const DELETE_PAGOOXXO_SUCCESS = 'DELETE_PAGOOXXO_SUCCESS';
export const DELETE_PAGOOXXO_ERROR = 'DELETE_PAGOOXXO_ERROR';


export const GET_EVERY_PAGOSOXXO = 'GET_EVERY_PAGOSOXXO';
export const GET_EVERY_PAGOOXXO = 'GET_EVERY_PAGOOXXO';
export const POST_EVERY_PAGOOXXO = 'POST_EVERY_PAGOOXXO';
export const PUT_EVERY_PAGOOXXO = 'PUT_EVERY_PAGOOXXO';
export const DELETE_EVERY_PAGOOXXO = 'DELETE_EVERY_PAGOOXXO';

 

export const GET_REGISTROSPAGOOXXO = 'GET_REGISTROSPAGOOXXO';
export const GET_REGISTROSPAGOOXXO_SUCCESS = 'GET_REGISTROSPAGOOXXO_SUCCESS';
export const GET_REGISTROSPAGOOXXO_ERROR = 'GET_REGISTROSPAGOOXXO_ERROR';

export const EJECUTA_EN_REGISTROPAGOOXXO = 'EJECUTA_EN_REGISTROPAGOOXXO';
export const GET_REGISTROPAGOOXXO = 'GET_REGISTROPAGOOXXO';
export const GET_REGISTROPAGOOXXO_SUCCESS = 'GET_REGISTROPAGOOXXO_SUCCESS';
export const GET_REGISTROPAGOOXXO_ERROR = 'GET_REGISTROPAGOOXXO_ERROR';

export const POST_REGISTROPAGOOXXO = 'POST_REGISTROPAGOOXXO';
export const POST_REGISTROPAGOOXXO_SUCCESS = 'POST_REGISTROPAGOOXXO_SUCCESS';
export const POST_REGISTROPAGOOXXO_ERROR = 'POST_REGISTROPAGOOXXO_ERROR';

export const PUT_REGISTROPAGOOXXO = 'PUT_REGISTROPAGOOXXO';
export const PUT_REGISTROPAGOOXXO_SUCCESS = 'PUT_REGISTROPAGOOXXO_SUCCESS';
export const PUT_REGISTROPAGOOXXO_ERROR = 'PUT_REGISTROPAGOOXXO_ERROR';

export const DELETE_REGISTROPAGOOXXO = 'DELETE_REGISTROPAGOOXXO';
export const DELETE_REGISTROPAGOOXXO_SUCCESS = 'DELETE_REGISTROPAGOOXXO_SUCCESS';
export const DELETE_REGISTROPAGOOXXO_ERROR = 'DELETE_REGISTROPAGOOXXO_ERROR';


export const GET_EVERY_REGISTROSPAGOOXXO = 'GET_EVERY_REGISTROSPAGOOXXO';
export const GET_EVERY_REGISTROPAGOOXXO = 'GET_EVERY_REGISTROPAGOOXXO';
export const POST_EVERY_REGISTROPAGOOXXO = 'POST_EVERY_REGISTROPAGOOXXO';
export const PUT_EVERY_REGISTROPAGOOXXO = 'PUT_EVERY_REGISTROPAGOOXXO';
export const DELETE_EVERY_REGISTROPAGOOXXO = 'DELETE_EVERY_REGISTROPAGOOXXO';

 

export const GET_ENDOSOSCERTIFICADOPOLIZA = 'GET_ENDOSOSCERTIFICADOPOLIZA';
export const GET_ENDOSOSCERTIFICADOPOLIZA_SUCCESS = 'GET_ENDOSOSCERTIFICADOPOLIZA_SUCCESS';
export const GET_ENDOSOSCERTIFICADOPOLIZA_ERROR = 'GET_ENDOSOSCERTIFICADOPOLIZA_ERROR';

export const EJECUTA_EN_ENDOSOCERTIFICADOPOLIZA = 'EJECUTA_EN_ENDOSOCERTIFICADOPOLIZA';
export const GET_ENDOSOCERTIFICADOPOLIZA = 'GET_ENDOSOCERTIFICADOPOLIZA';
export const GET_ENDOSOCERTIFICADOPOLIZA_SUCCESS = 'GET_ENDOSOCERTIFICADOPOLIZA_SUCCESS';
export const GET_ENDOSOCERTIFICADOPOLIZA_ERROR = 'GET_ENDOSOCERTIFICADOPOLIZA_ERROR';

export const POST_ENDOSOCERTIFICADOPOLIZA = 'POST_ENDOSOCERTIFICADOPOLIZA';
export const POST_ENDOSOCERTIFICADOPOLIZA_SUCCESS = 'POST_ENDOSOCERTIFICADOPOLIZA_SUCCESS';
export const POST_ENDOSOCERTIFICADOPOLIZA_ERROR = 'POST_ENDOSOCERTIFICADOPOLIZA_ERROR';

export const PUT_ENDOSOCERTIFICADOPOLIZA = 'PUT_ENDOSOCERTIFICADOPOLIZA';
export const PUT_ENDOSOCERTIFICADOPOLIZA_SUCCESS = 'PUT_ENDOSOCERTIFICADOPOLIZA_SUCCESS';
export const PUT_ENDOSOCERTIFICADOPOLIZA_ERROR = 'PUT_ENDOSOCERTIFICADOPOLIZA_ERROR';

export const DELETE_ENDOSOCERTIFICADOPOLIZA = 'DELETE_ENDOSOCERTIFICADOPOLIZA';
export const DELETE_ENDOSOCERTIFICADOPOLIZA_SUCCESS = 'DELETE_ENDOSOCERTIFICADOPOLIZA_SUCCESS';
export const DELETE_ENDOSOCERTIFICADOPOLIZA_ERROR = 'DELETE_ENDOSOCERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_ENDOSOSCERTIFICADOPOLIZA = 'GET_EVERY_ENDOSOSCERTIFICADOPOLIZA';
export const GET_EVERY_ENDOSOCERTIFICADOPOLIZA = 'GET_EVERY_ENDOSOCERTIFICADOPOLIZA';
export const POST_EVERY_ENDOSOCERTIFICADOPOLIZA = 'POST_EVERY_ENDOSOCERTIFICADOPOLIZA';
export const PUT_EVERY_ENDOSOCERTIFICADOPOLIZA = 'PUT_EVERY_ENDOSOCERTIFICADOPOLIZA';
export const DELETE_EVERY_ENDOSOCERTIFICADOPOLIZA = 'DELETE_EVERY_ENDOSOCERTIFICADOPOLIZA';


export const INSERTA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA = 'INSERTA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA';
export const BORRA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA = 'BORRA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA';
 

export const GET_BENEFSCERTIFICADOPOLIZA = 'GET_BENEFSCERTIFICADOPOLIZA';
export const GET_BENEFSCERTIFICADOPOLIZA_SUCCESS = 'GET_BENEFSCERTIFICADOPOLIZA_SUCCESS';
export const GET_BENEFSCERTIFICADOPOLIZA_ERROR = 'GET_BENEFSCERTIFICADOPOLIZA_ERROR';

export const EJECUTA_EN_BENEFCERTIFICADOPOLIZA = 'EJECUTA_EN_BENEFCERTIFICADOPOLIZA';
export const GET_BENEFCERTIFICADOPOLIZA = 'GET_BENEFCERTIFICADOPOLIZA';
export const GET_BENEFCERTIFICADOPOLIZA_SUCCESS = 'GET_BENEFCERTIFICADOPOLIZA_SUCCESS';
export const GET_BENEFCERTIFICADOPOLIZA_ERROR = 'GET_BENEFCERTIFICADOPOLIZA_ERROR';

export const POST_BENEFCERTIFICADOPOLIZA = 'POST_BENEFCERTIFICADOPOLIZA';
export const POST_BENEFCERTIFICADOPOLIZA_SUCCESS = 'POST_BENEFCERTIFICADOPOLIZA_SUCCESS';
export const POST_BENEFCERTIFICADOPOLIZA_ERROR = 'POST_BENEFCERTIFICADOPOLIZA_ERROR';

export const PUT_BENEFCERTIFICADOPOLIZA = 'PUT_BENEFCERTIFICADOPOLIZA';
export const PUT_BENEFCERTIFICADOPOLIZA_SUCCESS = 'PUT_BENEFCERTIFICADOPOLIZA_SUCCESS';
export const PUT_BENEFCERTIFICADOPOLIZA_ERROR = 'PUT_BENEFCERTIFICADOPOLIZA_ERROR';

export const DELETE_BENEFCERTIFICADOPOLIZA = 'DELETE_BENEFCERTIFICADOPOLIZA';
export const DELETE_BENEFCERTIFICADOPOLIZA_SUCCESS = 'DELETE_BENEFCERTIFICADOPOLIZA_SUCCESS';
export const DELETE_BENEFCERTIFICADOPOLIZA_ERROR = 'DELETE_BENEFCERTIFICADOPOLIZA_ERROR';


export const GET_EVERY_BENEFSCERTIFICADOPOLIZA = 'GET_EVERY_BENEFSCERTIFICADOPOLIZA';
export const GET_EVERY_BENEFCERTIFICADOPOLIZA = 'GET_EVERY_BENEFCERTIFICADOPOLIZA';
export const POST_EVERY_BENEFCERTIFICADOPOLIZA = 'POST_EVERY_BENEFCERTIFICADOPOLIZA';
export const PUT_EVERY_BENEFCERTIFICADOPOLIZA = 'PUT_EVERY_BENEFCERTIFICADOPOLIZA';
export const DELETE_EVERY_BENEFCERTIFICADOPOLIZA = 'DELETE_EVERY_BENEFCERTIFICADOPOLIZA';

 

export const GET_FACTURASAGENTE = 'GET_FACTURASAGENTE';
export const GET_FACTURASAGENTE_SUCCESS = 'GET_FACTURASAGENTE_SUCCESS';
export const GET_FACTURASAGENTE_ERROR = 'GET_FACTURASAGENTE_ERROR';

export const EJECUTA_EN_FACTURAAGENTE = 'EJECUTA_EN_FACTURAAGENTE';
export const GET_FACTURAAGENTE = 'GET_FACTURAAGENTE';
export const GET_FACTURAAGENTE_SUCCESS = 'GET_FACTURAAGENTE_SUCCESS';
export const GET_FACTURAAGENTE_ERROR = 'GET_FACTURAAGENTE_ERROR';

export const POST_FACTURAAGENTE = 'POST_FACTURAAGENTE';
export const POST_FACTURAAGENTE_SUCCESS = 'POST_FACTURAAGENTE_SUCCESS';
export const POST_FACTURAAGENTE_ERROR = 'POST_FACTURAAGENTE_ERROR';

export const PUT_FACTURAAGENTE = 'PUT_FACTURAAGENTE';
export const PUT_FACTURAAGENTE_SUCCESS = 'PUT_FACTURAAGENTE_SUCCESS';
export const PUT_FACTURAAGENTE_ERROR = 'PUT_FACTURAAGENTE_ERROR';

export const DELETE_FACTURAAGENTE = 'DELETE_FACTURAAGENTE';
export const DELETE_FACTURAAGENTE_SUCCESS = 'DELETE_FACTURAAGENTE_SUCCESS';
export const DELETE_FACTURAAGENTE_ERROR = 'DELETE_FACTURAAGENTE_ERROR';


export const GET_EVERY_FACTURASAGENTE = 'GET_EVERY_FACTURASAGENTE';
export const GET_EVERY_FACTURAAGENTE = 'GET_EVERY_FACTURAAGENTE';
export const POST_EVERY_FACTURAAGENTE = 'POST_EVERY_FACTURAAGENTE';
export const PUT_EVERY_FACTURAAGENTE = 'PUT_EVERY_FACTURAAGENTE';
export const DELETE_EVERY_FACTURAAGENTE = 'DELETE_EVERY_FACTURAAGENTE';


export const INSERTA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE = 'INSERTA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE';
export const BORRA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE = 'BORRA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE';
 

export const GET_PRIMASPRODUCTOPOLIZA = 'GET_PRIMASPRODUCTOPOLIZA';
export const GET_PRIMASPRODUCTOPOLIZA_SUCCESS = 'GET_PRIMASPRODUCTOPOLIZA_SUCCESS';
export const GET_PRIMASPRODUCTOPOLIZA_ERROR = 'GET_PRIMASPRODUCTOPOLIZA_ERROR';

export const EJECUTA_EN_PRIMAPRODUCTOPOLIZA = 'EJECUTA_EN_PRIMAPRODUCTOPOLIZA';
export const GET_PRIMAPRODUCTOPOLIZA = 'GET_PRIMAPRODUCTOPOLIZA';
export const GET_PRIMAPRODUCTOPOLIZA_SUCCESS = 'GET_PRIMAPRODUCTOPOLIZA_SUCCESS';
export const GET_PRIMAPRODUCTOPOLIZA_ERROR = 'GET_PRIMAPRODUCTOPOLIZA_ERROR';

export const POST_PRIMAPRODUCTOPOLIZA = 'POST_PRIMAPRODUCTOPOLIZA';
export const POST_PRIMAPRODUCTOPOLIZA_SUCCESS = 'POST_PRIMAPRODUCTOPOLIZA_SUCCESS';
export const POST_PRIMAPRODUCTOPOLIZA_ERROR = 'POST_PRIMAPRODUCTOPOLIZA_ERROR';

export const PUT_PRIMAPRODUCTOPOLIZA = 'PUT_PRIMAPRODUCTOPOLIZA';
export const PUT_PRIMAPRODUCTOPOLIZA_SUCCESS = 'PUT_PRIMAPRODUCTOPOLIZA_SUCCESS';
export const PUT_PRIMAPRODUCTOPOLIZA_ERROR = 'PUT_PRIMAPRODUCTOPOLIZA_ERROR';

export const DELETE_PRIMAPRODUCTOPOLIZA = 'DELETE_PRIMAPRODUCTOPOLIZA';
export const DELETE_PRIMAPRODUCTOPOLIZA_SUCCESS = 'DELETE_PRIMAPRODUCTOPOLIZA_SUCCESS';
export const DELETE_PRIMAPRODUCTOPOLIZA_ERROR = 'DELETE_PRIMAPRODUCTOPOLIZA_ERROR';


export const GET_EVERY_PRIMASPRODUCTOPOLIZA = 'GET_EVERY_PRIMASPRODUCTOPOLIZA';
export const GET_EVERY_PRIMAPRODUCTOPOLIZA = 'GET_EVERY_PRIMAPRODUCTOPOLIZA';
export const POST_EVERY_PRIMAPRODUCTOPOLIZA = 'POST_EVERY_PRIMAPRODUCTOPOLIZA';
export const PUT_EVERY_PRIMAPRODUCTOPOLIZA = 'PUT_EVERY_PRIMAPRODUCTOPOLIZA';
export const DELETE_EVERY_PRIMAPRODUCTOPOLIZA = 'DELETE_EVERY_PRIMAPRODUCTOPOLIZA';

 

export const GET_ASEGURADORAS = 'GET_ASEGURADORAS';
export const GET_ASEGURADORAS_SUCCESS = 'GET_ASEGURADORAS_SUCCESS';
export const GET_ASEGURADORAS_ERROR = 'GET_ASEGURADORAS_ERROR';

export const EJECUTA_EN_ASEGURADORA = 'EJECUTA_EN_ASEGURADORA';
export const GET_ASEGURADORA = 'GET_ASEGURADORA';
export const GET_ASEGURADORA_SUCCESS = 'GET_ASEGURADORA_SUCCESS';
export const GET_ASEGURADORA_ERROR = 'GET_ASEGURADORA_ERROR';

export const POST_ASEGURADORA = 'POST_ASEGURADORA';
export const POST_ASEGURADORA_SUCCESS = 'POST_ASEGURADORA_SUCCESS';
export const POST_ASEGURADORA_ERROR = 'POST_ASEGURADORA_ERROR';

export const PUT_ASEGURADORA = 'PUT_ASEGURADORA';
export const PUT_ASEGURADORA_SUCCESS = 'PUT_ASEGURADORA_SUCCESS';
export const PUT_ASEGURADORA_ERROR = 'PUT_ASEGURADORA_ERROR';

export const DELETE_ASEGURADORA = 'DELETE_ASEGURADORA';
export const DELETE_ASEGURADORA_SUCCESS = 'DELETE_ASEGURADORA_SUCCESS';
export const DELETE_ASEGURADORA_ERROR = 'DELETE_ASEGURADORA_ERROR';


export const GET_EVERY_ASEGURADORAS = 'GET_EVERY_ASEGURADORAS';
export const GET_EVERY_ASEGURADORA = 'GET_EVERY_ASEGURADORA';
export const POST_EVERY_ASEGURADORA = 'POST_EVERY_ASEGURADORA';
export const PUT_EVERY_ASEGURADORA = 'PUT_EVERY_ASEGURADORA';
export const DELETE_EVERY_ASEGURADORA = 'DELETE_EVERY_ASEGURADORA';


export const INSERTA_POLIZA_EN_ASEGURADORA = 'INSERTA_POLIZA_EN_ASEGURADORA';
export const BORRA_POLIZA_EN_ASEGURADORA = 'BORRA_POLIZA_EN_ASEGURADORA';
 
export const INSERTA_PRODUCTO_EN_ASEGURADORA = 'INSERTA_PRODUCTO_EN_ASEGURADORA';
export const BORRA_PRODUCTO_EN_ASEGURADORA = 'BORRA_PRODUCTO_EN_ASEGURADORA';
 

export const GET_PRODUCTOS = 'GET_PRODUCTOS';
export const GET_PRODUCTOS_SUCCESS = 'GET_PRODUCTOS_SUCCESS';
export const GET_PRODUCTOS_ERROR = 'GET_PRODUCTOS_ERROR';

export const EJECUTA_EN_PRODUCTO = 'EJECUTA_EN_PRODUCTO';
export const GET_PRODUCTO = 'GET_PRODUCTO';
export const GET_PRODUCTO_SUCCESS = 'GET_PRODUCTO_SUCCESS';
export const GET_PRODUCTO_ERROR = 'GET_PRODUCTO_ERROR';

export const POST_PRODUCTO = 'POST_PRODUCTO';
export const POST_PRODUCTO_SUCCESS = 'POST_PRODUCTO_SUCCESS';
export const POST_PRODUCTO_ERROR = 'POST_PRODUCTO_ERROR';

export const PUT_PRODUCTO = 'PUT_PRODUCTO';
export const PUT_PRODUCTO_SUCCESS = 'PUT_PRODUCTO_SUCCESS';
export const PUT_PRODUCTO_ERROR = 'PUT_PRODUCTO_ERROR';

export const DELETE_PRODUCTO = 'DELETE_PRODUCTO';
export const DELETE_PRODUCTO_SUCCESS = 'DELETE_PRODUCTO_SUCCESS';
export const DELETE_PRODUCTO_ERROR = 'DELETE_PRODUCTO_ERROR';


export const GET_EVERY_PRODUCTOS = 'GET_EVERY_PRODUCTOS';
export const GET_EVERY_PRODUCTO = 'GET_EVERY_PRODUCTO';
export const POST_EVERY_PRODUCTO = 'POST_EVERY_PRODUCTO';
export const PUT_EVERY_PRODUCTO = 'PUT_EVERY_PRODUCTO';
export const DELETE_EVERY_PRODUCTO = 'DELETE_EVERY_PRODUCTO';


export const INSERTA_PRODUCTOPOLIZA_EN_PRODUCTO = 'INSERTA_PRODUCTOPOLIZA_EN_PRODUCTO';
export const BORRA_PRODUCTOPOLIZA_EN_PRODUCTO = 'BORRA_PRODUCTOPOLIZA_EN_PRODUCTO';
 
export const INSERTA_DETALLELISTADEPRECIO_EN_PRODUCTO = 'INSERTA_DETALLELISTADEPRECIO_EN_PRODUCTO';
export const BORRA_DETALLELISTADEPRECIO_EN_PRODUCTO = 'BORRA_DETALLELISTADEPRECIO_EN_PRODUCTO';
 
export const INSERTA_CERTIFICADOPOLIZA_EN_PRODUCTO = 'INSERTA_CERTIFICADOPOLIZA_EN_PRODUCTO';
export const BORRA_CERTIFICADOPOLIZA_EN_PRODUCTO = 'BORRA_CERTIFICADOPOLIZA_EN_PRODUCTO';
 
export const INSERTA_ASEGURADOPRODUCTO_EN_PRODUCTO = 'INSERTA_ASEGURADOPRODUCTO_EN_PRODUCTO';
export const BORRA_ASEGURADOPRODUCTO_EN_PRODUCTO = 'BORRA_ASEGURADOPRODUCTO_EN_PRODUCTO';
 
export const INSERTA_DPCERTIFICADOPOLIZA_EN_PRODUCTO = 'INSERTA_DPCERTIFICADOPOLIZA_EN_PRODUCTO';
export const BORRA_DPCERTIFICADOPOLIZA_EN_PRODUCTO = 'BORRA_DPCERTIFICADOPOLIZA_EN_PRODUCTO';
 
export const INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTO = 'INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTO';
export const BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTO = 'BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTO';
 
export const INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO = 'INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO';
export const BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO = 'BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO';
 
export const INSERTA_COBERTURAPRODUCTO_EN_PRODUCTO = 'INSERTA_COBERTURAPRODUCTO_EN_PRODUCTO';
export const BORRA_COBERTURAPRODUCTO_EN_PRODUCTO = 'BORRA_COBERTURAPRODUCTO_EN_PRODUCTO';
 
export const INSERTA_PRODUCTOCLAVECOMISION_EN_PRODUCTO = 'INSERTA_PRODUCTOCLAVECOMISION_EN_PRODUCTO';
export const BORRA_PRODUCTOCLAVECOMISION_EN_PRODUCTO = 'BORRA_PRODUCTOCLAVECOMISION_EN_PRODUCTO';
 

export const GET_AGENTES = 'GET_AGENTES';
export const GET_AGENTES_SUCCESS = 'GET_AGENTES_SUCCESS';
export const GET_AGENTES_ERROR = 'GET_AGENTES_ERROR';

export const EJECUTA_EN_AGENTE = 'EJECUTA_EN_AGENTE';
export const GET_AGENTE = 'GET_AGENTE';
export const GET_AGENTE_SUCCESS = 'GET_AGENTE_SUCCESS';
export const GET_AGENTE_ERROR = 'GET_AGENTE_ERROR';

export const POST_AGENTE = 'POST_AGENTE';
export const POST_AGENTE_SUCCESS = 'POST_AGENTE_SUCCESS';
export const POST_AGENTE_ERROR = 'POST_AGENTE_ERROR';

export const PUT_AGENTE = 'PUT_AGENTE';
export const PUT_AGENTE_SUCCESS = 'PUT_AGENTE_SUCCESS';
export const PUT_AGENTE_ERROR = 'PUT_AGENTE_ERROR';

export const DELETE_AGENTE = 'DELETE_AGENTE';
export const DELETE_AGENTE_SUCCESS = 'DELETE_AGENTE_SUCCESS';
export const DELETE_AGENTE_ERROR = 'DELETE_AGENTE_ERROR';


export const GET_EVERY_AGENTES = 'GET_EVERY_AGENTES';
export const GET_EVERY_AGENTE = 'GET_EVERY_AGENTE';
export const POST_EVERY_AGENTE = 'POST_EVERY_AGENTE';
export const PUT_EVERY_AGENTE = 'PUT_EVERY_AGENTE';
export const DELETE_EVERY_AGENTE = 'DELETE_EVERY_AGENTE';


export const INSERTA_CERTIFICADOPOLIZA_EN_AGENTE = 'INSERTA_CERTIFICADOPOLIZA_EN_AGENTE';
export const BORRA_CERTIFICADOPOLIZA_EN_AGENTE = 'BORRA_CERTIFICADOPOLIZA_EN_AGENTE';
 
export const INSERTA_FACTURAAGENTE_EN_AGENTE = 'INSERTA_FACTURAAGENTE_EN_AGENTE';
export const BORRA_FACTURAAGENTE_EN_AGENTE = 'BORRA_FACTURAAGENTE_EN_AGENTE';
 

export const GET_DETALLESLISTADEPRECIO = 'GET_DETALLESLISTADEPRECIO';
export const GET_DETALLESLISTADEPRECIO_SUCCESS = 'GET_DETALLESLISTADEPRECIO_SUCCESS';
export const GET_DETALLESLISTADEPRECIO_ERROR = 'GET_DETALLESLISTADEPRECIO_ERROR';

export const EJECUTA_EN_DETALLELISTADEPRECIO = 'EJECUTA_EN_DETALLELISTADEPRECIO';
export const GET_DETALLELISTADEPRECIO = 'GET_DETALLELISTADEPRECIO';
export const GET_DETALLELISTADEPRECIO_SUCCESS = 'GET_DETALLELISTADEPRECIO_SUCCESS';
export const GET_DETALLELISTADEPRECIO_ERROR = 'GET_DETALLELISTADEPRECIO_ERROR';

export const POST_DETALLELISTADEPRECIO = 'POST_DETALLELISTADEPRECIO';
export const POST_DETALLELISTADEPRECIO_SUCCESS = 'POST_DETALLELISTADEPRECIO_SUCCESS';
export const POST_DETALLELISTADEPRECIO_ERROR = 'POST_DETALLELISTADEPRECIO_ERROR';

export const PUT_DETALLELISTADEPRECIO = 'PUT_DETALLELISTADEPRECIO';
export const PUT_DETALLELISTADEPRECIO_SUCCESS = 'PUT_DETALLELISTADEPRECIO_SUCCESS';
export const PUT_DETALLELISTADEPRECIO_ERROR = 'PUT_DETALLELISTADEPRECIO_ERROR';

export const DELETE_DETALLELISTADEPRECIO = 'DELETE_DETALLELISTADEPRECIO';
export const DELETE_DETALLELISTADEPRECIO_SUCCESS = 'DELETE_DETALLELISTADEPRECIO_SUCCESS';
export const DELETE_DETALLELISTADEPRECIO_ERROR = 'DELETE_DETALLELISTADEPRECIO_ERROR';


export const GET_EVERY_DETALLESLISTADEPRECIO = 'GET_EVERY_DETALLESLISTADEPRECIO';
export const GET_EVERY_DETALLELISTADEPRECIO = 'GET_EVERY_DETALLELISTADEPRECIO';
export const POST_EVERY_DETALLELISTADEPRECIO = 'POST_EVERY_DETALLELISTADEPRECIO';
export const PUT_EVERY_DETALLELISTADEPRECIO = 'PUT_EVERY_DETALLELISTADEPRECIO';
export const DELETE_EVERY_DETALLELISTADEPRECIO = 'DELETE_EVERY_DETALLELISTADEPRECIO';

 

export const GET_ASEGURADOSPRODUCTO = 'GET_ASEGURADOSPRODUCTO';
export const GET_ASEGURADOSPRODUCTO_SUCCESS = 'GET_ASEGURADOSPRODUCTO_SUCCESS';
export const GET_ASEGURADOSPRODUCTO_ERROR = 'GET_ASEGURADOSPRODUCTO_ERROR';

export const EJECUTA_EN_ASEGURADOPRODUCTO = 'EJECUTA_EN_ASEGURADOPRODUCTO';
export const GET_ASEGURADOPRODUCTO = 'GET_ASEGURADOPRODUCTO';
export const GET_ASEGURADOPRODUCTO_SUCCESS = 'GET_ASEGURADOPRODUCTO_SUCCESS';
export const GET_ASEGURADOPRODUCTO_ERROR = 'GET_ASEGURADOPRODUCTO_ERROR';

export const POST_ASEGURADOPRODUCTO = 'POST_ASEGURADOPRODUCTO';
export const POST_ASEGURADOPRODUCTO_SUCCESS = 'POST_ASEGURADOPRODUCTO_SUCCESS';
export const POST_ASEGURADOPRODUCTO_ERROR = 'POST_ASEGURADOPRODUCTO_ERROR';

export const PUT_ASEGURADOPRODUCTO = 'PUT_ASEGURADOPRODUCTO';
export const PUT_ASEGURADOPRODUCTO_SUCCESS = 'PUT_ASEGURADOPRODUCTO_SUCCESS';
export const PUT_ASEGURADOPRODUCTO_ERROR = 'PUT_ASEGURADOPRODUCTO_ERROR';

export const DELETE_ASEGURADOPRODUCTO = 'DELETE_ASEGURADOPRODUCTO';
export const DELETE_ASEGURADOPRODUCTO_SUCCESS = 'DELETE_ASEGURADOPRODUCTO_SUCCESS';
export const DELETE_ASEGURADOPRODUCTO_ERROR = 'DELETE_ASEGURADOPRODUCTO_ERROR';


export const GET_EVERY_ASEGURADOSPRODUCTO = 'GET_EVERY_ASEGURADOSPRODUCTO';
export const GET_EVERY_ASEGURADOPRODUCTO = 'GET_EVERY_ASEGURADOPRODUCTO';
export const POST_EVERY_ASEGURADOPRODUCTO = 'POST_EVERY_ASEGURADOPRODUCTO';
export const PUT_EVERY_ASEGURADOPRODUCTO = 'PUT_EVERY_ASEGURADOPRODUCTO';
export const DELETE_EVERY_ASEGURADOPRODUCTO = 'DELETE_EVERY_ASEGURADOPRODUCTO';

 

export const GET_LISTASDEPRECIO = 'GET_LISTASDEPRECIO';
export const GET_LISTASDEPRECIO_SUCCESS = 'GET_LISTASDEPRECIO_SUCCESS';
export const GET_LISTASDEPRECIO_ERROR = 'GET_LISTASDEPRECIO_ERROR';

export const EJECUTA_EN_LISTADEPRECIO = 'EJECUTA_EN_LISTADEPRECIO';
export const GET_LISTADEPRECIO = 'GET_LISTADEPRECIO';
export const GET_LISTADEPRECIO_SUCCESS = 'GET_LISTADEPRECIO_SUCCESS';
export const GET_LISTADEPRECIO_ERROR = 'GET_LISTADEPRECIO_ERROR';

export const POST_LISTADEPRECIO = 'POST_LISTADEPRECIO';
export const POST_LISTADEPRECIO_SUCCESS = 'POST_LISTADEPRECIO_SUCCESS';
export const POST_LISTADEPRECIO_ERROR = 'POST_LISTADEPRECIO_ERROR';

export const PUT_LISTADEPRECIO = 'PUT_LISTADEPRECIO';
export const PUT_LISTADEPRECIO_SUCCESS = 'PUT_LISTADEPRECIO_SUCCESS';
export const PUT_LISTADEPRECIO_ERROR = 'PUT_LISTADEPRECIO_ERROR';

export const DELETE_LISTADEPRECIO = 'DELETE_LISTADEPRECIO';
export const DELETE_LISTADEPRECIO_SUCCESS = 'DELETE_LISTADEPRECIO_SUCCESS';
export const DELETE_LISTADEPRECIO_ERROR = 'DELETE_LISTADEPRECIO_ERROR';


export const GET_EVERY_LISTASDEPRECIO = 'GET_EVERY_LISTASDEPRECIO';
export const GET_EVERY_LISTADEPRECIO = 'GET_EVERY_LISTADEPRECIO';
export const POST_EVERY_LISTADEPRECIO = 'POST_EVERY_LISTADEPRECIO';
export const PUT_EVERY_LISTADEPRECIO = 'PUT_EVERY_LISTADEPRECIO';
export const DELETE_EVERY_LISTADEPRECIO = 'DELETE_EVERY_LISTADEPRECIO';


export const INSERTA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO = 'INSERTA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO';
export const BORRA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO = 'BORRA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO';
 
export const INSERTA_ACTUALIZACION_EN_LISTADEPRECIO = 'INSERTA_ACTUALIZACION_EN_LISTADEPRECIO';
export const BORRA_ACTUALIZACION_EN_LISTADEPRECIO = 'BORRA_ACTUALIZACION_EN_LISTADEPRECIO';
 
export const INSERTA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO = 'INSERTA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO';
export const BORRA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO = 'BORRA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO';
 

export const GET_PRIMASLISTADEPRECIO = 'GET_PRIMASLISTADEPRECIO';
export const GET_PRIMASLISTADEPRECIO_SUCCESS = 'GET_PRIMASLISTADEPRECIO_SUCCESS';
export const GET_PRIMASLISTADEPRECIO_ERROR = 'GET_PRIMASLISTADEPRECIO_ERROR';

export const EJECUTA_EN_PRIMALISTADEPRECIO = 'EJECUTA_EN_PRIMALISTADEPRECIO';
export const GET_PRIMALISTADEPRECIO = 'GET_PRIMALISTADEPRECIO';
export const GET_PRIMALISTADEPRECIO_SUCCESS = 'GET_PRIMALISTADEPRECIO_SUCCESS';
export const GET_PRIMALISTADEPRECIO_ERROR = 'GET_PRIMALISTADEPRECIO_ERROR';

export const POST_PRIMALISTADEPRECIO = 'POST_PRIMALISTADEPRECIO';
export const POST_PRIMALISTADEPRECIO_SUCCESS = 'POST_PRIMALISTADEPRECIO_SUCCESS';
export const POST_PRIMALISTADEPRECIO_ERROR = 'POST_PRIMALISTADEPRECIO_ERROR';

export const PUT_PRIMALISTADEPRECIO = 'PUT_PRIMALISTADEPRECIO';
export const PUT_PRIMALISTADEPRECIO_SUCCESS = 'PUT_PRIMALISTADEPRECIO_SUCCESS';
export const PUT_PRIMALISTADEPRECIO_ERROR = 'PUT_PRIMALISTADEPRECIO_ERROR';

export const DELETE_PRIMALISTADEPRECIO = 'DELETE_PRIMALISTADEPRECIO';
export const DELETE_PRIMALISTADEPRECIO_SUCCESS = 'DELETE_PRIMALISTADEPRECIO_SUCCESS';
export const DELETE_PRIMALISTADEPRECIO_ERROR = 'DELETE_PRIMALISTADEPRECIO_ERROR';


export const GET_EVERY_PRIMASLISTADEPRECIO = 'GET_EVERY_PRIMASLISTADEPRECIO';
export const GET_EVERY_PRIMALISTADEPRECIO = 'GET_EVERY_PRIMALISTADEPRECIO';
export const POST_EVERY_PRIMALISTADEPRECIO = 'POST_EVERY_PRIMALISTADEPRECIO';
export const PUT_EVERY_PRIMALISTADEPRECIO = 'PUT_EVERY_PRIMALISTADEPRECIO';
export const DELETE_EVERY_PRIMALISTADEPRECIO = 'DELETE_EVERY_PRIMALISTADEPRECIO';

 

export const GET_COBERTURASPRODUCTO = 'GET_COBERTURASPRODUCTO';
export const GET_COBERTURASPRODUCTO_SUCCESS = 'GET_COBERTURASPRODUCTO_SUCCESS';
export const GET_COBERTURASPRODUCTO_ERROR = 'GET_COBERTURASPRODUCTO_ERROR';

export const EJECUTA_EN_COBERTURAPRODUCTO = 'EJECUTA_EN_COBERTURAPRODUCTO';
export const GET_COBERTURAPRODUCTO = 'GET_COBERTURAPRODUCTO';
export const GET_COBERTURAPRODUCTO_SUCCESS = 'GET_COBERTURAPRODUCTO_SUCCESS';
export const GET_COBERTURAPRODUCTO_ERROR = 'GET_COBERTURAPRODUCTO_ERROR';

export const POST_COBERTURAPRODUCTO = 'POST_COBERTURAPRODUCTO';
export const POST_COBERTURAPRODUCTO_SUCCESS = 'POST_COBERTURAPRODUCTO_SUCCESS';
export const POST_COBERTURAPRODUCTO_ERROR = 'POST_COBERTURAPRODUCTO_ERROR';

export const PUT_COBERTURAPRODUCTO = 'PUT_COBERTURAPRODUCTO';
export const PUT_COBERTURAPRODUCTO_SUCCESS = 'PUT_COBERTURAPRODUCTO_SUCCESS';
export const PUT_COBERTURAPRODUCTO_ERROR = 'PUT_COBERTURAPRODUCTO_ERROR';

export const DELETE_COBERTURAPRODUCTO = 'DELETE_COBERTURAPRODUCTO';
export const DELETE_COBERTURAPRODUCTO_SUCCESS = 'DELETE_COBERTURAPRODUCTO_SUCCESS';
export const DELETE_COBERTURAPRODUCTO_ERROR = 'DELETE_COBERTURAPRODUCTO_ERROR';


export const GET_EVERY_COBERTURASPRODUCTO = 'GET_EVERY_COBERTURASPRODUCTO';
export const GET_EVERY_COBERTURAPRODUCTO = 'GET_EVERY_COBERTURAPRODUCTO';
export const POST_EVERY_COBERTURAPRODUCTO = 'POST_EVERY_COBERTURAPRODUCTO';
export const PUT_EVERY_COBERTURAPRODUCTO = 'PUT_EVERY_COBERTURAPRODUCTO';
export const DELETE_EVERY_COBERTURAPRODUCTO = 'DELETE_EVERY_COBERTURAPRODUCTO';

 

export const GET_CLAVESCOMISION = 'GET_CLAVESCOMISION';
export const GET_CLAVESCOMISION_SUCCESS = 'GET_CLAVESCOMISION_SUCCESS';
export const GET_CLAVESCOMISION_ERROR = 'GET_CLAVESCOMISION_ERROR';

export const EJECUTA_EN_CLAVECOMISION = 'EJECUTA_EN_CLAVECOMISION';
export const GET_CLAVECOMISION = 'GET_CLAVECOMISION';
export const GET_CLAVECOMISION_SUCCESS = 'GET_CLAVECOMISION_SUCCESS';
export const GET_CLAVECOMISION_ERROR = 'GET_CLAVECOMISION_ERROR';

export const POST_CLAVECOMISION = 'POST_CLAVECOMISION';
export const POST_CLAVECOMISION_SUCCESS = 'POST_CLAVECOMISION_SUCCESS';
export const POST_CLAVECOMISION_ERROR = 'POST_CLAVECOMISION_ERROR';

export const PUT_CLAVECOMISION = 'PUT_CLAVECOMISION';
export const PUT_CLAVECOMISION_SUCCESS = 'PUT_CLAVECOMISION_SUCCESS';
export const PUT_CLAVECOMISION_ERROR = 'PUT_CLAVECOMISION_ERROR';

export const DELETE_CLAVECOMISION = 'DELETE_CLAVECOMISION';
export const DELETE_CLAVECOMISION_SUCCESS = 'DELETE_CLAVECOMISION_SUCCESS';
export const DELETE_CLAVECOMISION_ERROR = 'DELETE_CLAVECOMISION_ERROR';


export const GET_EVERY_CLAVESCOMISION = 'GET_EVERY_CLAVESCOMISION';
export const GET_EVERY_CLAVECOMISION = 'GET_EVERY_CLAVECOMISION';
export const POST_EVERY_CLAVECOMISION = 'POST_EVERY_CLAVECOMISION';
export const PUT_EVERY_CLAVECOMISION = 'PUT_EVERY_CLAVECOMISION';
export const DELETE_EVERY_CLAVECOMISION = 'DELETE_EVERY_CLAVECOMISION';


export const INSERTA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION = 'INSERTA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION';
export const BORRA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION = 'BORRA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION';
 
export const INSERTA_USUARIO_EN_CLAVECOMISION = 'INSERTA_USUARIO_EN_CLAVECOMISION';
export const BORRA_USUARIO_EN_CLAVECOMISION = 'BORRA_USUARIO_EN_CLAVECOMISION';
 
export const INSERTA_AGENTE_EN_CLAVECOMISION = 'INSERTA_AGENTE_EN_CLAVECOMISION';
export const BORRA_AGENTE_EN_CLAVECOMISION = 'BORRA_AGENTE_EN_CLAVECOMISION';
 

export const GET_PRODUCTOSCLAVECOMISION = 'GET_PRODUCTOSCLAVECOMISION';
export const GET_PRODUCTOSCLAVECOMISION_SUCCESS = 'GET_PRODUCTOSCLAVECOMISION_SUCCESS';
export const GET_PRODUCTOSCLAVECOMISION_ERROR = 'GET_PRODUCTOSCLAVECOMISION_ERROR';

export const EJECUTA_EN_PRODUCTOCLAVECOMISION = 'EJECUTA_EN_PRODUCTOCLAVECOMISION';
export const GET_PRODUCTOCLAVECOMISION = 'GET_PRODUCTOCLAVECOMISION';
export const GET_PRODUCTOCLAVECOMISION_SUCCESS = 'GET_PRODUCTOCLAVECOMISION_SUCCESS';
export const GET_PRODUCTOCLAVECOMISION_ERROR = 'GET_PRODUCTOCLAVECOMISION_ERROR';

export const POST_PRODUCTOCLAVECOMISION = 'POST_PRODUCTOCLAVECOMISION';
export const POST_PRODUCTOCLAVECOMISION_SUCCESS = 'POST_PRODUCTOCLAVECOMISION_SUCCESS';
export const POST_PRODUCTOCLAVECOMISION_ERROR = 'POST_PRODUCTOCLAVECOMISION_ERROR';

export const PUT_PRODUCTOCLAVECOMISION = 'PUT_PRODUCTOCLAVECOMISION';
export const PUT_PRODUCTOCLAVECOMISION_SUCCESS = 'PUT_PRODUCTOCLAVECOMISION_SUCCESS';
export const PUT_PRODUCTOCLAVECOMISION_ERROR = 'PUT_PRODUCTOCLAVECOMISION_ERROR';

export const DELETE_PRODUCTOCLAVECOMISION = 'DELETE_PRODUCTOCLAVECOMISION';
export const DELETE_PRODUCTOCLAVECOMISION_SUCCESS = 'DELETE_PRODUCTOCLAVECOMISION_SUCCESS';
export const DELETE_PRODUCTOCLAVECOMISION_ERROR = 'DELETE_PRODUCTOCLAVECOMISION_ERROR';


export const GET_EVERY_PRODUCTOSCLAVECOMISION = 'GET_EVERY_PRODUCTOSCLAVECOMISION';
export const GET_EVERY_PRODUCTOCLAVECOMISION = 'GET_EVERY_PRODUCTOCLAVECOMISION';
export const POST_EVERY_PRODUCTOCLAVECOMISION = 'POST_EVERY_PRODUCTOCLAVECOMISION';
export const PUT_EVERY_PRODUCTOCLAVECOMISION = 'PUT_EVERY_PRODUCTOCLAVECOMISION';
export const DELETE_EVERY_PRODUCTOCLAVECOMISION = 'DELETE_EVERY_PRODUCTOCLAVECOMISION';

 

export const GET_FORMASPAGO = 'GET_FORMASPAGO';
export const GET_FORMASPAGO_SUCCESS = 'GET_FORMASPAGO_SUCCESS';
export const GET_FORMASPAGO_ERROR = 'GET_FORMASPAGO_ERROR';

export const EJECUTA_EN_FORMAPAGO = 'EJECUTA_EN_FORMAPAGO';
export const GET_FORMAPAGO = 'GET_FORMAPAGO';
export const GET_FORMAPAGO_SUCCESS = 'GET_FORMAPAGO_SUCCESS';
export const GET_FORMAPAGO_ERROR = 'GET_FORMAPAGO_ERROR';

export const POST_FORMAPAGO = 'POST_FORMAPAGO';
export const POST_FORMAPAGO_SUCCESS = 'POST_FORMAPAGO_SUCCESS';
export const POST_FORMAPAGO_ERROR = 'POST_FORMAPAGO_ERROR';

export const PUT_FORMAPAGO = 'PUT_FORMAPAGO';
export const PUT_FORMAPAGO_SUCCESS = 'PUT_FORMAPAGO_SUCCESS';
export const PUT_FORMAPAGO_ERROR = 'PUT_FORMAPAGO_ERROR';

export const DELETE_FORMAPAGO = 'DELETE_FORMAPAGO';
export const DELETE_FORMAPAGO_SUCCESS = 'DELETE_FORMAPAGO_SUCCESS';
export const DELETE_FORMAPAGO_ERROR = 'DELETE_FORMAPAGO_ERROR';


export const GET_EVERY_FORMASPAGO = 'GET_EVERY_FORMASPAGO';
export const GET_EVERY_FORMAPAGO = 'GET_EVERY_FORMAPAGO';
export const POST_EVERY_FORMAPAGO = 'POST_EVERY_FORMAPAGO';
export const PUT_EVERY_FORMAPAGO = 'PUT_EVERY_FORMAPAGO';
export const DELETE_EVERY_FORMAPAGO = 'DELETE_EVERY_FORMAPAGO';


export const INSERTA_POLIZA_EN_FORMAPAGO = 'INSERTA_POLIZA_EN_FORMAPAGO';
export const BORRA_POLIZA_EN_FORMAPAGO = 'BORRA_POLIZA_EN_FORMAPAGO';
 
export const INSERTA_CERTIFICADOPOLIZA_EN_FORMAPAGO = 'INSERTA_CERTIFICADOPOLIZA_EN_FORMAPAGO';
export const BORRA_CERTIFICADOPOLIZA_EN_FORMAPAGO = 'BORRA_CERTIFICADOPOLIZA_EN_FORMAPAGO';
 
export const INSERTA_DETALLELISTADEPRECIO_EN_FORMAPAGO = 'INSERTA_DETALLELISTADEPRECIO_EN_FORMAPAGO';
export const BORRA_DETALLELISTADEPRECIO_EN_FORMAPAGO = 'BORRA_DETALLELISTADEPRECIO_EN_FORMAPAGO';
 

export const GET_PARENTEZCOS = 'GET_PARENTEZCOS';
export const GET_PARENTEZCOS_SUCCESS = 'GET_PARENTEZCOS_SUCCESS';
export const GET_PARENTEZCOS_ERROR = 'GET_PARENTEZCOS_ERROR';

export const EJECUTA_EN_PARENTEZCO = 'EJECUTA_EN_PARENTEZCO';
export const GET_PARENTEZCO = 'GET_PARENTEZCO';
export const GET_PARENTEZCO_SUCCESS = 'GET_PARENTEZCO_SUCCESS';
export const GET_PARENTEZCO_ERROR = 'GET_PARENTEZCO_ERROR';

export const POST_PARENTEZCO = 'POST_PARENTEZCO';
export const POST_PARENTEZCO_SUCCESS = 'POST_PARENTEZCO_SUCCESS';
export const POST_PARENTEZCO_ERROR = 'POST_PARENTEZCO_ERROR';

export const PUT_PARENTEZCO = 'PUT_PARENTEZCO';
export const PUT_PARENTEZCO_SUCCESS = 'PUT_PARENTEZCO_SUCCESS';
export const PUT_PARENTEZCO_ERROR = 'PUT_PARENTEZCO_ERROR';

export const DELETE_PARENTEZCO = 'DELETE_PARENTEZCO';
export const DELETE_PARENTEZCO_SUCCESS = 'DELETE_PARENTEZCO_SUCCESS';
export const DELETE_PARENTEZCO_ERROR = 'DELETE_PARENTEZCO_ERROR';


export const GET_EVERY_PARENTEZCOS = 'GET_EVERY_PARENTEZCOS';
export const GET_EVERY_PARENTEZCO = 'GET_EVERY_PARENTEZCO';
export const POST_EVERY_PARENTEZCO = 'POST_EVERY_PARENTEZCO';
export const PUT_EVERY_PARENTEZCO = 'PUT_EVERY_PARENTEZCO';
export const DELETE_EVERY_PARENTEZCO = 'DELETE_EVERY_PARENTEZCO';


export const INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_PARENTEZCO = 'INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_PARENTEZCO';
export const BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_PARENTEZCO = 'BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_PARENTEZCO';
 
export const INSERTA_ASEGURADOPRODUCTO_EN_PARENTEZCO = 'INSERTA_ASEGURADOPRODUCTO_EN_PARENTEZCO';
export const BORRA_ASEGURADOPRODUCTO_EN_PARENTEZCO = 'BORRA_ASEGURADOPRODUCTO_EN_PARENTEZCO';
 

export const GET_ESTATUSPAGOS = 'GET_ESTATUSPAGOS';
export const GET_ESTATUSPAGOS_SUCCESS = 'GET_ESTATUSPAGOS_SUCCESS';
export const GET_ESTATUSPAGOS_ERROR = 'GET_ESTATUSPAGOS_ERROR';

export const EJECUTA_EN_ESTATUSPAGO = 'EJECUTA_EN_ESTATUSPAGO';
export const GET_ESTATUSPAGO = 'GET_ESTATUSPAGO';
export const GET_ESTATUSPAGO_SUCCESS = 'GET_ESTATUSPAGO_SUCCESS';
export const GET_ESTATUSPAGO_ERROR = 'GET_ESTATUSPAGO_ERROR';

export const POST_ESTATUSPAGO = 'POST_ESTATUSPAGO';
export const POST_ESTATUSPAGO_SUCCESS = 'POST_ESTATUSPAGO_SUCCESS';
export const POST_ESTATUSPAGO_ERROR = 'POST_ESTATUSPAGO_ERROR';

export const PUT_ESTATUSPAGO = 'PUT_ESTATUSPAGO';
export const PUT_ESTATUSPAGO_SUCCESS = 'PUT_ESTATUSPAGO_SUCCESS';
export const PUT_ESTATUSPAGO_ERROR = 'PUT_ESTATUSPAGO_ERROR';

export const DELETE_ESTATUSPAGO = 'DELETE_ESTATUSPAGO';
export const DELETE_ESTATUSPAGO_SUCCESS = 'DELETE_ESTATUSPAGO_SUCCESS';
export const DELETE_ESTATUSPAGO_ERROR = 'DELETE_ESTATUSPAGO_ERROR';


export const GET_EVERY_ESTATUSPAGOS = 'GET_EVERY_ESTATUSPAGOS';
export const GET_EVERY_ESTATUSPAGO = 'GET_EVERY_ESTATUSPAGO';
export const POST_EVERY_ESTATUSPAGO = 'POST_EVERY_ESTATUSPAGO';
export const PUT_EVERY_ESTATUSPAGO = 'PUT_EVERY_ESTATUSPAGO';
export const DELETE_EVERY_ESTATUSPAGO = 'DELETE_EVERY_ESTATUSPAGO';


export const INSERTA_DPCERTIFICADOPOLIZA_EN_ESTATUSPAGO = 'INSERTA_DPCERTIFICADOPOLIZA_EN_ESTATUSPAGO';
export const BORRA_DPCERTIFICADOPOLIZA_EN_ESTATUSPAGO = 'BORRA_DPCERTIFICADOPOLIZA_EN_ESTATUSPAGO';
 

export const GET_TIPOSPRODUCTO = 'GET_TIPOSPRODUCTO';
export const GET_TIPOSPRODUCTO_SUCCESS = 'GET_TIPOSPRODUCTO_SUCCESS';
export const GET_TIPOSPRODUCTO_ERROR = 'GET_TIPOSPRODUCTO_ERROR';

export const EJECUTA_EN_TIPOPRODUCTO = 'EJECUTA_EN_TIPOPRODUCTO';
export const GET_TIPOPRODUCTO = 'GET_TIPOPRODUCTO';
export const GET_TIPOPRODUCTO_SUCCESS = 'GET_TIPOPRODUCTO_SUCCESS';
export const GET_TIPOPRODUCTO_ERROR = 'GET_TIPOPRODUCTO_ERROR';

export const POST_TIPOPRODUCTO = 'POST_TIPOPRODUCTO';
export const POST_TIPOPRODUCTO_SUCCESS = 'POST_TIPOPRODUCTO_SUCCESS';
export const POST_TIPOPRODUCTO_ERROR = 'POST_TIPOPRODUCTO_ERROR';

export const PUT_TIPOPRODUCTO = 'PUT_TIPOPRODUCTO';
export const PUT_TIPOPRODUCTO_SUCCESS = 'PUT_TIPOPRODUCTO_SUCCESS';
export const PUT_TIPOPRODUCTO_ERROR = 'PUT_TIPOPRODUCTO_ERROR';

export const DELETE_TIPOPRODUCTO = 'DELETE_TIPOPRODUCTO';
export const DELETE_TIPOPRODUCTO_SUCCESS = 'DELETE_TIPOPRODUCTO_SUCCESS';
export const DELETE_TIPOPRODUCTO_ERROR = 'DELETE_TIPOPRODUCTO_ERROR';


export const GET_EVERY_TIPOSPRODUCTO = 'GET_EVERY_TIPOSPRODUCTO';
export const GET_EVERY_TIPOPRODUCTO = 'GET_EVERY_TIPOPRODUCTO';
export const POST_EVERY_TIPOPRODUCTO = 'POST_EVERY_TIPOPRODUCTO';
export const PUT_EVERY_TIPOPRODUCTO = 'PUT_EVERY_TIPOPRODUCTO';
export const DELETE_EVERY_TIPOPRODUCTO = 'DELETE_EVERY_TIPOPRODUCTO';


export const INSERTA_PRODUCTO_EN_TIPOPRODUCTO = 'INSERTA_PRODUCTO_EN_TIPOPRODUCTO';
export const BORRA_PRODUCTO_EN_TIPOPRODUCTO = 'BORRA_PRODUCTO_EN_TIPOPRODUCTO';
 

export const GET_ORIGENES = 'GET_ORIGENES';
export const GET_ORIGENES_SUCCESS = 'GET_ORIGENES_SUCCESS';
export const GET_ORIGENES_ERROR = 'GET_ORIGENES_ERROR';

export const EJECUTA_EN_ORIGEN = 'EJECUTA_EN_ORIGEN';
export const GET_ORIGEN = 'GET_ORIGEN';
export const GET_ORIGEN_SUCCESS = 'GET_ORIGEN_SUCCESS';
export const GET_ORIGEN_ERROR = 'GET_ORIGEN_ERROR';

export const POST_ORIGEN = 'POST_ORIGEN';
export const POST_ORIGEN_SUCCESS = 'POST_ORIGEN_SUCCESS';
export const POST_ORIGEN_ERROR = 'POST_ORIGEN_ERROR';

export const PUT_ORIGEN = 'PUT_ORIGEN';
export const PUT_ORIGEN_SUCCESS = 'PUT_ORIGEN_SUCCESS';
export const PUT_ORIGEN_ERROR = 'PUT_ORIGEN_ERROR';

export const DELETE_ORIGEN = 'DELETE_ORIGEN';
export const DELETE_ORIGEN_SUCCESS = 'DELETE_ORIGEN_SUCCESS';
export const DELETE_ORIGEN_ERROR = 'DELETE_ORIGEN_ERROR';


export const GET_EVERY_ORIGENES = 'GET_EVERY_ORIGENES';
export const GET_EVERY_ORIGEN = 'GET_EVERY_ORIGEN';
export const POST_EVERY_ORIGEN = 'POST_EVERY_ORIGEN';
export const PUT_EVERY_ORIGEN = 'PUT_EVERY_ORIGEN';
export const DELETE_EVERY_ORIGEN = 'DELETE_EVERY_ORIGEN';


export const INSERTA_CERTIFICADOPOLIZA_EN_ORIGEN = 'INSERTA_CERTIFICADOPOLIZA_EN_ORIGEN';
export const BORRA_CERTIFICADOPOLIZA_EN_ORIGEN = 'BORRA_CERTIFICADOPOLIZA_EN_ORIGEN';
 
export const INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN = 'INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN';
export const BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN = 'BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN';
 

export const GET_GENEROS = 'GET_GENEROS';
export const GET_GENEROS_SUCCESS = 'GET_GENEROS_SUCCESS';
export const GET_GENEROS_ERROR = 'GET_GENEROS_ERROR';

export const EJECUTA_EN_GENERO = 'EJECUTA_EN_GENERO';
export const GET_GENERO = 'GET_GENERO';
export const GET_GENERO_SUCCESS = 'GET_GENERO_SUCCESS';
export const GET_GENERO_ERROR = 'GET_GENERO_ERROR';

export const POST_GENERO = 'POST_GENERO';
export const POST_GENERO_SUCCESS = 'POST_GENERO_SUCCESS';
export const POST_GENERO_ERROR = 'POST_GENERO_ERROR';

export const PUT_GENERO = 'PUT_GENERO';
export const PUT_GENERO_SUCCESS = 'PUT_GENERO_SUCCESS';
export const PUT_GENERO_ERROR = 'PUT_GENERO_ERROR';

export const DELETE_GENERO = 'DELETE_GENERO';
export const DELETE_GENERO_SUCCESS = 'DELETE_GENERO_SUCCESS';
export const DELETE_GENERO_ERROR = 'DELETE_GENERO_ERROR';


export const GET_EVERY_GENEROS = 'GET_EVERY_GENEROS';
export const GET_EVERY_GENERO = 'GET_EVERY_GENERO';
export const POST_EVERY_GENERO = 'POST_EVERY_GENERO';
export const PUT_EVERY_GENERO = 'PUT_EVERY_GENERO';
export const DELETE_EVERY_GENERO = 'DELETE_EVERY_GENERO';


export const INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO = 'INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO';
export const BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO = 'BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO';
 

export const GET_ESTATUSCERTIFICADOS = 'GET_ESTATUSCERTIFICADOS';
export const GET_ESTATUSCERTIFICADOS_SUCCESS = 'GET_ESTATUSCERTIFICADOS_SUCCESS';
export const GET_ESTATUSCERTIFICADOS_ERROR = 'GET_ESTATUSCERTIFICADOS_ERROR';

export const EJECUTA_EN_ESTATUSCERTIFICADO = 'EJECUTA_EN_ESTATUSCERTIFICADO';
export const GET_ESTATUSCERTIFICADO = 'GET_ESTATUSCERTIFICADO';
export const GET_ESTATUSCERTIFICADO_SUCCESS = 'GET_ESTATUSCERTIFICADO_SUCCESS';
export const GET_ESTATUSCERTIFICADO_ERROR = 'GET_ESTATUSCERTIFICADO_ERROR';

export const POST_ESTATUSCERTIFICADO = 'POST_ESTATUSCERTIFICADO';
export const POST_ESTATUSCERTIFICADO_SUCCESS = 'POST_ESTATUSCERTIFICADO_SUCCESS';
export const POST_ESTATUSCERTIFICADO_ERROR = 'POST_ESTATUSCERTIFICADO_ERROR';

export const PUT_ESTATUSCERTIFICADO = 'PUT_ESTATUSCERTIFICADO';
export const PUT_ESTATUSCERTIFICADO_SUCCESS = 'PUT_ESTATUSCERTIFICADO_SUCCESS';
export const PUT_ESTATUSCERTIFICADO_ERROR = 'PUT_ESTATUSCERTIFICADO_ERROR';

export const DELETE_ESTATUSCERTIFICADO = 'DELETE_ESTATUSCERTIFICADO';
export const DELETE_ESTATUSCERTIFICADO_SUCCESS = 'DELETE_ESTATUSCERTIFICADO_SUCCESS';
export const DELETE_ESTATUSCERTIFICADO_ERROR = 'DELETE_ESTATUSCERTIFICADO_ERROR';


export const GET_EVERY_ESTATUSCERTIFICADOS = 'GET_EVERY_ESTATUSCERTIFICADOS';
export const GET_EVERY_ESTATUSCERTIFICADO = 'GET_EVERY_ESTATUSCERTIFICADO';
export const POST_EVERY_ESTATUSCERTIFICADO = 'POST_EVERY_ESTATUSCERTIFICADO';
export const PUT_EVERY_ESTATUSCERTIFICADO = 'PUT_EVERY_ESTATUSCERTIFICADO';
export const DELETE_EVERY_ESTATUSCERTIFICADO = 'DELETE_EVERY_ESTATUSCERTIFICADO';


export const INSERTA_CERTIFICADOPOLIZA_EN_ESTATUSCERTIFICADO = 'INSERTA_CERTIFICADOPOLIZA_EN_ESTATUSCERTIFICADO';
export const BORRA_CERTIFICADOPOLIZA_EN_ESTATUSCERTIFICADO = 'BORRA_CERTIFICADOPOLIZA_EN_ESTATUSCERTIFICADO';
 

export const GET_ESTATUSPOLIZAS = 'GET_ESTATUSPOLIZAS';
export const GET_ESTATUSPOLIZAS_SUCCESS = 'GET_ESTATUSPOLIZAS_SUCCESS';
export const GET_ESTATUSPOLIZAS_ERROR = 'GET_ESTATUSPOLIZAS_ERROR';

export const EJECUTA_EN_ESTATUSPOLIZA = 'EJECUTA_EN_ESTATUSPOLIZA';
export const GET_ESTATUSPOLIZA = 'GET_ESTATUSPOLIZA';
export const GET_ESTATUSPOLIZA_SUCCESS = 'GET_ESTATUSPOLIZA_SUCCESS';
export const GET_ESTATUSPOLIZA_ERROR = 'GET_ESTATUSPOLIZA_ERROR';

export const POST_ESTATUSPOLIZA = 'POST_ESTATUSPOLIZA';
export const POST_ESTATUSPOLIZA_SUCCESS = 'POST_ESTATUSPOLIZA_SUCCESS';
export const POST_ESTATUSPOLIZA_ERROR = 'POST_ESTATUSPOLIZA_ERROR';

export const PUT_ESTATUSPOLIZA = 'PUT_ESTATUSPOLIZA';
export const PUT_ESTATUSPOLIZA_SUCCESS = 'PUT_ESTATUSPOLIZA_SUCCESS';
export const PUT_ESTATUSPOLIZA_ERROR = 'PUT_ESTATUSPOLIZA_ERROR';

export const DELETE_ESTATUSPOLIZA = 'DELETE_ESTATUSPOLIZA';
export const DELETE_ESTATUSPOLIZA_SUCCESS = 'DELETE_ESTATUSPOLIZA_SUCCESS';
export const DELETE_ESTATUSPOLIZA_ERROR = 'DELETE_ESTATUSPOLIZA_ERROR';


export const GET_EVERY_ESTATUSPOLIZAS = 'GET_EVERY_ESTATUSPOLIZAS';
export const GET_EVERY_ESTATUSPOLIZA = 'GET_EVERY_ESTATUSPOLIZA';
export const POST_EVERY_ESTATUSPOLIZA = 'POST_EVERY_ESTATUSPOLIZA';
export const PUT_EVERY_ESTATUSPOLIZA = 'PUT_EVERY_ESTATUSPOLIZA';
export const DELETE_EVERY_ESTATUSPOLIZA = 'DELETE_EVERY_ESTATUSPOLIZA';


export const INSERTA_POLIZA_EN_ESTATUSPOLIZA = 'INSERTA_POLIZA_EN_ESTATUSPOLIZA';
export const BORRA_POLIZA_EN_ESTATUSPOLIZA = 'BORRA_POLIZA_EN_ESTATUSPOLIZA';
 

export const GET_MEDIOSPAGO = 'GET_MEDIOSPAGO';
export const GET_MEDIOSPAGO_SUCCESS = 'GET_MEDIOSPAGO_SUCCESS';
export const GET_MEDIOSPAGO_ERROR = 'GET_MEDIOSPAGO_ERROR';

export const EJECUTA_EN_MEDIOPAGO = 'EJECUTA_EN_MEDIOPAGO';
export const GET_MEDIOPAGO = 'GET_MEDIOPAGO';
export const GET_MEDIOPAGO_SUCCESS = 'GET_MEDIOPAGO_SUCCESS';
export const GET_MEDIOPAGO_ERROR = 'GET_MEDIOPAGO_ERROR';

export const POST_MEDIOPAGO = 'POST_MEDIOPAGO';
export const POST_MEDIOPAGO_SUCCESS = 'POST_MEDIOPAGO_SUCCESS';
export const POST_MEDIOPAGO_ERROR = 'POST_MEDIOPAGO_ERROR';

export const PUT_MEDIOPAGO = 'PUT_MEDIOPAGO';
export const PUT_MEDIOPAGO_SUCCESS = 'PUT_MEDIOPAGO_SUCCESS';
export const PUT_MEDIOPAGO_ERROR = 'PUT_MEDIOPAGO_ERROR';

export const DELETE_MEDIOPAGO = 'DELETE_MEDIOPAGO';
export const DELETE_MEDIOPAGO_SUCCESS = 'DELETE_MEDIOPAGO_SUCCESS';
export const DELETE_MEDIOPAGO_ERROR = 'DELETE_MEDIOPAGO_ERROR';


export const GET_EVERY_MEDIOSPAGO = 'GET_EVERY_MEDIOSPAGO';
export const GET_EVERY_MEDIOPAGO = 'GET_EVERY_MEDIOPAGO';
export const POST_EVERY_MEDIOPAGO = 'POST_EVERY_MEDIOPAGO';
export const PUT_EVERY_MEDIOPAGO = 'PUT_EVERY_MEDIOPAGO';
export const DELETE_EVERY_MEDIOPAGO = 'DELETE_EVERY_MEDIOPAGO';


export const INSERTA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO = 'INSERTA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO';
export const BORRA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO = 'BORRA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO';
 

export const GET_ESTATUSREGISTROSPAGOOXXO = 'GET_ESTATUSREGISTROSPAGOOXXO';
export const GET_ESTATUSREGISTROSPAGOOXXO_SUCCESS = 'GET_ESTATUSREGISTROSPAGOOXXO_SUCCESS';
export const GET_ESTATUSREGISTROSPAGOOXXO_ERROR = 'GET_ESTATUSREGISTROSPAGOOXXO_ERROR';

export const EJECUTA_EN_ESTATUSREGISTROPAGOOXXO = 'EJECUTA_EN_ESTATUSREGISTROPAGOOXXO';
export const GET_ESTATUSREGISTROPAGOOXXO = 'GET_ESTATUSREGISTROPAGOOXXO';
export const GET_ESTATUSREGISTROPAGOOXXO_SUCCESS = 'GET_ESTATUSREGISTROPAGOOXXO_SUCCESS';
export const GET_ESTATUSREGISTROPAGOOXXO_ERROR = 'GET_ESTATUSREGISTROPAGOOXXO_ERROR';

export const POST_ESTATUSREGISTROPAGOOXXO = 'POST_ESTATUSREGISTROPAGOOXXO';
export const POST_ESTATUSREGISTROPAGOOXXO_SUCCESS = 'POST_ESTATUSREGISTROPAGOOXXO_SUCCESS';
export const POST_ESTATUSREGISTROPAGOOXXO_ERROR = 'POST_ESTATUSREGISTROPAGOOXXO_ERROR';

export const PUT_ESTATUSREGISTROPAGOOXXO = 'PUT_ESTATUSREGISTROPAGOOXXO';
export const PUT_ESTATUSREGISTROPAGOOXXO_SUCCESS = 'PUT_ESTATUSREGISTROPAGOOXXO_SUCCESS';
export const PUT_ESTATUSREGISTROPAGOOXXO_ERROR = 'PUT_ESTATUSREGISTROPAGOOXXO_ERROR';

export const DELETE_ESTATUSREGISTROPAGOOXXO = 'DELETE_ESTATUSREGISTROPAGOOXXO';
export const DELETE_ESTATUSREGISTROPAGOOXXO_SUCCESS = 'DELETE_ESTATUSREGISTROPAGOOXXO_SUCCESS';
export const DELETE_ESTATUSREGISTROPAGOOXXO_ERROR = 'DELETE_ESTATUSREGISTROPAGOOXXO_ERROR';


export const GET_EVERY_ESTATUSREGISTROSPAGOOXXO = 'GET_EVERY_ESTATUSREGISTROSPAGOOXXO';
export const GET_EVERY_ESTATUSREGISTROPAGOOXXO = 'GET_EVERY_ESTATUSREGISTROPAGOOXXO';
export const POST_EVERY_ESTATUSREGISTROPAGOOXXO = 'POST_EVERY_ESTATUSREGISTROPAGOOXXO';
export const PUT_EVERY_ESTATUSREGISTROPAGOOXXO = 'PUT_EVERY_ESTATUSREGISTROPAGOOXXO';
export const DELETE_EVERY_ESTATUSREGISTROPAGOOXXO = 'DELETE_EVERY_ESTATUSREGISTROPAGOOXXO';


export const INSERTA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO = 'INSERTA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO';
export const BORRA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO = 'BORRA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO';
 

export const GET_MOTIVOSCANCELACION = 'GET_MOTIVOSCANCELACION';
export const GET_MOTIVOSCANCELACION_SUCCESS = 'GET_MOTIVOSCANCELACION_SUCCESS';
export const GET_MOTIVOSCANCELACION_ERROR = 'GET_MOTIVOSCANCELACION_ERROR';

export const EJECUTA_EN_MOTIVOCANCELACION = 'EJECUTA_EN_MOTIVOCANCELACION';
export const GET_MOTIVOCANCELACION = 'GET_MOTIVOCANCELACION';
export const GET_MOTIVOCANCELACION_SUCCESS = 'GET_MOTIVOCANCELACION_SUCCESS';
export const GET_MOTIVOCANCELACION_ERROR = 'GET_MOTIVOCANCELACION_ERROR';

export const POST_MOTIVOCANCELACION = 'POST_MOTIVOCANCELACION';
export const POST_MOTIVOCANCELACION_SUCCESS = 'POST_MOTIVOCANCELACION_SUCCESS';
export const POST_MOTIVOCANCELACION_ERROR = 'POST_MOTIVOCANCELACION_ERROR';

export const PUT_MOTIVOCANCELACION = 'PUT_MOTIVOCANCELACION';
export const PUT_MOTIVOCANCELACION_SUCCESS = 'PUT_MOTIVOCANCELACION_SUCCESS';
export const PUT_MOTIVOCANCELACION_ERROR = 'PUT_MOTIVOCANCELACION_ERROR';

export const DELETE_MOTIVOCANCELACION = 'DELETE_MOTIVOCANCELACION';
export const DELETE_MOTIVOCANCELACION_SUCCESS = 'DELETE_MOTIVOCANCELACION_SUCCESS';
export const DELETE_MOTIVOCANCELACION_ERROR = 'DELETE_MOTIVOCANCELACION_ERROR';


export const GET_EVERY_MOTIVOSCANCELACION = 'GET_EVERY_MOTIVOSCANCELACION';
export const GET_EVERY_MOTIVOCANCELACION = 'GET_EVERY_MOTIVOCANCELACION';
export const POST_EVERY_MOTIVOCANCELACION = 'POST_EVERY_MOTIVOCANCELACION';
export const PUT_EVERY_MOTIVOCANCELACION = 'PUT_EVERY_MOTIVOCANCELACION';
export const DELETE_EVERY_MOTIVOCANCELACION = 'DELETE_EVERY_MOTIVOCANCELACION';


export const INSERTA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION = 'INSERTA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION';
export const BORRA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION = 'BORRA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION';
 

export const GET_REGIMENESFISCALES = 'GET_REGIMENESFISCALES';
export const GET_REGIMENESFISCALES_SUCCESS = 'GET_REGIMENESFISCALES_SUCCESS';
export const GET_REGIMENESFISCALES_ERROR = 'GET_REGIMENESFISCALES_ERROR';

export const EJECUTA_EN_REGIMENFISCAL = 'EJECUTA_EN_REGIMENFISCAL';
export const GET_REGIMENFISCAL = 'GET_REGIMENFISCAL';
export const GET_REGIMENFISCAL_SUCCESS = 'GET_REGIMENFISCAL_SUCCESS';
export const GET_REGIMENFISCAL_ERROR = 'GET_REGIMENFISCAL_ERROR';

export const POST_REGIMENFISCAL = 'POST_REGIMENFISCAL';
export const POST_REGIMENFISCAL_SUCCESS = 'POST_REGIMENFISCAL_SUCCESS';
export const POST_REGIMENFISCAL_ERROR = 'POST_REGIMENFISCAL_ERROR';

export const PUT_REGIMENFISCAL = 'PUT_REGIMENFISCAL';
export const PUT_REGIMENFISCAL_SUCCESS = 'PUT_REGIMENFISCAL_SUCCESS';
export const PUT_REGIMENFISCAL_ERROR = 'PUT_REGIMENFISCAL_ERROR';

export const DELETE_REGIMENFISCAL = 'DELETE_REGIMENFISCAL';
export const DELETE_REGIMENFISCAL_SUCCESS = 'DELETE_REGIMENFISCAL_SUCCESS';
export const DELETE_REGIMENFISCAL_ERROR = 'DELETE_REGIMENFISCAL_ERROR';


export const GET_EVERY_REGIMENESFISCALES = 'GET_EVERY_REGIMENESFISCALES';
export const GET_EVERY_REGIMENFISCAL = 'GET_EVERY_REGIMENFISCAL';
export const POST_EVERY_REGIMENFISCAL = 'POST_EVERY_REGIMENFISCAL';
export const PUT_EVERY_REGIMENFISCAL = 'PUT_EVERY_REGIMENFISCAL';
export const DELETE_EVERY_REGIMENFISCAL = 'DELETE_EVERY_REGIMENFISCAL';


export const INSERTA_USUARIO_EN_REGIMENFISCAL = 'INSERTA_USUARIO_EN_REGIMENFISCAL';
export const BORRA_USUARIO_EN_REGIMENFISCAL = 'BORRA_USUARIO_EN_REGIMENFISCAL';
 
export const INSERTA_FACTURAAGENTE_EN_REGIMENFISCAL = 'INSERTA_FACTURAAGENTE_EN_REGIMENFISCAL';
export const BORRA_FACTURAAGENTE_EN_REGIMENFISCAL = 'BORRA_FACTURAAGENTE_EN_REGIMENFISCAL';
 
export const INSERTA_AGENTE_EN_REGIMENFISCAL = 'INSERTA_AGENTE_EN_REGIMENFISCAL';
export const BORRA_AGENTE_EN_REGIMENFISCAL = 'BORRA_AGENTE_EN_REGIMENFISCAL';
 

export const GET_USUARIOS = 'GET_USUARIOS';
export const GET_USUARIOS_SUCCESS = 'GET_USUARIOS_SUCCESS';
export const GET_USUARIOS_ERROR = 'GET_USUARIOS_ERROR';

export const EJECUTA_EN_USUARIO = 'EJECUTA_EN_USUARIO';
export const GET_USUARIO = 'GET_USUARIO';
export const GET_USUARIO_SUCCESS = 'GET_USUARIO_SUCCESS';
export const GET_USUARIO_ERROR = 'GET_USUARIO_ERROR';

export const POST_USUARIO = 'POST_USUARIO';
export const POST_USUARIO_SUCCESS = 'POST_USUARIO_SUCCESS';
export const POST_USUARIO_ERROR = 'POST_USUARIO_ERROR';

export const PUT_USUARIO = 'PUT_USUARIO';
export const PUT_USUARIO_SUCCESS = 'PUT_USUARIO_SUCCESS';
export const PUT_USUARIO_ERROR = 'PUT_USUARIO_ERROR';

export const DELETE_USUARIO = 'DELETE_USUARIO';
export const DELETE_USUARIO_SUCCESS = 'DELETE_USUARIO_SUCCESS';
export const DELETE_USUARIO_ERROR = 'DELETE_USUARIO_ERROR';


export const GET_EVERY_USUARIOS = 'GET_EVERY_USUARIOS';
export const GET_EVERY_USUARIO = 'GET_EVERY_USUARIO';
export const POST_EVERY_USUARIO = 'POST_EVERY_USUARIO';
export const PUT_EVERY_USUARIO = 'PUT_EVERY_USUARIO';
export const DELETE_EVERY_USUARIO = 'DELETE_EVERY_USUARIO';


export const INSERTA_ACTUALIZACION_EN_USUARIO = 'INSERTA_ACTUALIZACION_EN_USUARIO';
export const BORRA_ACTUALIZACION_EN_USUARIO = 'BORRA_ACTUALIZACION_EN_USUARIO';
 

export const GET_ACTUALIZACIONES = 'GET_ACTUALIZACIONES';
export const GET_ACTUALIZACIONES_SUCCESS = 'GET_ACTUALIZACIONES_SUCCESS';
export const GET_ACTUALIZACIONES_ERROR = 'GET_ACTUALIZACIONES_ERROR';

export const EJECUTA_EN_ACTUALIZACION = 'EJECUTA_EN_ACTUALIZACION';
export const GET_ACTUALIZACION = 'GET_ACTUALIZACION';
export const GET_ACTUALIZACION_SUCCESS = 'GET_ACTUALIZACION_SUCCESS';
export const GET_ACTUALIZACION_ERROR = 'GET_ACTUALIZACION_ERROR';

export const POST_ACTUALIZACION = 'POST_ACTUALIZACION';
export const POST_ACTUALIZACION_SUCCESS = 'POST_ACTUALIZACION_SUCCESS';
export const POST_ACTUALIZACION_ERROR = 'POST_ACTUALIZACION_ERROR';

export const PUT_ACTUALIZACION = 'PUT_ACTUALIZACION';
export const PUT_ACTUALIZACION_SUCCESS = 'PUT_ACTUALIZACION_SUCCESS';
export const PUT_ACTUALIZACION_ERROR = 'PUT_ACTUALIZACION_ERROR';

export const DELETE_ACTUALIZACION = 'DELETE_ACTUALIZACION';
export const DELETE_ACTUALIZACION_SUCCESS = 'DELETE_ACTUALIZACION_SUCCESS';
export const DELETE_ACTUALIZACION_ERROR = 'DELETE_ACTUALIZACION_ERROR';


export const GET_EVERY_ACTUALIZACIONES = 'GET_EVERY_ACTUALIZACIONES';
export const GET_EVERY_ACTUALIZACION = 'GET_EVERY_ACTUALIZACION';
export const POST_EVERY_ACTUALIZACION = 'POST_EVERY_ACTUALIZACION';
export const PUT_EVERY_ACTUALIZACION = 'PUT_EVERY_ACTUALIZACION';
export const DELETE_EVERY_ACTUALIZACION = 'DELETE_EVERY_ACTUALIZACION';

 

export const GET_OPERACIONES = 'GET_OPERACIONES';
export const GET_OPERACIONES_SUCCESS = 'GET_OPERACIONES_SUCCESS';
export const GET_OPERACIONES_ERROR = 'GET_OPERACIONES_ERROR';

export const EJECUTA_EN_OPERACION = 'EJECUTA_EN_OPERACION';
export const GET_OPERACION = 'GET_OPERACION';
export const GET_OPERACION_SUCCESS = 'GET_OPERACION_SUCCESS';
export const GET_OPERACION_ERROR = 'GET_OPERACION_ERROR';

export const POST_OPERACION = 'POST_OPERACION';
export const POST_OPERACION_SUCCESS = 'POST_OPERACION_SUCCESS';
export const POST_OPERACION_ERROR = 'POST_OPERACION_ERROR';

export const PUT_OPERACION = 'PUT_OPERACION';
export const PUT_OPERACION_SUCCESS = 'PUT_OPERACION_SUCCESS';
export const PUT_OPERACION_ERROR = 'PUT_OPERACION_ERROR';

export const DELETE_OPERACION = 'DELETE_OPERACION';
export const DELETE_OPERACION_SUCCESS = 'DELETE_OPERACION_SUCCESS';
export const DELETE_OPERACION_ERROR = 'DELETE_OPERACION_ERROR';


export const GET_EVERY_OPERACIONES = 'GET_EVERY_OPERACIONES';
export const GET_EVERY_OPERACION = 'GET_EVERY_OPERACION';
export const POST_EVERY_OPERACION = 'POST_EVERY_OPERACION';
export const PUT_EVERY_OPERACION = 'PUT_EVERY_OPERACION';
export const DELETE_EVERY_OPERACION = 'DELETE_EVERY_OPERACION';


export const INSERTA_ACTUALIZACION_EN_OPERACION = 'INSERTA_ACTUALIZACION_EN_OPERACION';
export const BORRA_ACTUALIZACION_EN_OPERACION = 'BORRA_ACTUALIZACION_EN_OPERACION';
 

export const GET_RUTAS = 'GET_RUTAS';
export const GET_RUTAS_SUCCESS = 'GET_RUTAS_SUCCESS';
export const GET_RUTAS_ERROR = 'GET_RUTAS_ERROR';

export const EJECUTA_EN_RUTA = 'EJECUTA_EN_RUTA';
export const GET_RUTA = 'GET_RUTA';
export const GET_RUTA_SUCCESS = 'GET_RUTA_SUCCESS';
export const GET_RUTA_ERROR = 'GET_RUTA_ERROR';

export const POST_RUTA = 'POST_RUTA';
export const POST_RUTA_SUCCESS = 'POST_RUTA_SUCCESS';
export const POST_RUTA_ERROR = 'POST_RUTA_ERROR';

export const PUT_RUTA = 'PUT_RUTA';
export const PUT_RUTA_SUCCESS = 'PUT_RUTA_SUCCESS';
export const PUT_RUTA_ERROR = 'PUT_RUTA_ERROR';

export const DELETE_RUTA = 'DELETE_RUTA';
export const DELETE_RUTA_SUCCESS = 'DELETE_RUTA_SUCCESS';
export const DELETE_RUTA_ERROR = 'DELETE_RUTA_ERROR';


export const GET_EVERY_RUTAS = 'GET_EVERY_RUTAS';
export const GET_EVERY_RUTA = 'GET_EVERY_RUTA';
export const POST_EVERY_RUTA = 'POST_EVERY_RUTA';
export const PUT_EVERY_RUTA = 'PUT_EVERY_RUTA';
export const DELETE_EVERY_RUTA = 'DELETE_EVERY_RUTA';


export const INSERTA_RUTAROL_EN_RUTA = 'INSERTA_RUTAROL_EN_RUTA';
export const BORRA_RUTAROL_EN_RUTA = 'BORRA_RUTAROL_EN_RUTA';
 

export const GET_PERFILES = 'GET_PERFILES';
export const GET_PERFILES_SUCCESS = 'GET_PERFILES_SUCCESS';
export const GET_PERFILES_ERROR = 'GET_PERFILES_ERROR';

export const EJECUTA_EN_PERFIL = 'EJECUTA_EN_PERFIL';
export const GET_PERFIL = 'GET_PERFIL';
export const GET_PERFIL_SUCCESS = 'GET_PERFIL_SUCCESS';
export const GET_PERFIL_ERROR = 'GET_PERFIL_ERROR';

export const POST_PERFIL = 'POST_PERFIL';
export const POST_PERFIL_SUCCESS = 'POST_PERFIL_SUCCESS';
export const POST_PERFIL_ERROR = 'POST_PERFIL_ERROR';

export const PUT_PERFIL = 'PUT_PERFIL';
export const PUT_PERFIL_SUCCESS = 'PUT_PERFIL_SUCCESS';
export const PUT_PERFIL_ERROR = 'PUT_PERFIL_ERROR';

export const DELETE_PERFIL = 'DELETE_PERFIL';
export const DELETE_PERFIL_SUCCESS = 'DELETE_PERFIL_SUCCESS';
export const DELETE_PERFIL_ERROR = 'DELETE_PERFIL_ERROR';


export const GET_EVERY_PERFILES = 'GET_EVERY_PERFILES';
export const GET_EVERY_PERFIL = 'GET_EVERY_PERFIL';
export const POST_EVERY_PERFIL = 'POST_EVERY_PERFIL';
export const PUT_EVERY_PERFIL = 'PUT_EVERY_PERFIL';
export const DELETE_EVERY_PERFIL = 'DELETE_EVERY_PERFIL';


export const INSERTA_USUARIO_EN_PERFIL = 'INSERTA_USUARIO_EN_PERFIL';
export const BORRA_USUARIO_EN_PERFIL = 'BORRA_USUARIO_EN_PERFIL';
 
export const INSERTA_ROLPERFIL_EN_PERFIL = 'INSERTA_ROLPERFIL_EN_PERFIL';
export const BORRA_ROLPERFIL_EN_PERFIL = 'BORRA_ROLPERFIL_EN_PERFIL';
 

export const GET_RUTASROL = 'GET_RUTASROL';
export const GET_RUTASROL_SUCCESS = 'GET_RUTASROL_SUCCESS';
export const GET_RUTASROL_ERROR = 'GET_RUTASROL_ERROR';

export const EJECUTA_EN_RUTAROL = 'EJECUTA_EN_RUTAROL';
export const GET_RUTAROL = 'GET_RUTAROL';
export const GET_RUTAROL_SUCCESS = 'GET_RUTAROL_SUCCESS';
export const GET_RUTAROL_ERROR = 'GET_RUTAROL_ERROR';

export const POST_RUTAROL = 'POST_RUTAROL';
export const POST_RUTAROL_SUCCESS = 'POST_RUTAROL_SUCCESS';
export const POST_RUTAROL_ERROR = 'POST_RUTAROL_ERROR';

export const PUT_RUTAROL = 'PUT_RUTAROL';
export const PUT_RUTAROL_SUCCESS = 'PUT_RUTAROL_SUCCESS';
export const PUT_RUTAROL_ERROR = 'PUT_RUTAROL_ERROR';

export const DELETE_RUTAROL = 'DELETE_RUTAROL';
export const DELETE_RUTAROL_SUCCESS = 'DELETE_RUTAROL_SUCCESS';
export const DELETE_RUTAROL_ERROR = 'DELETE_RUTAROL_ERROR';


export const GET_EVERY_RUTASROL = 'GET_EVERY_RUTASROL';
export const GET_EVERY_RUTAROL = 'GET_EVERY_RUTAROL';
export const POST_EVERY_RUTAROL = 'POST_EVERY_RUTAROL';
export const PUT_EVERY_RUTAROL = 'PUT_EVERY_RUTAROL';
export const DELETE_EVERY_RUTAROL = 'DELETE_EVERY_RUTAROL';

 

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const EJECUTA_EN_ROL = 'EJECUTA_EN_ROL';
export const GET_ROL = 'GET_ROL';
export const GET_ROL_SUCCESS = 'GET_ROL_SUCCESS';
export const GET_ROL_ERROR = 'GET_ROL_ERROR';

export const POST_ROL = 'POST_ROL';
export const POST_ROL_SUCCESS = 'POST_ROL_SUCCESS';
export const POST_ROL_ERROR = 'POST_ROL_ERROR';

export const PUT_ROL = 'PUT_ROL';
export const PUT_ROL_SUCCESS = 'PUT_ROL_SUCCESS';
export const PUT_ROL_ERROR = 'PUT_ROL_ERROR';

export const DELETE_ROL = 'DELETE_ROL';
export const DELETE_ROL_SUCCESS = 'DELETE_ROL_SUCCESS';
export const DELETE_ROL_ERROR = 'DELETE_ROL_ERROR';


export const GET_EVERY_ROLES = 'GET_EVERY_ROLES';
export const GET_EVERY_ROL = 'GET_EVERY_ROL';
export const POST_EVERY_ROL = 'POST_EVERY_ROL';
export const PUT_EVERY_ROL = 'PUT_EVERY_ROL';
export const DELETE_EVERY_ROL = 'DELETE_EVERY_ROL';


export const INSERTA_RUTAROL_EN_ROL = 'INSERTA_RUTAROL_EN_ROL';
export const BORRA_RUTAROL_EN_ROL = 'BORRA_RUTAROL_EN_ROL';
 
export const INSERTA_ROLPERFIL_EN_ROL = 'INSERTA_ROLPERFIL_EN_ROL';
export const BORRA_ROLPERFIL_EN_ROL = 'BORRA_ROLPERFIL_EN_ROL';
 

export const GET_ROLESPERFIL = 'GET_ROLESPERFIL';
export const GET_ROLESPERFIL_SUCCESS = 'GET_ROLESPERFIL_SUCCESS';
export const GET_ROLESPERFIL_ERROR = 'GET_ROLESPERFIL_ERROR';

export const EJECUTA_EN_ROLPERFIL = 'EJECUTA_EN_ROLPERFIL';
export const GET_ROLPERFIL = 'GET_ROLPERFIL';
export const GET_ROLPERFIL_SUCCESS = 'GET_ROLPERFIL_SUCCESS';
export const GET_ROLPERFIL_ERROR = 'GET_ROLPERFIL_ERROR';

export const POST_ROLPERFIL = 'POST_ROLPERFIL';
export const POST_ROLPERFIL_SUCCESS = 'POST_ROLPERFIL_SUCCESS';
export const POST_ROLPERFIL_ERROR = 'POST_ROLPERFIL_ERROR';

export const PUT_ROLPERFIL = 'PUT_ROLPERFIL';
export const PUT_ROLPERFIL_SUCCESS = 'PUT_ROLPERFIL_SUCCESS';
export const PUT_ROLPERFIL_ERROR = 'PUT_ROLPERFIL_ERROR';

export const DELETE_ROLPERFIL = 'DELETE_ROLPERFIL';
export const DELETE_ROLPERFIL_SUCCESS = 'DELETE_ROLPERFIL_SUCCESS';
export const DELETE_ROLPERFIL_ERROR = 'DELETE_ROLPERFIL_ERROR';


export const GET_EVERY_ROLESPERFIL = 'GET_EVERY_ROLESPERFIL';
export const GET_EVERY_ROLPERFIL = 'GET_EVERY_ROLPERFIL';
export const POST_EVERY_ROLPERFIL = 'POST_EVERY_ROLPERFIL';
export const PUT_EVERY_ROLPERFIL = 'PUT_EVERY_ROLPERFIL';
export const DELETE_EVERY_ROLPERFIL = 'DELETE_EVERY_ROLPERFIL';


