import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store =createStore(rootReducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);




  return store
  //return {
  //  ...createStore(rootReducer, applyMiddleware(sagaMiddleware)),
  //  runSaga: sagaMiddleware.run(rootSaga)
  //}
};

 
export default configureStore;