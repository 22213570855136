import React from "react";
import PropTypes from "prop-types";
import FieldHeaderText from '../fieldheadertext'
export default function Contenedor(props) {
  const { name, field, required} = props;
  
  return (
  <label className='m-0 pt-2 text-primary' htmlFor={name}><small><strong><FieldHeaderText id={field} />{(required)?' *':''}</strong></small></label>
  );
}

Contenedor.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool
};