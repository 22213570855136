import React from 'react';
import { Link } from 'react-router-dom';
import ReactToExcel from "react-html-table-to-excel";
import FieldHeader from '../../components/fieldheader'
import CurrencyFormat from 'react-currency-format'
import Mailto from 'react-protected-mailto'


const CargaPagosOxxoViewFiles = (props) => {
  if (props.list === undefined) {
    return null;
  }
  
  return (
    <div className='pl-3 pt-3'>

       
      <div>
        <div className='row py-3'>
            <div className=" col-md-2">
              <ReactToExcel 
                table="tabla-certificados" 
                filename="certificados"
                sheet='Certificados'
                buttonText="export"
                />
            </div>
        </div>
      </div>


      <div className='table-responsive'>
        <table id="tabla-certificados" className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>
              


              <th><FieldHeader field={'registrospagooxxo.codigodebarras'} /></th>
              <th><FieldHeader field={'registrospagooxxo.importe'} /></th>
              <th><FieldHeader field={'certificadospoliza.aseguradotitular'} /></th>

            </tr>
          </thead>
          <tbody>
            {props.list
              .map(li => (  
              <tr key={li.id_registropagooxxo}>
                <td className='uppercase'>
                    <p>{li.codigodebarras} </p><p>{li.poliza} / {li.certificado} / {li.secuencia}</p>
                    <p>{li.producto} / {li.formapago}</p>
                    <label className={(li.id_estatusregistropagooxxo===2)?'alert alert-success':'alert alert-danger'}>{li.estatusregistropagooxxo} </label>
                </td>
                <td>
                <CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' value={li.importe}/>
                </td>
                <td>
                    <p><Link className='name' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.aseguradotitular}</Link></p>
                    <p><Mailto email={li.t_correoelectronico1} /> / {li.t_movil} </p>
                    
                    <p>Agente : {li.agente}</p>
                    <p>{li.correoagente} / {li.telagente}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default CargaPagosOxxoViewFiles;