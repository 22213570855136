import { takeEvery, takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/conciliapagos/types';

import {
    getConciliaPagosPagadoSaga, getConciliaPagosPendienteSaga,
    postConciliaPagoSaga, deleteConciliaPagoSaga } 
    from './conciliapagossaga';

export default function* watchConciliaPagos() {

    yield takeLeading (types.GET_CONCILIAPAGOS_PENDIENTE, getConciliaPagosPendienteSaga);
    yield takeEvery (types.GET_CONCILIAPAGOS_PAGADO, getConciliaPagosPagadoSaga);
    yield takeEvery (types.POST_CONCILIAPAGO, postConciliaPagoSaga);
    yield takeEvery (types.DELETE_CONCILIAPAGO, deleteConciliaPagoSaga);

}
