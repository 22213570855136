import * as types from '../../actions/procesos/types';


const estadoinicial = {
  borrado:false,
}

export default function(state = estadoinicial, action) {

  switch(action.type) {

    case types.BORRAR_CERTIFICADOPOLIZA_SUCCESS:
          return { ...state, borrado:true };

    case types.BORRAR_CERTIFICADOPOLIZA_ERROR:
          return { ...state, borrado:false };

    case types.BORRAR_CERTIFICADOPOLIZA_RESET:
      return { ...state, borrado:false };
      
    default:
      return state;
  }
};

