import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import { getCookie } from '../../utils/cookies';

import HeaderComponent from '../../components/commons/header/headerComponent';
import LoadingComponent from '../../components/commons/loading/loadingwithheaderComponent'

import DashboardPagosView from './dashboardPagosView';


import { getDashboardPagosAction, setDashboardPagosFiltroAction, setDashboardPagosVistaAction } from '../../store/actions/dashboards/actions';


class DashboardPagosComponent extends Component {

  state = {
  }
  
  

  componentDidMount() {
    console.log('componentDidMount');
    let anio = parseInt(this.props.match.params.anio,10);
    let mes = parseInt(this.props.match.params.mes,10);
    if (isNaN(anio)) {
       mes = new Date().getMonth()+1;
       anio = new Date().getFullYear();
      }
    if (!this.props.state.cargado || this.props.state.anio !== anio || this.props.state.mes !== mes){
      this.props.dispatch(getDashboardPagosAction({ anio: anio, mes: mes }));
    }
    
  }


 padeo(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}



onCorreoAgenteClick = async (event,id_agente) => {
  console.log('onCorreoAgenteClick', id_agente);
  console.log('onCorreoAgenteClick', this.props);
  event.preventDefault();
  const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'

    axios(`${srv}/correoagente/${id_agente}/${this.props.state.anio}/${this.props.state.mes}`, {
    method: "POST",
    responseType: "blob",
    headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}

    
    //Force to receive data in a Blob Format
  })
    .then(response => {
      //Create a Blob from the PDF Stream
      console.log('Correos enviados');

    })
    .catch(error => {
      console.log(error);
    });
};

onCorreoAgentesClick = async (event) => {
  event.preventDefault();
  const srv = (window.location.hostname==="localhost") ? 'http://localhost:8080':'https://biggenzi01.appspot.com'

    axios(`${srv}/correoagentes/${this.props.state.anio}/${this.props.state.mes}`, {
    method: "POST",
    responseType: "blob",
    headers: {'Authorization': 'Bearer '+ getCookie('timeoff-token')}

    
    //Force to receive data in a Blob Format
  })
    .then(response => {
      //Create a Blob from the PDF Stream
      console.log('Correos enviados');

    })
    .catch(error => {
      console.log(error);
    });
};


onPorAgenteClick = (event) => {
  event.preventDefault(); 
  this.props.dispatch(setDashboardPagosVistaAction('PorAgente'));
}
onPorPolizaClick = (event) => {
  event.preventDefault(); 
  this.props.dispatch(setDashboardPagosVistaAction('PorPoliza'));
}

onTodoClick = (event) => {
  event.preventDefault(); 
  this.props.dispatch(setDashboardPagosFiltroAction(''));
}

onPagadoClick = (event) => {
  event.preventDefault(); 
  this.props.dispatch(setDashboardPagosFiltroAction('Pagado'));
}

onCanceladoClick = (event) => {
  event.preventDefault(); 
  this.props.dispatch(setDashboardPagosFiltroAction('Cancelado'));
}


onCXCClick = (event) => {
  event.preventDefault(); 
  this.props.dispatch(setDashboardPagosFiltroAction('CXC'));
}



    render() {
        console.log('ESTADO...', this.props.state.pagos);

        if (this.props.state.cargado === false) {
          return (
            <LoadingComponent />
            )
        }

        var lista = this.props.state.pagos;
        var obj2 = this.props.state.pagos;
        var vista = this.props.state.vista;
        var order = 'asc';
        var mapped = [];
        mapped = lista.map(function(el, i) {
          if (vista ==='' || vista==='PorPoliza')
            return { index: i, value: el.poliza.toLowerCase()+ new Array(10 - el.certificado.toString().length + 1).join('0') + el.certificado.toString() };
          else
            return { index: i, value: el.agente.toLowerCase() + el.poliza.toLowerCase()+ new Array(10 - el.certificado.toString().length + 1).join('0') + el.certificado.toString() };
          })
        mapped.sort(function(a, b) {
          if ((a.value < b.value && order === 'desc') || (a.value > b.value && order === 'asc')) { return 1; }
          if ((a.value > b.value && order === 'desc') || (a.value < b.value && order === 'asc')) { return -1; }
          return 0;
          });
        obj2 = mapped.map(function(el){
          return lista[el.index];
          });


        var polizas = obj2.reduce(function(groups, currentValue) {
          
          if (vista ==='' || vista==='PorPoliza') {
            if ( groups.indexOf(currentValue.poliza) === -1 ) {
              groups.push(currentValue.poliza);
            }
          }
          else {
            if ( groups.indexOf(currentValue.correoagente) === -1 ) {
              groups.push(currentValue.correoagente);
            }
          }
          return groups;
            }, []).map(function(group) {
              if (vista ==='' || vista==='PorPoliza')
                return {
                    grupo: group,
                    pagos: obj2.filter(function(_el) {
                      return _el.poliza === group;
                    }).map(function(_el) { return _el; })
                }
              else
                return {
                    grupo: group,
                    pagos: obj2.filter(function(_el) {
                      return _el.correoagente === group;
                    }).map(function(_el) { return _el; })
                }
          });


          var i = 0
          for (i = 0; i < polizas.length; i++) {
              var pagado = 0.00
              var cancelado = 0.00
              var cxc = 0.00
              var c_pagado = 0
              var c_cancelado = 0
              var c_cxc = 0
              var j=0
              for (j = 0; j < polizas[i].pagos.length; j++) {
                const x = polizas[i].pagos[j]
                if (x.id_estatuspago===1) {
                  cxc += x.total;
                  ++c_cxc
                }
                if (x.id_estatuspago===2) {
                  pagado += x.total;
                  ++c_pagado
                }
                if (x.id_estatuspago===3) {
                  cancelado += x.total;
                  ++c_cancelado
                }
            }
            polizas[i].pagado = pagado
            polizas[i].cancelado= cancelado
            polizas[i].cxc = cxc
            polizas[i].c_pagado = c_pagado
            polizas[i].c_cancelado = c_cancelado
            polizas[i].c_cxc = c_cxc
          }
        // console.log('resu', polizas);

        // console.log('Estatdo',this.props.state);
        return (
            <div>
              <HeaderComponent />
              <div className='row'>
                  <div className='col-12'>
                      <DashboardPagosView 
                        cargado = {this.props.state.cargado}
                        anio = {this.props.state.anio}
                        mes = {this.props.state.mes}
                        periodo = {this.props.state.periodo}
                        onTodoClick={this.onTodoClick.bind(this)} 
                        onPagadoClick={this.onPagadoClick.bind(this)} 
                        onCanceladoClick={this.onCanceladoClick.bind(this)} 
                        onCXCClick={this.onCXCClick.bind(this)} 
                        onPorPolizaClick={this.onPorPolizaClick.bind(this)} 
                        onPorAgenteClick={this.onPorAgenteClick.bind(this)} 
                        onCorreoAgentesClick={this.onCorreoAgentesClick.bind(this)} 
                        onCorreoAgenteClick={this.onCorreoAgenteClick.bind(this)}
                        filtro = {this.props.state.filtro}
                        vista = {this.props.state.vista}
                        list={polizas} />
                  </div>
              </div>
            </div>
        )
    }
}





const mapStateToProps = (state, prevProps) => {
  console.log(state.dashboardsReducer.dashpagos);
  return (
  {
  state: state.dashboardsReducer.dashpagos,
  filtro: state.dashboardsReducer.dashpagos.filtro,
  vista: state.dashboardsReducer.dashpagos.vista
});

} 


export default connect(mapStateToProps)(DashboardPagosComponent);