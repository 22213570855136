import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    aseguradora: {
 id_aseguradora : 0, aseguradora : '',
      },

      lista_poliza_borrados : [],
      lista_producto_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ASEGURADORA:
      item = state.aseguradora;
      action.fn(item);
      return { ...state,  aseguradora: item };


    case types.GET_ASEGURADORA_SUCCESS:
      respuesta= action.response;
      console.log('GET_ASEGURADORA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', aseguradora: respuesta };

    case types.GET_ASEGURADORA_ERROR:
      respuesta= action.response;
      console.log('GET_ASEGURADORA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ASEGURADORA_SUCCESS:
      respuesta= action.response;
      console.log('POST_ASEGURADORA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', aseguradora: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ASEGURADORA_ERROR:
      respuesta= action.response;
      console.log('POST_ASEGURADORA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ASEGURADORA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ASEGURADORA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', aseguradora: respuesta };

    case types.PUT_ASEGURADORA_ERROR:
      respuesta= action.response;
      console.log('PUT_ASEGURADORA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ASEGURADORA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ASEGURADORA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ASEGURADORA_ERROR:
      respuesta= action.response;
      console.log('DELETE_ASEGURADORA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_POLIZA_EN_ASEGURADORA:
      item = state.aseguradora;
      item.polizas= [...item.polizas, action.payload]
      return { ...state, poliza:item};


    case types.BORRA_POLIZA_EN_ASEGURADORA:
      item = state.aseguradora;
      let lista_poliza_borrados= state.lista_poliza_borrados
      if (item.polizas[action.payload].id_poliza > 0){
            const elementopolizaborrar = { id_poliza : item.polizas[action.payload].id_poliza }
            lista_poliza_borrados= [...lista_poliza_borrados,  elementopolizaborrar]
      }
      item.polizas.splice(action.payload,1)
      return { ...state, poliza:item, lista_poliza_borrados : lista_poliza_borrados};

 
    case types.INSERTA_PRODUCTO_EN_ASEGURADORA:
      item = state.aseguradora;
      item.productos= [...item.productos, action.payload]
      return { ...state, producto:item};


    case types.BORRA_PRODUCTO_EN_ASEGURADORA:
      item = state.aseguradora;
      let lista_producto_borrados= state.lista_producto_borrados
      if (item.productos[action.payload].id_producto > 0){
            const elementoproductoborrar = { id_producto : item.productos[action.payload].id_producto }
            lista_producto_borrados= [...lista_producto_borrados,  elementoproductoborrar]
      }
      item.productos.splice(action.payload,1)
      return { ...state, producto:item, lista_producto_borrados : lista_producto_borrados};


    default:
      return state;
  }
};

