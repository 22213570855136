import { takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/auth/types';

import {
     loginSaga, logoutSaga,registerSaga, forgotPasswordSaga,   } from './authenticationSaga';

export default function* watchUserAuthentication() {

  yield takeLeading (types.LOGIN_USER, loginSaga);
  yield takeLeading (types.LOGOUT_USER, logoutSaga);
  yield takeLeading (types.REGISTER_USER, registerSaga);
  yield takeLeading (types.FORGOT_PASSWORD, forgotPasswordSaga);

}

