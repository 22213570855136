import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    endosocertificadopoliza: {
 id_endosocertificadopoliza : 0, id_certificadopoliza : 0, endoso : '', fechainicio : '',
      },

      lista_dpcertificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ENDOSOCERTIFICADOPOLIZA:
      item = state.endosocertificadopoliza;
      action.fn(item);
      return { ...state,  endosocertificadopoliza: item };


    case types.GET_ENDOSOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_ENDOSOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', endosocertificadopoliza: respuesta };

    case types.GET_ENDOSOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_ENDOSOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ENDOSOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_ENDOSOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', endosocertificadopoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ENDOSOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_ENDOSOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ENDOSOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ENDOSOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', endosocertificadopoliza: respuesta };

    case types.PUT_ENDOSOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_ENDOSOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ENDOSOCERTIFICADOPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ENDOSOCERTIFICADOPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ENDOSOCERTIFICADOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_ENDOSOCERTIFICADOPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA:
      item = state.endosocertificadopoliza;
      item.dpscertificadopoliza= [...item.dpscertificadopoliza, action.payload]
      return { ...state, dpcertificadopoliza:item};


    case types.BORRA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA:
      item = state.endosocertificadopoliza;
      let lista_dpcertificadopoliza_borrados= state.lista_dpcertificadopoliza_borrados
      if (item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza > 0){
            const elementodpcertificadopolizaborrar = { id_dpcertificadopoliza : item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza }
            lista_dpcertificadopoliza_borrados= [...lista_dpcertificadopoliza_borrados,  elementodpcertificadopolizaborrar]
      }
      item.dpscertificadopoliza.splice(action.payload,1)
      return { ...state, dpcertificadopoliza:item, lista_dpcertificadopoliza_borrados : lista_dpcertificadopoliza_borrados};


    default:
      return state;
  }
};

