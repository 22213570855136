import React from 'react';
import InputField from '../../components/inputfield'
import Boton from '../../components/buttons/boton'
// import ActualizarCertificadoViewAsegurados from './actualizarCertificadoViewAsegurados'
import ActualizarCertificadoViewAsegurados from './actualizarCertificadoViewAsegurados';
import ActualizarCertificadoViewBenefs from './actualizarCertificadoViewBenefs';
import ActualizarCertificadoViewEndosos from './actualizarCertificadoViewEndosos';
import ActualizaPolizaViewDPs from './actualizarCertificadoViewDPs'
import SelectorContratante from './selectorContratante'

const ActualizaCertificadoView = (props) => {
  
  let pagados = [];
  
  if (props.certificadopoliza.dpscertificadopoliza) pagados = props.certificadopoliza.dpscertificadopoliza.filter(item => (item.id_estatuspago===2) );
  

  return (
  
      <div className='container' >
        
    <form id='cform' onSubmit={props.onGrabarClick}>
      </form>


    <div className='container menu'>
    {(props.certificadopoliza.id_estatuscertificado===1) ? (
      <div className="row">

        <Boton className='col-1' id='grabar' form='cform'  />
        <div className=" col-8 "/>

          {(props.certificadopoliza.aseguradoscertificadopoliza) 
              ? (props.certificadopoliza.aseguradoscertificadopoliza.length===0) 
                ? (props.certificadopoliza.benefscertificadopoliza) 
                  ? (props.certificadopoliza.benefscertificadopoliza.length===0) 
                    ? <Boton className="col-1 pl-1 " warning small id='borrar' onClick={props.onBorrarCertificadoPolizaClick}/>
                  :<div className=" col-1 "/>
                :<div className=" col-1 "/>
              :<div className=" col-1 "/>
            :<div className=" col-1 "/>}
        
        {(props.certificadopoliza.id_certificadopoliza!==0)?<Boton className="col-1 pl-1 " warning small id='cancelar' onClick={props.onCancelarCertificadoPolizaClick}/>:''}
        {(props.certificadopoliza.id_certificadopoliza!==0)?<Boton className="col-1 pl-1 " warning small id='cerrar' onClick={props.onCerrarCertificadoPolizaClick}/>:''}
      
      </div>

    ):(props.certificadopoliza.id_estatuscertificado===2) ? (
      
      <div className="row">
        <div className='col-md-6'/>

        <Boton className="col-md-1"  id='pdfcert'           onClick={props.HandlerPDF_Certificado}/>
        <Boton className="col-md-2"  id='pdfrecibo'           onClick={props.HandlerPDF_Recibo}/>
        <Boton className="col-md-2"  id='credenciales'  onClick={props.HandlerImprimirCredencial}/>
        <Boton className="col-md-1"  id='abrir'         onClick={props.onAbrirCertificadoPolizaClick} warning/>

      </div>

    ):(
      <div className="row">
        <div className='col-md-11'/>
        <Boton className="col-1 "  id='activar' warning onClick={props.onActivarCertificadoPolizaClick}/>

      </div>

    )}
  </div>
      <div className="row">
        <div className='col-md-12'>
        < div className={(props.success) ? 'alert alert-success' : (props.message.length>0) ? 'alert alert-danger' : '' } role='alert'>{props.message}</div>
        </div>
      </div>




      <div className='row'>
        <InputField className='col-md-3' type='lookup' form='cform' field='certificadospoliza.id_poliza' value={props.certificadopoliza.id_poliza} />

        <InputField className='col-md-3' type='number' form='cform' field='certificadospoliza.certificado' value={props.certificadopoliza.certificado} onChange={props.handleChange} />
      
        <InputField className='col-md-3' type='lookup' form='cform' field='certificadospoliza.id_origen' value={props.certificadopoliza.id_origen} />
      
        <InputField className='col-md-3' type='date' form='cform' disabled={(props.certificadopoliza.id_origen!==2) || (props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.antiguedad' value={props.certificadopoliza.antiguedad} onChange={props.handleChange} />
          

      </div>
      
      <div className='row'>
        <InputField type='combobox' className='col-md-3' form='cform' 
                  field='certificadospoliza.id_producto' 
                  value={props.certificadopoliza.id_producto || ''}
                  onChange={props.handleChange}/>

        <InputField type='combobox' className='col-md-3' form='cform' 
                  field='certificadospoliza.id_formapago' 
                  value={props.certificadopoliza.id_formapago || ''}
                  onChange={props.handleChange}/>
                  
        <div className='col-md-3'>
        </div>

        <InputField className='col-md-3' form='cform' type='lookup' field='certificadospoliza.id_estatuscertificado' value={props.certificadopoliza.id_estatuscertificado} />
        


      <InputField className='col-md-6' disabled type='text' field='certificadospoliza.contratante' value={props.certificadopoliza.contratante} onChange={props.handleChange} />
      <InputField className='col-md-6' disabled type='text' field='certificadospoliza.aseguradotitular' value={props.certificadopoliza.aseguradotitular} onChange={props.handleChange} />
        
      </div>


      <nav>
      <div className="nav nav-pills nav-fill  mt-4 pt-2 border-top border-primary border-bottom " id="nav-tab" role="tablist">
        <a className="nav-item nav-link active h5" id="nav-Informacion-tab" data-toggle="tab" href="#nav-Informacion" role="tab" aria-controls="nav-Informacion" aria-selected="true">Información</a>
        {(props.certificadopoliza.id_certificadopoliza!==0)?<a className="nav-item nav-link h5" id="nav-asegurados-tab" data-toggle="tab" href="#nav-asegurados" role="tab" aria-controls="nav-asegurados" aria-selected="false">Asegurados {(props.certificadopoliza.aseguradoscertificadopoliza.length>0)?<span className="badge badge-pill badge-secondary">{props.certificadopoliza.aseguradoscertificadopoliza.length}</span>:''}</a>:''}
        {(props.certificadopoliza.id_certificadopoliza!==0)?<a className="nav-item nav-link h5" id="nav-benefs-tab" data-toggle="tab" href="#nav-benefs" role="tab" aria-controls="nav-benefs" aria-selected="false">Beneficiarios {(props.certificadopoliza.benefscertificadopoliza.length>0)?<span className="badge badge-pill badge-secondary">{props.certificadopoliza.benefscertificadopoliza.length}</span>:''}</a>:''}
        {(props.certificadopoliza.id_certificadopoliza!==0)?<a className="nav-item nav-link h5" id="nav-endosos-tab" data-toggle="tab" href="#nav-endosos" role="tab" aria-controls="nav-endosos" aria-selected="false">Endosos {(props.certificadopoliza.endososcertificadopoliza.length>0)?<span className="badge badge-pill badge-secondary">{props.certificadopoliza.endososcertificadopoliza.length}</span>:''}</a>:''}
        {(props.certificadopoliza.id_certificadopoliza!==0)?<a className="nav-item nav-link h5" id="nav-pagos-tab" data-toggle="tab" href="#nav-pagos" role="tab" aria-controls="nav-pagos" aria-selected="false">Pagos {(props.certificadopoliza.dpscertificadopoliza.length>0)?<span className="badge badge-pill badge-secondary">{pagados.length} /{props.certificadopoliza.dpscertificadopoliza.length}</span>:''}</a>:''}
      </div>
    </nav>
    <div className="tab-content" id="nav-tabContent">
      <div className="tab-pane fade show active" id="nav-Informacion" role="tabpanel" aria-labelledby="nav-Informacion-tab">
        <div className="row mt-4">
          <div className=" col-md-9 ">
            <h3>Contratante</h3>
          </div>
          <div className=" col-md-3 ">
            {(props.certificadopoliza.id_estatuscertificado===1) 
              ?<SelectorContratante value={props.certificadopoliza.c_nombre} handleSelect={props.handleSelect}/>
              :''}
          </div>

        </div>
        <div className='row'>
          <InputField className='col-md-4' type='text' form='cform' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_nombre' value={props.certificadopoliza.c_nombre} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_appaterno' value={props.certificadopoliza.c_appaterno} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_apmaterno' value={props.certificadopoliza.c_apmaterno} onChange={props.handleChange} />
        
          <InputField className='col-md-4' type='date' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_fnac' value={props.certificadopoliza.c_fnac} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_ocupacion' value={props.certificadopoliza.c_ocupacion} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_estadocivil' value={props.certificadopoliza.c_estadocivil} onChange={props.handleChange} />
        
          <InputField className='col-md-8' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_domicilio' value={props.certificadopoliza.c_domicilio} onChange={props.handleChange} />
          <InputField className='col-md-2' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_domicilionumero' value={props.certificadopoliza.c_domicilionumero} onChange={props.handleChange} />
          <InputField className='col-md-2' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_cp' value={props.certificadopoliza.c_cp} onChange={props.handleChange} />
        
          <InputField className='col-md-6' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_colonia' value={props.certificadopoliza.c_colonia} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_ciudad' value={props.certificadopoliza.c_ciudad} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_estado' value={props.certificadopoliza.c_estado} onChange={props.handleChange} />
        
          <InputField className='col-md-6' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_correoelectronico1' value={props.certificadopoliza.c_correoelectronico1} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_telefono' value={props.certificadopoliza.c_telefono} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_movil' value={props.certificadopoliza.c_movil} onChange={props.handleChange} />
        
          <InputField className='col-md-6' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_correoelectronico2' value={props.certificadopoliza.c_correoelectronico2} onChange={props.handleChange} />
          <div className='col-md-6' />

          <InputField className='col-md-6' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_giro' value={props.certificadopoliza.c_giro} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_rfc' value={props.certificadopoliza.c_rfc} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' disabled={(props.certificadopoliza.id_estatuscertificado!==1)} field='certificadospoliza.c_curp' value={props.certificadopoliza.c_curp} onChange={props.handleChange} />
        
          
        </div>

        
        <div className="row mt-4">
          <div className=" col-md-9 ">
            <h3>Titular</h3>
          </div>
          <Boton className='col-md-3' id='copiarcontratante' mostrar={(props.certificadopoliza.id_estatuscertificado===1)} onClick={props.onCopiarContatanteClick}/>
        </div>

        <div className='row'>
          <InputField className='col-md-4' field='certificadospoliza.t_nombre' value={props.certificadopoliza.t_nombre} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' field='certificadospoliza.t_appaterno' value={props.certificadopoliza.t_appaterno} onChange={props.handleChange} required />
          <InputField className='col-md-4' type='text' field='certificadospoliza.t_apmaterno' value={props.certificadopoliza.t_apmaterno} onChange={props.handleChange} />
        
          <InputField className='col-md-4' type='date' field='certificadospoliza.t_fnac' value={props.certificadopoliza.t_fnac} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' field='certificadospoliza.t_ocupacion' value={props.certificadopoliza.t_ocupacion} onChange={props.handleChange} />
          <InputField className='col-md-4' type='text' field='certificadospoliza.t_estadocivil' value={props.certificadopoliza.t_estadocivil} onChange={props.handleChange} />
        
          <InputField className='col-md-8' type='text' field='certificadospoliza.t_domicilio' value={props.certificadopoliza.t_domicilio} onChange={props.handleChange} />
          <InputField className='col-md-2' type='text' field='certificadospoliza.t_domicilionumero' value={props.certificadopoliza.t_domicilionumero} onChange={props.handleChange} />
          <InputField className='col-md-2' type='text' field='certificadospoliza.t_cp' value={props.certificadopoliza.t_cp} onChange={props.handleChange} />
        
          <InputField className='col-md-6' type='text' field='certificadospoliza.t_colonia' value={props.certificadopoliza.t_colonia} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' field='certificadospoliza.t_ciudad' value={props.certificadopoliza.t_ciudad} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' field='certificadospoliza.t_estado' value={props.certificadopoliza.t_estado} onChange={props.handleChange} />
        
          <InputField className='col-md-6' type='text' field='certificadospoliza.t_correoelectronico1' value={props.certificadopoliza.t_correoelectronico1} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' field='certificadospoliza.t_telefono' value={props.certificadopoliza.t_telefono} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' field='certificadospoliza.t_movil' value={props.certificadopoliza.t_movil} onChange={props.handleChange} />
        
          <InputField className='col-md-6' type='text' field='certificadospoliza.t_correoelectronico2' value={props.certificadopoliza.t_correoelectronico2} onChange={props.handleChange} />
          <div className='col-md-6' />
          
          <InputField className='col-md-6' type='text' field='certificadospoliza.t_giro' value={props.certificadopoliza.t_giro} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' field='certificadospoliza.t_rfc' value={props.certificadopoliza.t_rfc} onChange={props.handleChange} />
          <InputField className='col-md-3' type='text' field='certificadospoliza.t_curp' value={props.certificadopoliza.t_curp} onChange={props.handleChange} />
        
        <InputField type='combobox' className='col-md-3' 
                    field='certificadospoliza.id_agente' 
                    value={props.certificadopoliza.id_agente || ''}
                    onChange={props.handleChange}/>

          <InputField className='col-md-12' type='text' field='certificadospoliza.observaciones' value={props.certificadopoliza.observaciones} onChange={props.handleChange} />
        
          <InputField type='combobox' className='col-md-3' 
                    field='certificadospoliza.id_motivocancelacion' 
                    value={props.certificadopoliza.id_motivocancelacion || ''}
                    onChange={props.handleChange}/>

        </div>
        
      </div>
      <div className="tab-pane fade" id="nav-endosos" role="tabpanel" aria-labelledby="nav-endosos-tab">
        <ActualizarCertificadoViewEndosos
              id_certificadopoliza={props.certificadopoliza.id_certificadopoliza}
              certificadopoliza={props.certificadopoliza}
              list={props.certificadopoliza.endososcertificadopoliza}
              handleChange={props.handleEndosoChange} 
              onAgregaEndosoHandler = {props.onAgregaEndosoHandler}
              onBorrarEndosoHandler={props.onBorrarEndosoHandler}/>
      </div>

      <div className="tab-pane fade" id="nav-asegurados" role="tabpanel" aria-labelledby="nav-asegurados-tab">
        <ActualizarCertificadoViewAsegurados
              id_certificadopoliza={props.certificadopoliza.id_certificadopoliza}
              certificadopoliza={props.certificadopoliza}
              list={props.certificadopoliza.aseguradoscertificadopoliza}
              handleChange={props.handleAseguradoChange} 
              onSepararAseguradoClick ={props.onSepararAseguradoClick} 
              onEnviarACertificadoClick ={props.onEnviarACertificadoClick} 
              onAgregaAseguradoHandler = {props.onAgregaAseguradoHandler}
              onBorrarAseguradoHandler={props.onBorrarAseguradoHandler}/>
      </div>

      <div className="tab-pane fade" id="nav-benefs" role="tabpanel" aria-labelledby="nav-benefs-tab">
        <ActualizarCertificadoViewBenefs
              id_certificadopoliza={props.certificadopoliza.id_certificadopoliza}
              certificadopoliza={props.certificadopoliza}
              list={props.certificadopoliza.benefscertificadopoliza}
              handleChange={props.handleBenefChange} 
              onAgregaBenefHandler = {props.onAgregaBenefHandler}
              onBorrarBenefHandler={props.onBorrarBenefHandler}/>
      </div>

      <div className="tab-pane fade" id="nav-pagos" role="tabpanel" aria-labelledby="nav-pagos-tab">
        
      <ActualizaPolizaViewDPs
            certificadopoliza={props.certificadopoliza}
            handleChange={props.handlePagosChange} 
            list={props.certificadopoliza.dpscertificadopoliza} />
      </div>
    </div>



    {/* </form> */}
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </div>
)
    };

export default ActualizaCertificadoView;