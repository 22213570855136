import React from 'react';
import LoadingComponent from '../../components/commons/loading/loadingComponent'
import CurrencyFormat from 'react-currency-format'
import SiguienteAnterior from '../../components/commons/siguienteanterior/siguienteanteriorComponent'
//import './seguros.css';

const DashboardVentasView = (props) => {
    console.log('algo', props.list);
    if (props.list === undefined) {
      return <LoadingComponent />;
    }

  if (props.list.length === 0) {
    return  <div>
                <SiguienteAnterior anio={props.anio} mes={props.mes} liga='/dashboard/ventas/' />
                <div className='seguros-none'>No se encontraron ventas.</div>
            </div>
  }
  return (
    <div >
        {(props.dashboard===undefined || props.dashboard !=='Agente')?<SiguienteAnterior anio={props.anio} mes={props.mes} liga='/dashboard/ventas/' />:''}
          {props.list.map(li => (  
            <div key={li.id_agente} className='p-3'>
                  <div className='row pt-3  bg-light'>
                    <div className='col-12 h3'>
                        {li.agente}
                    </div>
                    </div>
                  <div className='row pb-5 bg-light'>
                    <div className='col-12'>
                      <div className='row bg-primary'>
                          <div className='col-1'>
                              
                          </div>
                          <div className='col-11'>
                              <div className='row border-bottom border-primary'>
                                    {props.meses.map( (messtr,index) => ( 
                                        <div key={index} className='col-1 text-right text-white'>
                                                {messtr}
                                        </div> 
                                    ))}
                                  
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              Renovaciones
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_renovacion}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_renovacion}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              Ventas
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_venta}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_venta}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              Certificados
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_certificados}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_certificados}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              Primas
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_primas}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_primas}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row border-top border-primary'>
                          <div className='col-1 bg-primary text-white'>
                              Pagos
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_pagos}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_pagos}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              Pagos (OP)
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_pagos_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_pagos_op}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row border-top border-primary'>
                          <div className='col-1 bg-primary text-white'>
                              CXC
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_cxc}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_cxc}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              CXC(OP)
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_cxc_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_cxc_op}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row border-top border-primary'>
                          <div className='col-1 bg-primary text-white'>
                              Cancelado
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_cancelado}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_cancelado}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row'>
                          <div className='col-1 bg-primary text-white'>
                              Canc(OP)
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_cancelado_op}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_cancelado_op}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row border-top border-primary'>
                          <div className='col-1 bg-primary text-white'>
                              Comision
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_comision}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_comision}/></div>
                              </div>
                          </div>
                      </div>
                      <div className='row border-bottom border-primary'>
                          <div className='col-1 bg-primary text-white'>
                              Cargos
                          </div>
                          <div className='col-11'>
                              <div className='row'>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p1_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p2_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p3_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p4_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p5_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p6_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p7_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p8_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p9_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p10_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p11_cargosdp}/></div>
                                  <div className='col-1 text-right'><CurrencyFormat thousandSeparator=',' decimalSeparator='' decimalScale={0} fixedDecimalScale={true} displayType='text' value={li.p12_cargosdp}/></div>
                              </div>
                          </div>
                      </div>


                    </div>
                  </div>
              </div>
          ))}
    </div>
  );
};

export default DashboardVentasView;