import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux';
import { uploadRequest, uploadReset } from '../../store/actions/uploadfile/actions';
import Boton from '../buttons/boton'
//import { getUploadProgress } from './selectors'; // not shown here


export class UploadFileComponent extends Component  {
    state = {
        myfile:''
    }

    onChange = e => {
        const [ file ] = e.target.files || e.dataTransfer.files;
        console.log('OnChange', file);
        const path = this.props.path;
        this.props.dispatch( uploadReset(file,path));
        this.setState ( {
            myfile: file
        })
    }

    onUploadClick = () =>{
        console.log('Holar', this.state);
        if (this.state.myfile){
            console.log('OnChange', this.state.myfile);
            const path = this.props.path;
            this.props.dispatch( uploadRequest(this.state.myfile, path));
        }

    }

    render() {
        console.log('render', this.props.state);
        // const {} = this.props;
        return (
            <Fragment >
                <input
                    type="file"
                    className="form-control rounded-large w-75"
                    onChange={ this.onChange }
                />
                <Boton onClick={this.onUploadClick.bind(this)} id='cargar' className='w-25 pl-2' />
                {(this.props.state.cargando)
                    ?<div className='alert alert-warning'> 
                        <strong >Cargando...</strong>
                    </div>
                    :''}
                {(this.props.state.success)
                    ?<div className='alert alert-success'> 
                        <strong >Cargado!</strong>
                    </div>
                    :''}
                {(this.props.state.error)
                    ?<div className='alert alert-danger'> 
                        Error: <strong >{this.props.state.error.message}</strong>
                    </div>
                    :''}
            </Fragment>
        );
    }
};


const mapStateToProps = (state, props) => {
    console.log('mapStateUploadFile', state);
    var estado = state.uploadfileReducer.filter(e=>{return e.path === props.path});
    var newestado = {}
    console.log('mapStateUploadFile estado', estado);
    if (estado.length === 0) {
        console.log('mapStateUploadFile1', estado);
        newestado = { cargando:false, success:false, progress:0, error:'', }
    }else{

        console.log('mapStateUploadFile2', estado);
        newestado =   estado[0]
    }
    console.log('newestado', newestado);
    return {state: newestado}
}
    
;


export default connect(mapStateToProps)(UploadFileComponent);