import React from 'react';
import Lookup from '../../components/lookups'
import ReactToExcel from "react-html-table-to-excel";
import CurrencyFormat from 'react-currency-format'

const ArchivoPolizaViewDetalle = (props) => {
  console.log('VIEW', props);
  if (props.list === undefined) {
    return null;
  }

  return (
    <div className='pl-3 pt-3'>
    <div>
      <div className='row py-3'>
          <div className=" col-md-6 ">

            <ReactToExcel 
                table="tabla-certificados" 
                filename={props.poliza.poliza}
                sheet={props.poliza.descripcion}
                buttonText="Exportar"
                />
          </div>
      </div>
    </div>
      <div className='table-responsive'>
        <table id="tabla-certificados"  className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>
            <td>CERTIFICADO</td>
            <td>FAMILIA</td>
            <td>NOMBRES</td>
            <td>AP_PAT</td>
            <td>AP_MAT</td>
            <td>SEXO</td>
            <td>FEC_NAC</td>
            <td>PARENTESCO</td>
            <td>ANTIGUEDAD</td>
            <td>SUB_GRUPO</td>
            <td>EDAD</td>
            <td>CONTRATANTE</td>
            <td>CALLE</td>
            <td>NUMERO</td>
            <td>COLONIA</td>
            <td>MUNICIPIO</td>
            <td>ESTADO</td>
            <td>C.P.</td>
            <td>FECHA DE NACIMIENTO</td>
            <td>RFC</td>
            <td>CURP</td>
            <td>CORREO ELECTRONICO </td>
            <td>TELEFONO</td>
            <td>SUMA ASEGURADA</td>
            <td>FORMA DE PAGO</td>
            <td>ORIGEN</td>
            <td>PRIMA POR EDAD</td>
            <td>PRIMA NETA ANUAL</td>
            <td>DER POL</td>
            <td>CARGO PAGO FRAC</td>
            <td>IVA</td>
            <td>PRIMA TOTAL ANUAL</td>
            <td>OBSERVACIONES</td>
            <td>AGENTE</td>

            </tr>



            
          </thead>
          <tbody>
            {props.list
              .sort((a, b) => a.certificado - b.certificado)
              .map(li => (li.aseguradoscertificadopoliza
              .sort((a, b) => new Date(a.fechanacimiento).getTime() - new Date(b.fechanacimiento).getTime())
              .sort((a, b) => a.id_parentezco - b.id_parentezco)
              .map(li2 => (  
                <tr key={li2.id_aseguradocertificadopoliza}>
                <td>
                  {li.certificado}
                </td>
                  <td>
                    {li.certificado}
                  </td>
                  <td>
                    {li2.nombre}
                  </td>
                  <td>
                    {li2.appaterno}
                  </td>
                  <td>
                    {li2.apmaterno}
                  </td>
                  <td>
                    {(props.poliza.id_aseguradora===1)?(li2.id_genero===1)?1:0:(li2.id_genero===1)?'M':'F'}
                  </td>
                  <td>
                    {li2.fechanacimiento.substring(0,10)}
                  </td>
                  <td>
                    {li2.id_parentezco}
                  </td>
                  <td>
                    {li2.antiguedad.substring(0,10)}
                  </td>
                  <td>
                    {li2.subgrupo}
                  </td>
                  <td>
                    {li2.edad}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.contratante:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_domicilio:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_domicilionumero:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_colonia:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_ciudad:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_estado:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_cp:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_fnac.substring(0,10):''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_rfc:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_curp:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_correoelectronico1:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?li.c_telefono:''}
                  </td>
                  <td className='uppercase'>
                    {(li2.id_parentezco===1)?<Lookup field='certificadospoliza.id_producto' value = {li.id_producto} />:''}
                  </td>
                  <td className='uppercase'>
                  {(li2.id_parentezco===1)?<Lookup field='certificadospoliza.id_formapago' value = {li.id_formapago} />:''}
                  </td>
                  <td className='uppercase'>
                  {(li2.id_parentezco===1)?<Lookup field='certificadospoliza.id_origen' value = {li.id_origen} />:''}
                  </td>
                  <td>
                    {(li2.primaporedad >= 0 && li2.factorprimaporedad=== 1)?<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                       value={li2.primaporedad}/>:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                       value={li.primanetatotalanual}/>:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                       value={li.derpoltotalanual}/>:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                       value={li.cargototalanual}/>:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                       value={li.ivatotalanual}/>:''}
                  </td>
                  <td>
                    {(li2.id_parentezco===1)?<CurrencyFormat thousandSeparator=',' decimalSeparator='.' decimalScale={2} fixedDecimalScale={true} displayType='text' 
                       value={li.primatotalanual}/>:''}
                  </td>
                  <td>
                    {
                      (li2.id_parentezco===1)
                      ?(li.id_estatuscertificado===3)
                          ?'Cancelado ' + li.observaciones
                          :li.observaciones
                      :(li.id_estatuscertificado===3)
                          ?'Cancelado '
                          :''
                      }
                  </td>
                  <td className='uppercase'>
                  {(li2.id_parentezco===1)?<Lookup field='certificadospoliza.id_agente' value = {li.id_agente} />:''}
                  </td>


                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>




    </div>
  );
};

export default ArchivoPolizaViewDetalle;