import * as types from './types';



export const getArchivoPolizaAction = (id) => {
  return {
    type: types.GET_ARCHIVPOLIZA,
    id
  }
};

export const resetArchivoPolizaAction = () => {
    return {
      type: types.GET_ARCHIVPOLIZA_RESET,
      data:{}
    }
  };