import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    facturaagente: {
 id_facturaagente : 0, facturaagente : '', id_agente : 0, fechapago : '', importedps : 0, subtotal : 0, iva : 0, retiva : 0, retisr : 0, total : 0, ajuste : 0, diferencia : 0, id_regimenfiscal : 0,
      },

      lista_dpcertificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_FACTURAAGENTE:
      item = state.facturaagente;
      action.fn(item);
      return { ...state,  facturaagente: item };


    case types.GET_FACTURAAGENTE_SUCCESS:
      respuesta= action.response;
      console.log('GET_FACTURAAGENTE_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', facturaagente: respuesta };

    case types.GET_FACTURAAGENTE_ERROR:
      respuesta= action.response;
      console.log('GET_FACTURAAGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_FACTURAAGENTE_SUCCESS:
      respuesta= action.response;
      console.log('POST_FACTURAAGENTE_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', facturaagente: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_FACTURAAGENTE_ERROR:
      respuesta= action.response;
      console.log('POST_FACTURAAGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_FACTURAAGENTE_SUCCESS:
      respuesta= action.response;
      console.log('PUT_FACTURAAGENTE_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', facturaagente: respuesta };

    case types.PUT_FACTURAAGENTE_ERROR:
      respuesta= action.response;
      console.log('PUT_FACTURAAGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_FACTURAAGENTE_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_FACTURAAGENTE_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_FACTURAAGENTE_ERROR:
      respuesta= action.response;
      console.log('DELETE_FACTURAAGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE:
      item = state.facturaagente;
      item.dpscertificadopoliza= [...item.dpscertificadopoliza, action.payload]
      return { ...state, dpcertificadopoliza:item};


    case types.BORRA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE:
      item = state.facturaagente;
      let lista_dpcertificadopoliza_borrados= state.lista_dpcertificadopoliza_borrados
      if (item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza > 0){
            const elementodpcertificadopolizaborrar = { id_dpcertificadopoliza : item.dpscertificadopoliza[action.payload].id_dpcertificadopoliza }
            lista_dpcertificadopoliza_borrados= [...lista_dpcertificadopoliza_borrados,  elementodpcertificadopolizaborrar]
      }
      item.dpscertificadopoliza.splice(action.payload,1)
      return { ...state, dpcertificadopoliza:item, lista_dpcertificadopoliza_borrados : lista_dpcertificadopoliza_borrados};


    default:
      return state;
  }
};

