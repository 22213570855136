import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    estatuspoliza: {
 id_estatuspoliza : 0, estatuspoliza : '',
      },

      lista_poliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ESTATUSPOLIZA:
      item = state.estatuspoliza;
      action.fn(item);
      return { ...state,  estatuspoliza: item };


    case types.GET_ESTATUSPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('GET_ESTATUSPOLIZA_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', estatuspoliza: respuesta };

    case types.GET_ESTATUSPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_ESTATUSPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ESTATUSPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('POST_ESTATUSPOLIZA_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', estatuspoliza: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ESTATUSPOLIZA_ERROR:
      respuesta= action.response;
      console.log('POST_ESTATUSPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ESTATUSPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ESTATUSPOLIZA_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', estatuspoliza: respuesta };

    case types.PUT_ESTATUSPOLIZA_ERROR:
      respuesta= action.response;
      console.log('PUT_ESTATUSPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ESTATUSPOLIZA_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ESTATUSPOLIZA_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ESTATUSPOLIZA_ERROR:
      respuesta= action.response;
      console.log('DELETE_ESTATUSPOLIZA_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_POLIZA_EN_ESTATUSPOLIZA:
      item = state.estatuspoliza;
      item.polizas= [...item.polizas, action.payload]
      return { ...state, poliza:item};


    case types.BORRA_POLIZA_EN_ESTATUSPOLIZA:
      item = state.estatuspoliza;
      let lista_poliza_borrados= state.lista_poliza_borrados
      if (item.polizas[action.payload].id_poliza > 0){
            const elementopolizaborrar = { id_poliza : item.polizas[action.payload].id_poliza }
            lista_poliza_borrados= [...lista_poliza_borrados,  elementopolizaborrar]
      }
      item.polizas.splice(action.payload,1)
      return { ...state, poliza:item, lista_poliza_borrados : lista_poliza_borrados};


    default:
      return state;
  }
};

