import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



export const postSepararAseguradoCertificadoPolizaService = (id) => {
  const apiURL= baseurl(`proceso/separaraseguradocertificadopoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const postEnviarAseguradoCertificadoPolizaService = (payload) => {
  const apiURL= baseurl(`proceso/enviaraseguradocertificadopoliza/${payload.id_aseguradocertificadopoliza}`);

  const params = {certificado:payload.certificado}

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(params),
  };

  console.log('post ---> ', parameters);
  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};