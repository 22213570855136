import { put, call } from 'redux-saga/effects';
import {
    postAsignaDPsPolizaService,
    postAsignaCertificadosPolizaService,
    postAgregarRenovacionesPolizaService,
    postAbrirPolizaService,
    postCerrarPolizaService,
    postBorrarPolizaService,
} from '../../services/procesos/polizasServices';

import * as types from '../../actions/db/types'
import * as procesostypes from '../../actions/procesos/types'


export function* postAsignaDPsPolizaSaga(payload) {
    try {

      let response = yield call(postAsignaDPsPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const poliza = {id : payload.id } 
      const accion = { type: types.GET_POLIZA, id:poliza };

      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postAsignaCertificadosPolizaSaga(payload) {
    try {
  
      let response = yield call(postAsignaCertificadosPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const poliza = {id : payload.id } 
      const accion = { type: types.GET_POLIZA, id:poliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postAgregarRenovacionesPolizaSaga(payload) {
    try {
  
      let response = yield call(postAgregarRenovacionesPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const poliza = {id : payload.id } 
      const accion = { type: types.GET_POLIZA, id:poliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postAbrirPolizaSaga(payload) {
    try {
  console.log('Abriendo Poliza SAGA--------');
      let response = yield call(postAbrirPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const poliza = {id : payload.id } 
      const accion = { type: types.GET_POLIZA, id:poliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postCerrarPolizaSaga(payload) {
    try {
  
      let response = yield call(postCerrarPolizaService, payload.id);
      if (response.error !== undefined){
        //response = {...response, success:false, message:response.error}
      }
      else{
        //response = {...response, success:true, message:''}
      }
      //const poliza = { id: payload.id } 
      const poliza = {id : payload.id } 
      const accion = { type: types.GET_POLIZA, id:poliza };
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }

  export function* postBorrarPolizaSaga(payload) {
    try {
  
      let accion = {}
      let response = yield call(postBorrarPolizaService, payload.id);
      if (response.error !== undefined){
        response = {...response, success:false, message:response.error}
        accion = { type: procesostypes.BORRAR_POLIZA_ERROR, response };
      }
      else{
        response = {...response, success:true, message:''}
        accion = { type: procesostypes.BORRAR_POLIZA_SUCCESS, response };
      }
  
      console.log('SAGA---------', accion);
      yield put(accion);
    } catch(error) {
    }
  }