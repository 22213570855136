import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



export const getBuscarService = (request) => {
  const apiURL = baseurl('buscar');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};


export const getBuscarContratantesService = (request) => {
  const apiURL = baseurl('buscarcontratantes');
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.data)
  };
  console.log('parameters.data', parameters);
  
  return fetch(apiURL, parameters)
    .then(response => {
      console.log('respondio', response);
      return response.json();
    })
    .then(json => {
      console.log('json', json);
      return json;
    })
    .catch(error => {
      console.log('error ricardo', error);
      return error;
    });
};


