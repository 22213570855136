import React from 'react';
import LoadingComponent from '../../components/commons/loading/loadingComponent'

import FacAg from './comisionesFacturaAgenteView'

const formatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2
})

const FacturasAgenteView = (props) => {
  if (props.listagentefacturas === undefined) {
    return <LoadingComponent />;
  }


  // if (props.listagentefacturas.length === 0) {
  //   return <div className='p-4' >
  //       <div className='seguros-none'>No se encontraron facturas.</div>
  //   </div>
  // }
  return (
    <section className='p-4'>
        <div className='row'>
          
          <h3>{props.agente.agente}</h3>

        </div>
        <div className='row'>
          <button className="btn-primary mt-4" 
                                    onClick={(e) => props.onEditarFacturaClick(e, 0)}>
                                    Crear factura
                              </button>

        </div>
        <div className='row'>
        <div className='col-4 p-4 ' >
              {props.listagentefacturas
                .sort((a, b) => b.id_facturaagente -  a.id_facturaagente)
                .map((pol) => (  
                <div key={pol.id_facturaagente} className='row  bg-light border border-primary' >

                    <div className='col-11'>  
                          <FacAg facturaagente={pol} />
                    </div>
                          <button className="col-1 mt-2 h-50 btn-secondary btn-block" 
                                onClick={(e) => props.onSeleccionarFacturaClick(e, pol.id_facturaagente)}>
                                <i className="fa fa-arrow-right"></i>
                          </button>
                  
                      
                      
                  </div>
              ))}
        </div>


        <div className='col-4 p-4 ' >
          <section>
            {(props.facturaseleccionada.id_facturaagente>0)
                ?<div className='row text-light bg-primary border border-primary' > 
                    
                    {(props.facturaseleccionada.editable)
                    ?<button className="col-3 h-50 btn-secondary" 
                                    onClick={(e) => props.onEditarFacturaClick(e, props.facturaseleccionada.id_facturaagente)}>
                                    Editar
                    </button>
                    :''}
                    {(props.facturaseleccionada.borrable && props.facturaseleccionada.editable )
                    ?<button className="col-3 h-50 btn-secondary" 
                        onClick={(e) => props.onBorrarFacturaClick(e, props.facturaseleccionada.id_facturaagente)}>
                        Borrar
                      </button>
                    :''}
                    
                    <div className='col-12'>   
                      <FacAg facturaagente={props.facturaseleccionada} />
                    </div>
                    
                </div>
            :''}
            {props.listafacturadps
                .sort((a, b) => a.certificado - b.certificado)
                .sort((a, b) => new Date(a.fechapago).getTime() - new Date(b.fechapago).getTime())
                .map((pol) => (  
                <div key={pol.id_dpcertificadopoliza} >
                      <div className='row pt-2  bg-light border border-primary'>

                      <div className='col-8'>
                            <div className='row '>
                              <div className='col-12 h6'>{pol.poliza} ({pol.certificado})</div>
                              <div className='col-12 h6'>{pol.asegurado }</div>
                              <div className='col-12 h6'>{pol.contratante}</div>
                              
                            </div>
                        </div>

                        <div className='col-3'>
                            <div className='row '>
                              <div className='col-12 h6'>{pol.fechapago.substr(0,10)}</div>
                              <div className='col-12 h6'>{pol.totalcomision}</div>
                             
                            </div>
                        </div>
                        {(props.facturaseleccionada.editable)
                        ?<button className="col-1 h-50 btn btn-danger btn-sm btn-block" 
                                  onClick={(e) => props.onDesAsignarClick(e, pol.id_dpcertificadopoliza)}>
                                  <i className="fa fa-times"></i>
                            </button>
                          :''}
                            

                      
                        </div>
                      
                  </div>
              ))}
          </section>
              
        </div>



        {(props.facturaseleccionada.id_facturaagente===undefined || props.facturaseleccionada.editable===false)
                ?''
                :<div className='col-4 p-4 ' >
              <div className='row '>
                <h3>Comisiones pendientes</h3>
                <div className='text-block'>
                  <h4 className='bg-dark m-2 p-2 text-light rounded'>{formatter.format(props.comisionespendientes)}</h4>
                </div>
                
              </div>
              {props.listagentedpspendientes
                .sort((a, b) => a.certificado - b.certificado)
                .sort((a, b) => new Date(a.fechapago).getTime() - new Date(b.fechapago).getTime())
                .map((pol) => (  
                <div key={pol.id_dpcertificadopoliza} >
                      <div className='row pt-2  bg-light rounded border border-primary'>

                      <button className="col-1 h-50 btn btn-primary btn-sm btn-block" 
                                  onClick={(e) => props.onAsignarClick(e, pol.id_dpcertificadopoliza)}>
                                  +
                            </button>

                        <div className='col-8'>
                            <div className='row '>
                              <div className='col-12 h6'>{pol.poliza }</div>
                              <div className='col-12 h6'>{pol.certificado}</div>
                              <div className='col-12 h6'>{pol.asegurado }</div>
                              <div className='col-12 h6'>{pol.contratante}</div>
                              
                            </div>
                        </div>

                        <div className='col-3'>
                            <div className='row '>
                              <div className='col-12 h6'>{pol.fechapago.substr(0,10)}</div>
                              <div className='col-12 h6'>{formatter.format(pol.totalcomision)}</div>
                             
                            </div>
                        </div>
                      
                        </div>
                      
                  </div>
              ))}
          </div>
}
        </div>
    </section>
  );
};

export default FacturasAgenteView;