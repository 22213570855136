import * as Types from './types'

export const uploadReset = (file,path) => ({
    type: Types.UPLOAD_RESET,
    payload: {},
    meta: { file, path },
});

export const uploadRequest = (file,path) => ({
    type: Types.UPLOAD_REQUEST,
    payload: {},
    meta: { file, path },
});
export const uploadProgress = (file, path, progress) => ({
    type: Types.UPLOAD_PROGRESS,
    payload: progress,
    meta: { file, path },
});
export const uploadSuccess = (file, path) => ({
    type: Types.UPLOAD_SUCCESS,
    meta: { file, path },
});
export const uploadFailure = (file, path, err) => ({
    type: Types.UPLOAD_FAILURE,
    payload: {err},
    meta: { file, path },
});

