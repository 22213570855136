import * as types from '../../actions/dashboards/types';

const initialState = {
  estado:'',
  dashventas: {
    cargado:false,
    anio:0,
    mes:0,
    meses:{},
    periodo:'',
    ventas:[]
  },
  dashpagos: {
    cargado:false,
    anio:0,
    mes:0,
    periodo:'',
    pagos:[],
    filtro:'',
    vista:'',
  },
  dashcertificados: {
    cargado:false,
    certificados:[],
    filtro:'',
  },
};



export default function(state = initialState, action) {
  if (state.estado===''){
    state.estado='inicio'
  }
  
  switch(action.type) {

    case types.SET_DASHBOARD_PAGOS_VISTA:
      let dpv = state.dashpagos;
      dpv.vista = action.vista;
      return {...state, dashpagos : dpv }

    case types.SET_DASHBOARD_PAGOS_FILTRO:
      let dashpagos = state.dashpagos;
      dashpagos.filtro = action.filtro;
      return {...state, dashpagos }

    case types.GET_DASHBOARD_VENTAS_SUCCESS:
      const response1 = action.response;
      if (response1.ventas === undefined || response1.anio === undefined || response1.mes === undefined) {
        return {...state}
      }
      console.log('GET_DASHBOARD_VENTAS_SUCCESS', response1);
      var resp = response1;
      resp.cargado=true;
      resp.meses = setMeses(resp.anio, resp.mes);
      resp.periodo = getMes(resp.mes) + '-' + resp.anio
      return { ...state, dashventas: response1 };

    case types.GET_DASHBOARD_VENTAS_ERROR:
      return { ...state };

    case types.GET_DASHBOARD_PAGOS_SUCCESS:
      const response2 = action.response;
      if (response2.pagos === undefined || response2.anio === undefined || response2.mes === undefined) {
        return {...state}
      }
      console.log('GET_DASHBOARD_PAGOS_SUCCESS', response2);
      var resp2 = response2;
      resp2.cargado=true;
      resp2.periodo = getMes(resp2.mes) + '-' + resp2.anio
      resp2.filtro=''
      resp2.vista='PorAgente'
      return { ...state, dashpagos: resp2 };
    
    case types.GET_DASHBOARD_PAGOS_ERROR:
        return { ...state };
  
  
    
      case types.GET_DASHBOARD_CERTIFICADOS_SUCCESS:
        console.log('GET_DASHBOARD_CERTIFICADOS_SUCCESS', action);
          const response3 = action.response;
          if (response3.certificados === undefined) {
            return {...state}
          }
          var resp3 = response3;
          resp3.cargado=true;
          return { ...state, dashcertificados: resp3 };
    
      case types.GET_DASHBOARD_CERTIFICADOS_ERROR:
        return { ...state };

    default:
      return state;
  }
};



function setMeses(anio, mes){
  var arr = [];
  for(var i=1; i<=12; i++) {
     arr.push(getMes(mes) + '-' + anio.toString());
     if (mes === 1) { 
       mes = 12;
       anio = anio - 1
     }
     else {
       mes--;
     }
  }
  return arr;
}

function getMes(mes){
  switch (mes) {
    case 1: return 'Ene'; 
    case 2: return 'Feb'; 
    case 3: return 'Mar'; 
    case 4: return 'Abr'; 
    case 5: return 'May'; 
    case 6: return 'Jun'; 
    case 7: return 'Jul'; 
    case 8: return 'Ago'; 
    case 9: return 'Sep'; 
    case 10: return 'Oct'; 
    case 11: return 'Nov'; 
    case 12: return 'Dic'; 
    default: return ''; 
}
}