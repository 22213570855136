import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    listadeprecio: {
 id_listadeprecio : 0, descripcion : '', fechainicio : '', fechatermino : '',
      },

      lista_detallelistadeprecio_borrados : [],
      lista_actualizacion_borrados : [],
      lista_primalistadeprecio_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      action.fn(item);
      return { ...state,  listadeprecio: item };


    case types.GET_LISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('GET_LISTADEPRECIO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', listadeprecio: respuesta };

    case types.GET_LISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('GET_LISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_LISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('POST_LISTADEPRECIO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', listadeprecio: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_LISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('POST_LISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_LISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_LISTADEPRECIO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', listadeprecio: respuesta };

    case types.PUT_LISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('PUT_LISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_LISTADEPRECIO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_LISTADEPRECIO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_LISTADEPRECIO_ERROR:
      respuesta= action.response;
      console.log('DELETE_LISTADEPRECIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      item.detalleslistadeprecio= [...item.detalleslistadeprecio, action.payload]
      return { ...state, detallelistadeprecio:item};


    case types.BORRA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      let lista_detallelistadeprecio_borrados= state.lista_detallelistadeprecio_borrados
      if (item.detalleslistadeprecio[action.payload].id_detallelistadeprecio > 0){
            const elementodetallelistadeprecioborrar = { id_detallelistadeprecio : item.detalleslistadeprecio[action.payload].id_detallelistadeprecio }
            lista_detallelistadeprecio_borrados= [...lista_detallelistadeprecio_borrados,  elementodetallelistadeprecioborrar]
      }
      item.detalleslistadeprecio.splice(action.payload,1)
      return { ...state, detallelistadeprecio:item, lista_detallelistadeprecio_borrados : lista_detallelistadeprecio_borrados};

 
    case types.INSERTA_ACTUALIZACION_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};

 
    case types.INSERTA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      item.primaslistadeprecio= [...item.primaslistadeprecio, action.payload]
      return { ...state, primalistadeprecio:item};


    case types.BORRA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO:
      item = state.listadeprecio;
      let lista_primalistadeprecio_borrados= state.lista_primalistadeprecio_borrados
      if (item.primaslistadeprecio[action.payload].id_primalistadeprecio > 0){
            const elementoprimalistadeprecioborrar = { id_primalistadeprecio : item.primaslistadeprecio[action.payload].id_primalistadeprecio }
            lista_primalistadeprecio_borrados= [...lista_primalistadeprecio_borrados,  elementoprimalistadeprecioborrar]
      }
      item.primaslistadeprecio.splice(action.payload,1)
      return { ...state, primalistadeprecio:item, lista_primalistadeprecio_borrados : lista_primalistadeprecio_borrados};


    default:
      return state;
  }
};

