import { takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/cargapagosoxxo/types';

 import {   getCargaPagosOxxoSaga, getCargaPagosOxxoDetalleSaga } from './cargapagosoxxosagas' ;

export default function* watchConciliaPagos() {

    yield takeLeading (types.GET_CARGAPAGOSOXXO, getCargaPagosOxxoSaga);
    yield takeLeading (types.GET_CARGAPAGOSOXXODETALLE, getCargaPagosOxxoDetalleSaga);

}
