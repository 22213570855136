import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    regimenfiscal: {
 id_regimenfiscal : 0, regimenfiscal : '', tasaiva : 0, tasaretiva : 0, tasaretisr : 0,
      },

      lista_usuario_borrados : [],
      lista_facturaagente_borrados : [],
      lista_agente_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      action.fn(item);
      return { ...state,  regimenfiscal: item };


    case types.GET_REGIMENFISCAL_SUCCESS:
      respuesta= action.response;
      console.log('GET_REGIMENFISCAL_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', regimenfiscal: respuesta };

    case types.GET_REGIMENFISCAL_ERROR:
      respuesta= action.response;
      console.log('GET_REGIMENFISCAL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_REGIMENFISCAL_SUCCESS:
      respuesta= action.response;
      console.log('POST_REGIMENFISCAL_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', regimenfiscal: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_REGIMENFISCAL_ERROR:
      respuesta= action.response;
      console.log('POST_REGIMENFISCAL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_REGIMENFISCAL_SUCCESS:
      respuesta= action.response;
      console.log('PUT_REGIMENFISCAL_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', regimenfiscal: respuesta };

    case types.PUT_REGIMENFISCAL_ERROR:
      respuesta= action.response;
      console.log('PUT_REGIMENFISCAL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_REGIMENFISCAL_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_REGIMENFISCAL_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_REGIMENFISCAL_ERROR:
      respuesta= action.response;
      console.log('DELETE_REGIMENFISCAL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_USUARIO_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      item.usuarios= [...item.usuarios, action.payload]
      return { ...state, usuario:item};


    case types.BORRA_USUARIO_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      let lista_usuario_borrados= state.lista_usuario_borrados
      if (item.usuarios[action.payload].id_usuario > 0){
            const elementousuarioborrar = { id_usuario : item.usuarios[action.payload].id_usuario }
            lista_usuario_borrados= [...lista_usuario_borrados,  elementousuarioborrar]
      }
      item.usuarios.splice(action.payload,1)
      return { ...state, usuario:item, lista_usuario_borrados : lista_usuario_borrados};

 
    case types.INSERTA_FACTURAAGENTE_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      item.facturasagente= [...item.facturasagente, action.payload]
      return { ...state, facturaagente:item};


    case types.BORRA_FACTURAAGENTE_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      let lista_facturaagente_borrados= state.lista_facturaagente_borrados
      if (item.facturasagente[action.payload].id_facturaagente > 0){
            const elementofacturaagenteborrar = { id_facturaagente : item.facturasagente[action.payload].id_facturaagente }
            lista_facturaagente_borrados= [...lista_facturaagente_borrados,  elementofacturaagenteborrar]
      }
      item.facturasagente.splice(action.payload,1)
      return { ...state, facturaagente:item, lista_facturaagente_borrados : lista_facturaagente_borrados};

 
    case types.INSERTA_AGENTE_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      item.agentes= [...item.agentes, action.payload]
      return { ...state, agente:item};


    case types.BORRA_AGENTE_EN_REGIMENFISCAL:
      item = state.regimenfiscal;
      let lista_agente_borrados= state.lista_agente_borrados
      if (item.agentes[action.payload].id_agente > 0){
            const elementoagenteborrar = { id_agente : item.agentes[action.payload].id_agente }
            lista_agente_borrados= [...lista_agente_borrados,  elementoagenteborrar]
      }
      item.agentes.splice(action.payload,1)
      return { ...state, agente:item, lista_agente_borrados : lista_agente_borrados};


    default:
      return state;
  }
};

