import * as types from '../../actions/cargapagosoxxo/types';

const initialState = {
  cargado:false,
  params: {
    fechainicio: '',
    fechatermino:''
  },
  listapagos:[],
  listadetalle:[],
      
};

var response;


export default function(state = initialState, action) {
  if (state.params.fechainicio==='') {
    const dia = new Date().getDate();
    const mes = new Date().getMonth()+1;
    const anio = new Date().getFullYear();
    var messtr = ''
    var diastr = ''
    if (mes < 10) messtr = '0' + mes; else messtr = mes ;
    if (dia < 10) diastr = '0' + dia; else diastr = dia ;
    state.params.fechainicio = anio + '-' + messtr + '-' + diastr;
    state.params.fechatermino = anio + '-' + messtr + '-' + diastr;
  }


  //console.log('buscarReducer', action);
  switch(action.type) {
    case types.GET_CARGAPAGOSOXXO:
      return{...state, listapagos:[], listadetalle:[]}
      
    case types.GET_CARGAPAGOSOXXO_SUCCESS:
      console.log('GET_CARGAPAGOSOXXO_SUCCESS',action);
      response = action.response;
      if (response.lista === undefined) {
        return {...state}
      }
      return { ...state, listapagos: response.lista };


      case types.GET_CARGAPAGOSOXXODETALLE:
        return{...state, listadetalle:[]}
        
      case types.GET_CARGAPAGOSOXXODETALLE_SUCCESS:
        console.log('GET_CARGAPAGOSOXXODETALLE_SUCCESS',action);
        response = action.response;
        if (response.lista === undefined) {
          return {...state}
        }
        return { ...state, listadetalle: response.lista };
  

    default:
      return state;
  }
};

