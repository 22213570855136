import React from 'react';
import InputField from '../../components/inputfield'
import Boton from '../../components/buttons/boton'


const ActualizarCertificadoViewBenefs = (props) => {
  if (props.list === undefined) {
    return null;
  }

  return (
  
    <div className='p-3' >

    <Boton className='col-md-3' id='agregarbenef' onClick={props.onAgregaBenefHandler} mostrar={props.certificadopoliza.id_estatuscertificado===1}/>
    
    

    {props.list
        .map((li,index) => (  
          <div key={index} className='container border border-primary p-4' >
        
      

        <div className='row '>

              <InputField className='col-md-4' field="benefscertificadopoliza.parentezco" value={li.parentezco} onChange={(e) => props.handleChange(e,index)}  />
              
              <div className='col-md-8'/>
              
                
            </div>
      
            <div className='row'>
              <InputField className='col-md-4' field="benefscertificadopoliza.nombre" value={li.nombre} onChange={(e) => props.handleChange(e,index)}  />
              <InputField className='col-md-4' field="benefscertificadopoliza.appaterno" value={li.appaterno} onChange={(e) => props.handleChange(e,index)}  />
              <InputField className='col-md-4' field="benefscertificadopoliza.apmaterno" value={li.apmaterno} onChange={(e) => props.handleChange(e,index)}  />
              
            </div>
                
            
            <div className='row'>
               <InputField className='col-md-3' field="benefscertificadopoliza.fechanacimiento" type="date" value={li.fechanacimiento} onChange={(e) => props.handleChange(e,index)}  />
                    
                    

                    <InputField className='col-md-2' type='number' field="benefscertificadopoliza.porcentaje"  value={li.porcentaje} onChange={(e) => props.handleChange(e,index)}  />
                    
            </div>
      
            {(props.certificadopoliza.id_estatuscertificado===1) 
              ?<div className='d-flex flex-md-row flex-sm-column  align-items-end justify-content-end'>
                <Boton className='pt-4 pr-1' warning id='borrar' onClick={(e) => props.onBorrarBenefHandler(e, index)} />
                
                <InputField className='' field="clave.clave" value={li.clave} onChange={(e) => props.handleChange(e,index)}  />
              </div>
              :''
              }

      
            </div>

      ))
    }
    </div>
)
  }
export default ActualizarCertificadoViewBenefs;