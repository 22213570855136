import * as types from './types';




export const getCertificadosPolizaAction = () => {
  return {
    type: types.GET_CERTIFICADOSPOLIZA
  }
};


export const ejecutaEnCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_CERTIFICADOPOLIZA,
    fn
  }
};

export const getCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_CERTIFICADOPOLIZA,
    id
  }
};

export const postCertificadoPolizaAction = (certificadopoliza) => {
  return {
    type: types.POST_CERTIFICADOPOLIZA,
    certificadopoliza
  }
};


export const putCertificadoPolizaAction = (certificadopoliza) => {
  return {
    type: types.PUT_CERTIFICADOPOLIZA,
    certificadopoliza
  }
};


export const deleteCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_CERTIFICADOPOLIZA,
    id
  }
};





export const getEveryCertificadosPolizaAction = () => {
  return {
    type: types.GET_EVERY_CERTIFICADOSPOLIZA
  }
};


export const getEveryCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_CERTIFICADOPOLIZA,
    id
  }
};

export const postEveryCertificadoPolizaAction = (certificadopoliza) => {
  return {
    type: types.POST_EVERY_CERTIFICADOPOLIZA,
    certificadopoliza
  }
};


export const putEveryCertificadoPolizaAction = (certificadopoliza) => {
  return {
    type: types.PUT_EVERY_CERTIFICADOPOLIZA,
    certificadopoliza
  }
};


export const deleteEveryCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_CERTIFICADOPOLIZA,
    id
  }
};
export const insertaAseguradoCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraAseguradoCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaTelCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraTelCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_TELCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaArchivoCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraArchivoCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_ARCHIVOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaDPCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraDPCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_DPCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaActualizacionEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraActualizacionEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaRegistroPagoOxxoEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraRegistroPagoOxxoEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_REGISTROPAGOOXXO_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaEndosoCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraEndosoCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_ENDOSOCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const insertaBenefCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
export const borraBenefCertificadoPolizaEnCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_BENEFCERTIFICADOPOLIZA_EN_CERTIFICADOPOLIZA, payload: payload } };
 


export const getPolizasAction = () => {
  return {
    type: types.GET_POLIZAS
  }
};


export const ejecutaEnPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_POLIZA,
    fn
  }
};

export const getPolizaAction = (id) => {
  return {
    type: types.GET_POLIZA,
    id
  }
};

export const postPolizaAction = (poliza) => {
  return {
    type: types.POST_POLIZA,
    poliza
  }
};


export const putPolizaAction = (poliza) => {
  return {
    type: types.PUT_POLIZA,
    poliza
  }
};


export const deletePolizaAction = (id) => {
  return {
    type: types.DELETE_POLIZA,
    id
  }
};





export const getEveryPolizasAction = () => {
  return {
    type: types.GET_EVERY_POLIZAS
  }
};


export const getEveryPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_POLIZA,
    id
  }
};

export const postEveryPolizaAction = (poliza) => {
  return {
    type: types.POST_EVERY_POLIZA,
    poliza
  }
};


export const putEveryPolizaAction = (poliza) => {
  return {
    type: types.PUT_EVERY_POLIZA,
    poliza
  }
};


export const deleteEveryPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_POLIZA,
    id
  }
};
export const insertaCertificadoPolizaEnPolizaAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_POLIZA, payload: payload } };
export const borraCertificadoPolizaEnPolizaAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_POLIZA, payload: payload } };
export const insertaProductoPolizaEnPolizaAction = (payload) => { return {  type: types.INSERTA_PRODUCTOPOLIZA_EN_POLIZA, payload: payload } };
export const borraProductoPolizaEnPolizaAction = (payload) => { return {  type: types.BORRA_PRODUCTOPOLIZA_EN_POLIZA, payload: payload } };
export const insertaDPProductoPolizaEnPolizaAction = (payload) => { return {  type: types.INSERTA_DPPRODUCTOPOLIZA_EN_POLIZA, payload: payload } };
export const borraDPProductoPolizaEnPolizaAction = (payload) => { return {  type: types.BORRA_DPPRODUCTOPOLIZA_EN_POLIZA, payload: payload } };
export const insertaActualizacionEnPolizaAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_POLIZA, payload: payload } };
export const borraActualizacionEnPolizaAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_POLIZA, payload: payload } };
export const insertaPrimaProductoPolizaEnPolizaAction = (payload) => { return {  type: types.INSERTA_PRIMAPRODUCTOPOLIZA_EN_POLIZA, payload: payload } };
export const borraPrimaProductoPolizaEnPolizaAction = (payload) => { return {  type: types.BORRA_PRIMAPRODUCTOPOLIZA_EN_POLIZA, payload: payload } };
 


export const getProductosPolizaAction = () => {
  return {
    type: types.GET_PRODUCTOSPOLIZA
  }
};


export const ejecutaEnProductoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PRODUCTOPOLIZA,
    fn
  }
};

export const getProductoPolizaAction = (id) => {
  return {
    type: types.GET_PRODUCTOPOLIZA,
    id
  }
};

export const postProductoPolizaAction = (productopoliza) => {
  return {
    type: types.POST_PRODUCTOPOLIZA,
    productopoliza
  }
};


export const putProductoPolizaAction = (productopoliza) => {
  return {
    type: types.PUT_PRODUCTOPOLIZA,
    productopoliza
  }
};


export const deleteProductoPolizaAction = (id) => {
  return {
    type: types.DELETE_PRODUCTOPOLIZA,
    id
  }
};





export const getEveryProductosPolizaAction = () => {
  return {
    type: types.GET_EVERY_PRODUCTOSPOLIZA
  }
};


export const getEveryProductoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_PRODUCTOPOLIZA,
    id
  }
};

export const postEveryProductoPolizaAction = (productopoliza) => {
  return {
    type: types.POST_EVERY_PRODUCTOPOLIZA,
    productopoliza
  }
};


export const putEveryProductoPolizaAction = (productopoliza) => {
  return {
    type: types.PUT_EVERY_PRODUCTOPOLIZA,
    productopoliza
  }
};


export const deleteEveryProductoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_PRODUCTOPOLIZA,
    id
  }
};
export const insertaDPProductoPolizaEnProductoPolizaAction = (payload) => { return {  type: types.INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA, payload: payload } };
export const borraDPProductoPolizaEnProductoPolizaAction = (payload) => { return {  type: types.BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA, payload: payload } };
export const insertaPrimaProductoPolizaEnProductoPolizaAction = (payload) => { return {  type: types.INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA, payload: payload } };
export const borraPrimaProductoPolizaEnProductoPolizaAction = (payload) => { return {  type: types.BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTOPOLIZA, payload: payload } };
 


export const getDPsProductoPolizaAction = () => {
  return {
    type: types.GET_DPSPRODUCTOPOLIZA
  }
};


export const ejecutaEnDPProductoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_DPPRODUCTOPOLIZA,
    fn
  }
};

export const getDPProductoPolizaAction = (id) => {
  return {
    type: types.GET_DPPRODUCTOPOLIZA,
    id
  }
};

export const postDPProductoPolizaAction = (dpproductopoliza) => {
  return {
    type: types.POST_DPPRODUCTOPOLIZA,
    dpproductopoliza
  }
};


export const putDPProductoPolizaAction = (dpproductopoliza) => {
  return {
    type: types.PUT_DPPRODUCTOPOLIZA,
    dpproductopoliza
  }
};


export const deleteDPProductoPolizaAction = (id) => {
  return {
    type: types.DELETE_DPPRODUCTOPOLIZA,
    id
  }
};





export const getEveryDPsProductoPolizaAction = () => {
  return {
    type: types.GET_EVERY_DPSPRODUCTOPOLIZA
  }
};


export const getEveryDPProductoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_DPPRODUCTOPOLIZA,
    id
  }
};

export const postEveryDPProductoPolizaAction = (dpproductopoliza) => {
  return {
    type: types.POST_EVERY_DPPRODUCTOPOLIZA,
    dpproductopoliza
  }
};


export const putEveryDPProductoPolizaAction = (dpproductopoliza) => {
  return {
    type: types.PUT_EVERY_DPPRODUCTOPOLIZA,
    dpproductopoliza
  }
};


export const deleteEveryDPProductoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_DPPRODUCTOPOLIZA,
    id
  }
};
 


export const getAseguradosCertificadoPolizaAction = () => {
  return {
    type: types.GET_ASEGURADOSCERTIFICADOPOLIZA
  }
};


export const ejecutaEnAseguradoCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ASEGURADOCERTIFICADOPOLIZA,
    fn
  }
};

export const getAseguradoCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_ASEGURADOCERTIFICADOPOLIZA,
    id
  }
};

export const postAseguradoCertificadoPolizaAction = (aseguradocertificadopoliza) => {
  return {
    type: types.POST_ASEGURADOCERTIFICADOPOLIZA,
    aseguradocertificadopoliza
  }
};


export const putAseguradoCertificadoPolizaAction = (aseguradocertificadopoliza) => {
  return {
    type: types.PUT_ASEGURADOCERTIFICADOPOLIZA,
    aseguradocertificadopoliza
  }
};


export const deleteAseguradoCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_ASEGURADOCERTIFICADOPOLIZA,
    id
  }
};





export const getEveryAseguradosCertificadoPolizaAction = () => {
  return {
    type: types.GET_EVERY_ASEGURADOSCERTIFICADOPOLIZA
  }
};


export const getEveryAseguradoCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_ASEGURADOCERTIFICADOPOLIZA,
    id
  }
};

export const postEveryAseguradoCertificadoPolizaAction = (aseguradocertificadopoliza) => {
  return {
    type: types.POST_EVERY_ASEGURADOCERTIFICADOPOLIZA,
    aseguradocertificadopoliza
  }
};


export const putEveryAseguradoCertificadoPolizaAction = (aseguradocertificadopoliza) => {
  return {
    type: types.PUT_EVERY_ASEGURADOCERTIFICADOPOLIZA,
    aseguradocertificadopoliza
  }
};


export const deleteEveryAseguradoCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_ASEGURADOCERTIFICADOPOLIZA,
    id
  }
};
export const insertaActualizacionEnAseguradoCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA, payload: payload } };
export const borraActualizacionEnAseguradoCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_ASEGURADOCERTIFICADOPOLIZA, payload: payload } };
 


export const getTelsCertificadoPolizaAction = () => {
  return {
    type: types.GET_TELSCERTIFICADOPOLIZA
  }
};


export const ejecutaEnTelCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_TELCERTIFICADOPOLIZA,
    fn
  }
};

export const getTelCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_TELCERTIFICADOPOLIZA,
    id
  }
};

export const postTelCertificadoPolizaAction = (telcertificadopoliza) => {
  return {
    type: types.POST_TELCERTIFICADOPOLIZA,
    telcertificadopoliza
  }
};


export const putTelCertificadoPolizaAction = (telcertificadopoliza) => {
  return {
    type: types.PUT_TELCERTIFICADOPOLIZA,
    telcertificadopoliza
  }
};


export const deleteTelCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_TELCERTIFICADOPOLIZA,
    id
  }
};





export const getEveryTelsCertificadoPolizaAction = () => {
  return {
    type: types.GET_EVERY_TELSCERTIFICADOPOLIZA
  }
};


export const getEveryTelCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_TELCERTIFICADOPOLIZA,
    id
  }
};

export const postEveryTelCertificadoPolizaAction = (telcertificadopoliza) => {
  return {
    type: types.POST_EVERY_TELCERTIFICADOPOLIZA,
    telcertificadopoliza
  }
};


export const putEveryTelCertificadoPolizaAction = (telcertificadopoliza) => {
  return {
    type: types.PUT_EVERY_TELCERTIFICADOPOLIZA,
    telcertificadopoliza
  }
};


export const deleteEveryTelCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_TELCERTIFICADOPOLIZA,
    id
  }
};
 


export const getArchivosCertificadoPolizaAction = () => {
  return {
    type: types.GET_ARCHIVOSCERTIFICADOPOLIZA
  }
};


export const ejecutaEnArchivoCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ARCHIVOCERTIFICADOPOLIZA,
    fn
  }
};

export const getArchivoCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_ARCHIVOCERTIFICADOPOLIZA,
    id
  }
};

export const postArchivoCertificadoPolizaAction = (archivocertificadopoliza) => {
  return {
    type: types.POST_ARCHIVOCERTIFICADOPOLIZA,
    archivocertificadopoliza
  }
};


export const putArchivoCertificadoPolizaAction = (archivocertificadopoliza) => {
  return {
    type: types.PUT_ARCHIVOCERTIFICADOPOLIZA,
    archivocertificadopoliza
  }
};


export const deleteArchivoCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_ARCHIVOCERTIFICADOPOLIZA,
    id
  }
};





export const getEveryArchivosCertificadoPolizaAction = () => {
  return {
    type: types.GET_EVERY_ARCHIVOSCERTIFICADOPOLIZA
  }
};


export const getEveryArchivoCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_ARCHIVOCERTIFICADOPOLIZA,
    id
  }
};

export const postEveryArchivoCertificadoPolizaAction = (archivocertificadopoliza) => {
  return {
    type: types.POST_EVERY_ARCHIVOCERTIFICADOPOLIZA,
    archivocertificadopoliza
  }
};


export const putEveryArchivoCertificadoPolizaAction = (archivocertificadopoliza) => {
  return {
    type: types.PUT_EVERY_ARCHIVOCERTIFICADOPOLIZA,
    archivocertificadopoliza
  }
};


export const deleteEveryArchivoCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_ARCHIVOCERTIFICADOPOLIZA,
    id
  }
};
 


export const getDPsCertificadoPolizaAction = () => {
  return {
    type: types.GET_DPSCERTIFICADOPOLIZA
  }
};


export const ejecutaEnDPCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_DPCERTIFICADOPOLIZA,
    fn
  }
};

export const getDPCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_DPCERTIFICADOPOLIZA,
    id
  }
};

export const postDPCertificadoPolizaAction = (dpcertificadopoliza) => {
  return {
    type: types.POST_DPCERTIFICADOPOLIZA,
    dpcertificadopoliza
  }
};


export const putDPCertificadoPolizaAction = (dpcertificadopoliza) => {
  return {
    type: types.PUT_DPCERTIFICADOPOLIZA,
    dpcertificadopoliza
  }
};


export const deleteDPCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_DPCERTIFICADOPOLIZA,
    id
  }
};





export const getEveryDPsCertificadoPolizaAction = () => {
  return {
    type: types.GET_EVERY_DPSCERTIFICADOPOLIZA
  }
};


export const getEveryDPCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_DPCERTIFICADOPOLIZA,
    id
  }
};

export const postEveryDPCertificadoPolizaAction = (dpcertificadopoliza) => {
  return {
    type: types.POST_EVERY_DPCERTIFICADOPOLIZA,
    dpcertificadopoliza
  }
};


export const putEveryDPCertificadoPolizaAction = (dpcertificadopoliza) => {
  return {
    type: types.PUT_EVERY_DPCERTIFICADOPOLIZA,
    dpcertificadopoliza
  }
};


export const deleteEveryDPCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_DPCERTIFICADOPOLIZA,
    id
  }
};
export const insertaActualizacionEnDPCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA, payload: payload } };
export const borraActualizacionEnDPCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_DPCERTIFICADOPOLIZA, payload: payload } };
export const insertaRegistroPagoOxxoEnDPCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA, payload: payload } };
export const borraRegistroPagoOxxoEnDPCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_REGISTROPAGOOXXO_EN_DPCERTIFICADOPOLIZA, payload: payload } };
 


export const getPagosOxxoAction = () => {
  return {
    type: types.GET_PAGOSOXXO
  }
};


export const ejecutaEnPagoOxxoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PAGOOXXO,
    fn
  }
};

export const getPagoOxxoAction = (id) => {
  return {
    type: types.GET_PAGOOXXO,
    id
  }
};

export const postPagoOxxoAction = (pagooxxo) => {
  return {
    type: types.POST_PAGOOXXO,
    pagooxxo
  }
};


export const putPagoOxxoAction = (pagooxxo) => {
  return {
    type: types.PUT_PAGOOXXO,
    pagooxxo
  }
};


export const deletePagoOxxoAction = (id) => {
  return {
    type: types.DELETE_PAGOOXXO,
    id
  }
};





export const getEveryPagosOxxoAction = () => {
  return {
    type: types.GET_EVERY_PAGOSOXXO
  }
};


export const getEveryPagoOxxoAction = (id) => {
  return {
    type: types.GET_EVERY_PAGOOXXO,
    id
  }
};

export const postEveryPagoOxxoAction = (pagooxxo) => {
  return {
    type: types.POST_EVERY_PAGOOXXO,
    pagooxxo
  }
};


export const putEveryPagoOxxoAction = (pagooxxo) => {
  return {
    type: types.PUT_EVERY_PAGOOXXO,
    pagooxxo
  }
};


export const deleteEveryPagoOxxoAction = (id) => {
  return {
    type: types.DELETE_EVERY_PAGOOXXO,
    id
  }
};
 


export const getRegistrosPagoOxxoAction = () => {
  return {
    type: types.GET_REGISTROSPAGOOXXO
  }
};


export const ejecutaEnRegistroPagoOxxoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_REGISTROPAGOOXXO,
    fn
  }
};

export const getRegistroPagoOxxoAction = (id) => {
  return {
    type: types.GET_REGISTROPAGOOXXO,
    id
  }
};

export const postRegistroPagoOxxoAction = (registropagooxxo) => {
  return {
    type: types.POST_REGISTROPAGOOXXO,
    registropagooxxo
  }
};


export const putRegistroPagoOxxoAction = (registropagooxxo) => {
  return {
    type: types.PUT_REGISTROPAGOOXXO,
    registropagooxxo
  }
};


export const deleteRegistroPagoOxxoAction = (id) => {
  return {
    type: types.DELETE_REGISTROPAGOOXXO,
    id
  }
};





export const getEveryRegistrosPagoOxxoAction = () => {
  return {
    type: types.GET_EVERY_REGISTROSPAGOOXXO
  }
};


export const getEveryRegistroPagoOxxoAction = (id) => {
  return {
    type: types.GET_EVERY_REGISTROPAGOOXXO,
    id
  }
};

export const postEveryRegistroPagoOxxoAction = (registropagooxxo) => {
  return {
    type: types.POST_EVERY_REGISTROPAGOOXXO,
    registropagooxxo
  }
};


export const putEveryRegistroPagoOxxoAction = (registropagooxxo) => {
  return {
    type: types.PUT_EVERY_REGISTROPAGOOXXO,
    registropagooxxo
  }
};


export const deleteEveryRegistroPagoOxxoAction = (id) => {
  return {
    type: types.DELETE_EVERY_REGISTROPAGOOXXO,
    id
  }
};
 


export const getEndososCertificadoPolizaAction = () => {
  return {
    type: types.GET_ENDOSOSCERTIFICADOPOLIZA
  }
};


export const ejecutaEnEndosoCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ENDOSOCERTIFICADOPOLIZA,
    fn
  }
};

export const getEndosoCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_ENDOSOCERTIFICADOPOLIZA,
    id
  }
};

export const postEndosoCertificadoPolizaAction = (endosocertificadopoliza) => {
  return {
    type: types.POST_ENDOSOCERTIFICADOPOLIZA,
    endosocertificadopoliza
  }
};


export const putEndosoCertificadoPolizaAction = (endosocertificadopoliza) => {
  return {
    type: types.PUT_ENDOSOCERTIFICADOPOLIZA,
    endosocertificadopoliza
  }
};


export const deleteEndosoCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_ENDOSOCERTIFICADOPOLIZA,
    id
  }
};





export const getEveryEndososCertificadoPolizaAction = () => {
  return {
    type: types.GET_EVERY_ENDOSOSCERTIFICADOPOLIZA
  }
};


export const getEveryEndosoCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_ENDOSOCERTIFICADOPOLIZA,
    id
  }
};

export const postEveryEndosoCertificadoPolizaAction = (endosocertificadopoliza) => {
  return {
    type: types.POST_EVERY_ENDOSOCERTIFICADOPOLIZA,
    endosocertificadopoliza
  }
};


export const putEveryEndosoCertificadoPolizaAction = (endosocertificadopoliza) => {
  return {
    type: types.PUT_EVERY_ENDOSOCERTIFICADOPOLIZA,
    endosocertificadopoliza
  }
};


export const deleteEveryEndosoCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_ENDOSOCERTIFICADOPOLIZA,
    id
  }
};
export const insertaDPCertificadoPolizaEnEndosoCertificadoPolizaAction = (payload) => { return {  type: types.INSERTA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA, payload: payload } };
export const borraDPCertificadoPolizaEnEndosoCertificadoPolizaAction = (payload) => { return {  type: types.BORRA_DPCERTIFICADOPOLIZA_EN_ENDOSOCERTIFICADOPOLIZA, payload: payload } };
 


export const getBenefsCertificadoPolizaAction = () => {
  return {
    type: types.GET_BENEFSCERTIFICADOPOLIZA
  }
};


export const ejecutaEnBenefCertificadoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_BENEFCERTIFICADOPOLIZA,
    fn
  }
};

export const getBenefCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_BENEFCERTIFICADOPOLIZA,
    id
  }
};

export const postBenefCertificadoPolizaAction = (benefcertificadopoliza) => {
  return {
    type: types.POST_BENEFCERTIFICADOPOLIZA,
    benefcertificadopoliza
  }
};


export const putBenefCertificadoPolizaAction = (benefcertificadopoliza) => {
  return {
    type: types.PUT_BENEFCERTIFICADOPOLIZA,
    benefcertificadopoliza
  }
};


export const deleteBenefCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_BENEFCERTIFICADOPOLIZA,
    id
  }
};





export const getEveryBenefsCertificadoPolizaAction = () => {
  return {
    type: types.GET_EVERY_BENEFSCERTIFICADOPOLIZA
  }
};


export const getEveryBenefCertificadoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_BENEFCERTIFICADOPOLIZA,
    id
  }
};

export const postEveryBenefCertificadoPolizaAction = (benefcertificadopoliza) => {
  return {
    type: types.POST_EVERY_BENEFCERTIFICADOPOLIZA,
    benefcertificadopoliza
  }
};


export const putEveryBenefCertificadoPolizaAction = (benefcertificadopoliza) => {
  return {
    type: types.PUT_EVERY_BENEFCERTIFICADOPOLIZA,
    benefcertificadopoliza
  }
};


export const deleteEveryBenefCertificadoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_BENEFCERTIFICADOPOLIZA,
    id
  }
};
 


export const getFacturasAgenteAction = () => {
  return {
    type: types.GET_FACTURASAGENTE
  }
};


export const ejecutaEnFacturaAgenteAction = (fn) => {
  return {
    type: types.EJECUTA_EN_FACTURAAGENTE,
    fn
  }
};

export const getFacturaAgenteAction = (id) => {
  return {
    type: types.GET_FACTURAAGENTE,
    id
  }
};

export const postFacturaAgenteAction = (facturaagente) => {
  return {
    type: types.POST_FACTURAAGENTE,
    facturaagente
  }
};


export const putFacturaAgenteAction = (facturaagente) => {
  return {
    type: types.PUT_FACTURAAGENTE,
    facturaagente
  }
};


export const deleteFacturaAgenteAction = (id) => {
  return {
    type: types.DELETE_FACTURAAGENTE,
    id
  }
};





export const getEveryFacturasAgenteAction = () => {
  return {
    type: types.GET_EVERY_FACTURASAGENTE
  }
};


export const getEveryFacturaAgenteAction = (id) => {
  return {
    type: types.GET_EVERY_FACTURAAGENTE,
    id
  }
};

export const postEveryFacturaAgenteAction = (facturaagente) => {
  return {
    type: types.POST_EVERY_FACTURAAGENTE,
    facturaagente
  }
};


export const putEveryFacturaAgenteAction = (facturaagente) => {
  return {
    type: types.PUT_EVERY_FACTURAAGENTE,
    facturaagente
  }
};


export const deleteEveryFacturaAgenteAction = (id) => {
  return {
    type: types.DELETE_EVERY_FACTURAAGENTE,
    id
  }
};
export const insertaDPCertificadoPolizaEnFacturaAgenteAction = (payload) => { return {  type: types.INSERTA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE, payload: payload } };
export const borraDPCertificadoPolizaEnFacturaAgenteAction = (payload) => { return {  type: types.BORRA_DPCERTIFICADOPOLIZA_EN_FACTURAAGENTE, payload: payload } };
 


export const getPrimasProductoPolizaAction = () => {
  return {
    type: types.GET_PRIMASPRODUCTOPOLIZA
  }
};


export const ejecutaEnPrimaProductoPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PRIMAPRODUCTOPOLIZA,
    fn
  }
};

export const getPrimaProductoPolizaAction = (id) => {
  return {
    type: types.GET_PRIMAPRODUCTOPOLIZA,
    id
  }
};

export const postPrimaProductoPolizaAction = (primaproductopoliza) => {
  return {
    type: types.POST_PRIMAPRODUCTOPOLIZA,
    primaproductopoliza
  }
};


export const putPrimaProductoPolizaAction = (primaproductopoliza) => {
  return {
    type: types.PUT_PRIMAPRODUCTOPOLIZA,
    primaproductopoliza
  }
};


export const deletePrimaProductoPolizaAction = (id) => {
  return {
    type: types.DELETE_PRIMAPRODUCTOPOLIZA,
    id
  }
};





export const getEveryPrimasProductoPolizaAction = () => {
  return {
    type: types.GET_EVERY_PRIMASPRODUCTOPOLIZA
  }
};


export const getEveryPrimaProductoPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_PRIMAPRODUCTOPOLIZA,
    id
  }
};

export const postEveryPrimaProductoPolizaAction = (primaproductopoliza) => {
  return {
    type: types.POST_EVERY_PRIMAPRODUCTOPOLIZA,
    primaproductopoliza
  }
};


export const putEveryPrimaProductoPolizaAction = (primaproductopoliza) => {
  return {
    type: types.PUT_EVERY_PRIMAPRODUCTOPOLIZA,
    primaproductopoliza
  }
};


export const deleteEveryPrimaProductoPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_PRIMAPRODUCTOPOLIZA,
    id
  }
};
 


export const getAseguradorasAction = () => {
  return {
    type: types.GET_ASEGURADORAS
  }
};


export const ejecutaEnAseguradoraAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ASEGURADORA,
    fn
  }
};

export const getAseguradoraAction = (id) => {
  return {
    type: types.GET_ASEGURADORA,
    id
  }
};

export const postAseguradoraAction = (aseguradora) => {
  return {
    type: types.POST_ASEGURADORA,
    aseguradora
  }
};


export const putAseguradoraAction = (aseguradora) => {
  return {
    type: types.PUT_ASEGURADORA,
    aseguradora
  }
};


export const deleteAseguradoraAction = (id) => {
  return {
    type: types.DELETE_ASEGURADORA,
    id
  }
};





export const getEveryAseguradorasAction = () => {
  return {
    type: types.GET_EVERY_ASEGURADORAS
  }
};


export const getEveryAseguradoraAction = (id) => {
  return {
    type: types.GET_EVERY_ASEGURADORA,
    id
  }
};

export const postEveryAseguradoraAction = (aseguradora) => {
  return {
    type: types.POST_EVERY_ASEGURADORA,
    aseguradora
  }
};


export const putEveryAseguradoraAction = (aseguradora) => {
  return {
    type: types.PUT_EVERY_ASEGURADORA,
    aseguradora
  }
};


export const deleteEveryAseguradoraAction = (id) => {
  return {
    type: types.DELETE_EVERY_ASEGURADORA,
    id
  }
};
export const insertaPolizaEnAseguradoraAction = (payload) => { return {  type: types.INSERTA_POLIZA_EN_ASEGURADORA, payload: payload } };
export const borraPolizaEnAseguradoraAction = (payload) => { return {  type: types.BORRA_POLIZA_EN_ASEGURADORA, payload: payload } };
export const insertaProductoEnAseguradoraAction = (payload) => { return {  type: types.INSERTA_PRODUCTO_EN_ASEGURADORA, payload: payload } };
export const borraProductoEnAseguradoraAction = (payload) => { return {  type: types.BORRA_PRODUCTO_EN_ASEGURADORA, payload: payload } };
 


export const getProductosAction = () => {
  return {
    type: types.GET_PRODUCTOS
  }
};


export const ejecutaEnProductoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PRODUCTO,
    fn
  }
};

export const getProductoAction = (id) => {
  return {
    type: types.GET_PRODUCTO,
    id
  }
};

export const postProductoAction = (producto) => {
  return {
    type: types.POST_PRODUCTO,
    producto
  }
};


export const putProductoAction = (producto) => {
  return {
    type: types.PUT_PRODUCTO,
    producto
  }
};


export const deleteProductoAction = (id) => {
  return {
    type: types.DELETE_PRODUCTO,
    id
  }
};





export const getEveryProductosAction = () => {
  return {
    type: types.GET_EVERY_PRODUCTOS
  }
};


export const getEveryProductoAction = (id) => {
  return {
    type: types.GET_EVERY_PRODUCTO,
    id
  }
};

export const postEveryProductoAction = (producto) => {
  return {
    type: types.POST_EVERY_PRODUCTO,
    producto
  }
};


export const putEveryProductoAction = (producto) => {
  return {
    type: types.PUT_EVERY_PRODUCTO,
    producto
  }
};


export const deleteEveryProductoAction = (id) => {
  return {
    type: types.DELETE_EVERY_PRODUCTO,
    id
  }
};
export const insertaProductoPolizaEnProductoAction = (payload) => { return {  type: types.INSERTA_PRODUCTOPOLIZA_EN_PRODUCTO, payload: payload } };
export const borraProductoPolizaEnProductoAction = (payload) => { return {  type: types.BORRA_PRODUCTOPOLIZA_EN_PRODUCTO, payload: payload } };
export const insertaDetalleListaDePrecioEnProductoAction = (payload) => { return {  type: types.INSERTA_DETALLELISTADEPRECIO_EN_PRODUCTO, payload: payload } };
export const borraDetalleListaDePrecioEnProductoAction = (payload) => { return {  type: types.BORRA_DETALLELISTADEPRECIO_EN_PRODUCTO, payload: payload } };
export const insertaCertificadoPolizaEnProductoAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_PRODUCTO, payload: payload } };
export const borraCertificadoPolizaEnProductoAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_PRODUCTO, payload: payload } };
export const insertaAseguradoProductoEnProductoAction = (payload) => { return {  type: types.INSERTA_ASEGURADOPRODUCTO_EN_PRODUCTO, payload: payload } };
export const borraAseguradoProductoEnProductoAction = (payload) => { return {  type: types.BORRA_ASEGURADOPRODUCTO_EN_PRODUCTO, payload: payload } };
export const insertaDPCertificadoPolizaEnProductoAction = (payload) => { return {  type: types.INSERTA_DPCERTIFICADOPOLIZA_EN_PRODUCTO, payload: payload } };
export const borraDPCertificadoPolizaEnProductoAction = (payload) => { return {  type: types.BORRA_DPCERTIFICADOPOLIZA_EN_PRODUCTO, payload: payload } };
export const insertaDPProductoPolizaEnProductoAction = (payload) => { return {  type: types.INSERTA_DPPRODUCTOPOLIZA_EN_PRODUCTO, payload: payload } };
export const borraDPProductoPolizaEnProductoAction = (payload) => { return {  type: types.BORRA_DPPRODUCTOPOLIZA_EN_PRODUCTO, payload: payload } };
export const insertaPrimaProductoPolizaEnProductoAction = (payload) => { return {  type: types.INSERTA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO, payload: payload } };
export const borraPrimaProductoPolizaEnProductoAction = (payload) => { return {  type: types.BORRA_PRIMAPRODUCTOPOLIZA_EN_PRODUCTO, payload: payload } };
export const insertaCoberturaProductoEnProductoAction = (payload) => { return {  type: types.INSERTA_COBERTURAPRODUCTO_EN_PRODUCTO, payload: payload } };
export const borraCoberturaProductoEnProductoAction = (payload) => { return {  type: types.BORRA_COBERTURAPRODUCTO_EN_PRODUCTO, payload: payload } };
export const insertaProductoClaveComisionEnProductoAction = (payload) => { return {  type: types.INSERTA_PRODUCTOCLAVECOMISION_EN_PRODUCTO, payload: payload } };
export const borraProductoClaveComisionEnProductoAction = (payload) => { return {  type: types.BORRA_PRODUCTOCLAVECOMISION_EN_PRODUCTO, payload: payload } };
 


export const getAgentesAction = () => {
  return {
    type: types.GET_AGENTES
  }
};


export const ejecutaEnAgenteAction = (fn) => {
  return {
    type: types.EJECUTA_EN_AGENTE,
    fn
  }
};

export const getAgenteAction = (id) => {
  return {
    type: types.GET_AGENTE,
    id
  }
};

export const postAgenteAction = (agente) => {
  return {
    type: types.POST_AGENTE,
    agente
  }
};


export const putAgenteAction = (agente) => {
  return {
    type: types.PUT_AGENTE,
    agente
  }
};


export const deleteAgenteAction = (id) => {
  return {
    type: types.DELETE_AGENTE,
    id
  }
};





export const getEveryAgentesAction = () => {
  return {
    type: types.GET_EVERY_AGENTES
  }
};


export const getEveryAgenteAction = (id) => {
  return {
    type: types.GET_EVERY_AGENTE,
    id
  }
};

export const postEveryAgenteAction = (agente) => {
  return {
    type: types.POST_EVERY_AGENTE,
    agente
  }
};


export const putEveryAgenteAction = (agente) => {
  return {
    type: types.PUT_EVERY_AGENTE,
    agente
  }
};


export const deleteEveryAgenteAction = (id) => {
  return {
    type: types.DELETE_EVERY_AGENTE,
    id
  }
};
export const insertaCertificadoPolizaEnAgenteAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_AGENTE, payload: payload } };
export const borraCertificadoPolizaEnAgenteAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_AGENTE, payload: payload } };
export const insertaFacturaAgenteEnAgenteAction = (payload) => { return {  type: types.INSERTA_FACTURAAGENTE_EN_AGENTE, payload: payload } };
export const borraFacturaAgenteEnAgenteAction = (payload) => { return {  type: types.BORRA_FACTURAAGENTE_EN_AGENTE, payload: payload } };
 


export const getDetallesListaDePrecioAction = () => {
  return {
    type: types.GET_DETALLESLISTADEPRECIO
  }
};


export const ejecutaEnDetalleListaDePrecioAction = (fn) => {
  return {
    type: types.EJECUTA_EN_DETALLELISTADEPRECIO,
    fn
  }
};

export const getDetalleListaDePrecioAction = (id) => {
  return {
    type: types.GET_DETALLELISTADEPRECIO,
    id
  }
};

export const postDetalleListaDePrecioAction = (detallelistadeprecio) => {
  return {
    type: types.POST_DETALLELISTADEPRECIO,
    detallelistadeprecio
  }
};


export const putDetalleListaDePrecioAction = (detallelistadeprecio) => {
  return {
    type: types.PUT_DETALLELISTADEPRECIO,
    detallelistadeprecio
  }
};


export const deleteDetalleListaDePrecioAction = (id) => {
  return {
    type: types.DELETE_DETALLELISTADEPRECIO,
    id
  }
};





export const getEveryDetallesListaDePrecioAction = () => {
  return {
    type: types.GET_EVERY_DETALLESLISTADEPRECIO
  }
};


export const getEveryDetalleListaDePrecioAction = (id) => {
  return {
    type: types.GET_EVERY_DETALLELISTADEPRECIO,
    id
  }
};

export const postEveryDetalleListaDePrecioAction = (detallelistadeprecio) => {
  return {
    type: types.POST_EVERY_DETALLELISTADEPRECIO,
    detallelistadeprecio
  }
};


export const putEveryDetalleListaDePrecioAction = (detallelistadeprecio) => {
  return {
    type: types.PUT_EVERY_DETALLELISTADEPRECIO,
    detallelistadeprecio
  }
};


export const deleteEveryDetalleListaDePrecioAction = (id) => {
  return {
    type: types.DELETE_EVERY_DETALLELISTADEPRECIO,
    id
  }
};
 


export const getAseguradosProductoAction = () => {
  return {
    type: types.GET_ASEGURADOSPRODUCTO
  }
};


export const ejecutaEnAseguradoProductoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ASEGURADOPRODUCTO,
    fn
  }
};

export const getAseguradoProductoAction = (id) => {
  return {
    type: types.GET_ASEGURADOPRODUCTO,
    id
  }
};

export const postAseguradoProductoAction = (aseguradoproducto) => {
  return {
    type: types.POST_ASEGURADOPRODUCTO,
    aseguradoproducto
  }
};


export const putAseguradoProductoAction = (aseguradoproducto) => {
  return {
    type: types.PUT_ASEGURADOPRODUCTO,
    aseguradoproducto
  }
};


export const deleteAseguradoProductoAction = (id) => {
  return {
    type: types.DELETE_ASEGURADOPRODUCTO,
    id
  }
};





export const getEveryAseguradosProductoAction = () => {
  return {
    type: types.GET_EVERY_ASEGURADOSPRODUCTO
  }
};


export const getEveryAseguradoProductoAction = (id) => {
  return {
    type: types.GET_EVERY_ASEGURADOPRODUCTO,
    id
  }
};

export const postEveryAseguradoProductoAction = (aseguradoproducto) => {
  return {
    type: types.POST_EVERY_ASEGURADOPRODUCTO,
    aseguradoproducto
  }
};


export const putEveryAseguradoProductoAction = (aseguradoproducto) => {
  return {
    type: types.PUT_EVERY_ASEGURADOPRODUCTO,
    aseguradoproducto
  }
};


export const deleteEveryAseguradoProductoAction = (id) => {
  return {
    type: types.DELETE_EVERY_ASEGURADOPRODUCTO,
    id
  }
};
 


export const getListasDePrecioAction = () => {
  return {
    type: types.GET_LISTASDEPRECIO
  }
};


export const ejecutaEnListaDePrecioAction = (fn) => {
  return {
    type: types.EJECUTA_EN_LISTADEPRECIO,
    fn
  }
};

export const getListaDePrecioAction = (id) => {
  return {
    type: types.GET_LISTADEPRECIO,
    id
  }
};

export const postListaDePrecioAction = (listadeprecio) => {
  return {
    type: types.POST_LISTADEPRECIO,
    listadeprecio
  }
};


export const putListaDePrecioAction = (listadeprecio) => {
  return {
    type: types.PUT_LISTADEPRECIO,
    listadeprecio
  }
};


export const deleteListaDePrecioAction = (id) => {
  return {
    type: types.DELETE_LISTADEPRECIO,
    id
  }
};





export const getEveryListasDePrecioAction = () => {
  return {
    type: types.GET_EVERY_LISTASDEPRECIO
  }
};


export const getEveryListaDePrecioAction = (id) => {
  return {
    type: types.GET_EVERY_LISTADEPRECIO,
    id
  }
};

export const postEveryListaDePrecioAction = (listadeprecio) => {
  return {
    type: types.POST_EVERY_LISTADEPRECIO,
    listadeprecio
  }
};


export const putEveryListaDePrecioAction = (listadeprecio) => {
  return {
    type: types.PUT_EVERY_LISTADEPRECIO,
    listadeprecio
  }
};


export const deleteEveryListaDePrecioAction = (id) => {
  return {
    type: types.DELETE_EVERY_LISTADEPRECIO,
    id
  }
};
export const insertaDetalleListaDePrecioEnListaDePrecioAction = (payload) => { return {  type: types.INSERTA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO, payload: payload } };
export const borraDetalleListaDePrecioEnListaDePrecioAction = (payload) => { return {  type: types.BORRA_DETALLELISTADEPRECIO_EN_LISTADEPRECIO, payload: payload } };
export const insertaActualizacionEnListaDePrecioAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_LISTADEPRECIO, payload: payload } };
export const borraActualizacionEnListaDePrecioAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_LISTADEPRECIO, payload: payload } };
export const insertaPrimaListaDePrecioEnListaDePrecioAction = (payload) => { return {  type: types.INSERTA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO, payload: payload } };
export const borraPrimaListaDePrecioEnListaDePrecioAction = (payload) => { return {  type: types.BORRA_PRIMALISTADEPRECIO_EN_LISTADEPRECIO, payload: payload } };
 


export const getPrimasListaDePrecioAction = () => {
  return {
    type: types.GET_PRIMASLISTADEPRECIO
  }
};


export const ejecutaEnPrimaListaDePrecioAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PRIMALISTADEPRECIO,
    fn
  }
};

export const getPrimaListaDePrecioAction = (id) => {
  return {
    type: types.GET_PRIMALISTADEPRECIO,
    id
  }
};

export const postPrimaListaDePrecioAction = (primalistadeprecio) => {
  return {
    type: types.POST_PRIMALISTADEPRECIO,
    primalistadeprecio
  }
};


export const putPrimaListaDePrecioAction = (primalistadeprecio) => {
  return {
    type: types.PUT_PRIMALISTADEPRECIO,
    primalistadeprecio
  }
};


export const deletePrimaListaDePrecioAction = (id) => {
  return {
    type: types.DELETE_PRIMALISTADEPRECIO,
    id
  }
};





export const getEveryPrimasListaDePrecioAction = () => {
  return {
    type: types.GET_EVERY_PRIMASLISTADEPRECIO
  }
};


export const getEveryPrimaListaDePrecioAction = (id) => {
  return {
    type: types.GET_EVERY_PRIMALISTADEPRECIO,
    id
  }
};

export const postEveryPrimaListaDePrecioAction = (primalistadeprecio) => {
  return {
    type: types.POST_EVERY_PRIMALISTADEPRECIO,
    primalistadeprecio
  }
};


export const putEveryPrimaListaDePrecioAction = (primalistadeprecio) => {
  return {
    type: types.PUT_EVERY_PRIMALISTADEPRECIO,
    primalistadeprecio
  }
};


export const deleteEveryPrimaListaDePrecioAction = (id) => {
  return {
    type: types.DELETE_EVERY_PRIMALISTADEPRECIO,
    id
  }
};
 


export const getCoberturasProductoAction = () => {
  return {
    type: types.GET_COBERTURASPRODUCTO
  }
};


export const ejecutaEnCoberturaProductoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_COBERTURAPRODUCTO,
    fn
  }
};

export const getCoberturaProductoAction = (id) => {
  return {
    type: types.GET_COBERTURAPRODUCTO,
    id
  }
};

export const postCoberturaProductoAction = (coberturaproducto) => {
  return {
    type: types.POST_COBERTURAPRODUCTO,
    coberturaproducto
  }
};


export const putCoberturaProductoAction = (coberturaproducto) => {
  return {
    type: types.PUT_COBERTURAPRODUCTO,
    coberturaproducto
  }
};


export const deleteCoberturaProductoAction = (id) => {
  return {
    type: types.DELETE_COBERTURAPRODUCTO,
    id
  }
};





export const getEveryCoberturasProductoAction = () => {
  return {
    type: types.GET_EVERY_COBERTURASPRODUCTO
  }
};


export const getEveryCoberturaProductoAction = (id) => {
  return {
    type: types.GET_EVERY_COBERTURAPRODUCTO,
    id
  }
};

export const postEveryCoberturaProductoAction = (coberturaproducto) => {
  return {
    type: types.POST_EVERY_COBERTURAPRODUCTO,
    coberturaproducto
  }
};


export const putEveryCoberturaProductoAction = (coberturaproducto) => {
  return {
    type: types.PUT_EVERY_COBERTURAPRODUCTO,
    coberturaproducto
  }
};


export const deleteEveryCoberturaProductoAction = (id) => {
  return {
    type: types.DELETE_EVERY_COBERTURAPRODUCTO,
    id
  }
};
 


export const getClavesComisionAction = () => {
  return {
    type: types.GET_CLAVESCOMISION
  }
};


export const ejecutaEnClaveComisionAction = (fn) => {
  return {
    type: types.EJECUTA_EN_CLAVECOMISION,
    fn
  }
};

export const getClaveComisionAction = (id) => {
  return {
    type: types.GET_CLAVECOMISION,
    id
  }
};

export const postClaveComisionAction = (clavecomision) => {
  return {
    type: types.POST_CLAVECOMISION,
    clavecomision
  }
};


export const putClaveComisionAction = (clavecomision) => {
  return {
    type: types.PUT_CLAVECOMISION,
    clavecomision
  }
};


export const deleteClaveComisionAction = (id) => {
  return {
    type: types.DELETE_CLAVECOMISION,
    id
  }
};





export const getEveryClavesComisionAction = () => {
  return {
    type: types.GET_EVERY_CLAVESCOMISION
  }
};


export const getEveryClaveComisionAction = (id) => {
  return {
    type: types.GET_EVERY_CLAVECOMISION,
    id
  }
};

export const postEveryClaveComisionAction = (clavecomision) => {
  return {
    type: types.POST_EVERY_CLAVECOMISION,
    clavecomision
  }
};


export const putEveryClaveComisionAction = (clavecomision) => {
  return {
    type: types.PUT_EVERY_CLAVECOMISION,
    clavecomision
  }
};


export const deleteEveryClaveComisionAction = (id) => {
  return {
    type: types.DELETE_EVERY_CLAVECOMISION,
    id
  }
};
export const insertaProductoClaveComisionEnClaveComisionAction = (payload) => { return {  type: types.INSERTA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION, payload: payload } };
export const borraProductoClaveComisionEnClaveComisionAction = (payload) => { return {  type: types.BORRA_PRODUCTOCLAVECOMISION_EN_CLAVECOMISION, payload: payload } };
export const insertaUsuarioEnClaveComisionAction = (payload) => { return {  type: types.INSERTA_USUARIO_EN_CLAVECOMISION, payload: payload } };
export const borraUsuarioEnClaveComisionAction = (payload) => { return {  type: types.BORRA_USUARIO_EN_CLAVECOMISION, payload: payload } };
export const insertaAgenteEnClaveComisionAction = (payload) => { return {  type: types.INSERTA_AGENTE_EN_CLAVECOMISION, payload: payload } };
export const borraAgenteEnClaveComisionAction = (payload) => { return {  type: types.BORRA_AGENTE_EN_CLAVECOMISION, payload: payload } };
 


export const getProductosClaveComisionAction = () => {
  return {
    type: types.GET_PRODUCTOSCLAVECOMISION
  }
};


export const ejecutaEnProductoClaveComisionAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PRODUCTOCLAVECOMISION,
    fn
  }
};

export const getProductoClaveComisionAction = (id) => {
  return {
    type: types.GET_PRODUCTOCLAVECOMISION,
    id
  }
};

export const postProductoClaveComisionAction = (productoclavecomision) => {
  return {
    type: types.POST_PRODUCTOCLAVECOMISION,
    productoclavecomision
  }
};


export const putProductoClaveComisionAction = (productoclavecomision) => {
  return {
    type: types.PUT_PRODUCTOCLAVECOMISION,
    productoclavecomision
  }
};


export const deleteProductoClaveComisionAction = (id) => {
  return {
    type: types.DELETE_PRODUCTOCLAVECOMISION,
    id
  }
};





export const getEveryProductosClaveComisionAction = () => {
  return {
    type: types.GET_EVERY_PRODUCTOSCLAVECOMISION
  }
};


export const getEveryProductoClaveComisionAction = (id) => {
  return {
    type: types.GET_EVERY_PRODUCTOCLAVECOMISION,
    id
  }
};

export const postEveryProductoClaveComisionAction = (productoclavecomision) => {
  return {
    type: types.POST_EVERY_PRODUCTOCLAVECOMISION,
    productoclavecomision
  }
};


export const putEveryProductoClaveComisionAction = (productoclavecomision) => {
  return {
    type: types.PUT_EVERY_PRODUCTOCLAVECOMISION,
    productoclavecomision
  }
};


export const deleteEveryProductoClaveComisionAction = (id) => {
  return {
    type: types.DELETE_EVERY_PRODUCTOCLAVECOMISION,
    id
  }
};
 


export const getFormasPagoAction = () => {
  return {
    type: types.GET_FORMASPAGO
  }
};


export const ejecutaEnFormaPagoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_FORMAPAGO,
    fn
  }
};

export const getFormaPagoAction = (id) => {
  return {
    type: types.GET_FORMAPAGO,
    id
  }
};

export const postFormaPagoAction = (formapago) => {
  return {
    type: types.POST_FORMAPAGO,
    formapago
  }
};


export const putFormaPagoAction = (formapago) => {
  return {
    type: types.PUT_FORMAPAGO,
    formapago
  }
};


export const deleteFormaPagoAction = (id) => {
  return {
    type: types.DELETE_FORMAPAGO,
    id
  }
};





export const getEveryFormasPagoAction = () => {
  return {
    type: types.GET_EVERY_FORMASPAGO
  }
};


export const getEveryFormaPagoAction = (id) => {
  return {
    type: types.GET_EVERY_FORMAPAGO,
    id
  }
};

export const postEveryFormaPagoAction = (formapago) => {
  return {
    type: types.POST_EVERY_FORMAPAGO,
    formapago
  }
};


export const putEveryFormaPagoAction = (formapago) => {
  return {
    type: types.PUT_EVERY_FORMAPAGO,
    formapago
  }
};


export const deleteEveryFormaPagoAction = (id) => {
  return {
    type: types.DELETE_EVERY_FORMAPAGO,
    id
  }
};
export const insertaPolizaEnFormaPagoAction = (payload) => { return {  type: types.INSERTA_POLIZA_EN_FORMAPAGO, payload: payload } };
export const borraPolizaEnFormaPagoAction = (payload) => { return {  type: types.BORRA_POLIZA_EN_FORMAPAGO, payload: payload } };
export const insertaCertificadoPolizaEnFormaPagoAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_FORMAPAGO, payload: payload } };
export const borraCertificadoPolizaEnFormaPagoAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_FORMAPAGO, payload: payload } };
export const insertaDetalleListaDePrecioEnFormaPagoAction = (payload) => { return {  type: types.INSERTA_DETALLELISTADEPRECIO_EN_FORMAPAGO, payload: payload } };
export const borraDetalleListaDePrecioEnFormaPagoAction = (payload) => { return {  type: types.BORRA_DETALLELISTADEPRECIO_EN_FORMAPAGO, payload: payload } };
 


export const getParentezcosAction = () => {
  return {
    type: types.GET_PARENTEZCOS
  }
};


export const ejecutaEnParentezcoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PARENTEZCO,
    fn
  }
};

export const getParentezcoAction = (id) => {
  return {
    type: types.GET_PARENTEZCO,
    id
  }
};

export const postParentezcoAction = (parentezco) => {
  return {
    type: types.POST_PARENTEZCO,
    parentezco
  }
};


export const putParentezcoAction = (parentezco) => {
  return {
    type: types.PUT_PARENTEZCO,
    parentezco
  }
};


export const deleteParentezcoAction = (id) => {
  return {
    type: types.DELETE_PARENTEZCO,
    id
  }
};





export const getEveryParentezcosAction = () => {
  return {
    type: types.GET_EVERY_PARENTEZCOS
  }
};


export const getEveryParentezcoAction = (id) => {
  return {
    type: types.GET_EVERY_PARENTEZCO,
    id
  }
};

export const postEveryParentezcoAction = (parentezco) => {
  return {
    type: types.POST_EVERY_PARENTEZCO,
    parentezco
  }
};


export const putEveryParentezcoAction = (parentezco) => {
  return {
    type: types.PUT_EVERY_PARENTEZCO,
    parentezco
  }
};


export const deleteEveryParentezcoAction = (id) => {
  return {
    type: types.DELETE_EVERY_PARENTEZCO,
    id
  }
};
export const insertaAseguradoCertificadoPolizaEnParentezcoAction = (payload) => { return {  type: types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_PARENTEZCO, payload: payload } };
export const borraAseguradoCertificadoPolizaEnParentezcoAction = (payload) => { return {  type: types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_PARENTEZCO, payload: payload } };
export const insertaAseguradoProductoEnParentezcoAction = (payload) => { return {  type: types.INSERTA_ASEGURADOPRODUCTO_EN_PARENTEZCO, payload: payload } };
export const borraAseguradoProductoEnParentezcoAction = (payload) => { return {  type: types.BORRA_ASEGURADOPRODUCTO_EN_PARENTEZCO, payload: payload } };
 


export const getEstatusPagosAction = () => {
  return {
    type: types.GET_ESTATUSPAGOS
  }
};


export const ejecutaEnEstatusPagoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ESTATUSPAGO,
    fn
  }
};

export const getEstatusPagoAction = (id) => {
  return {
    type: types.GET_ESTATUSPAGO,
    id
  }
};

export const postEstatusPagoAction = (estatuspago) => {
  return {
    type: types.POST_ESTATUSPAGO,
    estatuspago
  }
};


export const putEstatusPagoAction = (estatuspago) => {
  return {
    type: types.PUT_ESTATUSPAGO,
    estatuspago
  }
};


export const deleteEstatusPagoAction = (id) => {
  return {
    type: types.DELETE_ESTATUSPAGO,
    id
  }
};





export const getEveryEstatusPagosAction = () => {
  return {
    type: types.GET_EVERY_ESTATUSPAGOS
  }
};


export const getEveryEstatusPagoAction = (id) => {
  return {
    type: types.GET_EVERY_ESTATUSPAGO,
    id
  }
};

export const postEveryEstatusPagoAction = (estatuspago) => {
  return {
    type: types.POST_EVERY_ESTATUSPAGO,
    estatuspago
  }
};


export const putEveryEstatusPagoAction = (estatuspago) => {
  return {
    type: types.PUT_EVERY_ESTATUSPAGO,
    estatuspago
  }
};


export const deleteEveryEstatusPagoAction = (id) => {
  return {
    type: types.DELETE_EVERY_ESTATUSPAGO,
    id
  }
};
export const insertaDPCertificadoPolizaEnEstatusPagoAction = (payload) => { return {  type: types.INSERTA_DPCERTIFICADOPOLIZA_EN_ESTATUSPAGO, payload: payload } };
export const borraDPCertificadoPolizaEnEstatusPagoAction = (payload) => { return {  type: types.BORRA_DPCERTIFICADOPOLIZA_EN_ESTATUSPAGO, payload: payload } };
 


export const getTiposProductoAction = () => {
  return {
    type: types.GET_TIPOSPRODUCTO
  }
};


export const ejecutaEnTipoProductoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_TIPOPRODUCTO,
    fn
  }
};

export const getTipoProductoAction = (id) => {
  return {
    type: types.GET_TIPOPRODUCTO,
    id
  }
};

export const postTipoProductoAction = (tipoproducto) => {
  return {
    type: types.POST_TIPOPRODUCTO,
    tipoproducto
  }
};


export const putTipoProductoAction = (tipoproducto) => {
  return {
    type: types.PUT_TIPOPRODUCTO,
    tipoproducto
  }
};


export const deleteTipoProductoAction = (id) => {
  return {
    type: types.DELETE_TIPOPRODUCTO,
    id
  }
};





export const getEveryTiposProductoAction = () => {
  return {
    type: types.GET_EVERY_TIPOSPRODUCTO
  }
};


export const getEveryTipoProductoAction = (id) => {
  return {
    type: types.GET_EVERY_TIPOPRODUCTO,
    id
  }
};

export const postEveryTipoProductoAction = (tipoproducto) => {
  return {
    type: types.POST_EVERY_TIPOPRODUCTO,
    tipoproducto
  }
};


export const putEveryTipoProductoAction = (tipoproducto) => {
  return {
    type: types.PUT_EVERY_TIPOPRODUCTO,
    tipoproducto
  }
};


export const deleteEveryTipoProductoAction = (id) => {
  return {
    type: types.DELETE_EVERY_TIPOPRODUCTO,
    id
  }
};
export const insertaProductoEnTipoProductoAction = (payload) => { return {  type: types.INSERTA_PRODUCTO_EN_TIPOPRODUCTO, payload: payload } };
export const borraProductoEnTipoProductoAction = (payload) => { return {  type: types.BORRA_PRODUCTO_EN_TIPOPRODUCTO, payload: payload } };
 


export const getOrigenesAction = () => {
  return {
    type: types.GET_ORIGENES
  }
};


export const ejecutaEnOrigenAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ORIGEN,
    fn
  }
};

export const getOrigenAction = (id) => {
  return {
    type: types.GET_ORIGEN,
    id
  }
};

export const postOrigenAction = (origen) => {
  return {
    type: types.POST_ORIGEN,
    origen
  }
};


export const putOrigenAction = (origen) => {
  return {
    type: types.PUT_ORIGEN,
    origen
  }
};


export const deleteOrigenAction = (id) => {
  return {
    type: types.DELETE_ORIGEN,
    id
  }
};





export const getEveryOrigenesAction = () => {
  return {
    type: types.GET_EVERY_ORIGENES
  }
};


export const getEveryOrigenAction = (id) => {
  return {
    type: types.GET_EVERY_ORIGEN,
    id
  }
};

export const postEveryOrigenAction = (origen) => {
  return {
    type: types.POST_EVERY_ORIGEN,
    origen
  }
};


export const putEveryOrigenAction = (origen) => {
  return {
    type: types.PUT_EVERY_ORIGEN,
    origen
  }
};


export const deleteEveryOrigenAction = (id) => {
  return {
    type: types.DELETE_EVERY_ORIGEN,
    id
  }
};
export const insertaCertificadoPolizaEnOrigenAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_ORIGEN, payload: payload } };
export const borraCertificadoPolizaEnOrigenAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_ORIGEN, payload: payload } };
export const insertaAseguradoCertificadoPolizaEnOrigenAction = (payload) => { return {  type: types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN, payload: payload } };
export const borraAseguradoCertificadoPolizaEnOrigenAction = (payload) => { return {  type: types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_ORIGEN, payload: payload } };
 


export const getGenerosAction = () => {
  return {
    type: types.GET_GENEROS
  }
};


export const ejecutaEnGeneroAction = (fn) => {
  return {
    type: types.EJECUTA_EN_GENERO,
    fn
  }
};

export const getGeneroAction = (id) => {
  return {
    type: types.GET_GENERO,
    id
  }
};

export const postGeneroAction = (genero) => {
  return {
    type: types.POST_GENERO,
    genero
  }
};


export const putGeneroAction = (genero) => {
  return {
    type: types.PUT_GENERO,
    genero
  }
};


export const deleteGeneroAction = (id) => {
  return {
    type: types.DELETE_GENERO,
    id
  }
};





export const getEveryGenerosAction = () => {
  return {
    type: types.GET_EVERY_GENEROS
  }
};


export const getEveryGeneroAction = (id) => {
  return {
    type: types.GET_EVERY_GENERO,
    id
  }
};

export const postEveryGeneroAction = (genero) => {
  return {
    type: types.POST_EVERY_GENERO,
    genero
  }
};


export const putEveryGeneroAction = (genero) => {
  return {
    type: types.PUT_EVERY_GENERO,
    genero
  }
};


export const deleteEveryGeneroAction = (id) => {
  return {
    type: types.DELETE_EVERY_GENERO,
    id
  }
};
export const insertaAseguradoCertificadoPolizaEnGeneroAction = (payload) => { return {  type: types.INSERTA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO, payload: payload } };
export const borraAseguradoCertificadoPolizaEnGeneroAction = (payload) => { return {  type: types.BORRA_ASEGURADOCERTIFICADOPOLIZA_EN_GENERO, payload: payload } };
 


export const getEstatusCertificadosAction = () => {
  return {
    type: types.GET_ESTATUSCERTIFICADOS
  }
};


export const ejecutaEnEstatusCertificadoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ESTATUSCERTIFICADO,
    fn
  }
};

export const getEstatusCertificadoAction = (id) => {
  return {
    type: types.GET_ESTATUSCERTIFICADO,
    id
  }
};

export const postEstatusCertificadoAction = (estatuscertificado) => {
  return {
    type: types.POST_ESTATUSCERTIFICADO,
    estatuscertificado
  }
};


export const putEstatusCertificadoAction = (estatuscertificado) => {
  return {
    type: types.PUT_ESTATUSCERTIFICADO,
    estatuscertificado
  }
};


export const deleteEstatusCertificadoAction = (id) => {
  return {
    type: types.DELETE_ESTATUSCERTIFICADO,
    id
  }
};





export const getEveryEstatusCertificadosAction = () => {
  return {
    type: types.GET_EVERY_ESTATUSCERTIFICADOS
  }
};


export const getEveryEstatusCertificadoAction = (id) => {
  return {
    type: types.GET_EVERY_ESTATUSCERTIFICADO,
    id
  }
};

export const postEveryEstatusCertificadoAction = (estatuscertificado) => {
  return {
    type: types.POST_EVERY_ESTATUSCERTIFICADO,
    estatuscertificado
  }
};


export const putEveryEstatusCertificadoAction = (estatuscertificado) => {
  return {
    type: types.PUT_EVERY_ESTATUSCERTIFICADO,
    estatuscertificado
  }
};


export const deleteEveryEstatusCertificadoAction = (id) => {
  return {
    type: types.DELETE_EVERY_ESTATUSCERTIFICADO,
    id
  }
};
export const insertaCertificadoPolizaEnEstatusCertificadoAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_ESTATUSCERTIFICADO, payload: payload } };
export const borraCertificadoPolizaEnEstatusCertificadoAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_ESTATUSCERTIFICADO, payload: payload } };
 


export const getEstatusPolizasAction = () => {
  return {
    type: types.GET_ESTATUSPOLIZAS
  }
};


export const ejecutaEnEstatusPolizaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ESTATUSPOLIZA,
    fn
  }
};

export const getEstatusPolizaAction = (id) => {
  return {
    type: types.GET_ESTATUSPOLIZA,
    id
  }
};

export const postEstatusPolizaAction = (estatuspoliza) => {
  return {
    type: types.POST_ESTATUSPOLIZA,
    estatuspoliza
  }
};


export const putEstatusPolizaAction = (estatuspoliza) => {
  return {
    type: types.PUT_ESTATUSPOLIZA,
    estatuspoliza
  }
};


export const deleteEstatusPolizaAction = (id) => {
  return {
    type: types.DELETE_ESTATUSPOLIZA,
    id
  }
};





export const getEveryEstatusPolizasAction = () => {
  return {
    type: types.GET_EVERY_ESTATUSPOLIZAS
  }
};


export const getEveryEstatusPolizaAction = (id) => {
  return {
    type: types.GET_EVERY_ESTATUSPOLIZA,
    id
  }
};

export const postEveryEstatusPolizaAction = (estatuspoliza) => {
  return {
    type: types.POST_EVERY_ESTATUSPOLIZA,
    estatuspoliza
  }
};


export const putEveryEstatusPolizaAction = (estatuspoliza) => {
  return {
    type: types.PUT_EVERY_ESTATUSPOLIZA,
    estatuspoliza
  }
};


export const deleteEveryEstatusPolizaAction = (id) => {
  return {
    type: types.DELETE_EVERY_ESTATUSPOLIZA,
    id
  }
};
export const insertaPolizaEnEstatusPolizaAction = (payload) => { return {  type: types.INSERTA_POLIZA_EN_ESTATUSPOLIZA, payload: payload } };
export const borraPolizaEnEstatusPolizaAction = (payload) => { return {  type: types.BORRA_POLIZA_EN_ESTATUSPOLIZA, payload: payload } };
 


export const getMediosPagoAction = () => {
  return {
    type: types.GET_MEDIOSPAGO
  }
};


export const ejecutaEnMedioPagoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_MEDIOPAGO,
    fn
  }
};

export const getMedioPagoAction = (id) => {
  return {
    type: types.GET_MEDIOPAGO,
    id
  }
};

export const postMedioPagoAction = (mediopago) => {
  return {
    type: types.POST_MEDIOPAGO,
    mediopago
  }
};


export const putMedioPagoAction = (mediopago) => {
  return {
    type: types.PUT_MEDIOPAGO,
    mediopago
  }
};


export const deleteMedioPagoAction = (id) => {
  return {
    type: types.DELETE_MEDIOPAGO,
    id
  }
};





export const getEveryMediosPagoAction = () => {
  return {
    type: types.GET_EVERY_MEDIOSPAGO
  }
};


export const getEveryMedioPagoAction = (id) => {
  return {
    type: types.GET_EVERY_MEDIOPAGO,
    id
  }
};

export const postEveryMedioPagoAction = (mediopago) => {
  return {
    type: types.POST_EVERY_MEDIOPAGO,
    mediopago
  }
};


export const putEveryMedioPagoAction = (mediopago) => {
  return {
    type: types.PUT_EVERY_MEDIOPAGO,
    mediopago
  }
};


export const deleteEveryMedioPagoAction = (id) => {
  return {
    type: types.DELETE_EVERY_MEDIOPAGO,
    id
  }
};
export const insertaDPCertificadoPolizaEnMedioPagoAction = (payload) => { return {  type: types.INSERTA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO, payload: payload } };
export const borraDPCertificadoPolizaEnMedioPagoAction = (payload) => { return {  type: types.BORRA_DPCERTIFICADOPOLIZA_EN_MEDIOPAGO, payload: payload } };
 


export const getEstatusRegistrosPagoOxxoAction = () => {
  return {
    type: types.GET_ESTATUSREGISTROSPAGOOXXO
  }
};


export const ejecutaEnEstatusRegistroPagoOxxoAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ESTATUSREGISTROPAGOOXXO,
    fn
  }
};

export const getEstatusRegistroPagoOxxoAction = (id) => {
  return {
    type: types.GET_ESTATUSREGISTROPAGOOXXO,
    id
  }
};

export const postEstatusRegistroPagoOxxoAction = (estatusregistropagooxxo) => {
  return {
    type: types.POST_ESTATUSREGISTROPAGOOXXO,
    estatusregistropagooxxo
  }
};


export const putEstatusRegistroPagoOxxoAction = (estatusregistropagooxxo) => {
  return {
    type: types.PUT_ESTATUSREGISTROPAGOOXXO,
    estatusregistropagooxxo
  }
};


export const deleteEstatusRegistroPagoOxxoAction = (id) => {
  return {
    type: types.DELETE_ESTATUSREGISTROPAGOOXXO,
    id
  }
};





export const getEveryEstatusRegistrosPagoOxxoAction = () => {
  return {
    type: types.GET_EVERY_ESTATUSREGISTROSPAGOOXXO
  }
};


export const getEveryEstatusRegistroPagoOxxoAction = (id) => {
  return {
    type: types.GET_EVERY_ESTATUSREGISTROPAGOOXXO,
    id
  }
};

export const postEveryEstatusRegistroPagoOxxoAction = (estatusregistropagooxxo) => {
  return {
    type: types.POST_EVERY_ESTATUSREGISTROPAGOOXXO,
    estatusregistropagooxxo
  }
};


export const putEveryEstatusRegistroPagoOxxoAction = (estatusregistropagooxxo) => {
  return {
    type: types.PUT_EVERY_ESTATUSREGISTROPAGOOXXO,
    estatusregistropagooxxo
  }
};


export const deleteEveryEstatusRegistroPagoOxxoAction = (id) => {
  return {
    type: types.DELETE_EVERY_ESTATUSREGISTROPAGOOXXO,
    id
  }
};
export const insertaRegistroPagoOxxoEnEstatusRegistroPagoOxxoAction = (payload) => { return {  type: types.INSERTA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO, payload: payload } };
export const borraRegistroPagoOxxoEnEstatusRegistroPagoOxxoAction = (payload) => { return {  type: types.BORRA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO, payload: payload } };
 


export const getMotivosCancelacionAction = () => {
  return {
    type: types.GET_MOTIVOSCANCELACION
  }
};


export const ejecutaEnMotivoCancelacionAction = (fn) => {
  return {
    type: types.EJECUTA_EN_MOTIVOCANCELACION,
    fn
  }
};

export const getMotivoCancelacionAction = (id) => {
  return {
    type: types.GET_MOTIVOCANCELACION,
    id
  }
};

export const postMotivoCancelacionAction = (motivocancelacion) => {
  return {
    type: types.POST_MOTIVOCANCELACION,
    motivocancelacion
  }
};


export const putMotivoCancelacionAction = (motivocancelacion) => {
  return {
    type: types.PUT_MOTIVOCANCELACION,
    motivocancelacion
  }
};


export const deleteMotivoCancelacionAction = (id) => {
  return {
    type: types.DELETE_MOTIVOCANCELACION,
    id
  }
};





export const getEveryMotivosCancelacionAction = () => {
  return {
    type: types.GET_EVERY_MOTIVOSCANCELACION
  }
};


export const getEveryMotivoCancelacionAction = (id) => {
  return {
    type: types.GET_EVERY_MOTIVOCANCELACION,
    id
  }
};

export const postEveryMotivoCancelacionAction = (motivocancelacion) => {
  return {
    type: types.POST_EVERY_MOTIVOCANCELACION,
    motivocancelacion
  }
};


export const putEveryMotivoCancelacionAction = (motivocancelacion) => {
  return {
    type: types.PUT_EVERY_MOTIVOCANCELACION,
    motivocancelacion
  }
};


export const deleteEveryMotivoCancelacionAction = (id) => {
  return {
    type: types.DELETE_EVERY_MOTIVOCANCELACION,
    id
  }
};
export const insertaCertificadoPolizaEnMotivoCancelacionAction = (payload) => { return {  type: types.INSERTA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION, payload: payload } };
export const borraCertificadoPolizaEnMotivoCancelacionAction = (payload) => { return {  type: types.BORRA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION, payload: payload } };
 


export const getRegimenesFiscalesAction = () => {
  return {
    type: types.GET_REGIMENESFISCALES
  }
};


export const ejecutaEnRegimenFiscalAction = (fn) => {
  return {
    type: types.EJECUTA_EN_REGIMENFISCAL,
    fn
  }
};

export const getRegimenFiscalAction = (id) => {
  return {
    type: types.GET_REGIMENFISCAL,
    id
  }
};

export const postRegimenFiscalAction = (regimenfiscal) => {
  return {
    type: types.POST_REGIMENFISCAL,
    regimenfiscal
  }
};


export const putRegimenFiscalAction = (regimenfiscal) => {
  return {
    type: types.PUT_REGIMENFISCAL,
    regimenfiscal
  }
};


export const deleteRegimenFiscalAction = (id) => {
  return {
    type: types.DELETE_REGIMENFISCAL,
    id
  }
};





export const getEveryRegimenesFiscalesAction = () => {
  return {
    type: types.GET_EVERY_REGIMENESFISCALES
  }
};


export const getEveryRegimenFiscalAction = (id) => {
  return {
    type: types.GET_EVERY_REGIMENFISCAL,
    id
  }
};

export const postEveryRegimenFiscalAction = (regimenfiscal) => {
  return {
    type: types.POST_EVERY_REGIMENFISCAL,
    regimenfiscal
  }
};


export const putEveryRegimenFiscalAction = (regimenfiscal) => {
  return {
    type: types.PUT_EVERY_REGIMENFISCAL,
    regimenfiscal
  }
};


export const deleteEveryRegimenFiscalAction = (id) => {
  return {
    type: types.DELETE_EVERY_REGIMENFISCAL,
    id
  }
};
export const insertaUsuarioEnRegimenFiscalAction = (payload) => { return {  type: types.INSERTA_USUARIO_EN_REGIMENFISCAL, payload: payload } };
export const borraUsuarioEnRegimenFiscalAction = (payload) => { return {  type: types.BORRA_USUARIO_EN_REGIMENFISCAL, payload: payload } };
export const insertaFacturaAgenteEnRegimenFiscalAction = (payload) => { return {  type: types.INSERTA_FACTURAAGENTE_EN_REGIMENFISCAL, payload: payload } };
export const borraFacturaAgenteEnRegimenFiscalAction = (payload) => { return {  type: types.BORRA_FACTURAAGENTE_EN_REGIMENFISCAL, payload: payload } };
export const insertaAgenteEnRegimenFiscalAction = (payload) => { return {  type: types.INSERTA_AGENTE_EN_REGIMENFISCAL, payload: payload } };
export const borraAgenteEnRegimenFiscalAction = (payload) => { return {  type: types.BORRA_AGENTE_EN_REGIMENFISCAL, payload: payload } };
 


export const getUsuariosAction = () => {
  return {
    type: types.GET_USUARIOS
  }
};


export const ejecutaEnUsuarioAction = (fn) => {
  return {
    type: types.EJECUTA_EN_USUARIO,
    fn
  }
};

export const getUsuarioAction = (id) => {
  return {
    type: types.GET_USUARIO,
    id
  }
};

export const postUsuarioAction = (usuario) => {
  return {
    type: types.POST_USUARIO,
    usuario
  }
};


export const putUsuarioAction = (usuario) => {
  return {
    type: types.PUT_USUARIO,
    usuario
  }
};


export const deleteUsuarioAction = (id) => {
  return {
    type: types.DELETE_USUARIO,
    id
  }
};





export const getEveryUsuariosAction = () => {
  return {
    type: types.GET_EVERY_USUARIOS
  }
};


export const getEveryUsuarioAction = (id) => {
  return {
    type: types.GET_EVERY_USUARIO,
    id
  }
};

export const postEveryUsuarioAction = (usuario) => {
  return {
    type: types.POST_EVERY_USUARIO,
    usuario
  }
};


export const putEveryUsuarioAction = (usuario) => {
  return {
    type: types.PUT_EVERY_USUARIO,
    usuario
  }
};


export const deleteEveryUsuarioAction = (id) => {
  return {
    type: types.DELETE_EVERY_USUARIO,
    id
  }
};
export const insertaActualizacionEnUsuarioAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_USUARIO, payload: payload } };
export const borraActualizacionEnUsuarioAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_USUARIO, payload: payload } };
 


export const getActualizacionesAction = () => {
  return {
    type: types.GET_ACTUALIZACIONES
  }
};


export const ejecutaEnActualizacionAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ACTUALIZACION,
    fn
  }
};

export const getActualizacionAction = (id) => {
  return {
    type: types.GET_ACTUALIZACION,
    id
  }
};

export const postActualizacionAction = (actualizacion) => {
  return {
    type: types.POST_ACTUALIZACION,
    actualizacion
  }
};


export const putActualizacionAction = (actualizacion) => {
  return {
    type: types.PUT_ACTUALIZACION,
    actualizacion
  }
};


export const deleteActualizacionAction = (id) => {
  return {
    type: types.DELETE_ACTUALIZACION,
    id
  }
};





export const getEveryActualizacionesAction = () => {
  return {
    type: types.GET_EVERY_ACTUALIZACIONES
  }
};


export const getEveryActualizacionAction = (id) => {
  return {
    type: types.GET_EVERY_ACTUALIZACION,
    id
  }
};

export const postEveryActualizacionAction = (actualizacion) => {
  return {
    type: types.POST_EVERY_ACTUALIZACION,
    actualizacion
  }
};


export const putEveryActualizacionAction = (actualizacion) => {
  return {
    type: types.PUT_EVERY_ACTUALIZACION,
    actualizacion
  }
};


export const deleteEveryActualizacionAction = (id) => {
  return {
    type: types.DELETE_EVERY_ACTUALIZACION,
    id
  }
};
 


export const getOperacionesAction = () => {
  return {
    type: types.GET_OPERACIONES
  }
};


export const ejecutaEnOperacionAction = (fn) => {
  return {
    type: types.EJECUTA_EN_OPERACION,
    fn
  }
};

export const getOperacionAction = (id) => {
  return {
    type: types.GET_OPERACION,
    id
  }
};

export const postOperacionAction = (operacion) => {
  return {
    type: types.POST_OPERACION,
    operacion
  }
};


export const putOperacionAction = (operacion) => {
  return {
    type: types.PUT_OPERACION,
    operacion
  }
};


export const deleteOperacionAction = (id) => {
  return {
    type: types.DELETE_OPERACION,
    id
  }
};





export const getEveryOperacionesAction = () => {
  return {
    type: types.GET_EVERY_OPERACIONES
  }
};


export const getEveryOperacionAction = (id) => {
  return {
    type: types.GET_EVERY_OPERACION,
    id
  }
};

export const postEveryOperacionAction = (operacion) => {
  return {
    type: types.POST_EVERY_OPERACION,
    operacion
  }
};


export const putEveryOperacionAction = (operacion) => {
  return {
    type: types.PUT_EVERY_OPERACION,
    operacion
  }
};


export const deleteEveryOperacionAction = (id) => {
  return {
    type: types.DELETE_EVERY_OPERACION,
    id
  }
};
export const insertaActualizacionEnOperacionAction = (payload) => { return {  type: types.INSERTA_ACTUALIZACION_EN_OPERACION, payload: payload } };
export const borraActualizacionEnOperacionAction = (payload) => { return {  type: types.BORRA_ACTUALIZACION_EN_OPERACION, payload: payload } };
 


export const getRutasAction = () => {
  return {
    type: types.GET_RUTAS
  }
};


export const ejecutaEnRutaAction = (fn) => {
  return {
    type: types.EJECUTA_EN_RUTA,
    fn
  }
};

export const getRutaAction = (id) => {
  return {
    type: types.GET_RUTA,
    id
  }
};

export const postRutaAction = (ruta) => {
  return {
    type: types.POST_RUTA,
    ruta
  }
};


export const putRutaAction = (ruta) => {
  return {
    type: types.PUT_RUTA,
    ruta
  }
};


export const deleteRutaAction = (id) => {
  return {
    type: types.DELETE_RUTA,
    id
  }
};





export const getEveryRutasAction = () => {
  return {
    type: types.GET_EVERY_RUTAS
  }
};


export const getEveryRutaAction = (id) => {
  return {
    type: types.GET_EVERY_RUTA,
    id
  }
};

export const postEveryRutaAction = (ruta) => {
  return {
    type: types.POST_EVERY_RUTA,
    ruta
  }
};


export const putEveryRutaAction = (ruta) => {
  return {
    type: types.PUT_EVERY_RUTA,
    ruta
  }
};


export const deleteEveryRutaAction = (id) => {
  return {
    type: types.DELETE_EVERY_RUTA,
    id
  }
};
export const insertaRutaRolEnRutaAction = (payload) => { return {  type: types.INSERTA_RUTAROL_EN_RUTA, payload: payload } };
export const borraRutaRolEnRutaAction = (payload) => { return {  type: types.BORRA_RUTAROL_EN_RUTA, payload: payload } };
 


export const getPerfilesAction = () => {
  return {
    type: types.GET_PERFILES
  }
};


export const ejecutaEnPerfilAction = (fn) => {
  return {
    type: types.EJECUTA_EN_PERFIL,
    fn
  }
};

export const getPerfilAction = (id) => {
  return {
    type: types.GET_PERFIL,
    id
  }
};

export const postPerfilAction = (perfil) => {
  return {
    type: types.POST_PERFIL,
    perfil
  }
};


export const putPerfilAction = (perfil) => {
  return {
    type: types.PUT_PERFIL,
    perfil
  }
};


export const deletePerfilAction = (id) => {
  return {
    type: types.DELETE_PERFIL,
    id
  }
};





export const getEveryPerfilesAction = () => {
  return {
    type: types.GET_EVERY_PERFILES
  }
};


export const getEveryPerfilAction = (id) => {
  return {
    type: types.GET_EVERY_PERFIL,
    id
  }
};

export const postEveryPerfilAction = (perfil) => {
  return {
    type: types.POST_EVERY_PERFIL,
    perfil
  }
};


export const putEveryPerfilAction = (perfil) => {
  return {
    type: types.PUT_EVERY_PERFIL,
    perfil
  }
};


export const deleteEveryPerfilAction = (id) => {
  return {
    type: types.DELETE_EVERY_PERFIL,
    id
  }
};
export const insertaUsuarioEnPerfilAction = (payload) => { return {  type: types.INSERTA_USUARIO_EN_PERFIL, payload: payload } };
export const borraUsuarioEnPerfilAction = (payload) => { return {  type: types.BORRA_USUARIO_EN_PERFIL, payload: payload } };
export const insertaRolPerfilEnPerfilAction = (payload) => { return {  type: types.INSERTA_ROLPERFIL_EN_PERFIL, payload: payload } };
export const borraRolPerfilEnPerfilAction = (payload) => { return {  type: types.BORRA_ROLPERFIL_EN_PERFIL, payload: payload } };
 


export const getRutasRolAction = () => {
  return {
    type: types.GET_RUTASROL
  }
};


export const ejecutaEnRutaRolAction = (fn) => {
  return {
    type: types.EJECUTA_EN_RUTAROL,
    fn
  }
};

export const getRutaRolAction = (id) => {
  return {
    type: types.GET_RUTAROL,
    id
  }
};

export const postRutaRolAction = (rutarol) => {
  return {
    type: types.POST_RUTAROL,
    rutarol
  }
};


export const putRutaRolAction = (rutarol) => {
  return {
    type: types.PUT_RUTAROL,
    rutarol
  }
};


export const deleteRutaRolAction = (id) => {
  return {
    type: types.DELETE_RUTAROL,
    id
  }
};





export const getEveryRutasRolAction = () => {
  return {
    type: types.GET_EVERY_RUTASROL
  }
};


export const getEveryRutaRolAction = (id) => {
  return {
    type: types.GET_EVERY_RUTAROL,
    id
  }
};

export const postEveryRutaRolAction = (rutarol) => {
  return {
    type: types.POST_EVERY_RUTAROL,
    rutarol
  }
};


export const putEveryRutaRolAction = (rutarol) => {
  return {
    type: types.PUT_EVERY_RUTAROL,
    rutarol
  }
};


export const deleteEveryRutaRolAction = (id) => {
  return {
    type: types.DELETE_EVERY_RUTAROL,
    id
  }
};
 


export const getRolesAction = () => {
  return {
    type: types.GET_ROLES
  }
};


export const ejecutaEnRolAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ROL,
    fn
  }
};

export const getRolAction = (id) => {
  return {
    type: types.GET_ROL,
    id
  }
};

export const postRolAction = (rol) => {
  return {
    type: types.POST_ROL,
    rol
  }
};


export const putRolAction = (rol) => {
  return {
    type: types.PUT_ROL,
    rol
  }
};


export const deleteRolAction = (id) => {
  return {
    type: types.DELETE_ROL,
    id
  }
};





export const getEveryRolesAction = () => {
  return {
    type: types.GET_EVERY_ROLES
  }
};


export const getEveryRolAction = (id) => {
  return {
    type: types.GET_EVERY_ROL,
    id
  }
};

export const postEveryRolAction = (rol) => {
  return {
    type: types.POST_EVERY_ROL,
    rol
  }
};


export const putEveryRolAction = (rol) => {
  return {
    type: types.PUT_EVERY_ROL,
    rol
  }
};


export const deleteEveryRolAction = (id) => {
  return {
    type: types.DELETE_EVERY_ROL,
    id
  }
};
export const insertaRutaRolEnRolAction = (payload) => { return {  type: types.INSERTA_RUTAROL_EN_ROL, payload: payload } };
export const borraRutaRolEnRolAction = (payload) => { return {  type: types.BORRA_RUTAROL_EN_ROL, payload: payload } };
export const insertaRolPerfilEnRolAction = (payload) => { return {  type: types.INSERTA_ROLPERFIL_EN_ROL, payload: payload } };
export const borraRolPerfilEnRolAction = (payload) => { return {  type: types.BORRA_ROLPERFIL_EN_ROL, payload: payload } };
 


export const getRolesPerfilAction = () => {
  return {
    type: types.GET_ROLESPERFIL
  }
};


export const ejecutaEnRolPerfilAction = (fn) => {
  return {
    type: types.EJECUTA_EN_ROLPERFIL,
    fn
  }
};

export const getRolPerfilAction = (id) => {
  return {
    type: types.GET_ROLPERFIL,
    id
  }
};

export const postRolPerfilAction = (rolperfil) => {
  return {
    type: types.POST_ROLPERFIL,
    rolperfil
  }
};


export const putRolPerfilAction = (rolperfil) => {
  return {
    type: types.PUT_ROLPERFIL,
    rolperfil
  }
};


export const deleteRolPerfilAction = (id) => {
  return {
    type: types.DELETE_ROLPERFIL,
    id
  }
};





export const getEveryRolesPerfilAction = () => {
  return {
    type: types.GET_EVERY_ROLESPERFIL
  }
};


export const getEveryRolPerfilAction = (id) => {
  return {
    type: types.GET_EVERY_ROLPERFIL,
    id
  }
};

export const postEveryRolPerfilAction = (rolperfil) => {
  return {
    type: types.POST_EVERY_ROLPERFIL,
    rolperfil
  }
};


export const putEveryRolPerfilAction = (rolperfil) => {
  return {
    type: types.PUT_EVERY_ROLPERFIL,
    rolperfil
  }
};


export const deleteEveryRolPerfilAction = (id) => {
  return {
    type: types.DELETE_EVERY_ROLPERFIL,
    id
  }
};

