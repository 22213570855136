import { put, call } from 'redux-saga/effects';
import {getArchivoPolizaService
} from '../../services/archivopoliza/archivopolizaservices';


import * as types from '../../actions/archivopoliza/types'


export function* getArchivoPolizaSaga(payload) {
    try {
      let response = yield call(getArchivoPolizaService, payload.id);
      if (response.error !== undefined){
        response = {...response, success:false, message:response.error}
      }
      else{
        response = {...response, success:true, message:''}
      }
      const accion = { type: types.GET_ARCHIVPOLIZA_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_ARCHIVPOLIZA_ERROR, response: error };
      yield put(accion);
    }
  }
  