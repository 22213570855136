import React, { Component } from 'react';
import { connect } from 'react-redux';

//import LoadingComponent from '../commons/loading/loadingComponent'

import HeaderComponent from '../../components/commons/header/headerComponent';

import PagosOxxoViewFiles from './cargapagosoxxoViewFiles';
import PagosOxxoViewPagos from './cargapagosoxxoViewPagos';

import { getCargaPagosOxxoAction, getCargaPagosOxxoDetalleAction } from '../../store/actions/cargapagosoxxo/actions';

//import {getPagosOxxoAction} from '../../store/actions/pagosoxxo/actions'


class PagosOxxoView extends Component {
 state={}



 onPagoSelectClick = (event, id_pagooxxo) =>{
  event.preventDefault();
  this.props.dispatch(getCargaPagosOxxoDetalleAction(id_pagooxxo));
}

 handleChange(event) {
  let name = event.target.name;
  let params = this.state.params;
  params[name]= event.target.value;
  this.setState({
    params: params
  });
  this.props.dispatch(getCargaPagosOxxoAction(this.state.params.fechainicio, this.state.params.fechatermino));
}


  componentDidMount() {
    console.log('componentDidMount', this.state);
    if (!this.state.cargado){
      this.props.dispatch(getCargaPagosOxxoAction( this.state.params.fechainicio, this.state.params.fechatermino));
    }
    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    
  return {
      ...nextProps
    };
}
    render() {
        console.log('Estatdo',this.state);
        return (
            <div>

                <HeaderComponent />    
                <div className='row'>
                  <div className='col-6'>
                      <PagosOxxoViewFiles 
                        params={this.state.params}
                        list={this.state.listapagos}
                        handleChange={this.handleChange.bind(this)}
                        onPagoSelectClick={this.onPagoSelectClick} />
                  </div>
                  <div className='col-6'>
                      <PagosOxxoViewPagos
                        params={this.state.params}
                        list={this.state.listadetalle} />
                  </div>
              </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => {
  console.log('mapStateToProps PagosOxxo', state.cargapagosoxxoReducer);
    return state.cargapagosoxxoReducer;
} 
  

export default connect(mapStateToProps)(PagosOxxoView);