import { put, call } from 'redux-saga/effects';
import {
  getDashboardCertificadosService
} from '../../services/dashboards/dashboardsServices';

import * as types from '../../actions/dashboards/types'



  export function* getDashboardCertificadosSaga(payload) {
    try {
      
      let response = yield call(getDashboardCertificadosService);
      if (response.error !== undefined){
        response = {...response, success:false, message:response.error}
        const accion = { type: types.GET_DASHBOARD_CERTIFICADOS_ERROR, response };
        yield put(accion);
      }
      else{
        console.log('CORRECTO....', response);
        response = {...response, success:true, message:''}
        const accion = { type: types.GET_DASHBOARD_CERTIFICADOS_SUCCESS, response };
        yield put(accion);
      }
  
    } catch(error) {
    }
  }