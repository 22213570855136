import * as types from './types';


export const getBuscarAction = (data) => {
  return {
    type: types.GET_BUSCAR,
    data
  }
};

export const getBuscarContratanteAction = (data) => {
  return {
    type: types.GET_BUSCARCONTRATANTE,
    data
  }
};
