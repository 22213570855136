import * as types from './types';

export const asignaDPsPolizaAction = (id) => {
  return {
    type: types.ASIGNA_DPS_POLIZA,
    id
  }
};

export const asignaCertificadosPolizaAction = (id) => {
  return {
    type: types.ASIGNA_CERTIFICADOS_POLIZA,
    id
  }
};

export const agregarRenovacionesPolizaAction = (id) => {
  return {
    type: types.AGREGAR_RENOVACIONES_POLIZA,
    id
  }
};

export const abrirPolizaAction = (id) => {
  return {
    type: types.ABRIR_POLIZA,
    id
  }
};

export const cerrarPolizaAction = (id) => {
  return {
    type: types.CERRAR_POLIZA,
    id
  }
};

export const borrarPolizaAction = (id) => {
  return {
    type: types.BORRAR_POLIZA,
    id
  }
};


export const abrirCertificadoPolizaAction = (id) => {
  return {
    type: types.ABRIR_CERTIFICADOPOLIZA,
    id
  }
};

export const cerrarCertificadoPolizaAction = (id) => {
  return {
    type: types.CERRAR_CERTIFICADOPOLIZA,
    id
  }
};

export const cancelarCertificadoPolizaAction = (id, id_motivocancelacion) => {
  return {
    type: types.CANCELAR_CERTIFICADOPOLIZA,
    id,
    id_motivocancelacion
  }
};

export const activarCertificadoPolizaAction = (id) => {
  return {
    type: types.ACTIVAR_CERTIFICADOPOLIZA,
    id
  }
};

export const borrarCertificadoPolizaAction = (id) => {
  return {
    type: types.BORRAR_CERTIFICADOPOLIZA,
    id
  }
};

export const borrarCertificadoPolizaActionRESET = () => {
  return {
    type: types.BORRAR_CERTIFICADOPOLIZA_RESET,
    data:{}
  }
};

export const seprarAseguradoCertificadoPolizaAction = (id_certificadopoliza, id_aseguradocertificadopoliza) => {
  return {
    type: types.SEPARAR_ASEGURADOCERTIFICADOPOLIZA,
    id_certificadopoliza,
    id_aseguradocertificadopoliza
  }
};

export const enviarAseguradoCertificadoPolizaAction = (id_certificadopoliza, id_aseguradocertificadopoliza, certificado) => {
  return {
    type: types.ENVIAR_ASEGURADOCERTIFICADOPOLIZA,
    payload: {id_certificadopoliza,
    id_aseguradocertificadopoliza,
    certificado}
  }
};