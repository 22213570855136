import { put, call } from 'redux-saga/effects';
import {
    getBuscarService, getBuscarContratantesService,
} from '../../services/buscar/buscarServices';

import * as types from '../../actions/buscar/types'

export function* getSBuscarSaga(payload) {
  try {
    const response = yield call(getBuscarService, payload);
    const accion = { type: types.GET_BUSCAR_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_BUSCAR_ERROR, error };
    yield put(accion);
  }
}
export function* getSBuscarContratantesSaga(payload) {
  try {
    const response = yield call(getBuscarContratantesService, payload);
    const accion = { type: types.GET_BUSCARCONTRATANTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_BUSCARCONTRATANTE_ERROR, error };
    yield put(accion);
  }
}
