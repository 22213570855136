import React, { Component } from 'react';
import {connect} from 'react-redux'
import Header from "../header/headerComponent"
import CertificadosView from "./certificadosView"
class BuscarComponent extends Component {

    state = {
        listacertificados: undefined,
        
    }

    constructor(props){
        super(props);
        this.setState = { listacertificados: this.props.response.buscar.listacertificados }
    }


  static getDerivedStateFromProps(nextProps, prevState) {
      console.log('getDerived', nextProps);
      if (nextProps.response.buscar.listacertificados === null) {
          return {
            listacertificados: []
          }
      }
    return {
        listacertificados: nextProps.response.buscar.listacertificados
      };
  }

    render() {
        console.log('Lista para render', this.state.listacertificados);
        return (
            <React.Fragment>
               
            <Header />
            

            
            <CertificadosView
                  list={this.state.listacertificados} />   
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    response: state
  });

export default connect(mapStateToProps)(BuscarComponent);