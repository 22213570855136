import React from 'react';
import { Link } from 'react-router-dom';
import ReactToExcel from "react-html-table-to-excel";
import FieldHeader from '../../../components/fieldheader'
const ActualizaPolizaViewListaCertificados = (props) => {
  if (props.list === undefined) {
    return null;
  }

  return (
    <div className='pl-3 pt-3'>
       
      <div>
        <div className='row py-3'>
            <div className=" col-md-2">
              <ReactToExcel 
                table="tabla-certificados" 
                filename="certificados"
                sheet='Certificados'
                buttonText="Exportar"
                />
            </div>
        </div>
      </div>

      <div className='table-responsive'>
        <table id="tabla-certificados" className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>
              <th><FieldHeader field={'polizas.poliza'} /></th>
              <th><FieldHeader field={'polizas.fechainicio'} /></th>
              <th><FieldHeader field={'certificadospoliza.certificado'} /></th>
              <th><FieldHeader field={'certificadospoliza.aseguradotitular'} /></th>
              <th><FieldHeader field={'certificadospoliza.contratante'} /></th>
              <th><FieldHeader field={'certificadospoliza.antiguedad'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_producto'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_agente'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_origen'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_formapago'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_estatuscertificado'} /></th>
            </tr>
          </thead>
          <tbody>
            {props.list.sort((a, b) => a.certificado - b.certificado).map(li => (  
              <tr key={li.id_certificadopoliza}>
                <td>{li.poliza}</td>
                <td className='uppercase'>{li.fechainicio.substring(0,10)}</td>
                <td><Link className='name uppercase' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.certificado}</Link></td>
                <td><Link className='name' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.aseguradotitular}</Link></td>
                <td className='uppercase'>{li.contratante}</td>
                <td>
                  {li.antiguedad.substring(0,10)}
                </td>
                <td className='uppercase'>
                    {li.producto}
                </td>
                <td className='uppercase'>
                    {li.agente}
                </td>
                <td className='uppercase'>
                    {li.origen}
                </td>
                <td className='uppercase'>
                    {li.formapago}
                </td>
                <td className='uppercase'>
                    {li.estatuscertificado}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default ActualizaPolizaViewListaCertificados;