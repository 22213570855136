import React, {Component} from 'react'
import { connect } from 'react-redux';
import Llaves from '../../assets/db/llaves.json'



class LookUp extends Component{
    state={ 
        value:'...',
    }


    componentDidMount() {
        if (!this.props.state.cargado) {

            const get = 'GET_' + (this.props.table.toUpperCase())
            const action = {type: get} 
//            console.log('DidMount', action);
              this.props.dispatch(action );
         }
  }
        
    
  static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.state.cargado && prevState.value==='...' ) {
            const val = nextProps.lista
                .filter(a=> { return a[nextProps.llave]===nextProps.value} )
                .map(elem => {return elem[nextProps.descripcion]})
            if (val) {
                return {value:val}
            } else {
                return {value:'---'}
            }
        }
        else{
            return null;
        }
    }

    render() {
        const {className} = this.props

        return (
            <div className={className}> 
                {this.state.value}
            </div>
        )
    }

}




const mapStateToProps = (state, ownProps) => {
    const tablacampo = ownProps.field.split('.')
    const  name = tablacampo[1]

    const table = Llaves[name].table
    const llave = Llaves[name].campollave
    const descripcion = Llaves[name].campodescripcion 
    const _reducer = table + 'Reducer'

    //console.log('mapStateToProps ', state[_reducer]);

    return {
            name: name, 
            table: table, 
            llave:llave, 
            descripcion:descripcion, 
            lista:state[_reducer][table].lista,  
            state: state[_reducer]}
}


export default connect( mapStateToProps  )( LookUp );





