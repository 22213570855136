export const GET_COMISIONES_AGENTES = 'GET_COMISIONES_AGENTES';
export const GET_COMISIONES_AGENTES_SUCCESS = 'GET_COMISIONES_AGENTES_SUCCESS';
export const GET_COMISIONES_AGENTES_ERROR = 'GET_COMISIONES_AGENTES_ERROR';


export const GET_COMISIONES_AGENTE = 'GET_COMISIONES_AGENTE';
export const GET_COMISIONES_AGENTE_SUCCESS = 'GET_COMISIONES_AGENTE_SUCCESS';
export const GET_COMISIONES_AGENTE_ERROR = 'GET_COMISIONES_AGENTE_ERROR';

export const GET_COMISIONES_AGENTE_FACTURAS = 'GET_COMISIONES_AGENTE_FACTURAS';
export const GET_COMISIONES_AGENTE_FACTURAS_SUCCESS = 'GET_COMISIONES_AGENTE_FACTURAS_SUCCESS';
export const GET_COMISIONES_AGENTE_FACTURAS_ERROR = 'GET_COMISIONES_AGENTE_FACTURAS_ERROR';

export const GET_COMISIONES_AGENTE_DPSPENDIENTES = 'GET_COMISIONES_AGENTE_DPSPENDIENTES';
export const GET_COMISIONES_AGENTE_DPSPENDIENTES_SUCCESS = 'GET_COMISIONES_AGENTE_DPSPENDIENTES_SUCCESS';
export const GET_COMISIONES_AGENTE_DPSPENDIENTES_ERROR = 'GET_COMISIONES_AGENTE_DPSPENDIENTES_ERROR';

export const GET_COMISIONES_FACTURA_DPS = 'GET_COMISIONES_FACTURA_DPS';
export const GET_COMISIONES_FACTURA_DPS_SUCCESS = 'GET_COMISIONES_FACTURA_DPS_SUCCESS';
export const GET_COMISIONES_FACTURA_DPS_ERROR = 'GET_COMISIONES_FACTURA_DPS_ERROR';


export const POST_COMISIONES_ASIGNACION = 'POST_COMISIONES_ASIGNACION';
export const POST_COMISIONES_ASIGNACION_SUCCESS = 'POST_COMISIONES_ASIGNACION_SUCCESS';
export const POST_COMISIONES_ASIGNACION_ERROR = 'POST_COMISIONES_ASIGNACION_ERROR';

export const DELETE_COMISIONES_ASIGNACION = 'DELETE_COMISIONES_ASIGNACION';
export const DELETE_COMISIONES_ASIGNACION_SUCCESS = 'DELETE_COMISIONES_ASIGNACION_SUCCESS';
export const DELETE_COMISIONES_ASIGNACION_ERROR = 'DELETE_COMISIONES_ASIGNACION_ERROR';

export const COMISIONES_FACTURA_CLOSE= 'COMISIONES_FACTURA_CLOSE';
export const COMISIONES_FACTURA_CREAR = 'COMISIONES_FACTURA_CREAR';
export const COMISIONES_FACTURA_EDITAR = 'COMISIONES_FACTURA_EDITAR';

export const POST_COMISIONES_FACTURAAGENTE = 'POST_COMISIONES_FACTURAAGENTE';
export const PUT_COMISIONES_FACTURAAGENTE = 'PUT_COMISIONES_FACTURAAGENTE';
export const DELETE_COMISIONES_FACTURAAGENTE = 'DELETE_COMISIONES_FACTURAAGENTE';