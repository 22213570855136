import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderComponent from '../../components/commons/header/headerComponent';
import LoadingComponent from '../../components/commons/loading/loadingwithheaderComponent'

import DashboardVentasView from './dashboardVentasView';
import DashboardAgentesViewCertificados from './dashboardAgenteViewCertificados' 
import {  getCookie } from '../../utils/cookies';


import { getDashboardVentasAction, getDashboardCertificadosAction } from '../../store/actions/dashboards/actions';


class DashboardAgenteComponent extends Component {

    state = {
      }


  componentDidMount() {
    console.log('componentDidMount', this.state);
      let anio = parseInt(this.props.match.params.anio,10);
      let mes = parseInt(this.props.match.params.mes,10);
      if (isNaN(anio)) {
         mes = new Date().getMonth()+1;
         anio = new Date().getFullYear();
        }

        if (getCookie("role")==="2") {
          if (!this.state.cargado || this.state.anio !== anio || this.state.mes !== mes){
            this.props.dispatch(getDashboardVentasAction({ anio: anio, mes:mes }));
            this.props.dispatch(getDashboardCertificadosAction());
          }

        }
  }



  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerived', nextProps);
    return nextProps;
}


    render() {
        if (getCookie("role")==="1")
          return(
            <HeaderComponent />
          )
        console.log('ESTADO...', this.state);
        if (this.state.dash_ventas.cargado === false) {
          return (
            <LoadingComponent />
            )
        }

        var lista = this.state.dash_ventas.ventas;
        var obj2 = this.state.dash_ventas.ventas;
        var order = 'asc';
        var mapped = [];
        mapped = lista.map(function(el, i) {
          return { index: i, value: el.agente.toLowerCase() };
          })
        mapped.sort(function(a, b) {
          if ((a.value < b.value && order === 'desc') || (a.value > b.value && order === 'asc')) { return 1; }
          if ((a.value > b.value && order === 'desc') || (a.value < b.value && order === 'asc')) { return -1; }
          return 0;
          });
        obj2 = mapped.map(function(el){
          return lista[el.index];
          });

        var certs = this.state.dash_certificados.certificados;
        var certificados = certs.reduce(function(groups, currentValue) {
          if ( groups.indexOf(currentValue.referencia) === -1 ) {
            groups.push(currentValue.referencia);
          }
          return groups;
            }, []).map(function(group) {
                return {
                    referencia: group,
                    certificados: certs.filter(function(_el) {
                      return _el.referencia === group;
                    }).map(function(_el) { return _el; })
                }
            });
            var i = 0
            for (i = 0; i < certificados.length; i++) {
              var importe = 0.00
              var cant = 0
              var j=0
              for (j = 0; j < certificados[i].certificados.length; j++) {
                  const x = certificados[i].certificados[j]
                  importe += x.totalsiguientepago;
                  ++cant;
              }
              certificados[i].cantidad = cant
              certificados[i].importe= importe
            }
        console.log('Estatdo',this.state);
        return (
            <div>
              <HeaderComponent />
              <div className='row'>
                  <div className='col-12'>
                      <DashboardVentasView 
                        dashboard='Agente'
                        params={this.state.dash_ventas.paramspendientes}
                        cargado = {this.state.dash_ventas.cargado}
                        meses= {this.state.dash_ventas.meses}
                        anio = {this.state.dash_ventas.anio}
                        mes = {this.state.dash_ventas.mes}
                        periodo = {this.state.dash_ventas.periodo}
                        list={obj2} />
                  </div>
              </div>

              <div className='row'>
                  <div className='col-12'>
                      <DashboardAgentesViewCertificados 
                        dashboard='Agente'
                        params={this.state.dash_ventas.paramspendientes}
                        cargado = {this.state.dash_ventas.cargado}
                        list={certificados} />
                  </div>
              </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => {

    console.log('mapStateToProps call',state);
    return (
    {
        login:state.login.response,
        dash_ventas: state.dashboardsReducer.dashventas,
        dash_certificados: state.dashboardsReducer.dashcertificados
  });
  
} 
  

export default connect(mapStateToProps)(DashboardAgenteComponent);