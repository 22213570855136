import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    tipoproducto: {
 id_tipoproducto : 0, tipoproducto : '', primasporproducto : false, primaporasegurado : false, recibologosupizq : '', recibologoinfder : '', recibologoinfizq : '', primaporedad : false,
      },

      lista_producto_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_TIPOPRODUCTO:
      item = state.tipoproducto;
      action.fn(item);
      return { ...state,  tipoproducto: item };


    case types.GET_TIPOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('GET_TIPOPRODUCTO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', tipoproducto: respuesta };

    case types.GET_TIPOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('GET_TIPOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_TIPOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('POST_TIPOPRODUCTO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', tipoproducto: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_TIPOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('POST_TIPOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_TIPOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_TIPOPRODUCTO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', tipoproducto: respuesta };

    case types.PUT_TIPOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('PUT_TIPOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_TIPOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_TIPOPRODUCTO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_TIPOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('DELETE_TIPOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_PRODUCTO_EN_TIPOPRODUCTO:
      item = state.tipoproducto;
      item.productos= [...item.productos, action.payload]
      return { ...state, producto:item};


    case types.BORRA_PRODUCTO_EN_TIPOPRODUCTO:
      item = state.tipoproducto;
      let lista_producto_borrados= state.lista_producto_borrados
      if (item.productos[action.payload].id_producto > 0){
            const elementoproductoborrar = { id_producto : item.productos[action.payload].id_producto }
            lista_producto_borrados= [...lista_producto_borrados,  elementoproductoborrar]
      }
      item.productos.splice(action.payload,1)
      return { ...state, producto:item, lista_producto_borrados : lista_producto_borrados};


    default:
      return state;
  }
};

