import { takeEvery } from 'redux-saga/effects';
import * as types from '../../actions/uploadfile/types';

 import {   uploadFileSaga } from './uploadfilesagas' ;

export default function* watchUploadFile() {

    yield takeEvery (types.UPLOAD_REQUEST, uploadFileSaga);

}
