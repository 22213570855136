import * as types from '../../actions/db/types';

let respuesta={}

const estadoinicial={
    cargado: false,
    message: '',
    primasproductopoliza: [],
    data: {
      //id: getCookie('id'),
      //access: getCookie('role'),
      order: 'desc',
      sortBy: 'fecha'
    }
}

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  

 switch(action.type) {

    case types.GET_PRIMASPRODUCTOPOLIZA_SUCCESS:
      respuesta= action.response;
      return { ...state, cargado:true, message:'', primasproductopoliza: respuesta };

    case types.GET_PRIMASPRODUCTOPOLIZA_ERROR:
      respuesta= action.response;
      console.log('GET_PRIMASPRODUCTOPOLIZA_ERROR', respuesta);
      return { ...state,  message:respuesta };


    default:
      return state;
  }
};

