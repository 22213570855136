import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    agente: {
 id_agente : 0, agente : '', rfc : '', id_clavecomision : 0, id_regimenfiscal : 0, porciva : 0, porcretiva : 0, porcretisr : 0,
      },

      lista_certificadopoliza_borrados : [],
      lista_facturaagente_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_AGENTE:
      item = state.agente;
      action.fn(item);
      return { ...state,  agente: item };


    case types.GET_AGENTE_SUCCESS:
      respuesta= action.response;
      console.log('GET_AGENTE_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', agente: respuesta };

    case types.GET_AGENTE_ERROR:
      respuesta= action.response;
      console.log('GET_AGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_AGENTE_SUCCESS:
      respuesta= action.response;
      console.log('POST_AGENTE_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', agente: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_AGENTE_ERROR:
      respuesta= action.response;
      console.log('POST_AGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_AGENTE_SUCCESS:
      respuesta= action.response;
      console.log('PUT_AGENTE_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', agente: respuesta };

    case types.PUT_AGENTE_ERROR:
      respuesta= action.response;
      console.log('PUT_AGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_AGENTE_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_AGENTE_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_AGENTE_ERROR:
      respuesta= action.response;
      console.log('DELETE_AGENTE_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_CERTIFICADOPOLIZA_EN_AGENTE:
      item = state.agente;
      item.certificadospoliza= [...item.certificadospoliza, action.payload]
      return { ...state, certificadopoliza:item};


    case types.BORRA_CERTIFICADOPOLIZA_EN_AGENTE:
      item = state.agente;
      let lista_certificadopoliza_borrados= state.lista_certificadopoliza_borrados
      if (item.certificadospoliza[action.payload].id_certificadopoliza > 0){
            const elementocertificadopolizaborrar = { id_certificadopoliza : item.certificadospoliza[action.payload].id_certificadopoliza }
            lista_certificadopoliza_borrados= [...lista_certificadopoliza_borrados,  elementocertificadopolizaborrar]
      }
      item.certificadospoliza.splice(action.payload,1)
      return { ...state, certificadopoliza:item, lista_certificadopoliza_borrados : lista_certificadopoliza_borrados};

 
    case types.INSERTA_FACTURAAGENTE_EN_AGENTE:
      item = state.agente;
      item.facturasagente= [...item.facturasagente, action.payload]
      return { ...state, facturaagente:item};


    case types.BORRA_FACTURAAGENTE_EN_AGENTE:
      item = state.agente;
      let lista_facturaagente_borrados= state.lista_facturaagente_borrados
      if (item.facturasagente[action.payload].id_facturaagente > 0){
            const elementofacturaagenteborrar = { id_facturaagente : item.facturasagente[action.payload].id_facturaagente }
            lista_facturaagente_borrados= [...lista_facturaagente_borrados,  elementofacturaagenteborrar]
      }
      item.facturasagente.splice(action.payload,1)
      return { ...state, facturaagente:item, lista_facturaagente_borrados : lista_facturaagente_borrados};


    default:
      return state;
  }
};

