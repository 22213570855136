import React from 'react';
import ReactToExcel from "react-html-table-to-excel";
import {Link} from 'react-router-dom'
import FieldHeader from '../../components/fieldheader'

import InputField from '../../components/inputfield'

const ConciliaPagosViewPagado = (props) => {
  if (props.list === undefined) {
    return null;
  }
  
  return (
    <div className='pl-3 pt-3'>
       
      <div>
        <div className='row py-3'>
            <div className=" col-md-2">
              <ReactToExcel 
                table="tabla-certificados" 
                filename="certificados"
                sheet='Certificados'
                buttonText="export"
                />
            </div>
        </div>
      </div>
      <div className='row'>
        <InputField className='col-md-3' type='date' field='dpscertificadopoliza.fecha' value={props.params.fecha} onChange={props.handleChange} />
        <InputField type='combobox' className='col-md-3' 
                    field='dpscertificadopoliza.id_mediopago' 
                    value={props.params.id_mediopago}
                    onChange={props.handleChange}/>
      </div>
      <div className='table-responsive'>
        <table id="tabla-certificados" className='table table-bordered'>
          <thead className='bg-secondary'>
            <tr>

              <th><FieldHeader field={'certificadospoliza.id_poliza'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.fecha'} /></th>
              <th><FieldHeader field={'certificadospoliza.certificado'} /></th>
              <th><FieldHeader field={'certificadospoliza.contratante'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_producto'} /></th>
              <th><FieldHeader field={'certificadospoliza.id_formapago'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.total'} /></th>
              <th><FieldHeader field={'.accion'} /></th>
            </tr>
          </thead>
          <tbody>
            {props.list
              .sort((a, b) => a.certificado -  b.certificado)
              .sort((a, b) => a.id_poliza  -  b.id_poliza )
              .map(li => (  
              <tr key={li.id_dpcertificadopoliza}>
              <td>
                    {li.poliza}
              </td>
                <td className='uppercase'>
                    {li.fecha.substring(0,10)}
                </td>
                <td>
                    {li.certificado}
                </td>
                <td>
                  <Link className='name' to={`/certificado/actualizar/${li.id_certificadopoliza}`}>{li.contratante}</Link>
  
                </td>
                <td className='uppercase'>
                    {li.producto}
                </td>
                <td className='uppercase'>
                    {li.formapago}
                </td>
                <td className='uppercase'>
                    {li.total}
                </td>
                <td >
                  <button className="btn btn-outline-dark align-bottom mt-4" 
                        onClick={(e) => props.onCancelarClick(e, li.id_dpcertificadopoliza)}>
                        <i className="fa fa-times"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default ConciliaPagosViewPagado;