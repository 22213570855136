import React from 'react';
import Lookup from '../../components/lookups'
import './polizas.css';
import FieldHeader from '../../components/fieldheader'


const ActualizaPolizaViewDPs = (props) => {
  if (props.list === undefined) {
    return (
        <div className='pt-3'>

        <div className='row py-3'>
            <div className=" col-md-3">
              <button className="btn btn-primary btn-sm align-bottom mt-4 " onClick={props.onAsignaClick}>
                Asigna Documentos de Pago</button>
            </div>
        </div>

      <div className='table-responsive'>
        <table className='table-sm table-bordered'>
          <thead className='bg-secondary'>
            <tr>
              <th><FieldHeader field={'dpscertificadopoliza.id_producto'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.fecha'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.secuencia'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.descripcion'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.primaneta'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.cargopagofraccionado'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.derechodepoliza'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.total'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.cargoalagentepornopago'} /></th>
              {/* <th><FieldHeader field={'dpscertificadopoliza.comisionagente'} /></th> */}
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
      </div>
    );
  }

  return (
    <div className='pt-3'>


      <div className='table-responsive'>
        <table className='table-sm table-bordered'>
          <thead className='bg-secondary'>
            <tr>
              <th><FieldHeader field={'dpscertificadopoliza.id_producto'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.fecha'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.secuencia'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.descripcion'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.primaneta'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.cargopagofraccionado'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.derechodepoliza'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.total'} /></th>
              <th><FieldHeader field={'dpscertificadopoliza.cargoalagentepornopago'} /></th>
              {/* <th><FieldHeader field={'dpscertificadopoliza.comisionagente'} /></th> */}
            </tr>
          </thead>
          <tbody>
            {props.list.map((li,index) => (  
              <tr key={li.id_dpproductopoliza}>
                <td >
                  <Lookup field='dpsproductopoliza.id_producto' value = {li.id_producto} />
                </td>
                <td >
                  {li.fecha.substring(0,10)}
                </td>
                <td >
                  {li.secuencia}
                </td>
                <td >
                  {li.descripcion}
                </td>
                <td >
                  {li.primaneta}
                </td>
                <td >
                  {li.cargopagofraccionado}
                </td>
                <td >
                  {li.derechodepoliza}
                </td>
                <td >
                  {li.total}
                </td>
                <td >
                  {li.cargoalagentepornopago}
                </td>
                {/* <td >
                  {li.comisionagente}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default ActualizaPolizaViewDPs;