import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    usuario: {
 id_usuario : 0, usuario : '', password : '', nombre : '', esadministrador : false, esagente : false, telefono : '', rfc : '', id_perfil : 0, id_clavecomision : 0, id_regimenfiscal : 0,
      },

      lista_actualizacion_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_USUARIO:
      item = state.usuario;
      action.fn(item);
      return { ...state,  usuario: item };


    case types.GET_USUARIO_SUCCESS:
      respuesta= action.response;
      console.log('GET_USUARIO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', usuario: respuesta };

    case types.GET_USUARIO_ERROR:
      respuesta= action.response;
      console.log('GET_USUARIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_USUARIO_SUCCESS:
      respuesta= action.response;
      console.log('POST_USUARIO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', usuario: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_USUARIO_ERROR:
      respuesta= action.response;
      console.log('POST_USUARIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_USUARIO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_USUARIO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', usuario: respuesta };

    case types.PUT_USUARIO_ERROR:
      respuesta= action.response;
      console.log('PUT_USUARIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_USUARIO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_USUARIO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_USUARIO_ERROR:
      respuesta= action.response;
      console.log('DELETE_USUARIO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_ACTUALIZACION_EN_USUARIO:
      item = state.usuario;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_USUARIO:
      item = state.usuario;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};


    default:
      return state;
  }
};

