import React from 'react';

import Lookup from '../../components/lookups'
import InputField from '../../components/inputfield'

const ActualizaCertificadoPolizaViewDPs = (props) => {
  if (props.list === undefined) {
    return (
        <div className='pt-3'>

      </div>
    );
  }

  return (
    <div className='pl-3 pt-3 mb-5 pb-5'>

            {props.list.map((li,index) => (  
              <div key={li.id_dpcertificadopoliza} className='row pb-2 mb-2 border-bottom border-primary'>
                <div className='col-2'>
                  {li.fecha.substring(0,10)}
                </div>
                <div className='col-6'>
                  <div className='row'>

                  <div className='col-3'>
                   Concepto:
                  </div>
                  <div className='col-9'>
                   {li.secuencia} - {li.descripcion}
                  </div>

                  <div className='col-3'>
                   Prima:
                  </div>
                  <div className='col-9'>
                   {li.primaneta}
                  </div>

                  <div className='col-3'>
                   Cargo:
                  </div>
                  <div className='col-9'>
                   {li.cargopagofraccionado}
                  </div>

                  <div className='col-3'>
                   Derecho:
                  </div>
                  <div className='col-9'>
                   {li.derechodepoliza}
                  </div>


                  </div>
                </div>
                <div className='col-2'>
                  {
                    (props.certificadopoliza.id_estatuscertificado ===1 && li.id_estatuspago===1 && li.esasignadoporproducto===false  )
                    ?<InputField className='col-md-12' type='number' step='0.01' field="dpscertificadopoliza.total"  value={li.total} onChange={(e) => props.handleChange(e,index)}  />
                    :<label>{li.total}</label>
                  }
                  
                    
                </div>
                <div className='col-2'>
                  {(li.id_estatuspago!==1)
                    ?<div >
                      <div className='row'>
                        <Lookup field='dpscertificadopoliza.id_estatuspago' value = {li.id_estatuspago} />
                      </div>
                      <div className='row'>
                        <Lookup field='dpscertificadopoliza.id_mediopago' value = {li.id_mediopago} />
                      </div>
                      <div className='row'>
                        { (li.id_estatuspago>1) ? li.fechapago.substring(0,10):'' }
                      </div>

                    </div>
                    :''
                  }

                </div>
              </div>
            ))}
      </div>
  );
};

export default ActualizaCertificadoPolizaViewDPs;