import * as types from '../../actions/procesos/types';


const estadoinicial = {}

export default function(state = estadoinicial, action) {

  switch(action.type) {

    case types.ASIGNA_DPS_POLIZA_SUCCESS:
      return { ...state, estadoinicial };

    case types.ASIGNA_DPS_POLIZA_ERROR:
        return { ...state, estadoinicial };

    default:
      return state;
  }
};

