import { put, call } from 'redux-saga/effects';
import {
    getCargaPagosOxxoService,
    getCargaPagosOxxoDetalleService
} from '../../services/cargapagosoxxo/cargapagosoxxoServices';


import * as types from '../../actions/cargapagosoxxo/types'


export function* getCargaPagosOxxoSaga(payload) {
    try {
      const response = yield call(getCargaPagosOxxoService, payload);
      console.log('Saga response', response);
      const accion = { type: types.GET_CARGAPAGOSOXXO_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_CARGAPAGOSOXXO_ERROR, error };
      yield put(accion);
    }
  }


  export function* getCargaPagosOxxoDetalleSaga(payload) {
    try {
      const response = yield call(getCargaPagosOxxoDetalleService, payload);
      const accion = { type: types.GET_CARGAPAGOSOXXODETALLE_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_CARGAPAGOSOXXODETALLE_ERROR, error };
      yield put(accion);
    }
  }
