import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {IntlProvider} from 'react-intl'
//import './index.css';
import './custom.scss'
//import './components/commons/assets/custombootstrap.css'
import App from '././container/App';
import * as serviceWorker from './serviceWorker';
import messages from './assets/intl-messges'
import configureStore from './store/configureStore';

const store = configureStore();
// ejemplo de https://github.com/timeoffthescheduler/app


//const localeIntl = 'es-MX'
const localeIntl = 'en-US'

  ReactDOM.render(
      <Provider store={store}>
        <IntlProvider locale={localeIntl} messages={messages[localeIntl]}>
          <App />
        </IntlProvider>
      </Provider>
      , document.getElementById('root')
      );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
