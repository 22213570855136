import { put, call } from 'redux-saga/effects';
import {
    getbaseURLService, postbaseURLService, deletebaseURLService, putbaseURLService
} from '../../services/baseURLservices/baseURLservices';

import * as types from '../../actions/comisiones/types'






export function* getComisionesAgentesSaga(payload) {
    try {
      const response = yield call(getbaseURLService, 'comisiones/agentes', payload);
      const accion = { type: types.GET_COMISIONES_AGENTES_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTES_ERROR, error };
      yield put(accion);
    }
  }
  


  export function* getComisionesAgenteSaga(payload) {
    try {
      const response = yield call(getbaseURLService, 'comisiones/agente/info', payload);
      const accion = { type: types.GET_COMISIONES_AGENTE_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTE_ERROR, error };
      yield put(accion);
    }
  }

  
  export function* deleteComisionesFacturaAgenteSaga(payload) {
    try {
      yield call(deletebaseURLService, 'facturaagente/'+payload.data.id_facturaagente, payload);
      const response = yield call(getbaseURLService, 'comisiones/agente/facturas', payload);
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_ERROR, error };
      yield put(accion);
    }
  }

  export function* postComisionesFacturaAgenteSaga(payload) {
    try {
      yield call(postbaseURLService, 'facturaagente', payload);
      const response = yield call(getbaseURLService, 'comisiones/agente/facturas', payload);
      const accion2 = { type: types.COMISIONES_FACTURA_CLOSE, response };
      yield put(accion2);
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_ERROR, error };
      yield put(accion);
    }
  }

  export function* putComisionesFacturaAgenteSaga(payload) {
    try {
      yield call(putbaseURLService, 'facturaagente/'+payload.data.id_facturaagente, payload);
      const response = yield call(getbaseURLService, 'comisiones/agente/facturas', payload);
      const accion2 = { type: types.COMISIONES_FACTURA_CLOSE, response };
      yield put(accion2);
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_ERROR, error };
      yield put(accion);
    }
  }

  export function* getComisionesAgenteFacturasSaga(payload) {
    try {
      const response = yield call(getbaseURLService, 'comisiones/agente/facturas', payload);
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTE_FACTURAS_ERROR, error };
      yield put(accion);
    }
  }



  export function* getComisionesAgenteDPsPendientesSaga(payload) {
    try {
      const response = yield call(getbaseURLService, 'comisiones/agente/dpspendientes', payload);
      const accion = { type: types.GET_COMISIONES_AGENTE_DPSPENDIENTES_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_AGENTE_DPSPENDIENTES_ERROR, error };
      yield put(accion);
    }
  }

  export function* getComisionesFacturaDPsSaga(payload) {
    try {
      const response = yield call(getbaseURLService, 'comisiones/facturaagente/dps', payload);
      const accion = { type: types.GET_COMISIONES_FACTURA_DPS_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.GET_COMISIONES_FACTURA_DPS_ERROR, error };
      yield put(accion);
    }
  }




  export function* postComisionesAsingacionSaga(payload) {
    try {
      console.log('PAYLOAD',payload);
      const response = yield call(postbaseURLService, 'comisiones/asignacion', payload);
      const accion = { type: types.POST_COMISIONES_ASIGNACION_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.POST_COMISIONES_ASIGNACION_SUCCESS, error };
      yield put(accion);
    }
  }

  export function* deleteComisionesAsingacionSaga(payload) {
    try {
      const response = yield call(deletebaseURLService, 'comisiones/asignacion', payload);
      const accion = { type: types.DELETE_COMISIONES_ASIGNACION_SUCCESS, response };
      yield put(accion);
    } catch(error) {
      const accion = { type: types.DELETE_COMISIONES_ASIGNACION_ERROR, error };
      yield put(accion);
    }
  }
