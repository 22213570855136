import { combineReducers } from 'redux';
import  db_reducers from './db';
import  auth_reducers from './auth';
import  buscar_reducers from './buscar';
import  procesos_reducers from './procesos';
import  concilia_reducers from './conciliapagos';
import  comisiones_reducers from './comisiones';
import  prueba_reducers from './prueba';
import archivopolizaReducers from './archivopoliza'
import dashboardReducers from './dashboards'
import uploadfileReducers from './uploadfile'
import cargapagosoxxoReducer from './cargapagosoxxo'
console.log(db_reducers)
const rootReducer = combineReducers({
  ...db_reducers,
  ...auth_reducers,
  ...buscar_reducers,
  ...prueba_reducers,
  ...procesos_reducers,
  ...concilia_reducers,
  ...comisiones_reducers,
  ...archivopolizaReducers,
  ...dashboardReducers,
  ...uploadfileReducers,
  ...cargapagosoxxoReducer
});

export default rootReducer;