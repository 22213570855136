import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    rolperfil: {
 id_rolperfil : 0, id_perfil : 0, id_rol : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ROLPERFIL:
      item = state.rolperfil;
      action.fn(item);
      return { ...state,  rolperfil: item };


    case types.GET_ROLPERFIL_SUCCESS:
      respuesta= action.response;
      console.log('GET_ROLPERFIL_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', rolperfil: respuesta };

    case types.GET_ROLPERFIL_ERROR:
      respuesta= action.response;
      console.log('GET_ROLPERFIL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ROLPERFIL_SUCCESS:
      respuesta= action.response;
      console.log('POST_ROLPERFIL_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', rolperfil: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ROLPERFIL_ERROR:
      respuesta= action.response;
      console.log('POST_ROLPERFIL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ROLPERFIL_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ROLPERFIL_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', rolperfil: respuesta };

    case types.PUT_ROLPERFIL_ERROR:
      respuesta= action.response;
      console.log('PUT_ROLPERFIL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ROLPERFIL_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ROLPERFIL_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ROLPERFIL_ERROR:
      respuesta= action.response;
      console.log('DELETE_ROLPERFIL_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

