import { put, call } from 'redux-saga/effects';
import {
    getCertificadosPolizaService,getCertificadoPolizaService,postCertificadoPolizaService,putCertificadoPolizaService,deleteCertificadoPolizaService,
     getPolizasService,getPolizaService,postPolizaService,putPolizaService,deletePolizaService,
     getProductosPolizaService,getProductoPolizaService,postProductoPolizaService,putProductoPolizaService,deleteProductoPolizaService,
     getDPsProductoPolizaService,getDPProductoPolizaService,postDPProductoPolizaService,putDPProductoPolizaService,deleteDPProductoPolizaService,
     getAseguradosCertificadoPolizaService,getAseguradoCertificadoPolizaService,postAseguradoCertificadoPolizaService,putAseguradoCertificadoPolizaService,deleteAseguradoCertificadoPolizaService,
     getTelsCertificadoPolizaService,getTelCertificadoPolizaService,postTelCertificadoPolizaService,putTelCertificadoPolizaService,deleteTelCertificadoPolizaService,
     getArchivosCertificadoPolizaService,getArchivoCertificadoPolizaService,postArchivoCertificadoPolizaService,putArchivoCertificadoPolizaService,deleteArchivoCertificadoPolizaService,
     getDPsCertificadoPolizaService,getDPCertificadoPolizaService,postDPCertificadoPolizaService,putDPCertificadoPolizaService,deleteDPCertificadoPolizaService,
     getPagosOxxoService,getPagoOxxoService,postPagoOxxoService,putPagoOxxoService,deletePagoOxxoService,
     getRegistrosPagoOxxoService,getRegistroPagoOxxoService,postRegistroPagoOxxoService,putRegistroPagoOxxoService,deleteRegistroPagoOxxoService,
     getEndososCertificadoPolizaService,getEndosoCertificadoPolizaService,postEndosoCertificadoPolizaService,putEndosoCertificadoPolizaService,deleteEndosoCertificadoPolizaService,
     getBenefsCertificadoPolizaService,getBenefCertificadoPolizaService,postBenefCertificadoPolizaService,putBenefCertificadoPolizaService,deleteBenefCertificadoPolizaService,
     getFacturasAgenteService,getFacturaAgenteService,postFacturaAgenteService,putFacturaAgenteService,deleteFacturaAgenteService,
     getPrimasProductoPolizaService,getPrimaProductoPolizaService,postPrimaProductoPolizaService,putPrimaProductoPolizaService,deletePrimaProductoPolizaService,
     getAseguradorasService,getAseguradoraService,postAseguradoraService,putAseguradoraService,deleteAseguradoraService,
     getProductosService,getProductoService,postProductoService,putProductoService,deleteProductoService,
     getAgentesService,getAgenteService,postAgenteService,putAgenteService,deleteAgenteService,
     getDetallesListaDePrecioService,getDetalleListaDePrecioService,postDetalleListaDePrecioService,putDetalleListaDePrecioService,deleteDetalleListaDePrecioService,
     getAseguradosProductoService,getAseguradoProductoService,postAseguradoProductoService,putAseguradoProductoService,deleteAseguradoProductoService,
     getListasDePrecioService,getListaDePrecioService,postListaDePrecioService,putListaDePrecioService,deleteListaDePrecioService,
     getPrimasListaDePrecioService,getPrimaListaDePrecioService,postPrimaListaDePrecioService,putPrimaListaDePrecioService,deletePrimaListaDePrecioService,
     getCoberturasProductoService,getCoberturaProductoService,postCoberturaProductoService,putCoberturaProductoService,deleteCoberturaProductoService,
     getClavesComisionService,getClaveComisionService,postClaveComisionService,putClaveComisionService,deleteClaveComisionService,
     getProductosClaveComisionService,getProductoClaveComisionService,postProductoClaveComisionService,putProductoClaveComisionService,deleteProductoClaveComisionService,
     getFormasPagoService,getFormaPagoService,postFormaPagoService,putFormaPagoService,deleteFormaPagoService,
     getParentezcosService,getParentezcoService,postParentezcoService,putParentezcoService,deleteParentezcoService,
     getEstatusPagosService,getEstatusPagoService,postEstatusPagoService,putEstatusPagoService,deleteEstatusPagoService,
     getTiposProductoService,getTipoProductoService,postTipoProductoService,putTipoProductoService,deleteTipoProductoService,
     getOrigenesService,getOrigenService,postOrigenService,putOrigenService,deleteOrigenService,
     getGenerosService,getGeneroService,postGeneroService,putGeneroService,deleteGeneroService,
     getEstatusCertificadosService,getEstatusCertificadoService,postEstatusCertificadoService,putEstatusCertificadoService,deleteEstatusCertificadoService,
     getEstatusPolizasService,getEstatusPolizaService,postEstatusPolizaService,putEstatusPolizaService,deleteEstatusPolizaService,
     getMediosPagoService,getMedioPagoService,postMedioPagoService,putMedioPagoService,deleteMedioPagoService,
     getEstatusRegistrosPagoOxxoService,getEstatusRegistroPagoOxxoService,postEstatusRegistroPagoOxxoService,putEstatusRegistroPagoOxxoService,deleteEstatusRegistroPagoOxxoService,
     getMotivosCancelacionService,getMotivoCancelacionService,postMotivoCancelacionService,putMotivoCancelacionService,deleteMotivoCancelacionService,
     getRegimenesFiscalesService,getRegimenFiscalService,postRegimenFiscalService,putRegimenFiscalService,deleteRegimenFiscalService,
     getUsuariosService,getUsuarioService,postUsuarioService,putUsuarioService,deleteUsuarioService,
     getActualizacionesService,getActualizacionService,postActualizacionService,putActualizacionService,deleteActualizacionService,
     getOperacionesService,getOperacionService,postOperacionService,putOperacionService,deleteOperacionService,
     getRutasService,getRutaService,postRutaService,putRutaService,deleteRutaService,
     getPerfilesService,getPerfilService,postPerfilService,putPerfilService,deletePerfilService,
     getRutasRolService,getRutaRolService,postRutaRolService,putRutaRolService,deleteRutaRolService,
     getRolesService,getRolService,postRolService,putRolService,deleteRolService,
     getRolesPerfilService,getRolPerfilService,postRolPerfilService,putRolPerfilService,deleteRolPerfilService,
} from '../../services/db/services';

import * as types from '../../actions/db/types'

export function* getCertificadosPolizaSaga(payload) {
  try {
    let response = yield call(getCertificadosPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_CERTIFICADOSPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_CERTIFICADOSPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_CERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_CERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postCertificadoPolizaService, payload.certificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_CERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_CERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putCertificadoPolizaService, payload.certificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_CERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_CERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_CERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_CERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getPolizasSaga(payload) {
  try {
    let response = yield call(getPolizasService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_POLIZAS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_POLIZAS_ERROR, error };
    yield put(accion);
  }
}

export function* getPolizaSaga(payload) {
  try {
    let response = yield call(getPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_POLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_POLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postPolizaSaga(payload) {
  try {
    let response = yield call(postPolizaService, payload.poliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_POLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_POLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putPolizaSaga(payload) {
  try {
    let response = yield call(putPolizaService, payload.poliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_POLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_POLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deletePolizaSaga(payload) {
  try {
    let response = yield call(deletePolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_POLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_POLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getProductosPolizaSaga(payload) {
  try {
    let response = yield call(getProductosPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PRODUCTOSPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRODUCTOSPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getProductoPolizaSaga(payload) {
  try {
    let response = yield call(getProductoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postProductoPolizaSaga(payload) {
  try {
    let response = yield call(postProductoPolizaService, payload.productopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putProductoPolizaSaga(payload) {
  try {
    let response = yield call(putProductoPolizaService, payload.productopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PRODUCTOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteProductoPolizaSaga(payload) {
  try {
    let response = yield call(deleteProductoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getDPsProductoPolizaSaga(payload) {
  try {
    let response = yield call(getDPsProductoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_DPSPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_DPSPRODUCTOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getDPProductoPolizaSaga(payload) {
  try {
    let response = yield call(getDPProductoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_DPPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_DPPRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postDPProductoPolizaSaga(payload) {
  try {
    let response = yield call(postDPProductoPolizaService, payload.dpproductopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_DPPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_DPPRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putDPProductoPolizaSaga(payload) {
  try {
    let response = yield call(putDPProductoPolizaService, payload.dpproductopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_DPPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_DPPRODUCTOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteDPProductoPolizaSaga(payload) {
  try {
    let response = yield call(deleteDPProductoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_DPPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_DPPRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getAseguradosCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getAseguradosCertificadoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ASEGURADOSCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ASEGURADOSCERTIFICADOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getAseguradoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getAseguradoCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ASEGURADOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ASEGURADOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postAseguradoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postAseguradoCertificadoPolizaService, payload.aseguradocertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ASEGURADOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ASEGURADOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putAseguradoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putAseguradoCertificadoPolizaService, payload.aseguradocertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ASEGURADOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ASEGURADOCERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteAseguradoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteAseguradoCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ASEGURADOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ASEGURADOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getTelsCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getTelsCertificadoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_TELSCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_TELSCERTIFICADOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getTelCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getTelCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_TELCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_TELCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postTelCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postTelCertificadoPolizaService, payload.telcertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_TELCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_TELCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putTelCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putTelCertificadoPolizaService, payload.telcertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_TELCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_TELCERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteTelCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteTelCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_TELCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_TELCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getArchivosCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getArchivosCertificadoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ARCHIVOSCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ARCHIVOSCERTIFICADOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getArchivoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getArchivoCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ARCHIVOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ARCHIVOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postArchivoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postArchivoCertificadoPolizaService, payload.archivocertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ARCHIVOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ARCHIVOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putArchivoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putArchivoCertificadoPolizaService, payload.archivocertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ARCHIVOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ARCHIVOCERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteArchivoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteArchivoCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ARCHIVOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ARCHIVOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getDPsCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getDPsCertificadoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_DPSCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_DPSCERTIFICADOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getDPCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getDPCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_DPCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_DPCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postDPCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postDPCertificadoPolizaService, payload.dpcertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_DPCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_DPCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putDPCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putDPCertificadoPolizaService, payload.dpcertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_DPCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_DPCERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteDPCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteDPCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_DPCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_DPCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getPagosOxxoSaga(payload) {
  try {
    let response = yield call(getPagosOxxoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PAGOSOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PAGOSOXXO_ERROR, error };
    yield put(accion);
  }
}

export function* getPagoOxxoSaga(payload) {
  try {
    let response = yield call(getPagoOxxoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postPagoOxxoSaga(payload) {
  try {
    let response = yield call(postPagoOxxoService, payload.pagooxxo);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putPagoOxxoSaga(payload) {
  try {
    let response = yield call(putPagoOxxoService, payload.pagooxxo);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PAGOOXXO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deletePagoOxxoSaga(payload) {
  try {
    let response = yield call(deletePagoOxxoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getRegistrosPagoOxxoSaga(payload) {
  try {
    let response = yield call(getRegistrosPagoOxxoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_REGISTROSPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_REGISTROSPAGOOXXO_ERROR, error };
    yield put(accion);
  }
}

export function* getRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(getRegistroPagoOxxoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_REGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_REGISTROPAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(postRegistroPagoOxxoService, payload.registropagooxxo);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_REGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_REGISTROPAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(putRegistroPagoOxxoService, payload.registropagooxxo);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_REGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_REGISTROPAGOOXXO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(deleteRegistroPagoOxxoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_REGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_REGISTROPAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getEndososCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getEndososCertificadoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ENDOSOSCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ENDOSOSCERTIFICADOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getEndosoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getEndosoCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ENDOSOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ENDOSOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postEndosoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postEndosoCertificadoPolizaService, payload.endosocertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ENDOSOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ENDOSOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putEndosoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putEndosoCertificadoPolizaService, payload.endosocertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ENDOSOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ENDOSOCERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteEndosoCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteEndosoCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ENDOSOCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ENDOSOCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getBenefsCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getBenefsCertificadoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_BENEFSCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_BENEFSCERTIFICADOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getBenefCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(getBenefCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_BENEFCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_BENEFCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postBenefCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(postBenefCertificadoPolizaService, payload.benefcertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_BENEFCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_BENEFCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putBenefCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(putBenefCertificadoPolizaService, payload.benefcertificadopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_BENEFCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_BENEFCERTIFICADOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteBenefCertificadoPolizaSaga(payload) {
  try {
    let response = yield call(deleteBenefCertificadoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_BENEFCERTIFICADOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_BENEFCERTIFICADOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getFacturasAgenteSaga(payload) {
  try {
    let response = yield call(getFacturasAgenteService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_FACTURASAGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_FACTURASAGENTE_ERROR, error };
    yield put(accion);
  }
}

export function* getFacturaAgenteSaga(payload) {
  try {
    let response = yield call(getFacturaAgenteService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_FACTURAAGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_FACTURAAGENTE_ERROR, response: error };
    yield put(accion);
  }
}


export function* postFacturaAgenteSaga(payload) {
  try {
    let response = yield call(postFacturaAgenteService, payload.facturaagente);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_FACTURAAGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_FACTURAAGENTE_ERROR, response: error };
    yield put(accion);
  }
}

export function* putFacturaAgenteSaga(payload) {
  try {
    let response = yield call(putFacturaAgenteService, payload.facturaagente);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_FACTURAAGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_FACTURAAGENTE_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteFacturaAgenteSaga(payload) {
  try {
    let response = yield call(deleteFacturaAgenteService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_FACTURAAGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_FACTURAAGENTE_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getPrimasProductoPolizaSaga(payload) {
  try {
    let response = yield call(getPrimasProductoPolizaService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PRIMASPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRIMASPRODUCTOPOLIZA_ERROR, error };
    yield put(accion);
  }
}

export function* getPrimaProductoPolizaSaga(payload) {
  try {
    let response = yield call(getPrimaProductoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PRIMAPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRIMAPRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postPrimaProductoPolizaSaga(payload) {
  try {
    let response = yield call(postPrimaProductoPolizaService, payload.primaproductopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PRIMAPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PRIMAPRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putPrimaProductoPolizaSaga(payload) {
  try {
    let response = yield call(putPrimaProductoPolizaService, payload.primaproductopoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PRIMAPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PRIMAPRODUCTOPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deletePrimaProductoPolizaSaga(payload) {
  try {
    let response = yield call(deletePrimaProductoPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PRIMAPRODUCTOPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PRIMAPRODUCTOPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getAseguradorasSaga(payload) {
  try {
    let response = yield call(getAseguradorasService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ASEGURADORAS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ASEGURADORAS_ERROR, error };
    yield put(accion);
  }
}

export function* getAseguradoraSaga(payload) {
  try {
    let response = yield call(getAseguradoraService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ASEGURADORA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ASEGURADORA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postAseguradoraSaga(payload) {
  try {
    let response = yield call(postAseguradoraService, payload.aseguradora);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ASEGURADORA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ASEGURADORA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putAseguradoraSaga(payload) {
  try {
    let response = yield call(putAseguradoraService, payload.aseguradora);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ASEGURADORA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ASEGURADORA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteAseguradoraSaga(payload) {
  try {
    let response = yield call(deleteAseguradoraService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ASEGURADORA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ASEGURADORA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getProductosSaga(payload) {
  try {
    let response = yield call(getProductosService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PRODUCTOS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRODUCTOS_ERROR, error };
    yield put(accion);
  }
}

export function* getProductoSaga(payload) {
  try {
    let response = yield call(getProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postProductoSaga(payload) {
  try {
    let response = yield call(postProductoService, payload.producto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putProductoSaga(payload) {
  try {
    let response = yield call(putProductoService, payload.producto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PRODUCTO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteProductoSaga(payload) {
  try {
    let response = yield call(deleteProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getAgentesSaga(payload) {
  try {
    let response = yield call(getAgentesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_AGENTES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_AGENTES_ERROR, error };
    yield put(accion);
  }
}

export function* getAgenteSaga(payload) {
  try {
    let response = yield call(getAgenteService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_AGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_AGENTE_ERROR, response: error };
    yield put(accion);
  }
}


export function* postAgenteSaga(payload) {
  try {
    let response = yield call(postAgenteService, payload.agente);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_AGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_AGENTE_ERROR, response: error };
    yield put(accion);
  }
}

export function* putAgenteSaga(payload) {
  try {
    let response = yield call(putAgenteService, payload.agente);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_AGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_AGENTE_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteAgenteSaga(payload) {
  try {
    let response = yield call(deleteAgenteService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_AGENTE_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_AGENTE_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getDetallesListaDePrecioSaga(payload) {
  try {
    let response = yield call(getDetallesListaDePrecioService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_DETALLESLISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_DETALLESLISTADEPRECIO_ERROR, error };
    yield put(accion);
  }
}

export function* getDetalleListaDePrecioSaga(payload) {
  try {
    let response = yield call(getDetalleListaDePrecioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_DETALLELISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_DETALLELISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postDetalleListaDePrecioSaga(payload) {
  try {
    let response = yield call(postDetalleListaDePrecioService, payload.detallelistadeprecio);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_DETALLELISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_DETALLELISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putDetalleListaDePrecioSaga(payload) {
  try {
    let response = yield call(putDetalleListaDePrecioService, payload.detallelistadeprecio);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_DETALLELISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_DETALLELISTADEPRECIO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteDetalleListaDePrecioSaga(payload) {
  try {
    let response = yield call(deleteDetalleListaDePrecioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_DETALLELISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_DETALLELISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getAseguradosProductoSaga(payload) {
  try {
    let response = yield call(getAseguradosProductoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ASEGURADOSPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ASEGURADOSPRODUCTO_ERROR, error };
    yield put(accion);
  }
}

export function* getAseguradoProductoSaga(payload) {
  try {
    let response = yield call(getAseguradoProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ASEGURADOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ASEGURADOPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postAseguradoProductoSaga(payload) {
  try {
    let response = yield call(postAseguradoProductoService, payload.aseguradoproducto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ASEGURADOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ASEGURADOPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putAseguradoProductoSaga(payload) {
  try {
    let response = yield call(putAseguradoProductoService, payload.aseguradoproducto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ASEGURADOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ASEGURADOPRODUCTO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteAseguradoProductoSaga(payload) {
  try {
    let response = yield call(deleteAseguradoProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ASEGURADOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ASEGURADOPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getListasDePrecioSaga(payload) {
  try {
    let response = yield call(getListasDePrecioService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_LISTASDEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_LISTASDEPRECIO_ERROR, error };
    yield put(accion);
  }
}

export function* getListaDePrecioSaga(payload) {
  try {
    let response = yield call(getListaDePrecioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_LISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_LISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postListaDePrecioSaga(payload) {
  try {
    let response = yield call(postListaDePrecioService, payload.listadeprecio);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_LISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_LISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putListaDePrecioSaga(payload) {
  try {
    let response = yield call(putListaDePrecioService, payload.listadeprecio);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_LISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_LISTADEPRECIO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteListaDePrecioSaga(payload) {
  try {
    let response = yield call(deleteListaDePrecioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_LISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_LISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getPrimasListaDePrecioSaga(payload) {
  try {
    let response = yield call(getPrimasListaDePrecioService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PRIMASLISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRIMASLISTADEPRECIO_ERROR, error };
    yield put(accion);
  }
}

export function* getPrimaListaDePrecioSaga(payload) {
  try {
    let response = yield call(getPrimaListaDePrecioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PRIMALISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRIMALISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postPrimaListaDePrecioSaga(payload) {
  try {
    let response = yield call(postPrimaListaDePrecioService, payload.primalistadeprecio);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PRIMALISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PRIMALISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putPrimaListaDePrecioSaga(payload) {
  try {
    let response = yield call(putPrimaListaDePrecioService, payload.primalistadeprecio);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PRIMALISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PRIMALISTADEPRECIO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deletePrimaListaDePrecioSaga(payload) {
  try {
    let response = yield call(deletePrimaListaDePrecioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PRIMALISTADEPRECIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PRIMALISTADEPRECIO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getCoberturasProductoSaga(payload) {
  try {
    let response = yield call(getCoberturasProductoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_COBERTURASPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_COBERTURASPRODUCTO_ERROR, error };
    yield put(accion);
  }
}

export function* getCoberturaProductoSaga(payload) {
  try {
    let response = yield call(getCoberturaProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_COBERTURAPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_COBERTURAPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postCoberturaProductoSaga(payload) {
  try {
    let response = yield call(postCoberturaProductoService, payload.coberturaproducto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_COBERTURAPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_COBERTURAPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putCoberturaProductoSaga(payload) {
  try {
    let response = yield call(putCoberturaProductoService, payload.coberturaproducto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_COBERTURAPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_COBERTURAPRODUCTO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteCoberturaProductoSaga(payload) {
  try {
    let response = yield call(deleteCoberturaProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_COBERTURAPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_COBERTURAPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getClavesComisionSaga(payload) {
  try {
    let response = yield call(getClavesComisionService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_CLAVESCOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_CLAVESCOMISION_ERROR, error };
    yield put(accion);
  }
}

export function* getClaveComisionSaga(payload) {
  try {
    let response = yield call(getClaveComisionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_CLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_CLAVECOMISION_ERROR, response: error };
    yield put(accion);
  }
}


export function* postClaveComisionSaga(payload) {
  try {
    let response = yield call(postClaveComisionService, payload.clavecomision);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_CLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_CLAVECOMISION_ERROR, response: error };
    yield put(accion);
  }
}

export function* putClaveComisionSaga(payload) {
  try {
    let response = yield call(putClaveComisionService, payload.clavecomision);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_CLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_CLAVECOMISION_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteClaveComisionSaga(payload) {
  try {
    let response = yield call(deleteClaveComisionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_CLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_CLAVECOMISION_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getProductosClaveComisionSaga(payload) {
  try {
    let response = yield call(getProductosClaveComisionService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PRODUCTOSCLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRODUCTOSCLAVECOMISION_ERROR, error };
    yield put(accion);
  }
}

export function* getProductoClaveComisionSaga(payload) {
  try {
    let response = yield call(getProductoClaveComisionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PRODUCTOCLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PRODUCTOCLAVECOMISION_ERROR, response: error };
    yield put(accion);
  }
}


export function* postProductoClaveComisionSaga(payload) {
  try {
    let response = yield call(postProductoClaveComisionService, payload.productoclavecomision);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PRODUCTOCLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PRODUCTOCLAVECOMISION_ERROR, response: error };
    yield put(accion);
  }
}

export function* putProductoClaveComisionSaga(payload) {
  try {
    let response = yield call(putProductoClaveComisionService, payload.productoclavecomision);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PRODUCTOCLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PRODUCTOCLAVECOMISION_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteProductoClaveComisionSaga(payload) {
  try {
    let response = yield call(deleteProductoClaveComisionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PRODUCTOCLAVECOMISION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PRODUCTOCLAVECOMISION_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getFormasPagoSaga(payload) {
  try {
    let response = yield call(getFormasPagoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_FORMASPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_FORMASPAGO_ERROR, error };
    yield put(accion);
  }
}

export function* getFormaPagoSaga(payload) {
  try {
    let response = yield call(getFormaPagoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_FORMAPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_FORMAPAGO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postFormaPagoSaga(payload) {
  try {
    let response = yield call(postFormaPagoService, payload.formapago);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_FORMAPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_FORMAPAGO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putFormaPagoSaga(payload) {
  try {
    let response = yield call(putFormaPagoService, payload.formapago);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_FORMAPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_FORMAPAGO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteFormaPagoSaga(payload) {
  try {
    let response = yield call(deleteFormaPagoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_FORMAPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_FORMAPAGO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getParentezcosSaga(payload) {
  try {
    let response = yield call(getParentezcosService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PARENTEZCOS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PARENTEZCOS_ERROR, error };
    yield put(accion);
  }
}

export function* getParentezcoSaga(payload) {
  try {
    let response = yield call(getParentezcoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PARENTEZCO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PARENTEZCO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postParentezcoSaga(payload) {
  try {
    let response = yield call(postParentezcoService, payload.parentezco);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PARENTEZCO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PARENTEZCO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putParentezcoSaga(payload) {
  try {
    let response = yield call(putParentezcoService, payload.parentezco);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PARENTEZCO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PARENTEZCO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteParentezcoSaga(payload) {
  try {
    let response = yield call(deleteParentezcoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PARENTEZCO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PARENTEZCO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getEstatusPagosSaga(payload) {
  try {
    let response = yield call(getEstatusPagosService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSPAGOS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSPAGOS_ERROR, error };
    yield put(accion);
  }
}

export function* getEstatusPagoSaga(payload) {
  try {
    let response = yield call(getEstatusPagoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSPAGO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postEstatusPagoSaga(payload) {
  try {
    let response = yield call(postEstatusPagoService, payload.estatuspago);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ESTATUSPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ESTATUSPAGO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putEstatusPagoSaga(payload) {
  try {
    let response = yield call(putEstatusPagoService, payload.estatuspago);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ESTATUSPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ESTATUSPAGO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteEstatusPagoSaga(payload) {
  try {
    let response = yield call(deleteEstatusPagoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ESTATUSPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ESTATUSPAGO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getTiposProductoSaga(payload) {
  try {
    let response = yield call(getTiposProductoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_TIPOSPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_TIPOSPRODUCTO_ERROR, error };
    yield put(accion);
  }
}

export function* getTipoProductoSaga(payload) {
  try {
    let response = yield call(getTipoProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_TIPOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_TIPOPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postTipoProductoSaga(payload) {
  try {
    let response = yield call(postTipoProductoService, payload.tipoproducto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_TIPOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_TIPOPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putTipoProductoSaga(payload) {
  try {
    let response = yield call(putTipoProductoService, payload.tipoproducto);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_TIPOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_TIPOPRODUCTO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteTipoProductoSaga(payload) {
  try {
    let response = yield call(deleteTipoProductoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_TIPOPRODUCTO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_TIPOPRODUCTO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getOrigenesSaga(payload) {
  try {
    let response = yield call(getOrigenesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ORIGENES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ORIGENES_ERROR, error };
    yield put(accion);
  }
}

export function* getOrigenSaga(payload) {
  try {
    let response = yield call(getOrigenService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ORIGEN_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ORIGEN_ERROR, response: error };
    yield put(accion);
  }
}


export function* postOrigenSaga(payload) {
  try {
    let response = yield call(postOrigenService, payload.origen);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ORIGEN_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ORIGEN_ERROR, response: error };
    yield put(accion);
  }
}

export function* putOrigenSaga(payload) {
  try {
    let response = yield call(putOrigenService, payload.origen);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ORIGEN_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ORIGEN_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteOrigenSaga(payload) {
  try {
    let response = yield call(deleteOrigenService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ORIGEN_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ORIGEN_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getGenerosSaga(payload) {
  try {
    let response = yield call(getGenerosService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_GENEROS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_GENEROS_ERROR, error };
    yield put(accion);
  }
}

export function* getGeneroSaga(payload) {
  try {
    let response = yield call(getGeneroService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_GENERO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_GENERO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postGeneroSaga(payload) {
  try {
    let response = yield call(postGeneroService, payload.genero);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_GENERO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_GENERO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putGeneroSaga(payload) {
  try {
    let response = yield call(putGeneroService, payload.genero);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_GENERO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_GENERO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteGeneroSaga(payload) {
  try {
    let response = yield call(deleteGeneroService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_GENERO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_GENERO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getEstatusCertificadosSaga(payload) {
  try {
    let response = yield call(getEstatusCertificadosService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSCERTIFICADOS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSCERTIFICADOS_ERROR, error };
    yield put(accion);
  }
}

export function* getEstatusCertificadoSaga(payload) {
  try {
    let response = yield call(getEstatusCertificadoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSCERTIFICADO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSCERTIFICADO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postEstatusCertificadoSaga(payload) {
  try {
    let response = yield call(postEstatusCertificadoService, payload.estatuscertificado);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ESTATUSCERTIFICADO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ESTATUSCERTIFICADO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putEstatusCertificadoSaga(payload) {
  try {
    let response = yield call(putEstatusCertificadoService, payload.estatuscertificado);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ESTATUSCERTIFICADO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ESTATUSCERTIFICADO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteEstatusCertificadoSaga(payload) {
  try {
    let response = yield call(deleteEstatusCertificadoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ESTATUSCERTIFICADO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ESTATUSCERTIFICADO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getEstatusPolizasSaga(payload) {
  try {
    let response = yield call(getEstatusPolizasService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSPOLIZAS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSPOLIZAS_ERROR, error };
    yield put(accion);
  }
}

export function* getEstatusPolizaSaga(payload) {
  try {
    let response = yield call(getEstatusPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postEstatusPolizaSaga(payload) {
  try {
    let response = yield call(postEstatusPolizaService, payload.estatuspoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ESTATUSPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ESTATUSPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putEstatusPolizaSaga(payload) {
  try {
    let response = yield call(putEstatusPolizaService, payload.estatuspoliza);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ESTATUSPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ESTATUSPOLIZA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteEstatusPolizaSaga(payload) {
  try {
    let response = yield call(deleteEstatusPolizaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ESTATUSPOLIZA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ESTATUSPOLIZA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getMediosPagoSaga(payload) {
  try {
    let response = yield call(getMediosPagoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_MEDIOSPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_MEDIOSPAGO_ERROR, error };
    yield put(accion);
  }
}

export function* getMedioPagoSaga(payload) {
  try {
    let response = yield call(getMedioPagoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_MEDIOPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_MEDIOPAGO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postMedioPagoSaga(payload) {
  try {
    let response = yield call(postMedioPagoService, payload.mediopago);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_MEDIOPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_MEDIOPAGO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putMedioPagoSaga(payload) {
  try {
    let response = yield call(putMedioPagoService, payload.mediopago);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_MEDIOPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_MEDIOPAGO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteMedioPagoSaga(payload) {
  try {
    let response = yield call(deleteMedioPagoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_MEDIOPAGO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_MEDIOPAGO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getEstatusRegistrosPagoOxxoSaga(payload) {
  try {
    let response = yield call(getEstatusRegistrosPagoOxxoService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSREGISTROSPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSREGISTROSPAGOOXXO_ERROR, error };
    yield put(accion);
  }
}

export function* getEstatusRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(getEstatusRegistroPagoOxxoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ESTATUSREGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ESTATUSREGISTROPAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postEstatusRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(postEstatusRegistroPagoOxxoService, payload.estatusregistropagooxxo);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ESTATUSREGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ESTATUSREGISTROPAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putEstatusRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(putEstatusRegistroPagoOxxoService, payload.estatusregistropagooxxo);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ESTATUSREGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ESTATUSREGISTROPAGOOXXO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteEstatusRegistroPagoOxxoSaga(payload) {
  try {
    let response = yield call(deleteEstatusRegistroPagoOxxoService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ESTATUSREGISTROPAGOOXXO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ESTATUSREGISTROPAGOOXXO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getMotivosCancelacionSaga(payload) {
  try {
    let response = yield call(getMotivosCancelacionService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_MOTIVOSCANCELACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_MOTIVOSCANCELACION_ERROR, error };
    yield put(accion);
  }
}

export function* getMotivoCancelacionSaga(payload) {
  try {
    let response = yield call(getMotivoCancelacionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_MOTIVOCANCELACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_MOTIVOCANCELACION_ERROR, response: error };
    yield put(accion);
  }
}


export function* postMotivoCancelacionSaga(payload) {
  try {
    let response = yield call(postMotivoCancelacionService, payload.motivocancelacion);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_MOTIVOCANCELACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_MOTIVOCANCELACION_ERROR, response: error };
    yield put(accion);
  }
}

export function* putMotivoCancelacionSaga(payload) {
  try {
    let response = yield call(putMotivoCancelacionService, payload.motivocancelacion);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_MOTIVOCANCELACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_MOTIVOCANCELACION_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteMotivoCancelacionSaga(payload) {
  try {
    let response = yield call(deleteMotivoCancelacionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_MOTIVOCANCELACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_MOTIVOCANCELACION_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getRegimenesFiscalesSaga(payload) {
  try {
    let response = yield call(getRegimenesFiscalesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_REGIMENESFISCALES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_REGIMENESFISCALES_ERROR, error };
    yield put(accion);
  }
}

export function* getRegimenFiscalSaga(payload) {
  try {
    let response = yield call(getRegimenFiscalService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_REGIMENFISCAL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_REGIMENFISCAL_ERROR, response: error };
    yield put(accion);
  }
}


export function* postRegimenFiscalSaga(payload) {
  try {
    let response = yield call(postRegimenFiscalService, payload.regimenfiscal);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_REGIMENFISCAL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_REGIMENFISCAL_ERROR, response: error };
    yield put(accion);
  }
}

export function* putRegimenFiscalSaga(payload) {
  try {
    let response = yield call(putRegimenFiscalService, payload.regimenfiscal);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_REGIMENFISCAL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_REGIMENFISCAL_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteRegimenFiscalSaga(payload) {
  try {
    let response = yield call(deleteRegimenFiscalService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_REGIMENFISCAL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_REGIMENFISCAL_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getUsuariosSaga(payload) {
  try {
    let response = yield call(getUsuariosService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_USUARIOS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_USUARIOS_ERROR, error };
    yield put(accion);
  }
}

export function* getUsuarioSaga(payload) {
  try {
    let response = yield call(getUsuarioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_USUARIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_USUARIO_ERROR, response: error };
    yield put(accion);
  }
}


export function* postUsuarioSaga(payload) {
  try {
    let response = yield call(postUsuarioService, payload.usuario);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_USUARIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_USUARIO_ERROR, response: error };
    yield put(accion);
  }
}

export function* putUsuarioSaga(payload) {
  try {
    let response = yield call(putUsuarioService, payload.usuario);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_USUARIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_USUARIO_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteUsuarioSaga(payload) {
  try {
    let response = yield call(deleteUsuarioService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_USUARIO_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_USUARIO_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getActualizacionesSaga(payload) {
  try {
    let response = yield call(getActualizacionesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ACTUALIZACIONES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ACTUALIZACIONES_ERROR, error };
    yield put(accion);
  }
}

export function* getActualizacionSaga(payload) {
  try {
    let response = yield call(getActualizacionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ACTUALIZACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ACTUALIZACION_ERROR, response: error };
    yield put(accion);
  }
}


export function* postActualizacionSaga(payload) {
  try {
    let response = yield call(postActualizacionService, payload.actualizacion);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ACTUALIZACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ACTUALIZACION_ERROR, response: error };
    yield put(accion);
  }
}

export function* putActualizacionSaga(payload) {
  try {
    let response = yield call(putActualizacionService, payload.actualizacion);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ACTUALIZACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ACTUALIZACION_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteActualizacionSaga(payload) {
  try {
    let response = yield call(deleteActualizacionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ACTUALIZACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ACTUALIZACION_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getOperacionesSaga(payload) {
  try {
    let response = yield call(getOperacionesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_OPERACIONES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_OPERACIONES_ERROR, error };
    yield put(accion);
  }
}

export function* getOperacionSaga(payload) {
  try {
    let response = yield call(getOperacionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_OPERACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_OPERACION_ERROR, response: error };
    yield put(accion);
  }
}


export function* postOperacionSaga(payload) {
  try {
    let response = yield call(postOperacionService, payload.operacion);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_OPERACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_OPERACION_ERROR, response: error };
    yield put(accion);
  }
}

export function* putOperacionSaga(payload) {
  try {
    let response = yield call(putOperacionService, payload.operacion);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_OPERACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_OPERACION_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteOperacionSaga(payload) {
  try {
    let response = yield call(deleteOperacionService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_OPERACION_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_OPERACION_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getRutasSaga(payload) {
  try {
    let response = yield call(getRutasService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_RUTAS_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_RUTAS_ERROR, error };
    yield put(accion);
  }
}

export function* getRutaSaga(payload) {
  try {
    let response = yield call(getRutaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_RUTA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_RUTA_ERROR, response: error };
    yield put(accion);
  }
}


export function* postRutaSaga(payload) {
  try {
    let response = yield call(postRutaService, payload.ruta);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_RUTA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_RUTA_ERROR, response: error };
    yield put(accion);
  }
}

export function* putRutaSaga(payload) {
  try {
    let response = yield call(putRutaService, payload.ruta);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_RUTA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_RUTA_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteRutaSaga(payload) {
  try {
    let response = yield call(deleteRutaService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_RUTA_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_RUTA_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getPerfilesSaga(payload) {
  try {
    let response = yield call(getPerfilesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_PERFILES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PERFILES_ERROR, error };
    yield put(accion);
  }
}

export function* getPerfilSaga(payload) {
  try {
    let response = yield call(getPerfilService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_PERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_PERFIL_ERROR, response: error };
    yield put(accion);
  }
}


export function* postPerfilSaga(payload) {
  try {
    let response = yield call(postPerfilService, payload.perfil);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_PERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_PERFIL_ERROR, response: error };
    yield put(accion);
  }
}

export function* putPerfilSaga(payload) {
  try {
    let response = yield call(putPerfilService, payload.perfil);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_PERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_PERFIL_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deletePerfilSaga(payload) {
  try {
    let response = yield call(deletePerfilService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_PERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_PERFIL_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getRutasRolSaga(payload) {
  try {
    let response = yield call(getRutasRolService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_RUTASROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_RUTASROL_ERROR, error };
    yield put(accion);
  }
}

export function* getRutaRolSaga(payload) {
  try {
    let response = yield call(getRutaRolService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_RUTAROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_RUTAROL_ERROR, response: error };
    yield put(accion);
  }
}


export function* postRutaRolSaga(payload) {
  try {
    let response = yield call(postRutaRolService, payload.rutarol);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_RUTAROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_RUTAROL_ERROR, response: error };
    yield put(accion);
  }
}

export function* putRutaRolSaga(payload) {
  try {
    let response = yield call(putRutaRolService, payload.rutarol);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_RUTAROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_RUTAROL_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteRutaRolSaga(payload) {
  try {
    let response = yield call(deleteRutaRolService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_RUTAROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_RUTAROL_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getRolesSaga(payload) {
  try {
    let response = yield call(getRolesService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ROLES_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ROLES_ERROR, error };
    yield put(accion);
  }
}

export function* getRolSaga(payload) {
  try {
    let response = yield call(getRolService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ROL_ERROR, response: error };
    yield put(accion);
  }
}


export function* postRolSaga(payload) {
  try {
    let response = yield call(postRolService, payload.rol);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ROL_ERROR, response: error };
    yield put(accion);
  }
}

export function* putRolSaga(payload) {
  try {
    let response = yield call(putRolService, payload.rol);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ROL_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteRolSaga(payload) {
  try {
    let response = yield call(deleteRolService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ROL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ROL_ERROR, response: error };
    yield put(accion);
  }
}

 
export function* getRolesPerfilSaga(payload) {
  try {
    let response = yield call(getRolesPerfilService, payload);
    if (response.error !== undefined){
      response = { lista : response, success:false, message:response.error}
    }
    else{
      response = { lista : response, success:true, message:''}
    }
    const accion = { type: types.GET_ROLESPERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ROLESPERFIL_ERROR, error };
    yield put(accion);
  }
}

export function* getRolPerfilSaga(payload) {
  try {
    let response = yield call(getRolPerfilService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.GET_ROLPERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.GET_ROLPERFIL_ERROR, response: error };
    yield put(accion);
  }
}


export function* postRolPerfilSaga(payload) {
  try {
    let response = yield call(postRolPerfilService, payload.rolperfil);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }
    const accion = { type: types.POST_ROLPERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.POST_ROLPERFIL_ERROR, response: error };
    yield put(accion);
  }
}

export function* putRolPerfilSaga(payload) {
  try {
    let response = yield call(putRolPerfilService, payload.rolperfil);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.PUT_ROLPERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.PUT_ROLPERFIL_ERROR, response:  error };
    yield put(accion);
  }
}

export function* deleteRolPerfilSaga(payload) {
  try {
    let response = yield call(deleteRolPerfilService, payload.id);
    if (response.error !== undefined){
      response = {...response, success:false, message:response.error}
    }
    else{
      response = {...response, success:true, message:''}
    }const accion = { type: types.DELETE_ROLPERFIL_SUCCESS, response };
    yield put(accion);
  } catch(error) {
    const accion = { type: types.DELETE_ROLPERFIL_ERROR, response: error };
    yield put(accion);
  }
}


