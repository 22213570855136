import { getCookie } from '../../../utils/cookies';
import { buffers, eventChannel, END } from 'redux-saga';
import { baseurl } from '../baseurl';



export const createUploadFileChannel2 = (endpoint, file) =>
{
    const apiURL = baseurl(endpoint);
    const data = new FormData() 
    data.append('file', file);

    const parameters = {
      method: 'POST',
      headers: {
        
        'Authorization': getCookie('timeoff-token')
      },
      body: data
    };
    console.log('parameters.data', parameters);
    
    return fetch(apiURL, parameters)
      .then(response => {
        return { success: true };
      })
      .catch(error => {
        return { err: new Error('Upload failed') };
      });
  };
  

export const createUploadFileChannel = (endpoint, file) =>{
    const apiURL = baseurl(endpoint);
    return eventChannel(emitter => {
        const xhr = new XMLHttpRequest();
        const onProgress = (e) => {
            if (e.lengthComputable) {
                const progress = e.loaded / e.total;
                emitter({ progress });
            }
        };
        const onFailure = (e) => {
            emitter({ err: new Error('Upload failed') });
            emitter(END);
        };
        xhr.upload.addEventListener("progress", onProgress);
        xhr.upload.addEventListener("error", onFailure);
        xhr.upload.addEventListener("abort", onFailure);
        xhr.onreadystatechange = () => {
            const { readyState, status } = xhr;
            if (readyState === 4) {
                if (status === 200) {
                    emitter({ success: true });
                    emitter(END);
                }
                else {
                    onFailure(null);
                }
            }
        };
        xhr.open("POST", apiURL, true);
        xhr.setRequestHeader('Authorization', getCookie('timeoff-token'));
        
        xhr.send(file);
        return () => {
            xhr.upload.removeEventListener("progress", onProgress);
            xhr.upload.removeEventListener("error", onFailure);
            xhr.upload.removeEventListener("abort", onFailure);
            xhr.onreadystatechange = null;
            xhr.abort();
        };
    }, buffers.sliding(2));
}