import React from "react";
import PropTypes from "prop-types";
import Lookup from '../lookups'
import FieldHeader from '../fieldheader'
import Combobox from '../comboboxes'
import FieldTypes from '../../assets/fieldtypes'
import UploadFile from '../uploadfile'
export default function Contenedor(props) {
  const { className, field, value, onChange, children, type, form, step, disabled, required, onFileUploaded, path, accept, ...rest } = props;
  
  const tablacampo = field.split('.')
  const name = (tablacampo[1])?tablacampo[1]:tablacampo[0]
  
  const ft = FieldTypes[field]
  var tipo = type
  var longitud = 10
  var requerido = required

  if (ft) {
    if (tipo===undefined && ft["type"]) tipo =  ft["type"]
    if (ft["longitud"]) longitud = ft["longitud"]
    if (ft["required"]) requerido = requerido || ft["required"]
  }

  return (
    <div className={className} {...rest}>
        <FieldHeader name={name} field={field} required={requerido} />
        {
        (type==='lookup')
          ? <Lookup className="form-control rounded-large" form={form} field={field} value={value} required={requerido}  />
          :(type==='date')
            ? <input disabled={disabled} type={type}  form={form} name={name} className="form-control rounded-large" value={value.substring(0,10)} onChange={onChange} required={requerido} />
            :(type==='combobox')
              ?<Combobox disabled={disabled} field={field}  form={form} name={name} value={value} onChange={onChange} required={requerido} />
              :(type==='file')
                  ? <div className='d-flex '>
                      <UploadFile onFileUploaded={onFileUploaded} path={path} />
                    </div>
                  :(type==='text')
                    ?<input disabled={disabled} type={type} form={form} name={name} className="form-control rounded-large" value={(value || '')} onChange={onChange} required={requerido} maxLength={longitud} />
                    :<input disabled={disabled} type={type} form={form} step={step} name={name} className="form-control rounded-large" value={(value || '')} onChange={onChange} required={requerido} />
              
          }
    </div>
  );
}




Contenedor.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  children: PropTypes.node,
  required: PropTypes.bool
};