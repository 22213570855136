//
//  GRABAR
//
export function  OnHandleGrabaCertificado(event) {
    event.preventDefault();

    let newstate = this.props.item;
    console.log('state', this.props.item);
    console.log('newstat', newstate);
    console.log('target', event.target);
    newstate.grabado = false;
    newstate.antiguedad = newstate.antiguedad.substr(0,10);
    newstate.c_fnac =  newstate.c_fnac.substr(0,10);
    newstate.t_fnac = newstate.t_fnac.substr(0,10);

    console.log('onHandleRRR.... ', newstate);
    console.log('onHandleRRRp.... ', this.props.asegurados);


    if (newstate.id_certificadopoliza>0){
      
      this.props.asegurados.map( item =>{
        item.fechanacimiento = item.fechanacimiento.substr(0,10);
        item.antiguedad = item.antiguedad.substr(0,10);
    
          console.log('ActualizaAsegurado',item);
        if (item.id_aseguradocertificadopoliza === 0){
          this.props.postEveryAseguradoCertificadoPolizaAction(item);
        } else {
          if (item.modificado) this.props.putEveryAseguradoCertificadoPolizaAction(item);
        }
        return null
      })
      
      this.props.benefs.map( item =>{
        item.fechanacimiento = item.fechanacimiento.substr(0,10);
    
        if (item.id_benefcertificadopoliza === 0){
          console.log('Agrega Benficiario',item);
          this.props.postEveryBenefCertificadoPolizaAction(item);
        } else {
          console.log('Modifica Benficiario',item);
          if (item.modificado) this.props.putEveryBenefCertificadoPolizaAction(item);
        }
        return null
      })
      

        
        this.props.endosos.map( item =>{
          item.fechainicio = item.fechainicio.substr(0,10);
      
          if (item.id_endosocertificadopoliza === 0){
            if (item.endoso !== '') this.props.postEveryEndosoCertificadoPolizaAction(item);
          } else {
            if (item.modificado) this.props.putEveryEndosoCertificadoPolizaAction(item);
          }
          return null
        })
        
      

        
        this.props.pagos.map( item =>{
          if (item.modificado) { 
            item.fecha = item.fecha.substr(0,10);
            item.fechapago = item.fechapago.substr(0,10);
            item.fechalimitedepago = item.fechalimitedepago.substr(0,10);
            this.props.putEveryDPCertificadoPolizaAction(item);
          }
          return null
        })

        

        this.props.state.lista_aseguradocertificadopoliza_borrados.map( item =>{
          if (item.id_aseguradocertificadopoliza > 0){
            this.props.deleteEveryAseguradoCertificadoPolizaAction(item.id_aseguradocertificadopoliza);
          }
          return null
        })

        this.props.state.lista_benefcertificadopoliza_borrados.map( item =>{
          if (item.id_benefcertificadopoliza > 0){
            this.props.deleteEveryBenefCertificadoPolizaAction(item.id_benefcertificadopoliza);
          }
          return null
        })


        this.props.state.lista_endosocertificadopoliza_borrados.map( item =>{
          if (item.id_endosocertificadopoliza > 0){
            this.props.deleteEveryEndosoCertificadoPolizaAction(item.id_endosocertificadopoliza);
          }
          return null
        })

      this.props.putCertificadoPolizaAction(newstate);
    }
    else {
      this.props.postCertificadoPolizaAction(newstate);
    }
    
  }


export function eventvalue(event) {
    let name = event.target.name;

    if (event.target.type==='number'){
      if (event.target.step){
        return  parseFloat(parseFloat(event.target.value).toFixed(2));
      }else{
        return parseInt(event.target.value,10);
      }
    }
    else if (event.target.type==='date'){
      return event.target.value.substr(0,10);
    }
    else if (name.startsWith('id_')) {
      return parseInt(event.target.value,10)
    } else {
      return event.target.value;
    };

  }

export function HandleSelect(elem) {
        console.log('Contratante', elem);
        let item = this.props.item;
        item['contratante']= elem.contratante;
        item['c_nombre']= elem.c_nombre;
        item['c_appaterno']= elem.c_appaterno;
        item['c_apmaterno']= elem.c_apmaterno;
        item['c_fnac']= elem.c_fnac;
        item['c_ocupacion']= elem.c_ocupacion;
        item['c_estadocivil']= elem.c_estadocivil;
        item['c_domicilio']= elem.c_domicilio;
        item['c_domicilionumero']= elem.c_domicilionumero;
        item['c_cp']= elem.c_cp;
        item['c_colonia']= elem.c_colonia;
        item['c_ciudad']= elem.c_ciudad;
        item['c_estado']= elem.c_estado;
        item['c_correoelectronico1']= elem.c_correoelectronico1;
        item['c_correoelectronico2']= elem.c_correoelectronico2;
        item['c_telefono']= elem.c_telefono;
        item['c_movil']= elem.c_movil;
        item['c_giro']= elem.c_giro;
        item['c_rfc']= elem.c_rfc;
        item['c_curp']= elem.c_curp;
        this.setState({
          item: item
        });
      }
    



export function  HandleCertificadoChange(event) {
        console.log('event', event.target);
        let name = event.target.name;
        let item = this.props.item;
        
        item[name]= eventvalue(event);
        console.log('item', item);
        this.setState({
          item: item
        });
      }




export function copiarcontratante (item){

  item.t_nombre = item.c_nombre ;
  item.t_appaterno = item.c_appaterno ;
  item.t_apmaterno = item.c_apmaterno ;
  item.t_fnac = item.c_fnac ;
  item.t_ocupacion = item.c_ocupacion ;
  item.t_estadocivil = item.c_estadocivil ;
  item.t_domicilio = item.c_domicilio ;
  item.t_domicilionumero = item.c_domicilionumero ;
  item.t_cp = item.c_cp ;
  item.t_colonia = item.c_colonia ;
  item.t_ciudad = item.c_ciudad ;
  item.t_estado = item.c_estado ;
  item.t_correoelectronico1 = item.c_correoelectronico1 ;
  item.t_correoelectronico2 = item.c_correoelectronico2 ;
  item.t_telefono = item.c_telefono ;
  item.t_movil = item.c_movil ;
  item.t_giro = item.c_giro ;
  item.t_rfc = item.c_rfc ;
  item.t_ = item.c_rfc ;
  item.t_curp = item.c_curp ;

}


export function  onCopiarContatanteClick (event) {
    event.preventDefault(); 
    
    this.props.ejecutaEnCertificadoPolizaAction(copiarcontratante);
  }



  export function HandleAseguradoChange(event,index) {
    console.log('handleAseguradoChange index', index);
    console.log('handleAseguradoChange event', event);
    console.log('handleAseguradoChange props.asegurados', this.props.asegurados);
    let name = event.target.name;
    let asegurados = this.props.asegurados;

    let item = asegurados[index];

    item[name]= eventvalue(event);
    item['modificado']=true;
    this.setState({
      asegurados: asegurados
    });
  }

  export function HandleBenefChange(event,index) {
    console.log('handleBenefChange index', index);
    console.log('handleBenefChange event', event);
    console.log('handleBenefChange props.benefs', this.props.benefs);
    let name = event.target.name;
    let benefs = this.props.benefs;

    let item = benefs[index];

    item[name]= eventvalue(event);
    item['modificado']=true;
    this.setState({
      benefs: benefs
    });
  }

  export function HandlePagosChange(event,index) {
    const name = event.target.name;
    let pagos = this.props.pagos;

    let item = pagos[index];
    const pago1 = pagos[0];

    item[name]= eventvalue(event);

    if (name==='total'){

      //Obtiene el cargo fraccionado según el primer pago
      const factorprima = pago1['cargopagofraccionado'] / (pago1['subtotal']-pago1['derechodepoliza']) ;

      item['subtotal']=parseFloat(parseFloat(item['total']/1.16).toFixed(2));
      item['iva']=item['total']-item['subtotal'];

      item['cargopagofraccionado'] =parseFloat(parseFloat(item['subtotal'] * factorprima).toFixed(2));
      item['primaneta']=parseFloat(parseFloat(item['subtotal'] -item['cargopagofraccionado']).toFixed(2));
      item['modificado'] = true;
    }
    this.setState({
      pagos: pagos
    });
  }


  
  export function HandleEndosoChange(event,index) {
    let name = event.target.name;
    let endosos = this.props.endosos;

    let item = endosos[index];

    item[name]= eventvalue(event);

    this.setState({
      endosos: endosos
    });
  }