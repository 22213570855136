import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {  getCookie } from '../../../utils/cookies';

//import { getCookie } from '../../../utils/cookies';
import {getBuscarAction} from '../../../store/actions/buscar/actions'
import './header.css';

class HeaderComponent extends Component {

  state = {
    isRedirect: false,
    mes:0,
    anio:0
  }

  constructor(props) {
    super(props);
    const mes = new Date().getMonth()+1;
    const anio = new Date().getFullYear();
    this.state = {
      isRedirect:   false,
      mes:          mes,
      anio:         anio

    }
  }

  componentDidMount() {
    document.title = 'Taffeit';
  }

  onSubmitHandler = (event) =>{
    event.preventDefault();

    let concepto = event.target.buscar.value;
    const data = {concepto}
    this.props.dispatch(getBuscarAction(data));
    this.setState({isRedirect:true})
    }
  
  render() {
   
    if (this.state.isRedirect && window.location.pathname.split('/')[1]!=='buscar') {
      return <Redirect to='/buscar' />
    }
    const tok = getCookie('timeoff-token')
    if ((tok === null || tok === '') && (window.location.pathname.split('/')[2]!=='login')){
        return <Redirect to='/login' />;
    }
    
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-fixed">

        <Link to='/' className='navbar-brand logo'></Link>
        
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
      
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className='navbar-nav mr-auto'>
                {/* <li className='navbar-item'><Link className={(window.location.pathname.split('/')[1] === '') ? 'nav-link active' : 'nav-link inactive'} to='/'>Home</Link></li>
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'profile') ? 'nav-link active' : 'nav-link inactive'} to='/employee/profile'>Profile</Link></li>  */}
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'ventas') ? 'nav-link active' : 'nav-link inactive'} to={'/dashboard/ventas/'+this.state.anio+'/'+this.state.mes}>Ventas</Link></li> 
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'pagos') ? 'nav-link active' : 'nav-link inactive'} to={'/dashboard/pagos/'+this.state.anio+'/'+this.state.mes}>Pagos</Link></li> 
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'polizas') ? 'nav-link active' : 'nav-link inactive'} to='/polizas'>Pólizas</Link></li> 
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'conciliapagos') ? 'nav-link active' : 'nav-link inactive'} to='/conciliapagos'>Concilia pagos</Link></li> 
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'pagosoxxo') ? 'nav-link active' : 'nav-link inactive'} to='/cargapagosoxxo'>Carga pagos Oxxo</Link></li> 
                <li className='navbar-item'><Link className={(window.location.pathname.split('/')[2] === 'comisiones') ? 'nav-link active' : 'nav-link inactive'} to='/comisiones/pendientes'>Comisiones</Link></li> 
            </ul>
            <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmitHandler}>
                <input className="form-control mr-sm-2" id="buscar" type="search" placeholder="Buscar" aria-label="Search"/>
                <button className="btn btn-outline-dark my-2 my-sm-0" type="submit"><i className="fa fa-search"></i></button>
            </form>

            <div className="nav-item dropdown ml-5">
                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {/* {getCookie('name')} */}
                    <i className="fa fa-user fa-fw"></i> 
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item fas fa-sign-out-alt" href="/logout">Salir</a>
                
                </div>
            </div>



        </div>
      </nav>
    );
  }
}

const mapStateToProps = (estado) => ({login:estado.login.response});


export default connect(mapStateToProps)(HeaderComponent);