import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    estatusregistropagooxxo: {
 id_estatusregistropagooxxo : 0, estatusregistropagooxxo : '',
      },

      lista_registropagooxxo_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ESTATUSREGISTROPAGOOXXO:
      item = state.estatusregistropagooxxo;
      action.fn(item);
      return { ...state,  estatusregistropagooxxo: item };


    case types.GET_ESTATUSREGISTROPAGOOXXO_SUCCESS:
      respuesta= action.response;
      console.log('GET_ESTATUSREGISTROPAGOOXXO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', estatusregistropagooxxo: respuesta };

    case types.GET_ESTATUSREGISTROPAGOOXXO_ERROR:
      respuesta= action.response;
      console.log('GET_ESTATUSREGISTROPAGOOXXO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ESTATUSREGISTROPAGOOXXO_SUCCESS:
      respuesta= action.response;
      console.log('POST_ESTATUSREGISTROPAGOOXXO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', estatusregistropagooxxo: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ESTATUSREGISTROPAGOOXXO_ERROR:
      respuesta= action.response;
      console.log('POST_ESTATUSREGISTROPAGOOXXO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ESTATUSREGISTROPAGOOXXO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ESTATUSREGISTROPAGOOXXO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', estatusregistropagooxxo: respuesta };

    case types.PUT_ESTATUSREGISTROPAGOOXXO_ERROR:
      respuesta= action.response;
      console.log('PUT_ESTATUSREGISTROPAGOOXXO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ESTATUSREGISTROPAGOOXXO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ESTATUSREGISTROPAGOOXXO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ESTATUSREGISTROPAGOOXXO_ERROR:
      respuesta= action.response;
      console.log('DELETE_ESTATUSREGISTROPAGOOXXO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO:
      item = state.estatusregistropagooxxo;
      item.registrospagooxxo= [...item.registrospagooxxo, action.payload]
      return { ...state, registropagooxxo:item};


    case types.BORRA_REGISTROPAGOOXXO_EN_ESTATUSREGISTROPAGOOXXO:
      item = state.estatusregistropagooxxo;
      let lista_registropagooxxo_borrados= state.lista_registropagooxxo_borrados
      if (item.registrospagooxxo[action.payload].id_registropagooxxo > 0){
            const elementoregistropagooxxoborrar = { id_registropagooxxo : item.registrospagooxxo[action.payload].id_registropagooxxo }
            lista_registropagooxxo_borrados= [...lista_registropagooxxo_borrados,  elementoregistropagooxxoborrar]
      }
      item.registrospagooxxo.splice(action.payload,1)
      return { ...state, registropagooxxo:item, lista_registropagooxxo_borrados : lista_registropagooxxo_borrados};


    default:
      return state;
  }
};

