import * as types from './types';


export const getDashboardVentasAction = (data) => {
  return {
    type: types.GET_DASHBOARD_VENTAS,
    data
  }
};

export const getDashboardPagosAction = (data) => {
  return {
    type: types.GET_DASHBOARD_PAGOS,
    data
  }
};

export const setDashboardPagosVistaAction = (vista) => {
  return {
    type: types.SET_DASHBOARD_PAGOS_VISTA,
    vista
  }
};
export const setDashboardPagosFiltroAction = (filtro) => {
  return {
    type: types.SET_DASHBOARD_PAGOS_FILTRO,
    filtro
  }
};
export const setDashboardVentasFiltroAction = (filtro) => {
  return {
    type: types.SET_DASHBOARD_VENTAS_FILTRO,
    filtro
  }
};
export const setDashboardCertificadosFiltroAction = (filtro) => {
  return {
    type: types.SET_DASHBOARD_CERTIFICADOS_FILTRO,
    filtro
  }
};

export const getDashboardCertificadosAction = () => {
  return {
    type: types.GET_DASHBOARD_CERTIFICADOS,
    data:{}
  }
};
