import React from 'react';
import Modal from 'react-modal'
import InputField from '../../components/inputfield'

const FacturasAgenteView = (props) => {
  const customStyles = {
    
    overlay : {
      backgroundColor:'grey',
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };


  // if (props.listagentefacturas.length === 0) {
  //   return <div className='p-4' >
  //       <div className='seguros-none'>No se encontraron facturas.</div>
  //   </div>
  // }
  return (
    <section className='row p-4'>
       
        <Modal 
            isOpen={props.facturamodal.show}
            onRequestClose={(e) => props.onCerrarFacturaClick(e)}
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            >
            <InputField className='col-md-12'  type='text' field='facturasagente.facturaagente' value={props.facturaagente.facturaagente} onChange={props.HandleChange} />
            <InputField className='col-md-12'  type='date' field='facturasagente.fechapago' value={props.facturaagente.fechapago} onChange={props.HandleChange} />
        
            <InputField className='col-md-12' type='number' step='0.01' field="facturasagente.subtotal" value={props.facturaagente.subtotal} onChange={props.HandleChange} />
            <InputField className='col-md-12' disabled type='number' step='0.01' field="facturasagente.iva" value={props.facturaagente.iva} onChange={props.HandleChange} />
            <InputField className='col-md-12' disabled type='number' step='0.01' field="facturasagente.retiva" value={props.facturaagente.retiva} onChange={props.HandleChange} />
            <InputField className='col-md-12' disabled type='number' step='0.01' field="facturasagente.retisr" value={props.facturaagente.retisr} onChange={props.HandleChange} />
            <InputField className='col-md-12' disabled type='number' step='0.01' field="facturasagente.total" value={props.facturaagente.total} onChange={props.HandleChange} />
            <InputField className='col-md-12' type='number' step='0.01' field="facturasagente.ajuste" value={props.facturaagente.ajuste} onChange={props.HandleChange} />
                    
            <button className="btn-primary" 
                onClick={(e) => props.onCerrarFacturaClick(e)}>
                Cerrar
            </button>
            <button className="btn-primary m-3" 
                onClick={(e) => props.onGrabarFacturaClick(e)}>
                Grabar
            </button>
        </Modal>
    </section>
  );
};

export default FacturasAgenteView;