import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



// -----------------------CertificadosPoliza--------------- 
export const getCertificadosPolizaService = (request) => {
  const apiURL = baseurl('certificadospoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getCertificadoPolizaService = (CertificadoPoliza) => {
  const apiURL= baseurl(`certificadopoliza/${CertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postCertificadoPolizaService = (certificadopoliza) => {
  const apiURL= baseurl(`certificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(certificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', certificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putCertificadoPolizaService = (certificadopoliza) => {
  const apiURL= baseurl(`certificadopoliza/${certificadopoliza.id_certificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(certificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteCertificadoPolizaService = (id_certificadopoliza) => {
  const apiURL= baseurl(`certificadopoliza/${id_certificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Polizas--------------- 
export const getPolizasService = (request) => {
  const apiURL = baseurl('polizas');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getPolizaService = (Poliza) => {
  const apiURL= baseurl(`poliza/${Poliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postPolizaService = (poliza) => {
  const apiURL= baseurl(`poliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(poliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', poliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putPolizaService = (poliza) => {
  const apiURL= baseurl(`poliza/${poliza.id_poliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(poliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deletePolizaService = (id_poliza) => {
  const apiURL= baseurl(`poliza/${id_poliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------ProductosPoliza--------------- 
export const getProductosPolizaService = (request) => {
  const apiURL = baseurl('productospoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getProductoPolizaService = (ProductoPoliza) => {
  const apiURL= baseurl(`productopoliza/${ProductoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postProductoPolizaService = (productopoliza) => {
  const apiURL= baseurl(`productopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(productopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', productopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putProductoPolizaService = (productopoliza) => {
  const apiURL= baseurl(`productopoliza/${productopoliza.id_productopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(productopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteProductoPolizaService = (id_productopoliza) => {
  const apiURL= baseurl(`productopoliza/${id_productopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------DPsProductoPoliza--------------- 
export const getDPsProductoPolizaService = (request) => {
  const apiURL = baseurl('dpsproductopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getDPProductoPolizaService = (DPProductoPoliza) => {
  const apiURL= baseurl(`dpproductopoliza/${DPProductoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postDPProductoPolizaService = (dpproductopoliza) => {
  const apiURL= baseurl(`dpproductopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(dpproductopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', dpproductopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putDPProductoPolizaService = (dpproductopoliza) => {
  const apiURL= baseurl(`dpproductopoliza/${dpproductopoliza.id_dpproductopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(dpproductopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteDPProductoPolizaService = (id_dpproductopoliza) => {
  const apiURL= baseurl(`dpproductopoliza/${id_dpproductopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------AseguradosCertificadoPoliza--------------- 
export const getAseguradosCertificadoPolizaService = (request) => {
  const apiURL = baseurl('aseguradoscertificadopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getAseguradoCertificadoPolizaService = (AseguradoCertificadoPoliza) => {
  const apiURL= baseurl(`aseguradocertificadopoliza/${AseguradoCertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postAseguradoCertificadoPolizaService = (aseguradocertificadopoliza) => {
  const apiURL= baseurl(`aseguradocertificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(aseguradocertificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', aseguradocertificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putAseguradoCertificadoPolizaService = (aseguradocertificadopoliza) => {
  const apiURL= baseurl(`aseguradocertificadopoliza/${aseguradocertificadopoliza.id_aseguradocertificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(aseguradocertificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteAseguradoCertificadoPolizaService = (id_aseguradocertificadopoliza) => {
  const apiURL= baseurl(`aseguradocertificadopoliza/${id_aseguradocertificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------TelsCertificadoPoliza--------------- 
export const getTelsCertificadoPolizaService = (request) => {
  const apiURL = baseurl('telscertificadopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getTelCertificadoPolizaService = (TelCertificadoPoliza) => {
  const apiURL= baseurl(`telcertificadopoliza/${TelCertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postTelCertificadoPolizaService = (telcertificadopoliza) => {
  const apiURL= baseurl(`telcertificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(telcertificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', telcertificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putTelCertificadoPolizaService = (telcertificadopoliza) => {
  const apiURL= baseurl(`telcertificadopoliza/${telcertificadopoliza.id_telcertificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(telcertificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteTelCertificadoPolizaService = (id_telcertificadopoliza) => {
  const apiURL= baseurl(`telcertificadopoliza/${id_telcertificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------ArchivosCertificadoPoliza--------------- 
export const getArchivosCertificadoPolizaService = (request) => {
  const apiURL = baseurl('archivoscertificadopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getArchivoCertificadoPolizaService = (ArchivoCertificadoPoliza) => {
  const apiURL= baseurl(`archivocertificadopoliza/${ArchivoCertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postArchivoCertificadoPolizaService = (archivocertificadopoliza) => {
  const apiURL= baseurl(`archivocertificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(archivocertificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', archivocertificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putArchivoCertificadoPolizaService = (archivocertificadopoliza) => {
  const apiURL= baseurl(`archivocertificadopoliza/${archivocertificadopoliza.id_archivocertificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(archivocertificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteArchivoCertificadoPolizaService = (id_archivocertificadopoliza) => {
  const apiURL= baseurl(`archivocertificadopoliza/${id_archivocertificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------DPsCertificadoPoliza--------------- 
export const getDPsCertificadoPolizaService = (request) => {
  const apiURL = baseurl('dpscertificadopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getDPCertificadoPolizaService = (DPCertificadoPoliza) => {
  const apiURL= baseurl(`dpcertificadopoliza/${DPCertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postDPCertificadoPolizaService = (dpcertificadopoliza) => {
  const apiURL= baseurl(`dpcertificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(dpcertificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', dpcertificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putDPCertificadoPolizaService = (dpcertificadopoliza) => {
  const apiURL= baseurl(`dpcertificadopoliza/${dpcertificadopoliza.id_dpcertificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(dpcertificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteDPCertificadoPolizaService = (id_dpcertificadopoliza) => {
  const apiURL= baseurl(`dpcertificadopoliza/${id_dpcertificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------PagosOxxo--------------- 
export const getPagosOxxoService = (request) => {
  const apiURL = baseurl('pagosoxxo');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getPagoOxxoService = (PagoOxxo) => {
  const apiURL= baseurl(`pagooxxo/${PagoOxxo.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postPagoOxxoService = (pagooxxo) => {
  const apiURL= baseurl(`pagooxxo`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(pagooxxo)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', pagooxxo);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putPagoOxxoService = (pagooxxo) => {
  const apiURL= baseurl(`pagooxxo/${pagooxxo.id_pagooxxo}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(pagooxxo)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deletePagoOxxoService = (id_pagooxxo) => {
  const apiURL= baseurl(`pagooxxo/${id_pagooxxo}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------RegistrosPagoOxxo--------------- 
export const getRegistrosPagoOxxoService = (request) => {
  const apiURL = baseurl('registrospagooxxo');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getRegistroPagoOxxoService = (RegistroPagoOxxo) => {
  const apiURL= baseurl(`registropagooxxo/${RegistroPagoOxxo.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postRegistroPagoOxxoService = (registropagooxxo) => {
  const apiURL= baseurl(`registropagooxxo`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(registropagooxxo)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', registropagooxxo);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putRegistroPagoOxxoService = (registropagooxxo) => {
  const apiURL= baseurl(`registropagooxxo/${registropagooxxo.id_registropagooxxo}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(registropagooxxo)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteRegistroPagoOxxoService = (id_registropagooxxo) => {
  const apiURL= baseurl(`registropagooxxo/${id_registropagooxxo}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------EndososCertificadoPoliza--------------- 
export const getEndososCertificadoPolizaService = (request) => {
  const apiURL = baseurl('endososcertificadopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getEndosoCertificadoPolizaService = (EndosoCertificadoPoliza) => {
  const apiURL= baseurl(`endosocertificadopoliza/${EndosoCertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postEndosoCertificadoPolizaService = (endosocertificadopoliza) => {
  const apiURL= baseurl(`endosocertificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(endosocertificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', endosocertificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putEndosoCertificadoPolizaService = (endosocertificadopoliza) => {
  const apiURL= baseurl(`endosocertificadopoliza/${endosocertificadopoliza.id_endosocertificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(endosocertificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteEndosoCertificadoPolizaService = (id_endosocertificadopoliza) => {
  const apiURL= baseurl(`endosocertificadopoliza/${id_endosocertificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------BenefsCertificadoPoliza--------------- 
export const getBenefsCertificadoPolizaService = (request) => {
  const apiURL = baseurl('benefscertificadopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getBenefCertificadoPolizaService = (BenefCertificadoPoliza) => {
  const apiURL= baseurl(`benefcertificadopoliza/${BenefCertificadoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postBenefCertificadoPolizaService = (benefcertificadopoliza) => {
  const apiURL= baseurl(`benefcertificadopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(benefcertificadopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', benefcertificadopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putBenefCertificadoPolizaService = (benefcertificadopoliza) => {
  const apiURL= baseurl(`benefcertificadopoliza/${benefcertificadopoliza.id_benefcertificadopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(benefcertificadopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteBenefCertificadoPolizaService = (id_benefcertificadopoliza) => {
  const apiURL= baseurl(`benefcertificadopoliza/${id_benefcertificadopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------FacturasAgente--------------- 
export const getFacturasAgenteService = (request) => {
  const apiURL = baseurl('facturasagente');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getFacturaAgenteService = (FacturaAgente) => {
  const apiURL= baseurl(`facturaagente/${FacturaAgente.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postFacturaAgenteService = (facturaagente) => {
  const apiURL= baseurl(`facturaagente`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(facturaagente)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', facturaagente);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putFacturaAgenteService = (facturaagente) => {
  const apiURL= baseurl(`facturaagente/${facturaagente.id_facturaagente}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(facturaagente)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteFacturaAgenteService = (id_facturaagente) => {
  const apiURL= baseurl(`facturaagente/${id_facturaagente}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------PrimasProductoPoliza--------------- 
export const getPrimasProductoPolizaService = (request) => {
  const apiURL = baseurl('primasproductopoliza');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getPrimaProductoPolizaService = (PrimaProductoPoliza) => {
  const apiURL= baseurl(`primaproductopoliza/${PrimaProductoPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postPrimaProductoPolizaService = (primaproductopoliza) => {
  const apiURL= baseurl(`primaproductopoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(primaproductopoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', primaproductopoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putPrimaProductoPolizaService = (primaproductopoliza) => {
  const apiURL= baseurl(`primaproductopoliza/${primaproductopoliza.id_primaproductopoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(primaproductopoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deletePrimaProductoPolizaService = (id_primaproductopoliza) => {
  const apiURL= baseurl(`primaproductopoliza/${id_primaproductopoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Aseguradoras--------------- 
export const getAseguradorasService = (request) => {
  const apiURL = baseurl('aseguradoras');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getAseguradoraService = (Aseguradora) => {
  const apiURL= baseurl(`aseguradora/${Aseguradora.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postAseguradoraService = (aseguradora) => {
  const apiURL= baseurl(`aseguradora`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(aseguradora)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', aseguradora);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putAseguradoraService = (aseguradora) => {
  const apiURL= baseurl(`aseguradora/${aseguradora.id_aseguradora}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(aseguradora)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteAseguradoraService = (id_aseguradora) => {
  const apiURL= baseurl(`aseguradora/${id_aseguradora}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Productos--------------- 
export const getProductosService = (request) => {
  const apiURL = baseurl('productos');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getProductoService = (Producto) => {
  const apiURL= baseurl(`producto/${Producto.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postProductoService = (producto) => {
  const apiURL= baseurl(`producto`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(producto)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', producto);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putProductoService = (producto) => {
  const apiURL= baseurl(`producto/${producto.id_producto}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(producto)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteProductoService = (id_producto) => {
  const apiURL= baseurl(`producto/${id_producto}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Agentes--------------- 
export const getAgentesService = (request) => {
  const apiURL = baseurl('agentes');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getAgenteService = (Agente) => {
  const apiURL= baseurl(`agente/${Agente.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postAgenteService = (agente) => {
  const apiURL= baseurl(`agente`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(agente)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', agente);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putAgenteService = (agente) => {
  const apiURL= baseurl(`agente/${agente.id_agente}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(agente)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteAgenteService = (id_agente) => {
  const apiURL= baseurl(`agente/${id_agente}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------DetallesListaDePrecio--------------- 
export const getDetallesListaDePrecioService = (request) => {
  const apiURL = baseurl('detalleslistadeprecio');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getDetalleListaDePrecioService = (DetalleListaDePrecio) => {
  const apiURL= baseurl(`detallelistadeprecio/${DetalleListaDePrecio.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postDetalleListaDePrecioService = (detallelistadeprecio) => {
  const apiURL= baseurl(`detallelistadeprecio`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(detallelistadeprecio)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', detallelistadeprecio);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putDetalleListaDePrecioService = (detallelistadeprecio) => {
  const apiURL= baseurl(`detallelistadeprecio/${detallelistadeprecio.id_detallelistadeprecio}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(detallelistadeprecio)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteDetalleListaDePrecioService = (id_detallelistadeprecio) => {
  const apiURL= baseurl(`detallelistadeprecio/${id_detallelistadeprecio}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------AseguradosProducto--------------- 
export const getAseguradosProductoService = (request) => {
  const apiURL = baseurl('aseguradosproducto');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getAseguradoProductoService = (AseguradoProducto) => {
  const apiURL= baseurl(`aseguradoproducto/${AseguradoProducto.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postAseguradoProductoService = (aseguradoproducto) => {
  const apiURL= baseurl(`aseguradoproducto`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(aseguradoproducto)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', aseguradoproducto);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putAseguradoProductoService = (aseguradoproducto) => {
  const apiURL= baseurl(`aseguradoproducto/${aseguradoproducto.id_aseguradoproducto}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(aseguradoproducto)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteAseguradoProductoService = (id_aseguradoproducto) => {
  const apiURL= baseurl(`aseguradoproducto/${id_aseguradoproducto}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------ListasDePrecio--------------- 
export const getListasDePrecioService = (request) => {
  const apiURL = baseurl('listasdeprecio');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getListaDePrecioService = (ListaDePrecio) => {
  const apiURL= baseurl(`listadeprecio/${ListaDePrecio.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postListaDePrecioService = (listadeprecio) => {
  const apiURL= baseurl(`listadeprecio`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(listadeprecio)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', listadeprecio);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putListaDePrecioService = (listadeprecio) => {
  const apiURL= baseurl(`listadeprecio/${listadeprecio.id_listadeprecio}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(listadeprecio)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteListaDePrecioService = (id_listadeprecio) => {
  const apiURL= baseurl(`listadeprecio/${id_listadeprecio}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------PrimasListaDePrecio--------------- 
export const getPrimasListaDePrecioService = (request) => {
  const apiURL = baseurl('primaslistadeprecio');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getPrimaListaDePrecioService = (PrimaListaDePrecio) => {
  const apiURL= baseurl(`primalistadeprecio/${PrimaListaDePrecio.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postPrimaListaDePrecioService = (primalistadeprecio) => {
  const apiURL= baseurl(`primalistadeprecio`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(primalistadeprecio)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', primalistadeprecio);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putPrimaListaDePrecioService = (primalistadeprecio) => {
  const apiURL= baseurl(`primalistadeprecio/${primalistadeprecio.id_primalistadeprecio}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(primalistadeprecio)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deletePrimaListaDePrecioService = (id_primalistadeprecio) => {
  const apiURL= baseurl(`primalistadeprecio/${id_primalistadeprecio}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------CoberturasProducto--------------- 
export const getCoberturasProductoService = (request) => {
  const apiURL = baseurl('coberturasproducto');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getCoberturaProductoService = (CoberturaProducto) => {
  const apiURL= baseurl(`coberturaproducto/${CoberturaProducto.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postCoberturaProductoService = (coberturaproducto) => {
  const apiURL= baseurl(`coberturaproducto`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(coberturaproducto)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', coberturaproducto);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putCoberturaProductoService = (coberturaproducto) => {
  const apiURL= baseurl(`coberturaproducto/${coberturaproducto.id_coberturaproducto}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(coberturaproducto)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteCoberturaProductoService = (id_coberturaproducto) => {
  const apiURL= baseurl(`coberturaproducto/${id_coberturaproducto}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------ClavesComision--------------- 
export const getClavesComisionService = (request) => {
  const apiURL = baseurl('clavescomision');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getClaveComisionService = (ClaveComision) => {
  const apiURL= baseurl(`clavecomision/${ClaveComision.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postClaveComisionService = (clavecomision) => {
  const apiURL= baseurl(`clavecomision`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(clavecomision)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', clavecomision);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putClaveComisionService = (clavecomision) => {
  const apiURL= baseurl(`clavecomision/${clavecomision.id_clavecomision}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(clavecomision)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteClaveComisionService = (id_clavecomision) => {
  const apiURL= baseurl(`clavecomision/${id_clavecomision}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------ProductosClaveComision--------------- 
export const getProductosClaveComisionService = (request) => {
  const apiURL = baseurl('productosclavecomision');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getProductoClaveComisionService = (ProductoClaveComision) => {
  const apiURL= baseurl(`productoclavecomision/${ProductoClaveComision.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postProductoClaveComisionService = (productoclavecomision) => {
  const apiURL= baseurl(`productoclavecomision`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(productoclavecomision)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', productoclavecomision);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putProductoClaveComisionService = (productoclavecomision) => {
  const apiURL= baseurl(`productoclavecomision/${productoclavecomision.id_productoclavecomision}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(productoclavecomision)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteProductoClaveComisionService = (id_productoclavecomision) => {
  const apiURL= baseurl(`productoclavecomision/${id_productoclavecomision}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------FormasPago--------------- 
export const getFormasPagoService = (request) => {
  const apiURL = baseurl('formaspago');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getFormaPagoService = (FormaPago) => {
  const apiURL= baseurl(`formapago/${FormaPago.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postFormaPagoService = (formapago) => {
  const apiURL= baseurl(`formapago`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(formapago)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', formapago);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putFormaPagoService = (formapago) => {
  const apiURL= baseurl(`formapago/${formapago.id_formapago}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(formapago)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteFormaPagoService = (id_formapago) => {
  const apiURL= baseurl(`formapago/${id_formapago}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Parentezcos--------------- 
export const getParentezcosService = (request) => {
  const apiURL = baseurl('parentezcos');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getParentezcoService = (Parentezco) => {
  const apiURL= baseurl(`parentezco/${Parentezco.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postParentezcoService = (parentezco) => {
  const apiURL= baseurl(`parentezco`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(parentezco)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', parentezco);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putParentezcoService = (parentezco) => {
  const apiURL= baseurl(`parentezco/${parentezco.id_parentezco}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(parentezco)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteParentezcoService = (id_parentezco) => {
  const apiURL= baseurl(`parentezco/${id_parentezco}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------EstatusPagos--------------- 
export const getEstatusPagosService = (request) => {
  const apiURL = baseurl('estatuspagos');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getEstatusPagoService = (EstatusPago) => {
  const apiURL= baseurl(`estatuspago/${EstatusPago.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postEstatusPagoService = (estatuspago) => {
  const apiURL= baseurl(`estatuspago`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatuspago)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', estatuspago);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putEstatusPagoService = (estatuspago) => {
  const apiURL= baseurl(`estatuspago/${estatuspago.id_estatuspago}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatuspago)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteEstatusPagoService = (id_estatuspago) => {
  const apiURL= baseurl(`estatuspago/${id_estatuspago}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------TiposProducto--------------- 
export const getTiposProductoService = (request) => {
  const apiURL = baseurl('tiposproducto');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getTipoProductoService = (TipoProducto) => {
  const apiURL= baseurl(`tipoproducto/${TipoProducto.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postTipoProductoService = (tipoproducto) => {
  const apiURL= baseurl(`tipoproducto`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(tipoproducto)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', tipoproducto);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putTipoProductoService = (tipoproducto) => {
  const apiURL= baseurl(`tipoproducto/${tipoproducto.id_tipoproducto}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(tipoproducto)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteTipoProductoService = (id_tipoproducto) => {
  const apiURL= baseurl(`tipoproducto/${id_tipoproducto}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Origenes--------------- 
export const getOrigenesService = (request) => {
  const apiURL = baseurl('origenes');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getOrigenService = (Origen) => {
  const apiURL= baseurl(`origen/${Origen.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postOrigenService = (origen) => {
  const apiURL= baseurl(`origen`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(origen)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', origen);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putOrigenService = (origen) => {
  const apiURL= baseurl(`origen/${origen.id_origen}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(origen)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteOrigenService = (id_origen) => {
  const apiURL= baseurl(`origen/${id_origen}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Generos--------------- 
export const getGenerosService = (request) => {
  const apiURL = baseurl('generos');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getGeneroService = (Genero) => {
  const apiURL= baseurl(`genero/${Genero.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postGeneroService = (genero) => {
  const apiURL= baseurl(`genero`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(genero)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', genero);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putGeneroService = (genero) => {
  const apiURL= baseurl(`genero/${genero.id_genero}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(genero)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteGeneroService = (id_genero) => {
  const apiURL= baseurl(`genero/${id_genero}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------EstatusCertificados--------------- 
export const getEstatusCertificadosService = (request) => {
  const apiURL = baseurl('estatuscertificados');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getEstatusCertificadoService = (EstatusCertificado) => {
  const apiURL= baseurl(`estatuscertificado/${EstatusCertificado.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postEstatusCertificadoService = (estatuscertificado) => {
  const apiURL= baseurl(`estatuscertificado`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatuscertificado)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', estatuscertificado);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putEstatusCertificadoService = (estatuscertificado) => {
  const apiURL= baseurl(`estatuscertificado/${estatuscertificado.id_estatuscertificado}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatuscertificado)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteEstatusCertificadoService = (id_estatuscertificado) => {
  const apiURL= baseurl(`estatuscertificado/${id_estatuscertificado}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------EstatusPolizas--------------- 
export const getEstatusPolizasService = (request) => {
  const apiURL = baseurl('estatuspolizas');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getEstatusPolizaService = (EstatusPoliza) => {
  const apiURL= baseurl(`estatuspoliza/${EstatusPoliza.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postEstatusPolizaService = (estatuspoliza) => {
  const apiURL= baseurl(`estatuspoliza`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatuspoliza)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', estatuspoliza);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putEstatusPolizaService = (estatuspoliza) => {
  const apiURL= baseurl(`estatuspoliza/${estatuspoliza.id_estatuspoliza}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatuspoliza)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteEstatusPolizaService = (id_estatuspoliza) => {
  const apiURL= baseurl(`estatuspoliza/${id_estatuspoliza}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------MediosPago--------------- 
export const getMediosPagoService = (request) => {
  const apiURL = baseurl('mediospago');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getMedioPagoService = (MedioPago) => {
  const apiURL= baseurl(`mediopago/${MedioPago.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postMedioPagoService = (mediopago) => {
  const apiURL= baseurl(`mediopago`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(mediopago)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', mediopago);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putMedioPagoService = (mediopago) => {
  const apiURL= baseurl(`mediopago/${mediopago.id_mediopago}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(mediopago)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteMedioPagoService = (id_mediopago) => {
  const apiURL= baseurl(`mediopago/${id_mediopago}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------EstatusRegistrosPagoOxxo--------------- 
export const getEstatusRegistrosPagoOxxoService = (request) => {
  const apiURL = baseurl('estatusregistrospagooxxo');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getEstatusRegistroPagoOxxoService = (EstatusRegistroPagoOxxo) => {
  const apiURL= baseurl(`estatusregistropagooxxo/${EstatusRegistroPagoOxxo.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postEstatusRegistroPagoOxxoService = (estatusregistropagooxxo) => {
  const apiURL= baseurl(`estatusregistropagooxxo`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatusregistropagooxxo)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', estatusregistropagooxxo);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putEstatusRegistroPagoOxxoService = (estatusregistropagooxxo) => {
  const apiURL= baseurl(`estatusregistropagooxxo/${estatusregistropagooxxo.id_estatusregistropagooxxo}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(estatusregistropagooxxo)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteEstatusRegistroPagoOxxoService = (id_estatusregistropagooxxo) => {
  const apiURL= baseurl(`estatusregistropagooxxo/${id_estatusregistropagooxxo}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------MotivosCancelacion--------------- 
export const getMotivosCancelacionService = (request) => {
  const apiURL = baseurl('motivoscancelacion');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getMotivoCancelacionService = (MotivoCancelacion) => {
  const apiURL= baseurl(`motivocancelacion/${MotivoCancelacion.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postMotivoCancelacionService = (motivocancelacion) => {
  const apiURL= baseurl(`motivocancelacion`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(motivocancelacion)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', motivocancelacion);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putMotivoCancelacionService = (motivocancelacion) => {
  const apiURL= baseurl(`motivocancelacion/${motivocancelacion.id_motivocancelacion}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(motivocancelacion)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteMotivoCancelacionService = (id_motivocancelacion) => {
  const apiURL= baseurl(`motivocancelacion/${id_motivocancelacion}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------RegimenesFiscales--------------- 
export const getRegimenesFiscalesService = (request) => {
  const apiURL = baseurl('regimenesfiscales');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getRegimenFiscalService = (RegimenFiscal) => {
  const apiURL= baseurl(`regimenfiscal/${RegimenFiscal.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postRegimenFiscalService = (regimenfiscal) => {
  const apiURL= baseurl(`regimenfiscal`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(regimenfiscal)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', regimenfiscal);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putRegimenFiscalService = (regimenfiscal) => {
  const apiURL= baseurl(`regimenfiscal/${regimenfiscal.id_regimenfiscal}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(regimenfiscal)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteRegimenFiscalService = (id_regimenfiscal) => {
  const apiURL= baseurl(`regimenfiscal/${id_regimenfiscal}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Usuarios--------------- 
export const getUsuariosService = (request) => {
  const apiURL = baseurl('usuarios');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getUsuarioService = (Usuario) => {
  const apiURL= baseurl(`usuario/${Usuario.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postUsuarioService = (usuario) => {
  const apiURL= baseurl(`usuario`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(usuario)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', usuario);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putUsuarioService = (usuario) => {
  const apiURL= baseurl(`usuario/${usuario.id_usuario}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(usuario)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteUsuarioService = (id_usuario) => {
  const apiURL= baseurl(`usuario/${id_usuario}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Actualizaciones--------------- 
export const getActualizacionesService = (request) => {
  const apiURL = baseurl('actualizaciones');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getActualizacionService = (Actualizacion) => {
  const apiURL= baseurl(`actualizacion/${Actualizacion.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postActualizacionService = (actualizacion) => {
  const apiURL= baseurl(`actualizacion`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(actualizacion)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', actualizacion);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putActualizacionService = (actualizacion) => {
  const apiURL= baseurl(`actualizacion/${actualizacion.id_actualizacion}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(actualizacion)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteActualizacionService = (id_actualizacion) => {
  const apiURL= baseurl(`actualizacion/${id_actualizacion}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Operaciones--------------- 
export const getOperacionesService = (request) => {
  const apiURL = baseurl('operaciones');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getOperacionService = (Operacion) => {
  const apiURL= baseurl(`operacion/${Operacion.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postOperacionService = (operacion) => {
  const apiURL= baseurl(`operacion`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(operacion)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', operacion);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putOperacionService = (operacion) => {
  const apiURL= baseurl(`operacion/${operacion.id_operacion}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(operacion)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteOperacionService = (id_operacion) => {
  const apiURL= baseurl(`operacion/${id_operacion}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Rutas--------------- 
export const getRutasService = (request) => {
  const apiURL = baseurl('rutas');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getRutaService = (Ruta) => {
  const apiURL= baseurl(`ruta/${Ruta.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postRutaService = (ruta) => {
  const apiURL= baseurl(`ruta`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(ruta)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', ruta);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putRutaService = (ruta) => {
  const apiURL= baseurl(`ruta/${ruta.id_ruta}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(ruta)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteRutaService = (id_ruta) => {
  const apiURL= baseurl(`ruta/${id_ruta}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Perfiles--------------- 
export const getPerfilesService = (request) => {
  const apiURL = baseurl('perfiles');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getPerfilService = (Perfil) => {
  const apiURL= baseurl(`perfil/${Perfil.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postPerfilService = (perfil) => {
  const apiURL= baseurl(`perfil`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(perfil)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', perfil);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putPerfilService = (perfil) => {
  const apiURL= baseurl(`perfil/${perfil.id_perfil}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(perfil)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deletePerfilService = (id_perfil) => {
  const apiURL= baseurl(`perfil/${id_perfil}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------RutasRol--------------- 
export const getRutasRolService = (request) => {
  const apiURL = baseurl('rutasrol');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getRutaRolService = (RutaRol) => {
  const apiURL= baseurl(`rutarol/${RutaRol.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postRutaRolService = (rutarol) => {
  const apiURL= baseurl(`rutarol`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(rutarol)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', rutarol);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putRutaRolService = (rutarol) => {
  const apiURL= baseurl(`rutarol/${rutarol.id_rutarol}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(rutarol)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteRutaRolService = (id_rutarol) => {
  const apiURL= baseurl(`rutarol/${id_rutarol}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------Roles--------------- 
export const getRolesService = (request) => {
  const apiURL = baseurl('roles');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getRolService = (Rol) => {
  const apiURL= baseurl(`rol/${Rol.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postRolService = (rol) => {
  const apiURL= baseurl(`rol`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(rol)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', rol);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putRolService = (rol) => {
  const apiURL= baseurl(`rol/${rol.id_rol}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(rol)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteRolService = (id_rol) => {
  const apiURL= baseurl(`rol/${id_rol}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
 

// -----------------------RolesPerfil--------------- 
export const getRolesPerfilService = (request) => {
  const apiURL = baseurl('rolesperfil');
  
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(request.user)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const getRolPerfilService = (RolPerfil) => {
  const apiURL= baseurl(`rolperfil/${RolPerfil.id}`);

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const postRolPerfilService = (rolperfil) => {
  const apiURL= baseurl(`rolperfil`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(rolperfil)
  };

  console.log("Authorization: ", getCookie('timeoff-token'));
  console.log('body', rolperfil);

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const putRolPerfilService = (rolperfil) => {
  const apiURL= baseurl(`rolperfil/${rolperfil.id_rolperfil}`);

  const parameters = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    },
    body: JSON.stringify(rolperfil)
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};
export const deleteRolPerfilService = (id_rolperfil) => {
  const apiURL= baseurl(`rolperfil/${id_rolperfil}`);

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

