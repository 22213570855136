export function baseurl(endpoint) {
    const local ='http://localhost:8080/';
    const biggenzi = 'https://biggenzi01.appspot.com/';
    const taffeit = 'https://biggenzi01.appspot.com/';

    var _api_url = "";
    const x = window.location.hostname

    if (x==="localhost") {
      _api_url =local;
    } 

    if (x==="agentes.taffeitseguros.com") {
      _api_url =taffeit;
    } 

    if (x==="bigseguros.appspot.com") {
      _api_url =biggenzi;
    } 


    //console.log('windows.location.hostname',x);
    return `${_api_url}${endpoint}`;
  }