import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';


export const getArchivoPolizaService = (Poliza) => {
    const apiURL= baseurl(`poliza/archivo/${Poliza.id}`);
  
    const parameters = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getCookie('timeoff-token')
      }
    };
  
    return fetch(apiURL, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        return error;
      });
  };