import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getCookie, setCookie } from '../../utils/cookies';
import { connect } from 'react-redux';

import { logoutAction } from '../../store/actions/auth/actions';

class LogoutComponent extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(logoutAction({id: getCookie('id'), access: getCookie('role')}));
  }

  render() {
    console.log('Entro a logout');
    setCookie('timeoff-token', getCookie('timeoff-token'), 0);
    setCookie('role', getCookie('role'), 0);
    setCookie('name', getCookie('name'), 0);
    setCookie('id', getCookie('id'), 0);
    
    console.log('Logout Tok', getCookie('timeoff-token'));

    if (getCookie('timeoff-token') === null || getCookie('timeoff-token') === '') {
      console.log('Redirect 1');
    return <Redirect to='/' />;
    }
    
    console.log('Redirect 2');
    return <Redirect to='/login' />
  }
}

export default connect()(LogoutComponent);
