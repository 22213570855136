import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    motivocancelacion: {
 id_motivocancelacion : 0, motivocancelacion : '', escancelacion : false,
      },

      lista_certificadopoliza_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_MOTIVOCANCELACION:
      item = state.motivocancelacion;
      action.fn(item);
      return { ...state,  motivocancelacion: item };


    case types.GET_MOTIVOCANCELACION_SUCCESS:
      respuesta= action.response;
      console.log('GET_MOTIVOCANCELACION_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', motivocancelacion: respuesta };

    case types.GET_MOTIVOCANCELACION_ERROR:
      respuesta= action.response;
      console.log('GET_MOTIVOCANCELACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_MOTIVOCANCELACION_SUCCESS:
      respuesta= action.response;
      console.log('POST_MOTIVOCANCELACION_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', motivocancelacion: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_MOTIVOCANCELACION_ERROR:
      respuesta= action.response;
      console.log('POST_MOTIVOCANCELACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_MOTIVOCANCELACION_SUCCESS:
      respuesta= action.response;
      console.log('PUT_MOTIVOCANCELACION_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', motivocancelacion: respuesta };

    case types.PUT_MOTIVOCANCELACION_ERROR:
      respuesta= action.response;
      console.log('PUT_MOTIVOCANCELACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_MOTIVOCANCELACION_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_MOTIVOCANCELACION_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_MOTIVOCANCELACION_ERROR:
      respuesta= action.response;
      console.log('DELETE_MOTIVOCANCELACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION:
      item = state.motivocancelacion;
      item.certificadospoliza= [...item.certificadospoliza, action.payload]
      return { ...state, certificadopoliza:item};


    case types.BORRA_CERTIFICADOPOLIZA_EN_MOTIVOCANCELACION:
      item = state.motivocancelacion;
      let lista_certificadopoliza_borrados= state.lista_certificadopoliza_borrados
      if (item.certificadospoliza[action.payload].id_certificadopoliza > 0){
            const elementocertificadopolizaborrar = { id_certificadopoliza : item.certificadospoliza[action.payload].id_certificadopoliza }
            lista_certificadopoliza_borrados= [...lista_certificadopoliza_borrados,  elementocertificadopolizaborrar]
      }
      item.certificadospoliza.splice(action.payload,1)
      return { ...state, certificadopoliza:item, lista_certificadopoliza_borrados : lista_certificadopoliza_borrados};


    default:
      return state;
  }
};

