import {  all } from 'redux-saga/effects';
import dbwatchers from './db/watchers';
import prueba from './prueba/watchers'
import authWatchers from './auth/watchers'
import procWatchers from './procesos/watchers'
import buscarWatchers from './buscar/watchers'
import conciliaWatchers from './conciliapagos/watchers'
import comisionesWatchers from './comisiones/watchers'
import archivopolizaWatchers from './archivopoliza/watchers'
import dashboardWatchers from './dashboards/watchers'
import cargapagosoxxoWatchers from './cargapagosoxxo/watchers'
import uploadWatchers from './uploadfile/watchers'
export default function* startForman() {

  
  yield all([
    dbwatchers(),
    authWatchers(),
    prueba(),
    buscarWatchers(),
    procWatchers(),
    conciliaWatchers(),
    comisionesWatchers(),
    archivopolizaWatchers(),
    dashboardWatchers(),
    cargapagosoxxoWatchers(),
    uploadWatchers(),
  ])
}