import { takeLeading } from 'redux-saga/effects';
import * as types from '../../actions/buscar/types';

import {
    getSBuscarSaga, 
    getSBuscarContratantesSaga } 
    from './buscarsaga';
export default function* watchBuscar() {

  yield takeLeading (types.GET_BUSCAR, getSBuscarSaga);
  yield takeLeading (types.GET_BUSCARCONTRATANTE, getSBuscarContratantesSaga);

}

