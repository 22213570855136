
import { baseurl } from '../baseurl';
import { getCookie } from '../../../utils/cookies';



export const postAbrirCertificadoPolizaService = (id) => {
  const apiURL= baseurl(`proceso/abrircertificadopoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};


export const postCerrarCertificadoPolizaService = (id) => {
  const apiURL= baseurl(`proceso/cerrarcertificadopoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};


export const postCancelarCertificadoPolizaService = (id, id_motivocancelacion) => {
  const apiURL= baseurl(`proceso/cancelarcertificadopoliza/${id}/${id_motivocancelacion}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const postActivarCertificadoPolizaService = (id) => {
  const apiURL= baseurl(`proceso/activarcertificadopoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};

export const postBorrarCertificadoPolizaService = (id) => {
  const apiURL= baseurl(`proceso/borrarcertificadopoliza/${id}`);

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCookie('timeoff-token')
    }
  };

  return fetch(apiURL, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return error;
    });
};