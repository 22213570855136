import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    operacion: {
 id_operacion : 0, operacion : '',
      },

      lista_actualizacion_borrados : [],
     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_OPERACION:
      item = state.operacion;
      action.fn(item);
      return { ...state,  operacion: item };


    case types.GET_OPERACION_SUCCESS:
      respuesta= action.response;
      console.log('GET_OPERACION_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', operacion: respuesta };

    case types.GET_OPERACION_ERROR:
      respuesta= action.response;
      console.log('GET_OPERACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_OPERACION_SUCCESS:
      respuesta= action.response;
      console.log('POST_OPERACION_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', operacion: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_OPERACION_ERROR:
      respuesta= action.response;
      console.log('POST_OPERACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_OPERACION_SUCCESS:
      respuesta= action.response;
      console.log('PUT_OPERACION_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', operacion: respuesta };

    case types.PUT_OPERACION_ERROR:
      respuesta= action.response;
      console.log('PUT_OPERACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_OPERACION_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_OPERACION_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_OPERACION_ERROR:
      respuesta= action.response;
      console.log('DELETE_OPERACION_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.INSERTA_ACTUALIZACION_EN_OPERACION:
      item = state.operacion;
      item.actualizaciones= [...item.actualizaciones, action.payload]
      return { ...state, actualizacion:item};


    case types.BORRA_ACTUALIZACION_EN_OPERACION:
      item = state.operacion;
      let lista_actualizacion_borrados= state.lista_actualizacion_borrados
      if (item.actualizaciones[action.payload].id_actualizacion > 0){
            const elementoactualizacionborrar = { id_actualizacion : item.actualizaciones[action.payload].id_actualizacion }
            lista_actualizacion_borrados= [...lista_actualizacion_borrados,  elementoactualizacionborrar]
      }
      item.actualizaciones.splice(action.payload,1)
      return { ...state, actualizacion:item, lista_actualizacion_borrados : lista_actualizacion_borrados};


    default:
      return state;
  }
};

