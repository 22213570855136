import * as types from '../../actions/comisiones/types';

const initialState = {
  cargado:false,
  paramsagentes: {
    agente:'',
  },
  paramsfactura: {
    fecha: '',
    id_mediopago:0,
  },
  agente:{},
  listaagentes:[],
  listagentefacturas:[],
  listagentedpspendientes:[],
  listafacturadps:[],
  facturaseleccionada:{},
  facturamodal:{
    show:false,
    fechadefault:'',
    facturaagente:{
      id_facturaagente:0,
      facturaagente:'',
      fechapago:''
      }
    },
  comisionespendientes:0,
};


const calcdiferencia = (facsel , lf) =>{
  var imp = 0
  
  for(var i=0; i<lf.length; ++i){
    console.log(lf);
    imp = imp + lf[i].totalcomision
  }
  facsel.importedps = imp
  facsel.diferencia = (facsel.subtotal + facsel.ajuste) - facsel.importedps
  return facsel

};

var calcest = (est) =>{
  var comisionespendientes = 0
  
  for(var i=0; i< est.listagentedpspendientes.length; ++i){
    comisionespendientes = comisionespendientes + est.listagentedpspendientes[i].totalcomision
  }
  est.comisionespendientes = comisionespendientes

};

export default function(state = initialState, action) {
  if (state.paramsfactura.fecha==='') {
    const dia = new Date().getDate();
    const mes = new Date().getMonth()+1;
    const anio = new Date().getFullYear();
    var messtr = ''
    var diastr = ''
    if (mes < 10) messtr = '0' + mes; else messtr = mes ;
    if (dia < 10) diastr = '0' + dia; else diastr = dia ;
    state.paramsfactura.fecha = anio + '-' + messtr + '-' + diastr;
    state.facturamodal.facturaagente.fechapago=anio + '-' + messtr + '-' + diastr;
    state.facturamodal.fechadefault=anio + '-' + messtr + '-' + diastr;
  }

  var estado = state

  //console.log('buscarReducer', action);
  switch(action.type) {

    case types.COMISIONES_FACTURA_CREAR:
      estado.facturamodal.show = true;
      state.facturamodal.facturaagente.fechapago = state.facturamodal.fechadefault;
      state.facturamodal.facturaagente.facturaagente = ''
      state.facturamodal.facturaagente.id_agente = state.agente.id_agente
      state.facturamodal.facturaagente.subtotal = 0
      state.facturamodal.facturaagente.iva = 0
      state.facturamodal.facturaagente.retiva = 0
      state.facturamodal.facturaagente.retisr = 0
      state.facturamodal.facturaagente.total = 0
      state.facturamodal.facturaagente.ajuste = 0
      state.facturamodal.facturaagente.porciva = state.agente.porciva
      state.facturamodal.facturaagente.porcretiva = state.agente.porcretiva
      state.facturamodal.facturaagente.porcretisr = state.agente.porcretisr

      return { ...estado };

    case types.COMISIONES_FACTURA_EDITAR:
        estado.facturamodal.show = true;
        state.facturamodal.facturaagente.id_facturaagente = state.facturaseleccionada.id_facturaagente;
        state.facturamodal.facturaagente.fechapago = state.facturaseleccionada.fechapago;
        state.facturamodal.facturaagente.facturaagente = state.facturaseleccionada.facturaagente
        state.facturamodal.facturaagente.id_agente = state.facturaseleccionada.id_agente
        state.facturamodal.facturaagente.subtotal = state.facturaseleccionada.subtotal
        state.facturamodal.facturaagente.iva = state.facturaseleccionada.iva 
        state.facturamodal.facturaagente.retiva = state.facturaseleccionada.retiva
        state.facturamodal.facturaagente.retisr = state.facturaseleccionada.retisr
        state.facturamodal.facturaagente.total = state.facturaseleccionada.total
        state.facturamodal.facturaagente.ajuste = state.facturaseleccionada.ajuste
        state.facturamodal.facturaagente.porciva = state.agente.porciva
        state.facturamodal.facturaagente.porcretiva = state.agente.porcretiva
        state.facturamodal.facturaagente.porcretisr = state.agente.porcretisr
  
        return { ...estado };
  
    case types.COMISIONES_FACTURA_CLOSE:
      estado.facturamodal.show = false;
      return { ...estado };


    case types.GET_COMISIONES_AGENTES_SUCCESS:
      const response1 = action.response;
      if (response1.listaagentes === undefined) {
        return {...state}
      }
      return { ...state, listaagentes: response1.listaagentes };
    case types.GET_COMISIONES_AGENTES_ERROR:
      return { ...state };


    case types.GET_COMISIONES_AGENTE:
      return { ...state, 
              agente: {}, 
              listagentefacturas: [],
              listafacturadps: [],
              facturaseleccionada:{},
              listagentedpspendientes:[],
              comisionespendientes:0,
            };

    case types.PUT_COMISIONES_FACTURAAGENTE:
        estado.facturaseleccionada={}
        return { ...estado };

    case types.DELETE_COMISIONES_FACTURAAGENTE:
        estado.facturaseleccionada={}
        return { ...estado };

    case types.GET_COMISIONES_AGENTE_SUCCESS:
        const responseagente = action.response;
        if (responseagente.agente === undefined) {
          return {...state}
        }
        return { ...state, agente: responseagente };

    case types.GET_COMISIONES_AGENTE_FACTURAS:
        return { ...state, listagentefacturas: [] };

    case types.GET_COMISIONES_AGENTE_FACTURAS_SUCCESS:
        const responsefa = action.response;
        if (responsefa.listafacturas === undefined) {
          return {...state}
        }

        return { ...state, facturaseleccionada:{}, listagentefacturas: responsefa.listafacturas };


    case types.GET_COMISIONES_FACTURA_DPS:
      
         let fs = state.listagentefacturas.filter(elem=> elem.id_facturaagente === action.data.id_facturaagente)
         estado.facturaseleccionada = fs[0]
         estado.facturaseleccionada.borrable = false
         estado.facturaseleccionada.editable = true
         for (let i = 0; i < state.listagentefacturas.length; i++) {
            if(estado.facturaseleccionada.id_facturaagente < state.listagentefacturas[i].id_facturaagente)
              estado.facturaseleccionada.editable = false
        }

          console.log('ENTRO ENTRO ENTRO', fs);
          return {...estado, facturaseleccionada:fs[0] , listafacturadps:[] }
      
    case types.GET_COMISIONES_FACTURA_DPS_SUCCESS:
      const responsefdps = action.response;
      if (responsefdps.listadpsfactura === undefined) {
        return {...estado}
      }
      if (responsefdps.listadpsfactura.length === 0) estado.facturaseleccionada.borrable = true;
      return { ...estado, listafacturadps: responsefdps.listadpsfactura };


    case types.GET_COMISIONES_AGENTE_DPSPENDIENTES:

      return {...estado,listagentedpspendientes:[]}



    case types.GET_COMISIONES_AGENTE_DPSPENDIENTES_SUCCESS:
        console.log('PENDIENTES', action.response);
        const responsedpp = action.response;
        if (responsedpp.listadpspendientes === undefined) {
          return {...estado}
        }
        estado = { ...estado, listagentedpspendientes: responsedpp.listadpspendientes }
        calcest(estado);
        return { ...estado}

    case types.POST_COMISIONES_ASIGNACION_SUCCESS:
      const response3 = action.response;
      console.log('************12341234', response3);
      if (response3.id_dpcertificadopoliza > 0 ) {
          let lpend = estado.listagentedpspendientes;
          let lpag = estado.listafacturadps;
          let item = lpend.filter(elem=> elem.id_dpcertificadopoliza === response3.id_dpcertificadopoliza)
          let items = lpend.filter(elem=> elem.id_dpcertificadopoliza !== response3.id_dpcertificadopoliza)
          let l2 = [...lpag, ...item]
          if (l2.length === 0) estado.facturaseleccionada.borrable = true 
          else  estado.facturaseleccionada.borrable = false;

          var fsel = calcdiferencia(estado.facturaseleccionada, l2)
          estado = { ...estado, listafacturadps: l2, listagentedpspendientes: items, facturaseleccionada: fsel };
          calcest(estado)
          return {...estado}; 
          //return { ...estado, listafacturadps: l2, listagentedpspendientes: items};

      }
      return { ...state };

    case types.DELETE_COMISIONES_ASIGNACION_SUCCESS:
      const response4 = action.response;
      console.log('******************', response4);
      if (response4.id_dpcertificadopoliza > 0 ) {
          let lpend = estado.listagentedpspendientes;
          let lpag = estado.listafacturadps;
          let item = lpag.filter(elem=> elem.id_dpcertificadopoliza === response4.id_dpcertificadopoliza)
          let items = lpag.filter(elem=> elem.id_dpcertificadopoliza !== response4.id_dpcertificadopoliza)
          let l2 = [...lpend, ...item]
          if (items.length === 0) estado.facturaseleccionada.borrable = true 
          else  estado.facturaseleccionada.borrable = false;
          var fsel2 = calcdiferencia(estado.facturaseleccionada, items)
          estado = { ...estado, listafacturadps: items, listagentedpspendientes: l2, facturaseleccionada: fsel2 }; 
          calcest(estado)
          return {...estado}; 

      }

      return { ...state };
    default:
      return state;
  }
};
