import * as types from '../../actions/db/types';
 
let respuesta={}
let item={}
const estadoinicial={
    cargado: false,
    grabado: false,
    borrado: false,
    message: '',
    aseguradoproducto: {
 id_aseguradoproducto : 0, id_producto : 0, id_parentezco : 0, edadminimaenregistro : 0, edadmaximaenregistro : 0, edadminimaenrenovacion : 0, edadmaximaenrenovacion : 0, cantidadminima : 0, cantidadmaxima : 0, indice : 0,
      },

     id: 0,
    accioneshijos:[]} ;

export default function(state = estadoinicial, action) {
    //console.log('REDUCER...', action, state);
  
  switch(action.type) {

    case types.EJECUTA_EN_ASEGURADOPRODUCTO:
      item = state.aseguradoproducto;
      action.fn(item);
      return { ...state,  aseguradoproducto: item };


    case types.GET_ASEGURADOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('GET_ASEGURADOPRODUCTO_SUCCESS', respuesta);
      return { ...state, cargado:true, message:'', aseguradoproducto: respuesta };

    case types.GET_ASEGURADOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('GET_ASEGURADOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    case types.POST_ASEGURADOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('POST_ASEGURADOPRODUCTO_SUCCESS', respuesta);
      if (respuesta.error === undefined)  return { ...state, grabado:true, message:'Grabado', aseguradoproducto: respuesta };
      else return { ...state, grabado:false, message: respuesta.error };
    case types.POST_ASEGURADOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('POST_ASEGURADOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.PUT_ASEGURADOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('PUT_ASEGURADOPRODUCTO_SUCCESS', respuesta);
      return { ...state, grabado:true, message:'Grabado', aseguradoproducto: respuesta };

    case types.PUT_ASEGURADOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('PUT_ASEGURADOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };


    case types.DELETE_ASEGURADOPRODUCTO_SUCCESS:
      respuesta= action.response;
      console.log('DELETE_ASEGURADOPRODUCTO_SUCCESS', respuesta);
      return { ...state, borrado:true, message:'Borrado'};

    case types.DELETE_ASEGURADOPRODUCTO_ERROR:
      respuesta= action.response;
      console.log('DELETE_ASEGURADOPRODUCTO_ERROR', respuesta);
      return { ...state, grabado:false, message: respuesta };

    default:
      return state;
  }
};

